import moment from 'moment';
import React from 'react'
import EditIcon from "../../../assets/images/edit.png";
import AddNotes from "../../../assets/images/AddNote.png";
import ViewNotes from "../../../assets/images/ViewNote.png";
import { Link, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ViewDate } from '../../../utils/DateSupport';

const ObservationListing = ({data, handleViewNote, handleShow, patientData, practionerData, setNewNoteData}) => {
  const navigate = useNavigate()
  return (
    <tr key={data?.id}>
      <td>{data?.observationName}</td>
      <td>{data?.performer?.display.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
      <td>{ViewDate(data?.createdAt)}</td>
      {!data?.note && (
        <td>
          <Tooltip title={"Add Note"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() => handleShow(data, false)}
              style={{cursor: "pointer"}}
            >
              <img src={AddNotes} alt="Add Note" style={{width : "28px"}}/>
            </Link>
          </Tooltip>
        </td>
      )}
      {data?.note?.length > 0 && (
          <td>
            <Tooltip title={"View Note"}>
              <Link
                to=""
                variant="primary"
                className="success-btn"
                onClick={() => {
                  setNewNoteData(null);
                  handleViewNote(data, false);
                }}
                style={{cursor: "pointer"}}
              >
                <img src={ViewNotes} alt="View Note" style={{width : "28px"}}/>
              </Link>
          </Tooltip>
          </td>
        )}
    </tr>
  );
}

export default ObservationListing