import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"
import { useSelector } from "react-redux";

export function usePersonCountsQuery({ onSuccess }) {
    const userOrgId = useSelector((state) => state?.auth?.user?.organizations);

    const fetchPersonCounts = () => {
        return axios.get(`/person/counts?orgId=${userOrgId?.[0]?.id}`);
    };
    const query = useQuery(
        "personcount",
        () => fetchPersonCounts(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
