import React, { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";
//import { Button } from "../../common/Button";
import EditIcon from "../../../assets/images/bluePencil.png";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { FullPageSpinner } from '../../common/Spinner/FullPageSpinner';
import QueueModal from "./QueueModal";
import Search from "./Search";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import "./Queue.css";
import { AttachementModal } from "./AttachementModal";
import { FilePresentOutlined, InfoOutlined, VideocamOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NoteModal from "./NoteModal";
import moment from 'moment';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ViewIcon from "../../../assets/images/view.png";
import { getSignature } from "../../Zoom/functions";
import { generatePass } from "../../apps/AppointmentRequests/Constants";
import { failed, success } from "../../common/Toastify";
import { webSocketEndpoint } from "../../../environment/index";

function QueueList() {
    const user = useSelector((state) => state?.auth?.user);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [modalShow, setModalShow] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.authentication;
    const practitionerId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [participantName, setParticipantName] = useState('');
    const [participantId, setParticipantId] = useState('');
    const [userScopeOfPractice, setUserScopeOfPractice] = useState("");
    const [initiateConnection, setInitiateConnection] = useState(false);
    const userOrganizationId = useSelector((state) => state?.auth?.user?.["organizations"][0].id);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [newRecordId, setNewRecordId] = useState(null);
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [selectedPatientName, setSelectedPatientName] = useState('');
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    useEffect(() => {
        setLoading(true);
        fetchPractitionerData().then(data => {
            setUserScopeOfPractice(data.data.scopeOfPractice);
            setParticipantId(data.data.id);
            setParticipantName(data.data?.name[0]?.text);
            setInitiateConnection(true);
        }).catch(error => {
            console.error("ERROR IN CATCH BLOCK", error);
        });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000); // Update every minute
        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        if (initiateConnection) {
            fetchData();
            console.log(webSocketEndpoint);
            const wsUrl = `wss://${webSocketEndpoint}?token=${token}&organizationId=${userOrganizationId}&scopeOfPractice=${JSON.stringify(userScopeOfPractice)}`
            const ws = new WebSocket(wsUrl);

            ws.onopen = () => {
                console.log("WebSocket connection opened successfully");
                setLoading(false);
            };
            ws.onmessage = (event) => {

                const newRecord = JSON.parse(event.data);

                switch (newRecord.messageType) {
                    case "INSERT":
                        setTableData((prevTableData) => {
                            const updatedTableData = [...prevTableData, newRecord];
                            updatedTableData.sort((a, b) => a.priority - b.priority);
                            setNewRecordId(newRecord.id);
                            setTimeout(() => {
                                setNewRecordId(null);
                            }, 500);

                            return updatedTableData;
                        });
                        break;

                    case "MODIFY":
                        
                        setTableData((prevTableData) => {
                            const existingIndex = prevTableData.findIndex((item) => item.subject.reference.split("/")[1] === newRecord.patientId);

                            if (existingIndex !== -1) {
                                const updatedTableData = [...prevTableData];
                                updatedTableData[existingIndex].priority = newRecord.priority;
                                updatedTableData.sort((a, b) => a.priority - b.priority);
                                return updatedTableData;
                            }
                            return prevTableData;
                        });
                        break;

                    case "REMOVE":
                        setTableData((prevTableData) => {
                            const existingIndex = prevTableData.findIndex((item) => item.subject.reference.split("/")[1] === newRecord.patientId);

                            if (existingIndex !== -1) {
                                const updatedTableData = [...prevTableData];
                                updatedTableData.splice(existingIndex, 1);
                                updatedTableData.sort((a, b) => a.priority - b.priority);
                                return updatedTableData;
                            }
                            return prevTableData;
                        });
                        break;

                    default:
                        break;
                }
            };

            ws.onclose = (event) => {
                console.log("WebSocket connection closed:", event);
            };

            ws.onerror = (error) => {
                console.error("WebSocket encountered an error:", error);
            };

            return () => {
                if (ws) {
                    ws.close();
                }
            };
        }
    }, [initiateConnection]);

    const [page, setPage] = useState(0);
    const rowsPerPage = 13;
    const totalRows = tableData.length;

    const fetchData = async () => {
        try {
            const encodedScopeOfPractice = encodeURIComponent(JSON.stringify(userScopeOfPractice))
            const response = await axios.get(`queue?scopeOfPractice=${encodedScopeOfPractice}`);
            const sortedData = response.result.sort((a, b) => {
                const priorityComparison = a.priority - b.priority;
                if (priorityComparison === 0) {
                    return new Date(a.period.start) - new Date(b.period.start);
                }
                return priorityComparison;
            })
            setTableData(sortedData);
            setFilterData(sortedData);

        } catch (error) {
            console.log("ERROR FETCHING DATA: ", error)
        }
    }

    const fetchPractitionerData = async () => {
        try {
            const response = await axios.get(`users?id=${practitionerId}&type=Practitioner&orgId=${adminOrgs?.[0]?.id}`);
            return response;
        } catch (error) {
            console.log("ERROR FETCHING DATA: ", error)
        }
    }

    const handlePriorityChange = async (editedItem, newPriority) => {
        setLoading(true);

        try {
            const response = await axios.put("queue", {
                "id": editedItem.id,
                "priority": newPriority
            });
            setLoading(false);
            success(`Priority updated for ${editedItem.subject.display} to ${newPriority}`);
            setSelectedPriority(null);
            return response;

        } catch (error) {
            console.log("Error changing priority: ", error);
        }
    }

    const handleLaunchClick = (data) => {
        
        setSelectedRecord(data);
        setModalShow(true);
    }

    const handleCloseModal = () => {
        setModalShow(false);
        setSelectedRecord({});
    }

    const handleFilterData = (data) => {
        setFilterData(data);
        page !== 0 && setPage(0);
    };

    const handleConnect = async (data) => {
        const statusCheckResponse = await axios.get(`encounter/${data.id}`);
        const currentStatus = statusCheckResponse.data.status;
        if (currentStatus !== 'arrived') {
            failed("Session already in progress by another practitioner");
            return;
        }
        await handleCreateSession(data);
        await updateStatus(data);
        await updateParticipant(data);
        try {
            const response = await axios.delete(`queue?id=${data.id}`);
            return response;
        } catch (error) {
            console.log("ERROR updating status: ", error);
        }
    }

    const handleStoreSessionToken = async (id, sessionToken) => {
        try {
            const response = await axios.put(`encounter/${id}`, {
                    "sessionToken": sessionToken
                });
        } catch (error) {
            console.log("ERROR storing token", error)
        }
    }


    const calculateWaitTime = (arrivalTimestamp) => {
        const currentTime = new Date();
        const currentTimestamp = currentTime.getTime();
        const waitTimeInMinutes = Math.floor((currentTimestamp - arrivalTimestamp) / (1000 * 60));
        const hours = Math.floor(waitTimeInMinutes / 60);
        const minutes = waitTimeInMinutes % 60;

        return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}`;
    };

    const updateStatus = async(data) => {
        try {
            const response = await axios.put(`encounter/${data.id}`, {
                "status": "in-progress-waitingroom",
                "actor": {
                    "reference": `Practitioner/${participantId}`,
                    "display": `${participantName}`,
                },
                "requestedPeriod": [{
                    "start": moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    "end": moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
                }]
            });

        } catch (error) {

        }
    }

    const updateParticipant = async (data) => {
        try {
            const response = await axios.put(`encounter/${data.id}`, {
                "participant": [{
                    "individual": {
                        "display": `${participantName}`,
                        "reference": `Practitioner/${participantId}`,
                        "type": "Practitioner"
                    },
                    "period": {
                        "start":moment.utc().format(),
                        "end":null
                    }                    
                }]
            });

        } catch (error) {
            console.log(error);
        }        
    }

    const generateSessionTokenForParticipant = async (data) => {
        setLoading(true);
        const encounterId = data.id;
        const sessionTopic = 'FonemedVirtualVisit' + Math.round((new Date().getTime())/1000);
        const password = generatePass();
        await getSignature({ topic: sessionTopic, role: 1, password: password })
            .then(async (res) => {
                await handleStoreSessionToken(encounterId, res?.data?.sessionToken);
                await navigate("/app/zoom-meeting-create-vv", { state: { encounterId, appointmentData: data, topic: sessionTopic, sessionToken: res?.data?.sessionToken, password: password, } });
            }).catch((error) => console.log("getsignature error", { error }))
            .finally(() => setLoading(false))
    }

    const handleShowFile = async (data) => {
        setShowAttachmentModal(true)
        setAttachmentUrl(data?.url)
    };

    const handleNoteClick = (note, patientName) => {
        setSelectedNote(note);
        setSelectedPatientName(patientName);
        setShowNoteModal(true);
    };

    const handleView = (data) => {
        const baseUrl = window.location.origin;
        const patientId = data?.subject?.reference.split("/")[1];
        const patientName = data?.subject?.display;
        const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
        window.open(url, "_blank");
    };

    const handleCreateSession = async (data) => {
        await generateSessionTokenForParticipant(data);
    }

    return (

        <section className="common-listing">
            {loading ? <FullPageSpinner /> : null}
            {showAttachmentModal ? <AttachementModal modalShow={showAttachmentModal} url={attachmentUrl} handleShow={() => setShowAttachmentModal(false)} /> : null}

            <div className="heading-wrap h-change">

                <Search list={tableData} filterData={handleFilterData} />
            </div>
            {tableData && (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', border: '1px solid #ddd' }}>
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px', width: '10%' }}>Priority</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Patient Name</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Patient Location</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Reason</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Arrival Time</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Wait Time</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Notes</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '10px', textAlign: 'center', marginRight: '10px' }}>Attachments</th>
                            <th style={{ backgroundColor: '#f0f5fa', padding: '17px', textAlign: 'center', marginRight: '10px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                            //const formattedArrivalTime = new Date(item.period.start).toISOString().replace(/T/, ' ').slice(0, 16);
                            const formattedArrivalTime = new Intl.DateTimeFormat('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }).format(new Date(item.createdAt));

                            const waitTime = calculateWaitTime(item.createdAt);

                            return (
                                <tr key={index} className={`record-row ${newRecordId === item.id ? 'new-record' : ''}`} style={{ borderBottom: '1px solid #ddd' }}>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>
                                        <div onBlur={() => setEditingItem(null)}>
                                            <Select
                                                autoFocus  // Automatically focus on the Select component for immediate interaction
                                                value={
                                                    selectedPriority !== null
                                                        ? { label: selectedPriority, value: selectedPriority }
                                                        : item.priority
                                                            ? { label: item.priority, value: item.priority }
                                                            : null
                                                }
                                                options={[1, 2, 3, 4, 5].map((value) => ({ label: value, value }))}
                                                onChange={(selectedOption) => {
                                                    setSelectedPriority(selectedOption ? selectedOption.value : null);
                                                    handlePriorityChange(item, selectedOption ? selectedOption.value : null);
                                                    setEditingItem(null);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px', textTransform: "capitalize" }}>{item.subject.display}</td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>{item.patientLocation}</td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>{item.reason || "-"}</td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>{formattedArrivalTime}</td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>{waitTime} m</td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>
                                        {item.note ? (
                                            <Tooltip title={"View Note"}>
                                                <div onClick={() => handleNoteClick(item.note, item.subject.display)}>
                                                    <Link variant="primary" className="view-btn">
                                                        <InfoOutlined />
                                                    </Link>
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <div>-</div>
                                        )}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px' }}>
                                        {item?.document?.length > 0 &&
                                            item?.document.map((pdfUrl, index) => (
                                                <Tooltip key={index} title={"View"}>
                                                    <Link
                                                        onClick={() => handleShowFile(pdfUrl)}
                                                        variant="primary"
                                                        className="view-btn"
                                                    >
                                                        <FilePresentOutlined />
                                                    </Link>
                                                </Tooltip>
                                            ))}
                                        {item?.document?.url && (
                                            <Tooltip title={"View"}>
                                                <Link
                                                    onClick={() => handleShowFile(item?.document?.url)}
                                                    variant="primary"
                                                    className="view-btn"
                                                >
                                                    <FilePresentOutlined />
                                                </Link>
                                            </Tooltip>
                                        )}
                                        {!item?.document?.length > 0 &&
                                            !item?.document?.url && <div>-</div>}
                                    </td>






                                    <td style={{ padding: '8px', textAlign: 'center', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Tooltip title="View Patient Dashboard">
                                            <div className="view-btn" onClick={() => handleView(item)} style={{ cursor: 'pointer', backgroundColor: '#005da8', padding: '5px', borderRadius: '50%', width: '34px', height: '34px', marginLeft: '12px' }}>
                                                <img src={ViewIcon} alt="View" />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Launch">
                                            <div className="view-btn" style={{ backgroundColor: '#06b155', padding: '4px', borderRadius: '50%', width: '34px', height: '34px', marginLeft: '10px' }}>
                                                <Link to="" variant="primary" onClick={() => handleLaunchClick(item)} className="success-btn" >
                                                    <VideocamOutlined style={{ color: "white", }} />
                                                </Link>
                                            </div>
                                        </Tooltip>


                                        {/*<Button style={{ display: "flex", alignItems: "center", marginLeft: 'auto', marginRight: 'auto' }} onClick={() => handleLaunchClick(item)}>
                                            Launch
                                        </Button>*/}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
            <br />
            {filterData?.length > 0
                ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page}
                    handlePage={(pageNo) => setPage(pageNo)} />
                : null}

            {modalShow && <QueueModal show={modalShow} onHide={handleCloseModal} record={selectedRecord} handleConnect={handleConnect} />}
            {showNoteModal && <NoteModal show={showNoteModal} onHide={() => setShowNoteModal(false)} note={selectedNote} patientName={selectedPatientName} />}

        </section>
    );
}

export default QueueList;
