import React, { useEffect, useState, useRef } from "react";
import "../Styles/dashboard.css";
import UsersIcon from '../../../assets/images/users.png';
import AppointmentIcon from '../../../assets/images/appointmentsDashboard.png'
import PatientIcon from '../../../assets/images/patients.png';
import DoctorIcon from '../../../assets/images/doctor.png';
import SessionIcon from '../../../assets/images/session.png';
import QueueIcon from '../../../assets/images/fluent--people-queue-20-regular.svg'
import NotificationIcon from '../../../assets/images/dashNotification.png';
import DocumentIcon from '../../../assets/images/document.png';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation, } from "react-router-dom";
import { getSingleUser } from "../../api/Individual";
import { PractitonerHeadCells, PractitonerDataFields, PatientHeadCells, PatientDataFields } from "./Constant";
import CustomTable from "../../common/table/CustomTable";
import { failed, success } from "../../common/Toastify";
import DashboardCard from "./dashboard-card";
import { usePersonCountsQuery } from "../../../hooks/ReactQueryHooks/usePersonCountsQuery";
import { usePatientDiseaseListQuery } from "../../../hooks/ReactQueryHooks/usePatientDiseaseListQuery";
import AppointmentsList from "../../apps/AppointmentRequests/AppointmentsList";
import UserNotificationsList from "../Notifications/UserNotificationsList";
import PatientsAssigned from "../../apps/Patient/patients-assigned";
import { useSelector } from "react-redux";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { Button } from "../../common/Button";
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { useNavigate } from "react-router-dom";
import InBoundCall from "./InBoundCall";
import { createEncounterInbound } from "../../apps/api/AppointmentRequests";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import moment from "moment";
import DocumentDashboard from "../DocumentDashboard/DocumentDashboard";
import { getOrganization } from "../../api/Organization";
import { getDocumentDashboardCount , getDocumentDashboard } from "../../api/FillableDocument.js";
import DashboardEncounterList from "../../apps/Encounters/DashboardEncounterList";

function Dashboard() {
    const [practitionersList, setPractitionersList] = useState([]);
    const [personCounts, setPersonCounts] = useState([]);
    const [patientsList, setPatientsList] = useState([]);
    const [appointmentCount, setAppointmentCount] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);
    const [inBoundCallModal, showInBoundCallModal] = useState(false);
    const [isWaitingRoom, setIsWaitingRoom] = useState(null)
    const navigate = useNavigate();
    const patientsAssignedRef = useRef(null);
    const upcomingAppointmentsRef = useRef(null);
    const notificationsRef = useRef(null);
    const practitionersRef = useRef(null);
    const encountersRef = useRef(null);
    const patientsRef = useRef(null);
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState(() => location?.state?.activeTab ?  false : permissions?.includes("Appointment") ? "appointments" : "practitioners");
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const practitioner = userRoles?.includes('Practitioner')
    const { data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isRPM_VirtualCareEnable = orgLicensedProducts?.virtualCare || orgLicensedProducts?.RPM
    const is_VirtualCareEnabled = orgLicensedProducts?.virtualCare
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const [isInboundLoad, setIsInboundLoad] = useState(false)
    const [encounterId, setEncounterId] = useState('')
    const [encounteredData, setEncounterData] = useState({})
    const [documentCount, setDocumentCount] = useState(0)
    const user = useSelector((state) => state?.auth?.user);
    const [practitionerData, setPractitionerData] = useState(null)

    const scrollToComponent = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const onSuccessPersonCounts = (data) => {
        setPersonCounts(data)
    }
    const { isLoading } = usePersonCountsQuery({ onSuccess: onSuccessPersonCounts });

    const onSuccessPatientDiseaseList = (data) => {
        setPatientsList(data)
    }
    const { isLoading: isPatientListLoading } = usePatientDiseaseListQuery({ onSuccess: onSuccessPatientDiseaseList });

    useEffect(() => {
        //Api for practitioners List 
        getSingleUser({ type: "Practitioner", userOrgId: adminOrgs?.[0]?.id })
            .then((res) => {
                setPractitionersList(res?.data);
            })
            .catch((res) => {
                failed(
                    res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                );
            });


            getOrganization(adminOrgs?.[0]?.id)
              .then((res) => {
                setIsWaitingRoom(res?.data?.isWaitingRoom)
              })
              .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                );
              });

              getSingleUser({ id : user["custom:unique_id"] , type : "Practitioner", userOrgId: adminOrgs?.[0]?.id})
              .then((res) => {
                setPractitionerData(res?.data)
              })
              .catch((res) => {
                console.log("error fetching practioner data",res)
              }) 
    }, []);

    const handleInboundModal = ()=>{
        let encounterPayload = {
            orgId : adminOrgs?.[0]?.id,
            requestedPeriod : {start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: null},
            practitionerId : practitionerData?.link?.[0]?.target?.reference?.split("/")?.[1],
            practitionerName : practitionerData?.name?.[0]?.text,
            status : "in-progress-intake",
            statusHistory:[{
                status:"in-progress-intake",
                actor: {
                    reference: practitionerData?.link?.[0]?.target?.reference,
                    display: practitionerData?.name?.[0]?.text,
                },
                period: {
                    start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    end: null
                }
            }] 
        }
        setIsInboundLoad(true)
        createEncounterInbound(encounterPayload).then((res)=>{
            if(res.status === true){
              setEncounterData(res.data)              
              setEncounterId(res.data.id)
              setIsInboundLoad(false)
              showInBoundCallModal(true)
            }else{
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
              setIsInboundLoad(false)
              showInBoundCallModal(false)
            }
        }).catch((res) => {
            showInBoundCallModal(false)
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
        }).finally(() => setIsInboundLoad(false));
    }

    const noOfRowsToShow = patientsList?.length > practitionersList?.length ? practitionersList.length : patientsList?.length;

    // Display first character of patient's first and last name in capital letter.
    if(patientsList) {
        patientsList?.map((patient) => {
            const modifiedName = patient?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            patient.name[0].text = modifiedName;
        });
    }
    // Display first character of practitioner's first and last name in capital letter.
    if(practitionersList) {
        practitionersList?.map((practitioner) => {
            const modifiedPracName = practitioner?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            practitioner.name[0].text = modifiedPracName;
        });
    }

    async function fetchAssignedList() {
        try {
            let documentCountList = await getDocumentDashboard({ status: "assigned", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id }) 
          //  getDocumentDashboard({ status: "assigned", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id })           
            setDocumentCount((documentCountList?.data).length);      
                       
        } catch (error) {
            console.error('Error fetching Assignedlist:', error);           
        }
    }   
    useEffect(() => {
        fetchAssignedList();
    }, [])

    return (
        <>
            {inBoundCallModal && <InBoundCall encounteredData={encounteredData}  encounterId = {encounterId} modalShow={inBoundCallModal} handleShow={()=> showInBoundCallModal(false)}/>}
            {isInboundLoad && <FullPageSpinner/>}
            <section className="common-listing">
                <div className="four-cards">
                    <Row className="g-3">
                        {admin &&
                            <>
                                <Col sm={4} md="auto" onClick={() => setSelectedTab("users")}>
                                    <DashboardCard
                                        title={"Total Users"}
                                        count={personCounts?.users?.users}
                                        icon={UsersIcon}
                                        percentage={parseInt(personCounts?.users?.percentage || 0)}
                                        loading={isLoading}
                                        selected={selectedTab==="users"}
                                    />
                                </Col>

                                <Col sm={4} md="auto" onClick={() => setSelectedTab("patients")}>
                                    <DashboardCard
                                        title={"Patients"}
                                        count={personCounts?.patients?.patients}
                                        icon={PatientIcon}
                                        percentage={parseInt(personCounts?.patients?.percentage || 0)}
                                        loading={isLoading}
                                        selected={selectedTab==="patients"}
                                    />
                                </Col>

                                <Col sm={4} md="auto" onClick={() => setSelectedTab("practitioners")}>
                                    <DashboardCard
                                        title={"Practitioners"}
                                        count={personCounts?.practitioners?.practitioners}
                                        icon={DoctorIcon}
                                        percentage={parseInt(personCounts?.practitioners?.percentage || 0)}
                                        loading={isLoading}
                                        selected={selectedTab==="practitioners"}
                                    />
                                </Col>

                                <Col sm={4} md="auto" onClick={() => setSelectedTab("sessions")}>
                                    <DashboardCard
                                        title={"Total Sessions"}
                                        count={personCounts?.sessions?.sessions}
                                        icon={SessionIcon}
                                        percentage={parseInt(personCounts?.sessions?.percentage || 0)}
                                        loading={isLoading}
                                        selected={selectedTab==="sessions"}
                                    />
                                </Col>
                            </>
                        }

                        {isRPM_VirtualCareEnable && permissions?.includes("Appointment") && <Col sm={4} md="auto" onClick={() => {setSelectedTab("appointments"); navigate(location?.pathname, {state : {}, replace: true})}} >
                            <DashboardCard
                                title={"Appointments"}
                                count={appointmentCount}
                                icon={AppointmentIcon}
                                percentage={parseInt(personCounts?.practitioners?.percentage)}
                                loading={isLoading}
                                selected={selectedTab==="appointments"}
                            />
                        </Col>}

                        {<Col sm={4} md="auto" onClick={() => {setSelectedTab("encounters"); navigate(location?.pathname, {state : {}, replace: true})}} >
                            <DashboardCard
                                title={"Encounters"}
                                icon={UsersIcon}
                                percentage={parseInt(personCounts?.practitioners?.percentage)}
                                loading={isLoading}
                                selected={selectedTab==="encounters"}
                            />
                        </Col>}

                        <Col sm={4} md="auto" onClick={() => {setSelectedTab("patients"); navigate(location?.pathname, {state : {}, replace: true})}}>
                            <DashboardCard
                                title={"Patients"}
                                icon={PatientIcon}
                                percentage={parseInt(personCounts?.patients?.percentage)}
                                loading={isLoading}
                                selected={selectedTab==="patients"}
                            />
                        </Col>

                        {practitioner && <Col sm={4} md="auto" onClick={() =>{ setSelectedTab("notifications"); navigate(location?.pathname, {state : {}, replace: true})}}>
                            <DashboardCard
                                title={"Notifications"}
                                count={notificationCount}
                                icon={NotificationIcon}
                                percentage={parseInt(personCounts?.sessions?.percentage)}
                                loading={isLoading}
                                selected={selectedTab==="notifications"}
                            />
                        </Col>}

                        {practitioner && is_VirtualCareEnabled && <Col sm={4} md="auto" onClick={() => {setSelectedTab("documents"); navigate(location?.pathname, {state : {}, replace: true})}}>
                                <DashboardCard
                                    title={"Documents"}
                                    count={documentCount}
                                    icon={DocumentIcon}
                                    loading={isLoading}
                                    selected={location?.state?.activeTab ? location?.state?.activeTab : selectedTab==="documents"}
                                />
                            </Col>
                        }

                        <Col className="dashHeaderBtns" >
                            {!admin &&  orgLicensedProducts?.virtualCare === true && <Button onClick={handleInboundModal} title="New Encounter"><AddIcCallOutlinedIcon/> <span className="dashBtnTxt">New Encounter</span></Button>}
                            {!admin ? isWaitingRoom && <Button onClick={() => navigate('/app/waiting-room')} style={{marginLeft:"5px"}} title="View Queue"><img style={{width:"24px", height:"24px"}}src={QueueIcon} /><span className="dashBtnTxt">Queue</span> </Button> : null}
                            {!admin && <Button onClick={() => navigate('/app/schedule')} style={{marginLeft:"5px"}} title="View Schedule"><ScheduleOutlinedIcon/> <span className="dashBtnTxt">Schedule</span></Button>}
                        </Col>
                    </Row>
                </div>

                <Row>
                    {practitioner  && <Col style={{ display: selectedTab === "notifications" ? 'block' : 'none' }}>
                        <div ref={notificationsRef} className="dash-wrap">
                            <UserNotificationsList dashboard={true} setCount={setNotificationCount} />
                        </div>
                    </Col>}

                    {selectedTab === "encounters" && (
                        <Col style={{ display: selectedTab === "encounters" ? 'block' : 'none' }}>
                            <div ref={encountersRef} className="dash-wrap">
                                <DashboardEncounterList />
                            </div>
                        </Col>
                        )}

                    {isRPM_VirtualCareEnable && permissions?.includes("Appointment") && <Col style={{ display: selectedTab==="appointments"  ? 'block' : 'none' }}>
                        <div ref={upcomingAppointmentsRef} className="dash-wrap">
                            <AppointmentsList dashboard={true} setCount={setAppointmentCount} />
                        </div>
                    </Col>}

                    {isRPM_VirtualCareEnable && selectedTab==="patients" && permissions?.includes('Patients Assigned') && <><Col>
                        <div ref={patientsAssignedRef} className="dash-wrap">
                            <PatientsAssigned dashboard={true} />
                        </div>
                    </Col></>}

                    {practitioner && is_VirtualCareEnabled && (selectedTab==="documents" || location?.state?.activeTab) && <><Col>
                        <div ref={patientsAssignedRef} className="dash-wrap">
                            <DocumentDashboard dashboard={true}/>
                        </div>
                    </Col></>}

                    {admin && <>
                        {selectedTab==="practitioners" && <Col>
                            <section ref={practitionersRef} className="dash-wrap dashboard">
                                <div className="heading-wrap h-change">
                                    <h1>Practitioners</h1>
                                    <div className="right-wrap">
                                        <Link
                                            className="view-all"
                                            title="View All"
                                            to={"/app/manage-individuals?type=Practitioner"}
                                        >
                                            View All
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <CustomTable
                                        tableData={practitionersList}
                                        headCells={PractitonerHeadCells}
                                        dataFields={PractitonerDataFields}
                                        loading={isLoading}
                                        rowOptions={false}
                                        canView={false}
                                        canDelete={false}
                                        canEdit={false}
                                        page={0}
                                        rowsPerPage={noOfRowsToShow > 5 ? 5 : noOfRowsToShow}
                                        hidePagination={true}
                                    />
                                </div>
                            </section>
                        </Col>}
                        {selectedTab==="patients" && <Col>
                            <section ref={patientsRef} className="dash-wrap dashboard">
                                <div className="heading-wrap h-change">
                                    <h1>Patients</h1>
                                    <div className="right-wrap">
                                        <Link
                                            className="view-all"
                                            title="View All"
                                            to={"/app/manage-individuals?type=Patient"}
                                        >
                                            View All
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <CustomTable
                                        tableData={patientsList}
                                        headCells={PatientHeadCells}
                                        dataFields={PatientDataFields}
                                        loading={isPatientListLoading}
                                        rowOptions={false}
                                        canView={false}
                                        canDelete={false}
                                        canEdit={false}
                                        page={0}
                                        rowsPerPage={noOfRowsToShow > 5 ? 5 : noOfRowsToShow}
                                        hidePagination={true}
                                    />
                                </div>
                            </section>
                        </Col>}

                       
                    </>}
                </Row>

            </section>
        </>
    );
}

export default Dashboard;