/**
 * buildSessionTopic
 * generates a session topic for zoom sessions. They should be unique or as close to as possible.
 * 
 *
 * @param description as string 
 * @param suffix as string (defaults to "fonemed")
 * @param timestamp as boolean to indicate if adding a timestamp to the second is required.
 * @returns well formated session string
 *  
 */
export function buildSessionTopic(description, suffix = "fonemed", timestamp = true) {
    const maxlength = 36;
    let datetimevalue = Math.round(new Date().getTime()/1000).toString();
    
    // strip whitespace
    let sessionTopic = description.toLowerCase().replace(" ", "");
    
    // build suffix
    let fullsuffix = suffix + ((timestamp) ? datetimevalue : "");

    if(fullsuffix.length > maxlength && sessionTopic.length > maxlength) // only use sessionTopic and truncate
        return removeAccents(sessionTopic.substring(0,maxlength));
    else if((sessionTopic.length + fullsuffix.length) > maxlength) // substring sessiontopic and add suffix
        return removeAccents(sessionTopic.substring(0, maxlength - fullsuffix.length) + fullsuffix)
    else // fit length so just send it
        return removeAccents(sessionTopic + fullsuffix);
}
/**
 * removeAccents
 * takes accented string and normalizes it to non-accented characters, so é becomes e etc...
 * @param {string} str 
 * @returns string
 */
export const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')