import { useFormik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { priorityReason } from "../../apps/Queue/Constants";
import Select from "react-select";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getPractitionerList, getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import moment from "moment";
import { useSelector } from "react-redux";
import { editEncounter, getEncounterRecord } from "../../apps/api/AppointmentRequests";
import { useNavigate } from "react-router-dom";

const AddToWaitingRoomModal = ({ data, onShow, onHide, handleShow }) => {
  const navigate = useNavigate();
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };
  const priorityReasonData = priorityReason;
  const [myUserId, setMyUserId] = useState("");
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );
  const practitionerId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null);
  const [encounterData, setEncounterData] = useState(null);
  const [selectedCommunicationChannel, setSelectedCommunicationChannel] =
    useState({
      value: "phone",
      label: "Phone",
      data: "phone",
    });
  const priorityReasonDataOptions = priorityReasonData.map((option) => ({
    value: option?.reason,
    label: option?.reason,
    data: option,
  }));
  const priorityLevelOptions = [1, 2, 3, 4, 5].map((option) => ({
    value: option,
    label: option,
    data: option,
  }));

  const communicationChannelOptions = ["Phone"].map((option) => ({
    value: option,
    label: option,
    data: option,
  }));

  const [selectedPriorityReason, setSelectedPriorityReason] = useState(null);

  const [practitionerList, setPractitionerList] = useState([]);

  const practitionerOptions = practitionerList.map((practitioner) => ({
    value: practitioner.name?.[0]?.text,
    label: capitalizeWords(practitioner.name?.[0]?.text),
    data: practitioner,
  }));

  useEffect(() => {
    getSingleUser({ id: practitionerId, type: "Practitioner" })
      .then((res) => {
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    getEncounterRecord({ encounterId: data?.encounterId })
      .then((res) => {
        if (res?.status === true) {
          setEncounterData(res?.data);
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      );
  }, []);

  const handlePriorityReasonChange = (selectedOption) => {
    setSelectedPriorityReason(selectedOption);
    setSelectedPriorityLevel({
      value: selectedOption?.data?.defaultPriority,
      label: selectedOption?.data?.defaultPriority,
      data: selectedOption?.data?.defaultPriority,
    });
    formik.setFieldValue("priorityReason", selectedOption?.value);
    if (selectedOption?.data) {
      const defaultPriority = selectedOption.data.defaultPriority;
      formik.setFieldValue("priorityLevel", defaultPriority);
    } else {
      formik.setFieldValue("priorityLevel", null);
    }
  };

  const handlePriorityLevelChange = (selectedOption) => {
    setSelectedPriorityLevel(selectedOption);
    formik.setFieldValue("priorityLevel", selectedPriorityLevel?.value);
  };

  const handlePractitionerChange = (selectedOption) => {
    setSelectedPractitioner(selectedOption);
    formik.setFieldValue("assignedPractitioner", selectedOption?.data);
  };

  const handleCommunicationChannelChange = (selectedOption) => {
    setSelectedCommunicationChannel(selectedOption);
    formik.setFieldValue("communicationChannel", selectedOption.value);
  };

  const formik = useFormik({
    initialValues: {
      priorityReason: "",
      priorityLevel: null,
      communicationChannel: selectedCommunicationChannel?.value,
      assignedPractitioner: null,
    },
    validationSchema: Yup.object({
      communicationChannel: Yup.string().required("Required field."),
    }),
    onSubmit: (values) => {
      const utcString = new Date().toISOString().split(".")[0] + ".00Z";
      if (values.priorityReason.length === 0) {
        failed("Please select priority reason to proceed");
      } else {
        setIsLoading(true);

        let newEncounterPayload = data;
        delete newEncounterPayload?.participant;
        const updatedQuestionnaireInfo = {
          ...newEncounterPayload?.questionnairInfo,
          intakeCommunicationChannel: values?.communicationChannel,
          intakePriorityReason: values?.priorityReason,
          intakePriorityLevel: values?.priorityLevel,
          intakeAssignedPractitioner: values?.assignedPractitioner
            ? {
                individual: {
                  display: values?.assignedPractitioner?.name[0]?.text,
                  reference: `Practitioner/${values?.assignedPractitioner?.id}`,
                  type: "Practitioner",
                },
                period: {
                  start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                  end: null,
                },
              }
            : null,
        };

        const updatedParticipant = [
          {
            individual: {
              display: practUserName,
              reference: `Practitioner/${myUserId}`,
              type: "Practitioner",
            },
            period: {
              start: newEncounterPayload?.period?.start,
              end: null,
            },
          },
          {
            individual: {
              display: newEncounterPayload?.patient?.display,
              reference: newEncounterPayload?.patient?.reference,
              type: "Patient",
            },
            period: {
              end: null,
              start: utcString,
            },
          },
        ];


        const updatedPeriod = [
          {
            start: utcString,
            end: utcString,
          },
        ];

        const updatedEncounterPayload = {
          ...newEncounterPayload,
          questionnairInfo: updatedQuestionnaireInfo,
          status: "arrived",
          participant: updatedParticipant,
          requestedPeriod: updatedPeriod,
          encounterClass: "NULL"
        };

        editEncounter(updatedEncounterPayload)
          .then((resEncounter) => {
            if (resEncounter.status === true) {
              success("Patient successfully added to Waiting Room");
              //navigate("/app/waiting-room");
              onHide();
              handleShow();
            }
          })
          .catch((res) =>
            failed(
              res?.response?.data?.message ||
                res?.response?.data?.error ||
                res.message
            )
          )
          .finally(() => setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    getPractitionerList(data?.orgId, data?.patientLocation)
      .then((res) => {
        setPractitionerList(res?.result);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Patient To Waiting Room
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <>
            <Row>
              <Col>
                <span>Priority Reason:</span>
                <Select
                  onChange={handlePriorityReasonChange}
                  isMulti={false}
                  name="priorityReason"
                  options={priorityReasonDataOptions}
                  className="search-select"
                  classNamePrefix="select"
                  value={selectedPriorityReason?.reason}
                  isClearable={false}
                  isLoading={priorityReasonData?.length > 0 ? false : true}
                />
              </Col>
              <Col>
                <span>Priority Level (Optional):</span>
                <Select
                  onChange={handlePriorityLevelChange}
                  isMulti={false}
                  name="priorityLevel"
                  options={priorityLevelOptions}
                  className="search-select"
                  classNamePrefix="select"
                  value={selectedPriorityLevel}
                  defaultValue={"1"}
                  isClearable={false}
                  isLoading={false}
                />
              </Col>
            </Row>
            <br />
            <br />

            <Row>
              <Col>
                <span>Assign Practitioner (Optional):</span>
                <Select
                  onChange={handlePractitionerChange}
                  isMulti={false}
                  name="assignedPractitioner"
                  options={practitionerOptions}
                  className="search-select"
                  classNamePrefix="select"
                  value={selectedPractitioner}
                  isClearable={false}
                  isLoading={practitionerList?.length > 0 ? false : true}
                />
              </Col>

              <Col>
                <span>Communication Channel:</span>
                <Select
                  onChange={handleCommunicationChannelChange}
                  isMulti={false}
                  name="communicationChannel"
                  options={communicationChannelOptions}
                  className="search-select"
                  classNamePrefix="select"
                  value={selectedCommunicationChannel}
                  isClearable={false}
                  isLoading={
                    communicationChannelOptions?.length > 0 ? false : true
                  }
                />
              </Col>
            </Row>

            <div className="btn-wrap">
              <Button
                onClick={() => {
                  onHide();
                }}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </div>
          </>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddToWaitingRoomModal;
