import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Button } from '../common/Button'
import { Accordion, AccordionDetails, AccordionSummary, Typography, Tooltip } from '@mui/material'
import { ExpandMore, Launch } from '@mui/icons-material'
import TextEditor from '../common/textfield/TextEditor'
import { QuestionnaireFormikObj } from './constant'
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik'
import SelectFiled from '../common/textfield/SelectFiled'
import Fields from '../Admin/Dashboard/Fields'; 
import { createQuestionnaireResponse, editQuestionnaireResponse } from "../api/Questionnaire";
import { failed, success } from "../common/Toastify";
import { useQuestionnaireQuery } from '../../hooks/ReactQueryHooks/useQuestionnaireQuery';
import { getOrganization } from '../api/Organization';
import { useSelector } from "react-redux";
import { getVideoVisitQuestionnaire } from '../api/Questionnaire'
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'

const QuestionnaireBox = forwardRef((props, ref) =>{
    const [btnLoading, setBtnLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const orgId = adminOrgs?.[0]?.id;
    const [getCareVisitId, setGetCareVisitId] = useState([]);

    const [textEditorKey, setTextEditorKey] = useState(0);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(questionnaireList.length && questionnaireList[0]?.id || '');
    const [submitStatus, setSubmitStatus] = useState(false);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getOrganization(adminOrgs?.[0]?.id);
                const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
                const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
                if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                    const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), orgId)
                    const modifiedData = VVRes?.data?.map(item => ({
                        ...item,
                        name: item.title,
                        value: item.id
                    }));
                    setQuestionnaireList(modifiedData);
                }
                
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        };
        fetchData();
        // const intervalId = setInterval(fetchData, 1000);
        // return () => clearInterval(intervalId);
    }, [adminOrgs?.[0]?.id]);  

    const findQrId = (id) => {
        let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId"))
        questionResponseId = questionResponseId?.[id] || null
        if (questionResponseId){
            return questionResponseId
        }
        return false;
    }
    const formik = useFormik({
        ...QuestionnaireFormikObj,
        onSubmit: (values, { setErrors, setFieldValue }) => {
            if(!values.questionResponse){
                failed("Select and save questionnaire before proceeding");
                return;
            } 
            if (values?.questionResponse.length === 0 || values.questionResponse === undefined ) {
                failed("Save questionnaire before proceeding");
                return;
            }
            for (let i = 0; i < values?.questionResponse.length; i++) {
                const item = values?.questionResponse[i];
                if (item.required && !isAnswered(item)) {
                    setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                    return;
                }
            }
            setBtnLoading(true);
            setIsDisabled(false);
            let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
            let prevQuestionnaireResponse =JSON.parse(localStorage.getItem("questionnaireResponse")) || {}
            let QRid = values.questionResponseId  || questionResponseId?.[selectedQuestionnaire];
            if(prevQuestionnaireResponse?.[selectedQuestionnaire]){
                editQuestionnaireResponse(QRid, { patientID: props?.patientId, encounterId: props?.encounterId, questionResponse: values?.questionResponse, appointmentId: props?.appointmentId , questionnaire: selectedQuestionnaire})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                        questionResponseId[selectedQuestionnaire] = QRid
                        localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                        localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                        props.handleIsQuestionnaireComplete(true);
                        setSubmitStatus(true);
                        success(res.message);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
            }else{
                createQuestionnaireResponse({ patientID: props?.patientId, encounterId: props?.encounterId, questionResponse: values?.questionResponse, appointmentId: props?.appointmentId, questionnaire: selectedQuestionnaire })
                .then((res) => {
                    setFieldValue("questionResponseId", res.data.id)
                    prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                    questionResponseId[selectedQuestionnaire] = res.data.id
                    localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                    localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                    props.handleIsQuestionnaireComplete(true);
                    setSubmitStatus(true);
                    success(res.message);
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            }
            
        },
    });

    useEffect(() => {
        const questionResponse = questionnaireList.filter(item => item?.id === formik?.values?.questionnaire)?.[0]?.item.map(item => ({
            id: item?.linkId,
            question: item?.text,
            answerType: item?.answerType,
            answer: item.answerOption?.map(item => ({ answer: "", id: item?.id })),
            required: item?.required,
        }));
        formik.setFieldValue("questionResponse", questionResponse)
    }, [formik.values?.questionnaire])

    const handleOpenNewTab = () => {
        const newTabUrl = `/app/zoom-question-tab?data=${encodeURIComponent([props?.encounterId, props?.patientId, props?.appointmentId, props?.slotStart, props?.slotEnd, props?.patientName])}`;
        const newTab = window.open(newTabUrl, '_blank');
        if (newTab) {
            newTab.location.href = newTabUrl;
        }
    };

    const setEmptyQuestionnaire = (qId) => {
        return questionnaireList
            .filter(item => item?.id === qId)?.[0]?.item
            .map((item , index) => ({
                id: item?.linkId,
                question: item?.text,
                key:{index},
                questionType: item?.answerType,
                answerType: item?.answerType,
                answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
                answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
                required: item?.required,
            }))
    }

    const handleSelectChange = (event) => {
        const selectedQuestionnaireId = event.target.value;        
        let questionResponse =[];
        let questionnaireResponse =JSON.parse(localStorage.getItem("questionnaireResponse"))
        if(questionnaireResponse?.[selectedQuestionnaireId]){
            questionResponse = questionnaireResponse[selectedQuestionnaireId]
        }else{
            questionResponse = setEmptyQuestionnaire(selectedQuestionnaireId)
        }        
        formik.setFieldValue("questionResponse", questionResponse);
        // formik.setFieldValue("questionResponseId", "")
        setSelectedQuestionnaire(selectedQuestionnaireId);
        setTextEditorKey(prevKey => prevKey + 1);
        setSubmitStatus(true);
        localStorage.setItem("selectedQuestionnaire", selectedQuestionnaireId);
    };

    useEffect(() => {
        const savedQuestionnaireId = localStorage.getItem("selectedQuestionnaire");
        console.log(savedQuestionnaireId);
        if (savedQuestionnaireId && questionnaireList.some(q => q.id === savedQuestionnaireId)) {
            setSelectedQuestionnaire(savedQuestionnaireId);
            formik.setFieldValue("questionResponse", 
                JSON.parse(localStorage.getItem("questionnaireResponse"))?.[savedQuestionnaireId] || setEmptyQuestionnaire(savedQuestionnaireId)
            );
        }  
    }, [questionnaireList]);

    const handleParkCallClickCall = () => { 
        formik.handleSubmit();
        if (submitStatus) {
            props.handleParkCallClick();
        }
       
      };
    
    const handleFollowUpClickCall = () => { 
        formik.handleSubmit();
        if (submitStatus) {
            props.handleFollowUpClick(); 
        } 
      };

    useImperativeHandle(ref, () => formik.handleSubmit);

    return (

        <div className="chat-sidebar" style={props?.show ? {} : { position: "absolute", left: "-1000px" }}>
            <div className="individual-chart-wrap">
                <div className="chat-body common-form" style={{ paddingTop: "1rem" }}>
                    <div style={{ float: "right" }} onClick={handleOpenNewTab}>
                        <Tooltip title="Open questionnaire in new tab">
                            <Launch style={{ color: "#005da8", cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                    <SelectFiled
                        keyField={"questionnaire"}
                        label={"Select Questionnaire"}
                        formik={formik}
                        readOnly={isDisabled}
                        options={questionnaireList}
                        onChange={handleSelectChange}
                        value={selectedQuestionnaire} 
                    />

                    <hr />
                    {formik.values.questionResponse && formik.values.questionResponse.length > 0 && (
                        <QuestionnaireRenderer 
                            formik={formik} 
                            questionResponse={formik.values.questionResponse} 
                            parentIndex={textEditorKey} 
                        />
                    )}
                    
                    {/* {formik.errors.isQuestionsError && (
                                <div className="error-message" style={{ color: "red" }}>{formik.errors.isQuestionsError}</div>
                            )} */}


                </div>
                <div className="msg-footer">
                    <div className="btn-wrap" style={{ display: 'flex', justifyContent: "space-evenly", gap: "1rem" }}>
                        <Button type="submit" style={{display: "flex"}} isLoading={btnLoading} title="Park Call" variant="primary" onClick={handleParkCallClickCall}>Park Call</Button>
                        <Button type="submit" style={{display: "flex"}} isLoading={btnLoading} title="Add to Follow-Up" variant="primary" onClick={handleFollowUpClickCall}>Add to Follow Up</Button>
                        <Button type="submit" isLoading={btnLoading} className="custom-btn-green" onClick={formik.handleSubmit} style={{background: 'linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)'}} >{findQrId(selectedQuestionnaire) ? "Update" : "Save"}</Button>
                        
                    </div>
                </div>
            </div>
        </div>

    )
});

export default QuestionnaireBox