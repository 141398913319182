import React, { useEffect, useState, useRef } from 'react'
import { FormikObj } from './constant'
import { Button } from '../../common/Button';
import SelectFiled from '../../common/textfield/SelectFiled';
import { useFormik } from 'formik'
import { failed } from "../../common/Toastify";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { useSelector } from 'react-redux';
import { useGetPdfDocumentsQuery } from '../../../hooks/ReactQueryHooks/useGetPdfDocumentsQuery';
import PdfViewerComponent from '../../common/components/PdfViewerComponent';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../../common/textfield/TextInput';
import moment from 'moment';
import { usePatientEncounterHistoryQuery } from '../../../hooks/ReactQueryHooks/usePatientEncounterHistoryQuery';
import { getPatientData, getSingleUser } from '../../api/Individual';
import { splitContactNumber } from '../../../utils/phoneNumberSupport';
import ServiceLocationModal from '../ServiceRequest/ServiceLocationModal';


const CreateMedicationRequest = () => {
    const [openModal, setOpenModal] = useState(false)
    const [patientData, setPatientData] = useState(true)
    const [searchParams] = useSearchParams();
    const paramsObj = Object.fromEntries(searchParams?.entries())
    const user = useSelector(state => state?.auth?.user)
    const practitionerId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const navigate = useNavigate()
    const instanceRef = useRef(null);
    const initialPdfFormValues = useRef(null);
    const [documentOptions, setDocumentOptions] = useState([]);
    const [encounteredData, setEncounteredData] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);

    const onSuccessOfDocumentsList = (data) => {
        setDocumentOptions(
            data
                .filter(item => item?.category?.[0]?.coding?.[0]?.code === "Prescription")
                .map(item => ({ ...item, name: item?.documentName, value: item?.id }))
        );
    };
    const onSuccessForEncounterHistory = (data) => {
        setEncounteredData(data?.map((item) => ({ ...item, name: item?.id + (item?.period?.start ? `(${moment(item?.period?.start).format("YYYY-MM-DD")})` : ''), value: item?.id })));
    };
    usePatientEncounterHistoryQuery({ onSuccess: onSuccessForEncounterHistory, startDate: moment().subtract(3, 'months').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), patientId: paramsObj?.patientId, practitionerId: user?.["custom:unique_id"], refetchOnWindowFocus: false });

    useGetPdfDocumentsQuery({  orgId:adminOrgs?.[0]?.id, onSuccess: onSuccessOfDocumentsList });
    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setOpenModal(true)
        },
    });

    useEffect(() => {
        let tempFormValues = {};
        getPatientData(paramsObj?.patientId)
            .then((res) => {
                setPatientData(res?.result)
                tempFormValues = {
                    "patient-dob": moment(res?.result?.birthDate).format("MMM-DD-YYYY").toUpperCase(),
                    "patient-name": res?.result?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                    "patient-street": res?.result?.address?.[0]?.line?.[0],
                    "patient-city": res?.result?.address?.[0]?.city,
                    "patient-state": res?.result?.address?.[0]?.state,
                    "patient-postalcode": res?.result?.address?.[0]?.postalCode,
                    "patient-sex": res?.result?.gender?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                    "patient-HCN": res?.result?.identifier?.find((ele) => ele?.type === "HCN")?.value || "",
                    "patient-telephone":res?.result?.telecom?.find((ele) => ele?.system === "phone")?.value,
                    "encounter-id": paramsObj?.encounterId || "",
                    "date": moment().format("MMM-DD-YYYY"),
                }
                return getSingleUser({ id: practitionerId, type: "Practitioner", userOrgId: adminOrgs?.[0]?.id })
                    .then((res) => {
                        initialPdfFormValues.current = {
                            ...tempFormValues,
                            "practitioner-name": res?.data?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                            "practitioner-license": res?.data?.qualification[0]?.identifier[0]?.value || "",
                            "practitioner-telephone": splitContactNumber(res?.data?.telecom?.find((ele) => ele?.system === "phone")?.value || "").phone_number,
                            "practitioner-street": res?.data?.address?.[0]?.line?.[0],
                            "practitioner-city": res?.data?.address?.[0]?.city,
                            "practitioner-state": res?.data?.address?.[0]?.state,
                            "practitioner-postalcode": res?.data?.address?.[0]?.postalCode,
                            "practitioner-signature_af_image": res.data?.signatureURL?.base64Data,
                        }
                })
            }).catch((err) => {
                failed(err?.response?.data?.message || err?.response?.data?.error || err.message)
            })
        formik?.setValues({ ...formik?.values, ...paramsObj })
    }, [])

    const handleFormChange = (e) => {
        if (e?.target?.id === "encounterId") {
            initialPdfFormValues.current = {
                ...initialPdfFormValues.current,
                "encounter-id": e?.target?.value,
            }
            instanceRef?.current?.setFormFieldValues({
                "encounter-id": e?.target?.value,
            });
        }
    };

    useEffect(() => {
        localStorage.setItem("activeTableKey", "eighth")
    }, [])
    

    return (
        <>
            {openModal ? <ServiceLocationModal
                onHide={() => setOpenModal(false)}
                modalShow={openModal}
                paramsObj={paramsObj}
                practitionerData={user}
                instanceRef={instanceRef}
                requestType={"Medication"}
                patientData={patientData}
                orgName={adminOrgs?.[0]?.name}
                fromServiceRequest={false}
                fromMedicationRequest={true}
                encounterId={formik.values?.encounterId}
                documentName={documentOptions?.find(item => item?.id === formik.values?.documentId)?.name}
            /> : null}
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                {paramsObj?.fromPatient === "true" && <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}><ArrowBack /></span>}
            </div>
            <div className="custom-card p-4">
            <form className="common-form border-fields" onSubmit={formik.handleSubmit} onChange={handleFormChange}>
                <Row>
                    <Col>
                        {!paramsObj?.encounterId ? <SelectFiled formik={formik} keyField={"encounterId"} label={"Encounter ID"} options={encounteredData} /> : <TextInput disabled={true} formik={formik} keyField={"encounterId"} label={"Encounter ID"} placeholder={"111D1"} />}
                    </Col>
                    <Col>
                        <SelectFiled
                            keyField={"documentId"}
                            label={"Select Document"}
                            formik={formik}
                            options={documentOptions}
                            readOnly={!formik.values?.encounterId}
                        />
                    </Col>                   
                </Row>
                <div className="Pdf-viewer">
                    {formik.values.documentId ?
                        <PdfViewerComponent initialPdfFormValues={initialPdfFormValues.current} documentId={documentOptions.find(item => item?.id === formik.values.documentId).id} 
                            instanceRef={instanceRef} /> : null}
                </div>

                <div className="btn-wrap" style={{
                    display: "flex",
                    gap: "2rem"
                }}>
                    <Button onClick={() => paramsObj?.fromPatient === "true" ? navigate(-1) : window.close()} variant="secondary" title="Cancel"> Cancel </Button> 
                    <Button type="submit">Next</Button>
                </div>
            </form>
            </div>
        </section>
        </>
    )
}

export default CreateMedicationRequest