import React, { useEffect, useState } from "react";
import SearchBar from "../../common/components/SearchBar";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import Table from "react-bootstrap/Table";
import { Pagination, Skeleton, TableCell, Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import AppointmentImg from "../../../assets/images/appointment.svg";
import ViewIcon from "../../../assets/images/view.png";
import { ArrowDropDown, ArrowDropUp, CalendarMonthOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetUsersByOrg } from "../../../hooks/ReactQueryHooks/useGetUsersByOrg";
import { EditIndividual } from "../../Admin/Individuals/edit-individual";
import BookAppointment from "../AppointmentRequests/BookAppointment";
import ViewAppointmentModalCalender from "./ViewAppointmentModalCalender";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { ActionTitle, CreateAppointmentTitle, ManagePractitionerTitle, NameTitle, NoDataAvailTitle, PhoneNumber, ViewAppointmentsTooltipTitle, ViewInfoTitle, ViewScheduleTitle } from "./Constants";
import { ViewDate } from "../../../utils/DateSupport";
import { createEncounterInbound } from "../../apps/api/AppointmentRequests";
import { getAllUsers, getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import InBoundCall from "../../Admin/Dashboard/InBoundCall";
import IndividualSearchBar from "../../common/components/individualSearch";

function ListOfPractitioner() {
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [sortBy, setSortBy] = useState("AscName");
    const [appointmentModalShow, setAppointmentModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const [listAppointmentsModalShow, setListAppointmentsModalShow] = useState(false);
    const userPermissions = useSelector((state) => state?.auth?.user?.permissions);
    //const { isLoading, isFetching, refetch } = useGetUsersByOrg({ onSuccess: setUsersList, orgId: adminOrgs?.[0]?.id, type: "Practitioner", })
    const [practitionerData, setPractitionerData] = useState(null)
    const user = useSelector((state) => state?.auth?.user);
    const [isInboundLoad, setIsInboundLoad] = useState(false)
    const [encounterId, setEncounterId] = useState('')
    const [encounteredData, setEncounterData] = useState({})
    const [inBoundCallModal, showInBoundCallModal] = useState(false);
    const [selectedPractitionerId, setSelectedPractitionerId] = useState(null);
    const [numRows, setNumRows] = useState();
    const [isLoadingScreen, setLoadingScreen] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData?.length;
    const [searchVal, setSearchVal] = useState("");

    useEffect(() => {
        getPractitionersList({ orgId: adminOrgs?.[0]?.id, type: "Practitioner", active: true, limit: 10 , pageNumber: page + 1, searchQuery: searchVal });
    }, [])

    const getPractitionersList = (apiArgs, active) => {
        setLoadingScreen(true);
        getAllUsers(apiArgs, active)
            .then((res) => {
                setLoadingScreen(false);
                setUsersList(res?.data);
                setNumRows(res?.totalCount);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => { setLoadingScreen(false) });
    };

    
    const handleShow = (data) => {
        setModalShow(modalShow ? false : data);
    };


    const handleSchedule = (data) => {
        navigate("/app/schedule", { state: { data } })
    }

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) > decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) > decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "DscName":
                setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) < decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) < decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "AscOrg":
                setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display > b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display > a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            case "DscOrg":
                setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display < b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display < a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            default:
                setFilterData(filterData)
                break;
        }
    }


    const handleInboundModal = (data)=>{
        setSelectedPractitionerId(data?.id);
        let encounterPayload = {
            orgId : adminOrgs?.[0]?.id,
            requestedPeriod : {start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: null},
            practitionerId : data?.link?.[0]?.target?.reference?.split("/")?.[1],
            practitionerName : data?.name?.[0]?.text,
            status : "in-progress-intake",
            statusHistory:[{
                status:"in-progress-intake",
                actor: {
                    reference: data?.link?.[0]?.target?.reference,
                    display: data?.name?.[0]?.text,
                },
                period: {
                    start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    end: null
                }
            }] 
        }
        setIsInboundLoad(true)
        createEncounterInbound(encounterPayload).then((res)=>{
            if(res.status === true){
              setEncounterData(res.data)              
              setEncounterId(res.data.id)
              setIsInboundLoad(false)
              showInBoundCallModal(true)
            }else{
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
              setIsInboundLoad(false)
              showInBoundCallModal(false)
            }
        }).catch((res) => {
            showInBoundCallModal(false)
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
        }).finally(() => setIsInboundLoad(false));
    }
    const handleSearch = (searchQuery) => {
        setSearchVal(searchQuery)
        getPractitionersList({ orgId : adminOrgs?.[0]?.id, active: true ,type: "Practitioner", limit: 10 , pageNumber: page + 1, searchQuery: searchQuery})
    };

    const handlePage = async(pageNo) => {
        setPage(pageNo);
        getPractitionersList({ orgId: adminOrgs?.[0]?.id, active: true ,type: "Practitioner", limit: 10 , pageNumber: pageNo + 1, searchQuery: searchVal })
    };
    return (
        <>
        {inBoundCallModal && <InBoundCall encounteredData={encounteredData}  encounterId = {encounterId} modalShow={inBoundCallModal} handleShow={()=> showInBoundCallModal(false)} selectedPractitionerId={selectedPractitionerId}/>} 
        <section className="common-listing">

            {modalShow ?
                <EditIndividual
                    modalShow={modalShow}
                    handleShow={handleShow}
                    refreshTable={false}
                    isReadOnly={true}
                    inactive={false}
                    typeOfUser={"Practitioner"}
                /> : null}

            {appointmentModalShow
                ? <BookAppointment
                    modalShow={(appointmentModalShow)}
                    handleShow={() => setAppointmentModalShow(false)}
                /> : null}

            {listAppointmentsModalShow ? <ViewAppointmentModalCalender
                modalShow={(listAppointmentsModalShow)}
                handleShow={() => setListAppointmentsModalShow(false)} /> : null}


            <div className="heading-wrap">
                <div className="filter-row-blc d-flex flex-wrap">
                    <div className="filter-col d-flex align-items-center">
                        <IndividualSearchBar placeholder="Search... " page={page} setPage={setPage} encoded={true} list={usersList} filterData={(data) => setFilterData(data)} onSearch={handleSearch} searchVal={searchVal} setSearchVal={setSearchVal}/>
                    </div>
                </div>
            </div>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>{NameTitle}
                                {sortBy === "AscName" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>
                            <th>{PhoneNumber}</th>
                            <th
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {ActionTitle}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoadingScreen ? (
                            [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td> 
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.map((data) => {
                                const { name, telecom, id } = data;
                                return (
                                    <tr key={id}>
                                        <td className="name-text" style={{ textTransform: 'capitalize' }}>{decryptData(name?.[0]?.text || "-")}</td>
                                        <td>{decryptData(telecom?.find((ele) => ele?.system === "phone")?.value || "-")}</td>
                                        <td>
                                            <div className="action-wrap">
                                                {userPermissions?.includes("Manage Individuals") && <Tooltip title={ViewInfoTitle}>
                                                    <div onClick={() => handleShow(data, true)} className="view-btn">
                                                        <img src={ViewIcon} alt="View" />
                                                    </div>
                                                </Tooltip>}
                                                <Tooltip title={ViewScheduleTitle}>
                                                    <div onClick={() => handleSchedule(data)} className="view-btn">
                                                        <CalendarMonthOutlined style={{ color: "#ffff" }} />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={ViewAppointmentsTooltipTitle}>
                                                    <div onClick={() => setListAppointmentsModalShow(data)} className="view-btn">
                                                        <img src={AppointmentImg} width={"90%"} alt="View" />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={CreateAppointmentTitle}>
                                                    <div onClick={() => handleInboundModal(data)} className="view-btn">
                                                        <AddIcCallIcon style={{ color: "#ffff" }} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan={"5"} style={{ textAlign: "center" }}>
                                    {NoDataAvailTitle}
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {filterData?.length > 0 ? 
            <div className="pagination-wrap">
                <p style={{ marginBottom: Math.ceil(numRows / rowsPerPage) > 1 ? "10px" : "0" }}>
                    Showing {numRows > 0 ? Math.min(page * rowsPerPage + 1, numRows) : 0}-{numRows > 0 ? Math.min(page * rowsPerPage + rowsPerPage, numRows) : 0} of {numRows}
                </p>
                <Pagination
                    page={page + 1}
                    count={Math.ceil(numRows / rowsPerPage)}
                    color="primary"
                    boundarycount={1}
                    onChange={(event, newPage) => handlePage(newPage-1)}
                />
            </div> : null}
        </section>
        </>
    );
}

export default ListOfPractitioner;
