import React, { useEffect, useState } from 'react'
import LocationsSearch from './LocationsSearch'
import { useFormik } from 'formik'
import { locaitonModalFormikObj } from './constant.js'
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectFiled from '../../common/textfield/SelectFiled.jsx';
import { Button } from '../../common/Button/index.js';
import { createServiceRequest, sendSFT, editServiceRequest } from '../api/ServiceRequest/index.js';
import { createDocument } from '../api/commonApis.js';
import { convertBufferArrayToBase64, imageURLToBase64, removePrefixFromBase64 } from '../../../utils/ImageToBase64.js';
import { failed, success } from '../../common/Toastify.jsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { usePdfRefDocumentRequestQuery } from '../../../hooks/ReactQueryHooks/usePdfRefDocumentRequestQuery';
import { getOrganization } from '../../api/Organization';
import { useSelector } from 'react-redux';
import { createMedicationRequest, editMedicationRequest } from '../api/MedicationRequest';
import { axios } from '../../../lib/axios';

let locaitonModalRecipientOptions = [
  { name: "Patient", value: "patient" },
  { name: "Location", value: "location" }
]

let locaitonModalRecipientOptionsEncounter = [
  { name: "Location", value: "location" }
]

const getServiceType = (requestType) => {
  switch (requestType) {
    case 'Lab':
      return "requisition-lab"
    case 'Diagnostic':
      return "requisition-diagnostic-imaging"
    case 'Referral':
      return "referral"
    case 'Encounter Report':
      return "Encounter Report"
    default:
      return "prescription"
  }
}

const ServiceLocationModal = ({ modalShow, onHide, paramsObj, practitionerData, instanceRef, orgName, requestType, categoryVar, patientData, documentName, encounterId, fromServiceRequest, fromMedicationRequest, fromEncounter }) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [docData, setDocData] = useState([]);
  const [orgnization, setOrganizations] = useState({})
  const navigate = useNavigate()
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
  let documentTitle = documentName || docData?.[0]?.documentName
  let recipientOption
  if(fromEncounter && orgnization?.sft?.enabled) {
    recipientOption = locaitonModalRecipientOptionsEncounter
  } else if(orgnization?.sft?.enabled && !fromEncounter){
    if(orgnization?.sft?.individualDelivery && orgnization?.sft?.locationDelivery) recipientOption = locaitonModalRecipientOptions
    else if(orgnization?.sft?.locationDelivery) recipientOption = locaitonModalRecipientOptions.filter((data)=> data.name !== "Patient")
    else if(orgnization?.sft?.individualDelivery) recipientOption = locaitonModalRecipientOptions.filter((data)=> data.name !== "Location")
    else if(orgnization?.sft?.individualDelivery === false && orgnization?.sft?.locationDelivery === false)  recipientOption = []
  }else{
    recipientOption = []
  }
  const onSuccessViewMedication = (data) => {
      if(data){
          setDocData(data);
      }
  };
  
  usePdfRefDocumentRequestQuery({ onSuccess: onSuccessViewMedication, Id: modalShow?.id, typeData: modalShow?.resourceType, patientId: paramsObj?.patientIdForDocument || patientData?.id }) 

  const formik = useFormik({
    ...locaitonModalFormikObj,
    onSubmit: async (values) => {
      setBtnLoading(true);
      const base64 = await instanceRef?.current.exportPDF({flatten: true}).then(function (buffer) {
        return convertBufferArrayToBase64(buffer)
      })

      const recipient = values?.recepient === "patient"
        ? {
          individual: {
            id: !patientData?.telecom?.find((ele) => ele?.system === "email")?.value ? patientData?.link?.[0]?.other?.reference?.split('/')[1] : patientData?.id,
            email_address: patientData?.telecom?.find((ele) => ele?.system === "email")?.value ? patientData?.telecom?.find((ele) => ele?.system === "email")?.value : patientData?.link?.[0]?.other?.display,
            identity_challenge: patientData?.identifier?.find((ele) => ele?.type === "HCN")?.value?.replace(/\D+/g, '') || "",
          }
        } : {
          location: {
            id: values?.locationId,
            fax_number: Number("1" + values?.locationFax_number?.replace(/-|_/g, "")),
            name: values?.name
          }
        }

       const getServiceTypeForEncounter = (requestType) => {
        switch (requestType) {
          case 'Lab':
            return "MBL"
          case 'Diagnostic':
            return "RADDX"
          case 'Referral':
            return "DX"
          case 'Encounter Report':
            return "HOSP"
          default:
            return "PHARM"
        }
      }


       const documentDelivery = values?.recepient === "location" 
       ? [ {
          "type": getServiceTypeForEncounter(requestType),
          "display": values?.name,
          "reference": `Location/${values?.locationId}`,
          "deliveryTime": moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          "telecom": [{
          "system": "fax",
          "value": values?.locationFax_number,
          }],
        }]
      : null

      const payload = {
        package: {
          name: `${documentTitle}.pdf`,
          content_base64: base64 || paramsObj?.base64 || removePrefixFromBase64(docData?.[0].content?.[0]?.attachment?.base64String, "data:application/pdf;base64,"),
          validity_length: orgnization?.sft.expirationDays,
          type: getServiceType(requestType),
          time_sent: moment().unix(),
        },
        subject: {
            name: patientData?.name?.[0]?.text,
            id: patientData?.id,
        },
        sender: {
          organization_name: practitionerData?.organizations?.[0]?.name,
          organization_id: practitionerData?.organizations?.[0]?.id,
          practitioner_name: practitionerData?.name?.[0]?.text,
          practitioner_id: practitionerData?.["custom:unique_id"],
        },
        recipient: [recipient],
      }
      if(paramsObj?.fromListing){
        sendSFT(payload).then(async(res) => {
          if(values?.recepient === "location") {
            const encounterID = modalShow?.encounter?.reference?.split('/')[1];
            await axios.put(`encounter/${encounterID}`, {"documentDelivery" : documentDelivery}); 
          }
          success(res?.message);
          onHide()
        }).catch((res) =>
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          )
        ).finally(() => setBtnLoading(false));
      }else{
        if(fromServiceRequest){
          let serviceRequestId;
          createServiceRequest({
            requestType,
            patientId: paramsObj?.patientId,
            encounterId: encounterId,
            text: "",
            status: "active"
          }).then((res) => {
            serviceRequestId = res?.data?.id;
            return createDocument({
              practitionerId: practitionerData?.["custom:unique_id"],
              requestType,
              patientId: paramsObj?.patientId,
              orgId: res?.data?.orgId,
              serviceRequestId: res?.data?.id,
              document: base64,
              category: categoryVar,
              orgName,
              documentName: documentTitle
            })
          }).then((res) => {
            return sendSFT(payload)
          }).then(async(res) => {
            if(values?.recepient === "location") {
              await axios.put(`encounter/${encounterId}`, {"documentDelivery" : documentDelivery});
            }
            success("Service Request created successfully!");
            // paramsObj?.fromPatient ? navigate(-1) : window.close();
            if (paramsObj?.fromPatient === "false") {
              setTimeout(() => {
                window.close();
              }, 2000);
            } else {
              navigate(-1);
            }
          }).catch((res) => {
            failed(
              res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
            );
            let reqdata = {
              status: "entered-in-error",
              patientId: paramsObj?.patientId,
              serviceRequestId: serviceRequestId
            };
            editServiceRequest({
              id: reqdata?.serviceRequestId,
              patientId: reqdata?.patientId,
              status: reqdata?.status
            }).then((res) => {
              // success("Status updated successfully!");
            })
            .catch((res) => {
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            })
          }
          ).finally(() => setBtnLoading(false));
        }else if(fromMedicationRequest){
          let medicationRequestId;
          createMedicationRequest({
            patientId: paramsObj?.patientId,
            encounterId: encounterId,
            practitionerId: practitionerData?.["custom:unique_id"],
            practitionerName: practitionerData?.name?.[0]?.text,
            orgId: adminOrgs?.[0]?.id,
          }).then((res) => {
            medicationRequestId = res?.data?.id;
            return createDocument({
              practitionerId: practitionerData?.["custom:unique_id"],
              patientId: paramsObj?.patientId,
              orgId: res?.data?.orgId,
              medicationRequestId: res?.data?.id,
              document: base64,
              category: "Prescription",
              orgName,
              documentName: documentTitle
            })
          }).then((res) => {
            return sendSFT(payload)
          }).then(async(res) => {
            if(values?.recepient === "location") {
              await axios.put(`encounter/${encounterId}`, {"documentDelivery" : documentDelivery}); 
            }
            success("Medication Request created successfully!");
            // paramsObj?.fromPatient ? navigate(-1) : window.close()
            if (paramsObj?.fromPatient === "false") {
              setTimeout(() => {
                window.close();
              }, 2000);
            } else {
              navigate(-1);
            }
          }).catch((res) => {
            failed(
              res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
            )
            let reqdata = {
              status: "entered-in-error",
              patientId: paramsObj?.patientId,
              medicationRequestId: medicationRequestId
            };
            editMedicationRequest({
              id: reqdata?.medicationRequestId,
              patientId: reqdata?.patientId,
              status: reqdata?.status
            }).then((res) => {
              // success("Status updated successfully!");
            })
            .catch((res) => {
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            })
          }
          ).finally(() => setBtnLoading(false));
        }else if(fromEncounter){
          createDocument({
              practitionerId: practitionerData?.["custom:unique_id"],
              requestType,
              patientId: paramsObj?.patientId,
              orgId:  paramsObj?.orgId,
              document: paramsObj?.base64,
              category: categoryVar,
              orgName,
              documentName: documentTitle,
              encounterId : modalShow?.encounter?.reference?.split('/')[1] || modalShow?.id
          }).then((res) => {
            return sendSFT(payload)
          }).then(async(res) => {
            if(values?.recepient === "location") {
              await axios.put(`encounter/${encounterId}`, {"documentDelivery" : documentDelivery}); 
            }
            success("Document sent successfully!");
            // if (paramsObj?.fromPatient === "false") {
              setTimeout(() => {
                // window.close();
                // onHide()
                navigate("/app/patients-details", { state: { id: patientData?.id, name: patientData?.name?.[0]?.text } })
              }, 2000);
            // } else {
            //   navigate(-1);
            // }
          }).catch((res) => {
            failed(
              res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
            );
          }
          ).finally(() => setBtnLoading(false));          
        }

      }
    }
  })

  useEffect(() => {
    getOrganization(adminOrgs?.[0]?.id)
        .then((res) => {
            setOrganizations(res.data);
        })
        .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        });
}, []);

  useEffect(() => {
    formik.setFieldValue("HCN", patientData?.identifier?.find((ele) => ele?.type === "HCN")?.value ? patientData?.identifier?.find((ele) => ele?.type === "HCN")?.value : "" )
  }, [])

  const handleRecepient = (event)=>{
    if(event?.target?.value === "patient"){
      if(formik?.values?.HCN === undefined || formik?.values?.HCN === "") failed("HCN is required. Can't send document to patient.")
      else formik?.setFieldValue("recepient", event?.target?.value)
    }else{
      formik?.setFieldValue("recepient", event?.target?.value)
    }
  }

  return (
    <Modal
      backdropClassName
      backdrop={'static'}
      show={modalShow} onHide={onHide} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered className="custom-dialog">
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">Select Recipient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <SelectFiled
                keyField={'recepient'}
                label={'Recipient'}
                formik={formik}
                options={recipientOption}
                onChange={handleRecepient}
              />
            </Col>
          </Row>
          {formik?.values?.recepient === "location" ? <Row>
            <Col>
              <LocationsSearch
                label={'Select Location'}
                formik={formik}
                requestType={requestType}
                keyField={'locationId'}
                required
              />
            </Col>
          </Row> : null}
          <div className="btn-wrap" style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button onClick={()=> !btnLoading && onHide()} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ServiceLocationModal