import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { questionnaireForOptionsOrganization, QuestionnaireFormikAssign } from "./Constant";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import { useQuestionnaireSortingQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireSortingQuery";
import { assignedQuestionnaireToOrganization } from "../../api/Questionnaire";
import { getOrganization } from "../../api/Organization";
import { Skeleton, TableCell, Tooltip, Switch } from "@mui/material";
import { debounce } from 'lodash';
import MultiSelect from "../../common/textfield/MultiSelect";

// edit-program dialog starts
const AssignQuestionnaire = ({ modalData, handleHide, refreshTable }) => {
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [getCareAptId, setGetCareAptId] = useState();
  const [getCareWaitingId, setGetCareWaitingId] = useState();
  const [getboundCallId, setGetboundCallId] = useState();
  const [getCareVisitId, setGetCareVisitId] = useState([]);
  const [questionnaireItem, setQuestionnaireItem] = useState([]);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);

  const [questionnaireApt, setQuestionnaireApt] = useState();
  const [questionnaireWroom, setQuestionnaireWroom] = useState();
  const [questionnaireVvisit, setQuestionnaireVvisit] = useState();
  const [loading, setLoading] = useState(true);

  const [questionnaireInbound, setQuestionnaireInbound] = useState();

  const [initialMultiSelectValue, setInitialMultiSelectValue] = useState([]);
  const [selectedVideoVisit, setSelectedVideoVisit] = useState(true);
  const [isClick, setIsClick] = useState(true);

  let formattedOptions;
  let filteredDataVideoVisit;
  let orgID = useSelector((state) => state?.auth?.user?.["custom:orgId"]);

  const debouncedGetOrganization = debounce((data, orgId) => {
    getOrganization(orgId)
      .then((res) => {
        const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
        const foundAptItem =
          assignedQuestionnaire["getcare-appointment"]
            ? data.find((item) => item?.id === assignedQuestionnaire["getcare-appointment"])
            : "";
        foundAptItem ? setGetCareAptId(foundAptItem.title) : setGetCareAptId("");
        const foundWaitingItem =
          assignedQuestionnaire["getcare-waitingroom"]
            ? data.find((item) => item?.id === assignedQuestionnaire["getcare-waitingroom"])
            : "";
        foundWaitingItem ? setGetCareWaitingId(foundWaitingItem.title) : setGetCareWaitingId("");
        // ibound call
        const foundIcalltem =
          assignedQuestionnaire['telephone-intake']
            ? data.find((item) => item?.id === assignedQuestionnaire['telephone-intake'])
            : "";
            foundIcalltem ? setGetboundCallId(foundIcalltem.title) : setGetboundCallId("");

        const videoVisitIds =
          assignedQuestionnaire["video-visit"] || [];
        setGetCareVisitId(
          videoVisitIds.map((id) => {
            const foundItem = data.find((item) => item?.id === id);
            return foundItem ? foundItem.title : "";
          })
        );

        if (foundAptItem) {
          formik.setFieldValue("questionnaireAptField", foundAptItem?.id || "");
        }
        if (foundWaitingItem) {
          formik.setFieldValue("questionnaireWroomField", foundWaitingItem?.id || "");
        }
        if (foundIcalltem) {
          formik.setFieldValue("questionnaireInboundCallField", foundIcalltem?.id || "");
        }
      })
      .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
  }, 500);

  const onSuccess = (data) => {
    debouncedGetOrganization(data, adminOrgs?.[0]?.id);
  };
  const debouncedSuccess = debounce(onSuccess, 500);


  const { isLoading, refetch, isFetching, data: programList } = useQuestionnaireSortingQuery({ orgID, onSuccess: debouncedSuccess });
  const formik = useFormik(
    {
      ...QuestionnaireFormikAssign,
      onSubmit: (values) => {
        let payload = {};
        let VidoeData = values.questionnaireVvisitField;
        if ((selectedVideoVisit == true && VidoeData.length == 0 && isClick == false) || (VidoeData.length === 0 && isClick == false)) {
          VidoeData = values.questionnaireVvisitField;
        } else {
          (initialMultiSelectValue.length > 0 && VidoeData.length == 0) ? VidoeData = initialMultiSelectValue : VidoeData = values.questionnaireVvisitField;
        }
        payload = {
          "assignedQuestionnaire": {
            "getcare-appointment": values.questionnaireAptField,
            "getcare-waitingroom": values.questionnaireWroomField,
            "video-visit": VidoeData,
            "telephone-intake": values.questionnaireInboundCallField,
          }
        }
        setBtnLoading(true);
        assignedQuestionnaireToOrganization(adminOrgs?.[0]?.id, payload)
          .then((res) => {
            getOrganization(adminOrgs?.[0]?.id).then((res) => {

              if (res.data?.assignedQuestionnaire["getcare-appointment"]) {
                const foundAptItem = programList.find((item) => item?.id === res.data?.assignedQuestionnaire["getcare-appointment"]);
                foundAptItem ? setGetCareAptId(foundAptItem.title) : setGetCareAptId('');
              }

              if (res.data?.assignedQuestionnaire["getcare-waitingroom"]) {
                const foundWaitingItem = programList.find((item) => item?.id === res.data?.assignedQuestionnaire["getcare-waitingroom"]);
                foundWaitingItem ? setGetCareWaitingId(foundWaitingItem.title) : setGetCareWaitingId('');
              }
              // in bound
              if (res.data?.assignedQuestionnaire["telephone-intake"]) {
                const foundIcalltem = programList.find((item) => item?.id === res.data?.assignedQuestionnaire["telephone-intake"]);
                foundIcalltem ? setGetboundCallId(foundIcalltem.title) : setGetboundCallId('');
              }          

              const videoVisitIds = res.data?.assignedQuestionnaire["video-visit"];
              videoVisitIds ?
                setGetCareVisitId(videoVisitIds.map(id => {
                  const foundItem = programList.find(item => item?.id === id);
                  return foundItem ? [foundItem.title] : '';
                })) : setGetCareVisitId([]);

              if (programList) {
                let filteredQuestionnaireVvisitRes = programList
                  .filter((item) => (item?.purpose === 'Video Visit' && item?.status === 'active'))
                  .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id, id: dataItem?.id }));
                if (filteredQuestionnaireVvisitRes.length > 0) {
                  setQuestionnaireVvisit(filteredQuestionnaireVvisitRes);
                  if (res?.data?.assignedQuestionnaire['video-visit'].length > 0) {
                    let itemDataVVisit = res?.data?.assignedQuestionnaire['video-visit'].map(id => {
                      const foundItem = programList.find(item => item?.id === id);
                      return foundItem ? foundItem.title : '';
                    })
                    setQuestionnaireItem(filteredQuestionnaireVvisitRes.filter(item => itemDataVVisit.includes(item.name)))
                    filteredDataVideoVisit = filteredQuestionnaireVvisitRes.filter(item => itemDataVVisit.includes(item.name));
                  }
                }
              }
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
            //  refreshTable();            
            success("Questionnaire Assigned Successfully.");
            handleHide();
            refreshTable();
          })
          .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
          .finally(() => setBtnLoading(false));
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (programList) {
          const filteredQuestionnaireVvisit = programList
            .filter((item) => (item?.purpose === 'Video Visit' && item?.status === 'active'))
            .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id, id: dataItem?.id }));
          if (filteredQuestionnaireVvisit.length > 0) {
            setQuestionnaireVvisit(filteredQuestionnaireVvisit);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      debouncedGetOrganization.cancel();
    };
  }, [programList, formik.values?.questionnaireVvisitField]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (programList) {
          const filteredQuestionnaireApt = programList
            .filter((item) => (item?.purpose === 'getcare-appointment' && item?.status === 'active'))
            .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
          setQuestionnaireApt(filteredQuestionnaireApt);
          const filteredQuestionnaireWroom = programList
            .filter((item) => (item?.purpose === 'getcare-waitingroom' && item?.status === 'active'))
            .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
          setQuestionnaireWroom(filteredQuestionnaireWroom);
          //
          const filteredQuestionnaireIcall = programList
            .filter((item) => (item?.purpose === 'telephone-intake' && item?.status === 'active'))
            .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
            setQuestionnaireInbound(filteredQuestionnaireIcall);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [programList]);

  if (questionnaireVvisit != undefined) {
    formattedOptions = questionnaireVvisit.map(item => ({
      name: item.name,
      value: item.value,
      id: item.value,
    }));
    filteredDataVideoVisit = questionnaireVvisit.filter(item => getCareVisitId.includes(item.name));
  } else { formattedOptions = []; }

  const handlePurposeChange = (fieldName, e) => {
    formik.setFieldValue(fieldName, e.target.value);
    setSelectedVideoVisit(false)
  }


  useEffect(() => {
    let initialValue = [];

    if (filteredDataVideoVisit && filteredDataVideoVisit.length > 0) {
      initialValue = filteredDataVideoVisit.map((item) => item.value);
    } else if (questionnaireItem && questionnaireItem.length > 0) {
      initialValue = questionnaireItem.map((item) => item.value);
    }
    const newDefaultValue = initialValue.length > -1 ? initialValue : undefined;

    if (JSON.stringify(newDefaultValue) !== JSON.stringify(initialMultiSelectValue)) {
      setInitialMultiSelectValue(newDefaultValue);
    }
  }, [filteredDataVideoVisit, questionnaireItem, initialMultiSelectValue]);


  return (
    <Modal
      show={modalData}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
      backdropClassName
      backdrop={"static"}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Assign Questionnaire
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {getCareWaitingId || getCareAptId ? <>
          <h6>
            Assigned Questionnaire
          </h6>
          <div className="table-wrap">
            <Table responsive>
              {getCareAptId ?
                <thead>
                  <tr>
                    <th><b>Get Care - Appointment : </b></th>
                    {isLoading || isFetching ?
                      (
                        [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                          <th key={val} > <Skeleton animation="wave" /></th>)))
                      :
                      <th> {getCareAptId} </th>}
                  </tr>
                </thead> : null}
              {getCareWaitingId ?
                <thead>
                  <tr>
                    <th><b>Get Care - Waiting Room :  </b></th>
                    {isLoading || isFetching ?
                      (
                        [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                          <th key={val} > <Skeleton animation="wave" /></th>)))
                      :
                      <th> {getCareWaitingId} </th>}
                  </tr>
                </thead> : null}

              {getCareVisitId ?
                <thead>
                  <tr>
                    <th><b>Video Visit : </b></th>
                    {isLoading || isFetching ?
                      (
                        [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                          <th key={val} > <Skeleton animation="wave" /></th>)))
                      :
                      <th> {getCareVisitId.join(', ')} </th>}
                  </tr>
                </thead> : null}
            </Table>
          </div>
          <hr />
        </> : null} */}
        {/* <h6>
            Assign Questionnaire
          </h6> */}
        <Row>
          <Col>
            <h6>Purpose</h6>
          </Col>
          <Col>
            <h6>Questionnaire</h6>
          </Col>
        </Row>

        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
              Get Care - Appointment
            </Col>
            <Col>
              <SelectFiled
                keyField={"questionnaireAptField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireApt}
                onChange={(e) => handlePurposeChange('questionnaireAptField', e)}
              />
            </Col>
          </Row>
          <Row style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
              Get Care - Waiting Room
            </Col>
            <Col>
              <SelectFiled
                keyField={"questionnaireWroomField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireWroom}
                onChange={(e) => handlePurposeChange('questionnaireWroomField', e)}
              />
            </Col>
          </Row>
          <Row style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
                Intake - Inbound Call
            </Col>
            <Col>
              <SelectFiled
                keyField={"questionnaireInboundCallField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireInbound}
                onChange={(e) => handlePurposeChange('questionnaireInboundCallField', e)}
              />
            </Col>
          </Row>
          <Row style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
              Video Visit
            </Col>
            <Col>
              {/* <MultiSelect
                required={false}
                keyField={"questionnaireVvisitField"}
                formik={formik}
                options={formattedOptions}
                isSelectAllEnable={true}
                // isSelectAll={true}
                 defaultValue={initialMultiSelectValue}
                // defaultValue={filteredDataVideoVisit && filteredDataVideoVisit.length > 0
                //   ? filteredDataVideoVisit.map((item) => item.value)
                //   : (initialMultiSelectValue > 0 ? initialMultiSelectValue : questionnaireItem.map((item) => item.value))
                // }
                propsOptionData={0}
              /> */}
              <MultiSelect
                required={false}
                keyField={"questionnaireVvisitField"}
                formik={formik}
                options={formattedOptions}
                isSelectAllEnable={true}
                defaultValue={initialMultiSelectValue}
                propsOptionData={0}
                onClick={(event) => {setIsClick(false)}}
              />
            </Col>
          </Row>
          
          <div
            className="btn-wrap"
            style={{ display: "flex", marginTop: "2rem" }}
          >
            <Button variant="secondary" title="Cancel" onClick={handleHide}>
              Cancel
            </Button>
            <Button
              type="submit" isLoading={btnLoading}
            // onClick={handleSubmit}          
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
export default AssignQuestionnaire;
