import React, { useEffect, useState } from "react";
import { failed, success } from "../../common/Toastify";
import { useFormik } from "formik";
import { QuestionsFormikObjEdit, } from "./Constant";
import { editQuestion } from "../../api/Questionnaire";
import { Modal } from "react-bootstrap";
import { QuestionForm } from "./QuestionForm";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const EditQuestion = ({ modalShow, handleShow, refreshTable, isReadOnly }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [tagValue, setTagValue] = useState();
    const [isModalTag, setIsModalTag] = useState();
    const navigate = useNavigate();
    let tagDataValue = modalShow?.tag;
    let channelDataMap =  { id: Date.now(), text: "Phone" }

    const formik = useFormik({
        ...QuestionsFormikObjEdit,
        validationSchema: Yup.object({
            category: Yup.string().required('Please fill the Category field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            question: Yup.string().required('Please fill the Question field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(400, "Must be 400 characters or less"),
            answerType: Yup.string().required('Please fill the Answer Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            note: Yup.string().when("answerType", (answerType, schema) => {
                if (answerType === "freeText") return schema.required('Please fill the Note field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
                return schema
            }),
            note: Yup.string().matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(250, "Must be 250 characters or less"),
            required: Yup.boolean(),
            options: Yup.array().when("answerType", (answerType, schema) => {
                if (answerType !== "freeText" && tagValue !== "CHANNEL") return schema.of(
                    Yup.object().shape({
                        text: Yup.string().required('Please fill the answer field'),
                    })
                )
                if (tagValue == "CHANNEL") return schema.of(
                    Yup.object().shape({
                        text: Yup.string().required('Please Select Chat/Video'),
                    })
                )
                return schema
            }),
        }),
        onSubmit: (values) => {
            tagDataValue = (tagValue !== undefined && isModalTag === undefined) ? tagValue :
                (tagValue === undefined && isModalTag !== undefined) ? '' :
                    (tagValue !== undefined && isModalTag !== undefined) ? tagValue :
                        modalShow?.tag;
            setBtnLoading(true);            
            values.category = values?.category?.split('%')?.[1];
            // values.tag = tagValue;
            values.tag = tagDataValue;
            if (values.tag == "CHANNEL") {
                const hasPhone = values.options.some(option => option.text === "Phone");
                if (!hasPhone) {
                    values.options.push(channelDataMap);
                }
            } else {
                values.options = values.options;
            }  
            editQuestion(modalShow?.id, values)
                .then((res) => {
                    refreshTable();
                    handleShow();
                    success(res.message);
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });
    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("category", modalShow?.category || "");
            formik.setFieldValue("question", modalShow?.question || "");
            formik.setFieldValue("answerType", modalShow?.answerType || "freeText");
            formik.setFieldValue("note", modalShow?.note || "");
            formik.setFieldValue("required", modalShow?.required || "");
            formik.setFieldValue("tag", modalShow?.tag || "")
            formik.setFieldValue("createdAt", modalShow?.createdAt || "")
            formik.setFieldValue("updatedAt", modalShow?.updatedAt || "")
            formik.setFieldValue("id", modalShow?.id || "")
            modalShow?.options?.length && formik.setFieldValue("options", modalShow?.options || QuestionsFormikObjEdit?.initialValues?.options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    if (!modalShow) {
        return null;
    }
    //  const handleQuestionTagValue = (value) => { setTagValue(value); };
    const handleQuestionTagValue = (value) => {
        const typeOfValue = typeof value;
        if (typeOfValue === 'string') {
            setTagValue(value);
        }
        if (typeOfValue === 'object') {
            //  navigate("/app/create-questions");
            return;
        }
    };

    const handleIsModalTagChange = (newIsModalTag) => {
        setIsModalTag(newIsModalTag);
    };

    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {isReadOnly ? "View" : "Edit"} Question
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {formik?.values?.category ? <QuestionForm btnLoading={btnLoading} formik={formik} handleShow={handleShow} isCreate={false} handleQuestionTagValue={handleQuestionTagValue} isReadOnly={isReadOnly} refreshTable={refreshTable} modalShow={modalShow} onIsModalTagChange={handleIsModalTagChange} /> : null}
            </Modal.Body>
        </Modal >
    );
}

export default EditQuestion;
