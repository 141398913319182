import { TextField } from '@mui/material';
import { CalendarPickerSkeleton, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useState } from 'react';

const onKeyDown = (e) => {
    e.preventDefault();
};

    /**
     * 
     * @param {object} formik 
     * @param {string} keyField 
     * @returns a div containing an error message or null
     * 
     * In the case of a multi-line form, so unavailability, or for example a dynamic length form, where you 
     * can add duplicate rows, the keyfields will be set in a groupname.rowindex.fieldname, however the formik
     * errors don't translate gracefully to this mult-row setup, and come as a JSON object like follows:
     * 
     * formik.errors = {
     *      groupname: [
     *          fieldname
     *      ]
     * }
     * where the rowindex is the index of the field in the array.
     */
const renderError = (formik, keyField) => {
    let keyFieldParts = keyField?.split('.');
    
    if(keyFieldParts?.length === 3 && formik?.errors[keyFieldParts[0]]) {
        let errorString = formik?.errors[keyFieldParts[0]]?.[keyFieldParts[1]]?.[keyFieldParts[2]];
        return   <div>
                    { errorString ? <div className="error-text">{errorString}</div>: null }
                 </div>
    }
    else {
        return <div>{formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
    }
}

function DateSelector({ keyField, formik, label, minDate = moment().subtract(200, "years"), maxDate = moment(), required = true, hasValue = false, readOnly = false, onDateChange }) {
    const [value, setValue] = useState(hasValue || "");
    const [open, setOpen] = useState(false)
    
    const handleChange = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        if(isValidDate){
            formik.setFieldValue(keyField, moment(val).format("MM/DD/YYYY"));
            setValue(val);
            
            if (onDateChange) {
                onDateChange(moment(val));
            }
        }else{
            formik.setFieldError(keyField, 'Invalid date format')
        }
    };

    return (
        <div className="field-wrap createPatientDOB">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={false}
                    inputFormat="MMM-DD-YYYY"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={hasValue ? moment(formik.values[keyField], "MM-DD-YYYY") : value}
                    onChange={handleChange}
                    readOnly={readOnly}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    renderLoading={() => <CalendarPickerSkeleton />}
                    renderInput={(params) => 
                        <TextField
                            {...params}
                            onKeyDown={onKeyDown}
                            onBeforeInput={onKeyDown}
                            // onClick={() => !readOnly && setOpen(true)}  {...params} error={false} 
                            inputProps={{
                                ...params.inputProps,
                                style: { textTransform: 'uppercase' } // Change text to uppercase
                            }}
                        />
                    }
                />
            </LocalizationProvider>
            {
                renderError(formik, keyField)
            }
        </div>
    )
}

export default DateSelector



export function DateSelectorWithoutFormik({componentName="", type, formik, keyField, isError = "", handleChange, onOpen, value, label, required = true, minDate, maxDate,readOnly = false, hideRequired= false, ...props  }) {
    const [open, setOpen] = useState(false)

    const handleKeyDown = (event) => {       
        if (event.keyCode === 8 && handleChange) {            
            handleChange(null);
        }
    };
    
    return (
        <div className="field-wrap createPatientDOB">
            {label ? <label>
                {label}
                {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    // label={label}
                    className={componentName}
                    value={formik?.values?.[keyField] || value}
                    inputFormat='MMM-DD-YYYY'
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(value)=>{setOpen(false);handleChange(value)}}
                    readOnly={readOnly}
                    onKeyDown={handleKeyDown}
                    {...props}
                    closeOnSelect
                    onOpen={() => {!readOnly && setOpen(true); if (onOpen) onOpen()}}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    sx={{
                        ".MuiInputBase-root": {
                            borderRadius: "10px",
                         },
                         ".MuiFilledInput-input": {
                            padding: "16.5px 14px",
                         }
                    }}
                    renderInput={(params) => 
                            <TextField
                                value={formik?.values?.[keyField] || value}
                                {...params}
                                placeholder="YYYY-MM-DD"
                                inputProps={{
                                    ...params.inputProps,
                                    readOnly: type === "readonly" ?  true : false,
                                    style: { textTransform: 'uppercase' } // Change text to uppercase
                                }}
                                
                                onBeforeInput={handleKeyDown}
                                onKeyDown={handleKeyDown}
                                // onClick={() => !readOnly && setOpen(true)}  
                                error={false} 
                            />
                    }
                />
            </LocalizationProvider>
            {/* <div>{isError ? <div className="error-text">{isError}</div> : null}</div> */}
            {
                renderError(formik, keyField)
            }
        </div>
    )
}
