import React, { useState } from "react";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { commonDisableAPI } from "../../api/CommonApis";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { activeOptions, activeNotOptionsDisabled, listTypeOptions, listTypeOptionsForAllOrgs, nameToVal } from "./Constants";
import { failed, success } from "../../common/Toastify";
import { EditIndividual } from "./edit-individual";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import Table from "react-bootstrap/Table";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import { Spinner, } from "react-bootstrap";
import EditIcon from "../../../assets/images/editIcon.svg";
import DeleteIcon from "../../../assets/images/delete.png";
import ViewIcon from "../../../assets/images/icomoon-free--profile.svg";
import { Link } from "react-router-dom";
import { AddRPMModal } from "./AddRPM";
import {getAllUsers} from "../../api/Individual";
import "./list.css";
import { useQueryParams } from "../../../hooks/useQueryParams";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import employeeIcon from '../../../assets/images/clarity--employee-line.svg';
import PatientIcon from '../../../assets/images/patients.png';
import addUserIcon from '../../../assets/images/ant-design--user-add-outlined.svg';
import adminIcon from '../../../assets/images/eos-icons--admin-outlined.svg'
import DeviceEditViewModal from "./DeviceEditViewModal";
import moment from "moment";
import { ArrowDropDown, ArrowDropUp, } from "@mui/icons-material";
import BookAppointment from "../../apps/AppointmentRequests/BookAppointment";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { safeAgeString, dateifier, ViewDate } from "../../../utils/DateSupport";
import { useSelector } from "react-redux";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import DashboardCard from "../Dashboard/dashboard-card";
import { Pagination } from "@mui/material"
import IndividualSearchBar from "../../common/components/individualSearch";

function ListUsers() {
    const { getConfirmation } = useConfirmDialog();
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState("AscName");
    const [devicemodalShow, setDeviceModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [isPatient, setIsPatient] = useState(false);
    const [isRelatedPerson, setIsRelatedPerson] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState({});
    const [selectedAll, setSelectedAll] = useState(false);
    const [addRPM, setAddRPM] = useState(false);
    const [inactive, setInactive] = useState(false);
    const [typeDisable, setTypeDisable] = useState(false);
    const [defaultSelectedProgram, setDefaultSelectedProgram] = useState(false);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const [selectedTab, setSelectedTab] = useState(() => admin ? "super-admin" : "employees")
    const { data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isRPMEnable = admin ? true : orgLicensedProducts?.RPM
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const loggedInUserId = useSelector((state) => state?.auth?.user?.["custom:unique_id"])
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const practitioner = userRoles?.includes('Practitioner');
    const hasAccess = ((permissions?.includes('Manage Individuals') && practitioner) || userRoles?.includes("Super-Admin"));
    const [searchVal, setSearchVal] = useState("");
    const [sortingBy, setSortingBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState();
    const rowsPerPage = 10; 

    const formik = useFormik({
        initialValues: {
            org: admin ? "All" : adminOrgs?.[0]?.id,
            active: true,
        },
    });

    useOrgQuery({
        onSuccess: (data) => {
            setOrganizationsData(data);
            setOrganizations(data?.map((org) => ({ name: org?.name, value: org?.id })))
            getUsersList({ orgId: adminOrgs?.[0]?.id, type: nameToVal[selectedTab], active: formik?.values?.active, limit: 10 , pageNumber: page + 1, searchQuery: searchVal });
        },
        refetchOnWindowFocus: false,
    })

    const handleShow = (data, isReadOnly = false, isDevice = false) => {
        isDevice ? setDeviceModalShow(devicemodalShow ? false : data) : setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
    };
    
    const handleView = (data) => { 
        const patientId = data.link?.find(link => link.target?.type === "Patient")?.target?.reference?.split("/")[1];  
        navigate("/app/patients-details", { state: { id: patientId, name: data?.name[0]?.text, isDoctor: false } })
    };
    const getUsersList = (apiArgs, active) => {
        setIsLoading(true);
        getAllUsers(apiArgs, active)
            .then((res) => {
                setIsLoading(false);
                setUsersList(res?.data);
                setTotalRows(res?.totalCount);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => { setIsLoading(false) });
    };

    //function for delete individual
    const handleDelete = async (id, entryName) => {
        const body = {
            key: "person",
            id,
            type: formik?.values?.type === "All" ? false : formik?.values?.type,
            userOrgId: adminOrgs?.[0]?.id
        };

        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Deactivate",
            message: "Are you sure you would like to deactivate "
        });

        if (confirmed) {
            handleShow()
            setIsLoading(true)
            setDeleting(id);
            commonDisableAPI(body)
                .then(() => {
                    setFilterData(filterData.filter((data) => data.id !== id));
                    success("Individual Deactivated!");
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => {setDeleting(false)
                                setIsLoading(false)});
        }
    };

    //To enable disabled person
    const handleEnableClick = async (id, entryName) => {
        const status = "enable";
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Activate",
            message: "Are you sure you would like to activate "
        });
        if (confirmed) {
            setBtnLoading(id);
            commonDisableAPI({
                key: "person",
                id,
                status,
            }).then(() => {
                setFilterData(filterData.filter((data) => data.id !== id));
                success("Activated Individual!");

            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        }
    };

    const hanldeAddRPM = (id, programId = "") => {
        setAddRPM(addRPM ? false : id);
        setDefaultSelectedProgram(programId)
    };

    const handleSelectAll = () => {
        setSelectedAll(!selectedAll);
        setSelectedPatients(
            selectedAll
                ? {}
                : filterData.reduce(
                    (a, v) => ({
                        ...a,
                        [v.id]: true,
                    }),
                    {}
                )
        );
    };

    const handleSelectedPatients = (id) => {
        selectedAll && setSelectedAll(false);
        setSelectedPatients({
            ...selectedPatients,
            [id]: !selectedPatients[id],
        });
    };

    const handleOrgChange = (event) => {
        const typeSelected = nameToVal[selectedTab]
        let isActive = formik?.values?.active;
        if (selectedTab === "employees") {
            setSelectedPatients({});
            setSelectedAll(false);
            setInactive(false);
            setTypeDisable(false);
        }
        let value = event?.target?.value;
        formik.setFieldValue("org", value);
        getUsersList({ orgId: value, type: typeSelected , active: isActive, limit: 10 , pageNumber: 1, searchQuery: searchVal})
        setPage(0);
    };

    const handleTypeChange = (val) => {
        setSelectedTab(val);
        const value = nameToVal[val];
        let orgId;
        orgId = formik?.values?.org;
        let isActive = formik?.values?.active;
        getUsersList({ orgId, type: value, active: isActive, limit: 10 , pageNumber: 1, searchQuery: searchVal })
        setIsPatient(value === "Patient");
        setIsRelatedPerson(value === "RelatedPerson")
        if (value !== "Patient") {
            setSelectedPatients({});
            setSelectedAll(false);
        }
        
        setPage(0);
    };

    const handleActiveChange = (event) => {
        const typeSelected = nameToVal[selectedTab]
        let orgId = formik?.values?.org;
        orgId = orgId === "All" ? "" : orgId;
        const value = event?.target?.value;
        formik.setFieldValue("active", value);
        if(value === "true"){
            setInactive(false);
        }else{
            setInactive(true)
        }
        
        getUsersList({ orgId, type: typeSelected, active: value, limit: 10 , pageNumber: 1, searchQuery: searchVal })
        setPage(0);
    };

    const handlerefresh = () => {
        getUsersList({
            orgId: formik?.values?.org, type: "Patient", limit: 10 , pageNumber: page + 1, searchQuery: searchVal
        });
    }

    const handleSort = (sort_by, sort_order) => {
        const sortOrder = sort_order === 'asc' ? 1 : -1;
        setSortingBy(sort_by);
        setSortOrder(sortOrder);
        getUsersList({
            orgId: formik?.values?.org, type: nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchVal, sortBy: sort_by, sortOrder: sortOrder
        });
    }

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                handleSort("name","asc")
                // setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) > decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) > decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "DscName":
                handleSort("name","desc")
                // setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) < decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) < decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "AscOrg":
                handleSort("managingOrganization","asc")
                // setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display > b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display > a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            case "DscOrg":
                handleSort("managingOrganization","desc")
                // setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display < b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display < a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            // case "AscRole":
            //     setFilterData(filterData?.sort((a, b) => (a.roleName > b.roleName) ? 1 : ((b.roleName > a.roleName) ? -1 : 0)))
            //     break;
            // case "DscRole":
            //     setFilterData(filterData?.sort((a, b) => (a.roleName < b.roleName) ? 1 : ((b.roleName < a.roleName) ? -1 : 0)))
            //     break;
            default:
                handleSort("name","asc")
                // setFilterData(filterData)
                break;
        }
    }
    const computeOptions = (org) => {
        let res =  org.filter(org => org.value !== adminOrgs?.[0]?.id)
        let firstOrg;
        if(admin){
            firstOrg = {name: "All", value: "All"};
            if(selectedTab === "super-admin"){
                return [firstOrg]
            }else{
                return [firstOrg, ...org];
            }
        }
        return [{name: adminOrgs?.[0]?.name, value:adminOrgs?.[0]?.id }, ...res]
    }

    // filterData.sort((a, b) => {
    //     if (a?.id === loggedInUserId) return -1; 
    //     if (b?.id === loggedInUserId) return 1;
    //     return 0;
    //   })

    const handlePage = async(pageNo) => {
        setPage(pageNo)
        let orgId = formik?.values?.org;
        let isActive = formik?.values?.active;
        getUsersList({ orgId, active: isActive ,type: nameToVal[selectedTab], limit: 10 , pageNumber: pageNo + 1, searchQuery: searchVal, sortBy: sortingBy, sortOrder: sortOrder})
    };

    // Search handler
    const handleSearch = (searchQuery) => {
        setSearchVal(searchQuery)
        getUsersList({ orgId : formik?.values?.org, active: formik?.values?.active ,type: nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchQuery})
    };
    
    return (
        <section className="common-listing">
            {modalShow ?
                <EditIndividual
                    modalShow={modalShow}
                    handleShow={handleShow}
                    handleDelete ={handleDelete}
                    handleEnableClick = {handleEnableClick}
                    refreshTable={() => getUsersList({ orgId: formik?.values?.org, type: formik?.values?.type === "All" ? "" : nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchVal })}
                    isReadOnly={readOnly}
                    inactive={inactive}
                    typeOfUser={formik?.values?.type === "All" ? "" : formik?.values?.type}
                    organizations={organizations}
                    organizationsData={organizationsData}  
                /> : null}

            {devicemodalShow ?
                <DeviceEditViewModal
                    modalShow={devicemodalShow}
                    handleHide={handleShow}
                    refreshTable={() => getUsersList({ orgId: formik?.values?.org, type: formik?.values?.type, limit: 10 , pageNumber: page + 1, searchQuery: searchVal })}
                    isReadOnly={readOnly}
                /> : null}

            {addRPM ? <AddRPMModal modalShow={addRPM} refreshPatients={() => handlerefresh()} handleShow={hanldeAddRPM} programId={defaultSelectedProgram} orgId={formik?.values?.org} /> : null}
                    <Row className="user-Management-header">
                        {admin && <Col md="auto" onClick={() => handleTypeChange("super-admin")}>
                            <DashboardCard
                                title={"Super-Admin"}
                                icon={adminIcon}
                                selected={selectedTab==="super-admin"}
                            />
                        </Col>}
                        <Col md="auto" onClick={() => handleTypeChange("employees")}>
                            <DashboardCard
                                title={"Employees"}
                                icon={employeeIcon}
                                selected={selectedTab==="employees"}
                            />
                        </Col>
                        <Col md="auto" onClick={() => handleTypeChange("patients")}>
                            <DashboardCard
                                title={"Patients"}
                                icon={PatientIcon}
                                selected={selectedTab==="patients"}
                            />
                        </Col>
                        <Col md="auto">
                            <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                                    <SelectFiled
                                        keyField={"org"}
                                        label={false}
                                        formik={formik}
                                        selectLabel={"Select Organization"}
                                        options={computeOptions(organizations)}
                                        onChange={handleOrgChange}
                                        required={false}
                                        optional={false}
                                        readOnly={isLoading}
                                    />

                                </form>
                        </Col>
                        <Col md="auto">
                            <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                                    
                                    <SelectFiled
                                        keyField={"active"}
                                        label={false}
                                        formik={formik}
                                        options={activeOptions}
                                        onChange={handleActiveChange}
                                        required={false}
                                        optional={false}
                                        readOnly={isLoading || !formik?.values?.org}
                                    />
                                </form>
                        </Col>
                        
                        <Col style={{display:"flex", justifyContent: "flex-end"}}>
                            <IndividualSearchBar page={page} setPage={setPage} encoded={true} list={usersList} filterData={(data) => setFilterData(data)} onSearch={handleSearch} searchVal={searchVal} setSearchVal={setSearchVal}/>
                            
                        </Col>
                        <Col md="auto" style={{display:"flex", justifyContent: "flex-end"}}>
                             <Button
                                onClick={() => navigate("/app/create-individual")}
                                title="Create User"
                            >
                            <img style={{width:"26px", height:"26px"}}src={addUserIcon} />
                                Create User
                            </Button>
                        </Col>
                    </Row>
            {/* <div className="heading-wrap">
                   
                <div className="filter-row-blc d-flex flex-wrap">
                
                    <div className="btns-col ms-auto">
                        {isPatient ? (
                            <>
                                {Object.values(selectedPatients).filter((n) => n).length >= 1 ? (
                                    <Button
                                        onClick={() =>
                                            hanldeAddRPM(
                                                Object.entries(selectedPatients)
                                                    .filter(([key, value]) => value)
                                                    .map((n) => n[0])
                                            )
                                        }
                                        className="custom-btn"
                                        title="Multiple assign rpm"
                                        style={{
                                            width: "auto",
                                        }}
                                    >
                                        Multiple Assign RPM
                                    </Button>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </div>
            </div> */}
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            {/* {isPatient ? (
                                <th>
                                    <label className="custom-checkbox patient-list">
                                        <input type="checkbox" checked={selectedAll} onChange={handleSelectAll} />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                            ) : null} */}
                            <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>Name
                                {sortBy === "AscName" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>
                            {admin && <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscOrg" ? "DscOrg" : "AscOrg")}>Organization
                                {sortBy === "AscOrg" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>}
                            {selectedTab === "employees"
                                ? <th style={{ cursor: "pointer" }} /* onClick={() => sortFilterData(sortBy === "AscRole" ? "DscRole" : "AscRole")} */>Role
                                    {/* {sortBy === "AscRole" ? <span>
                                        <ArrowDropUp />
                                    </span> : <span>
                                        <ArrowDropDown />
                                    </span>} */}
                                </th>
                                : null}
                            {selectedTab === "patients" && <th>Date Of Birth</th>}
                            {selectedTab === "employees" ? null : <th>Email</th>}
                            <th>Phone Number</th>
                            <th
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    {selectedTab!== "employees" && <td>
                                        <Skeleton animation="wave" />
                                    </td>}
                                    
                                    {admin && selectedTab!=="super-admin"   ? <td>
                                        <Skeleton animation="wave" />
                                    </td> : null}
                                    
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.map((data) => {
                                const { name, birthDate, telecom, managingOrganization, id, type, createdAt } = data;
                                const isDevice = type?.[0] === "Device";
                                const decryptedBirthDate = decryptData(birthDate)?.replace(/-/g,'/');
                                const parts = decryptedBirthDate?.split('/');
                                const dateFormat = parts?.join('-');
                                return (
                                    <tr key={id}>
                                        {/* {isPatient ? (
                                            <td>
                                                <label className="custom-checkbox patient-list">
                                                    <input type="checkbox" checked={selectedPatients?.[id] || false} onChange={() => handleSelectedPatients(id)} /> <span className="checkmark"></span>
                                                </label>
                                            </td>
                                        ) : null} */}
                                        <td className="name-text" style={{ textTransform: 'capitalize' }}><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{decryptData(name?.[0]?.text || "-")}</span></td>
                                        {admin && <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{managingOrganization?.[0]?.display || "-"}</span></td>}
                                        {selectedTab === "employees"
                                            ? <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{data?.roleName}</span></td>
                                            : null}
                                        {selectedTab === "patients" && <td style={{ whiteSpace: "nowrap" }}><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{dateifier(decryptedBirthDate) && ViewDate(dateFormat) || '-'}</span></td>}
                                        {selectedTab === "employees" ? null : <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{telecom?.find((ele) => ele?.system === "email")?.value || "-"}</span></td>
                                        }
                                        <td style={{ whiteSpace: "nowrap" }}><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{decryptData(telecom?.find((ele) => ele?.system === "phone")?.value || "-")}</span></td>
                                        <td>
                                            <div className="action-wrap user">
                                                {inactive ? (
                                                    <Button
                                                        onClick={() => handleEnableClick(id, decryptData(name?.[0]?.text))}
                                                        isLoading={btnLoading === id}
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        Activate
                                                    </Button>
                                                ) : (
                                                    <>
                                                        {(!inactive && selectedTab === "patients") && <Tooltip title="View">
                                                            <div onClick={() => handleView(data)}>
                                                                <img src={ViewIcon} alt="View" />
                                                            </div>
                                                        </Tooltip>}
                                                        <Tooltip  title={"Update Details"}>
                                                            <Link  to="" onClick={() => isDevice ? handleShow(id, false, true) : handleShow(data, false)} disabled={!hasAccess} >
                                                                <img src={EditIcon} alt="Update" />
                                                            </Link>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan={isPatient ? "7" : "5"} style={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {filterData?.length > 0 ? 
            <div className="pagination-wrap">
                <p style={{ marginBottom: Math.ceil(totalRows / rowsPerPage) > 1 ? "10px" : "0" }}>
                    Showing {totalRows > 0 ? Math.min(page * rowsPerPage + 1, totalRows) : 0}-{totalRows > 0 ? Math.min(page * rowsPerPage + rowsPerPage, totalRows) : 0} of {totalRows}
                </p>
                <Pagination
                    page={page + 1}
                    count={Math.ceil(totalRows / rowsPerPage)}
                    color="primary"
                    boundarycount={1}
                    onChange={(event, newPage) => handlePage(newPage - 1)}
                />
            </div> : null}
        </section>
    );
}

export default ListUsers;
