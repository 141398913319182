import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { genderOptions } from "./Constant";
import moment from "moment";
import Fields from "./Fields";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { Button } from "../../common/Button";
import { createUser, getPractitionerList, getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import { createContactNumber } from "../../../utils/phoneNumberSupport";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { safeAgeString } from "../../../utils/DateSupport";
import MultiSelect from "../../common/textfield/MultiSelect";
import { createAccountOptions } from "../Individuals/Constants";
import { useSelector } from "react-redux";
import { getPatientByPersonId } from "../../apps/api/Patient";
import * as Yup from "yup";

const AddPatient = ({ modalShow, formikInBoundCall, handleShow, getAddedPatient }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [organizationsData, setOrganizationsData] = useState([]);
  // const [defaultAssignedPractitioner, setDefaultAssignedPractitioner] = useState([]);
  const [generalPractitionerOptions, setGeneralPractitionerOptions] = useState([]);
  // const [generalPractitionerData, setGeneralPractitionerData] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const userOrg = useSelector((state) => state?.auth?.user?.organizations)
  const userOrgId = userOrg?.[0]?.id;
  const [createAccountValue, setCreateAccountValue] = useState(true);

    //Fetching Organization Data
    const onSuccessOrg = (data) => {
      setOrganizationsData(data);
    };

    useOrgQuery({ onSuccess: onSuccessOrg });
    
  const formik = useFormik({
    initialValues: {
      orgId: "",
      orgName: "",
      first_name: "",
      last_name: "",
      date_of_birth: formikInBoundCall.values.date_of_birth || "",
      gender : "",
      country: "",
      city:"",
      region: "",
      address: "",
      postalCode: "",
      email: "",
      phone_number: "",
      general_practitioner: "",
      hcn: "",
      ext: "",
      create_account : ""
  },
    validationSchema: Yup.object({
      first_name:  Yup.string().max(15, "Must be 15 characters or less").required("Required first name field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      last_name: Yup.string().max(15, "Must be 15 characters or less").required("Required last name field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      date_of_birth: Yup.string().required("Required Date Of Birth field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
      gender: Yup.string().required("Required gender field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      country: Yup.string().required("Required country field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      region: Yup.string().required(`Required field`).matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      address: Yup.string().required("Required address field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      postalCode: Yup.string().when(
          ["country"],
          (country) =>
            country === "US"
              ? Yup.number()
                  .typeError("Zip code must be a number")
                  .required("Required field")
              : Yup.string()
                  .required("Required field")
                  .matches(
                    /^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/,
                    "Postal code must be in A1A 1A1 format."
                  ),
          { is: (value) => value !== undefined }
      ),
      email: Yup.string().when("create_account", {
        is: "true", 
        then: Yup.string()
          .email("Invalid email address")
          .required("Required email field")
          .matches(/^\S+$/, "Only blank spaces are not valid."),
        otherwise: Yup.string().email("Invalid email address")
      }),
      phone_number: Yup.string().matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Phone Number."),
      city: Yup.string().required("Required city field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      // general_practitioner: Yup.array(),
      hcn: Yup.string().required("HCN is required when creating a patient."),
      ext: Yup.string().matches(/^\d{0,4}$/, "Must be 0 to 4 digits."),
      create_account : Yup.string().required("Required Create Account field")
    }),
    onSubmit: (values) => {
      const state = values?.country + "-" + values?.region;
      let phoneNumber = createContactNumber(values);
      // const generalPractitionersSelected = generalPractitionerData?.filter((data) => values?.general_practitioner?.includes(data.id));
      let encryptedData = {
        first_name: encryptData(values?.first_name.toLowerCase()),
        last_name: encryptData(values?.last_name.toLowerCase()),
        full_name: encryptData([values?.first_name.toLowerCase(), values?.last_name.toLowerCase()].join(" ")),
        address: encryptData(values?.address),
        date_of_birth: encryptData(moment(values?.date_of_birth).format("MM-DD-YYYY")),
        phone_number: encryptData(phoneNumber),
        postalCode: encryptData(values?.postalCode.toUpperCase()),
        city: encryptData(values?.city),
        gender: encryptData(values?.gender),
        email: encryptData(values?.email?.toLowerCase()),
        healthCardNumber: encryptData(values?.hcn)
      };      
      setBtnLoading(true)
      values.type = "Patient"
      values.organization = values.orgId
      createUser(
        values,
        encryptedData,
        organizationsData,
        state,
        "Patient",
        [],
        [],
        userOrgId
    ).then((res) => {
          if (res.status === true) {
            getPatientByPersonId({orgId: res.data.managingOrganization[0].reference.split('/')[1], patient_id: res.data.id})
              .then((res1) => {
                if (res1.status === true) {
                  getAddedPatient( res1.result);
                  handleShow();  
                  success(res1.message);
                  setBtnLoading(false)
                } else {
                  setBtnLoading(false)
                  failed(res.error);
                }
              })
              .catch((res1) => {
                failed(
                  res1?.response?.data?.message ||
                  res1?.response?.data?.error ||
                  res1.message
                );
              })
              .finally(() => setBtnLoading(false));    
          } else {
            setBtnLoading(false)
            failed(res.error);
          }
        })
        .catch((res) => {
          console.log("error", res)
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });

  const handleCreateAccountChange = useCallback((event) => {
    const value = event?.target?.value; 
    if (value == "true") { 
      setCreateAccountValue(true);
    }
    else{ 
      setCreateAccountValue(false);
    }   
    formik?.setFieldValue("create_account", value);
  }, [formik, setCreateAccountValue]); 

  const handleDateChange = (val) => {
    const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
    if(val == null || val == ""){
     formik.setFieldValue("date_of_birth", val || ""  );
   }else{
    if(isValidDate){
      formik.setFieldValue("date_of_birth", val.format("YYYY-MM-DD") );
    }else{
      formik.setFieldError('date_of_birth', 'Invalid date format')
    }
   } 
};

  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    let spacedValue = value;
    if (formik?.values?.country !== "US") {
      // Remove spaces and non-alphanumeric characters
      const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
      // Add a space after every 3 letters
      spacedValue = formattedValue.replace(
        /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
        "$1 $2"
      );
    }
    // Set the formatted value in the formik
    formik?.setFieldValue("postalCode", spacedValue);
  };

  useEffect(() => {
    formik.setFieldValue("orgName", formikInBoundCall.values.orgName)
    formik.setFieldValue("orgId", formikInBoundCall.values.orgId)
    formik.setFieldValue("hcn", formikInBoundCall.values.hcn)
    formik.setFieldValue("date_of_birth", formikInBoundCall.values.date_of_birth)
    formik.setFieldValue("first_name", formikInBoundCall.values.first_name || "")
    formik.setFieldValue("last_name", formikInBoundCall.values.last_name || "")

    const phoneNumberAnswer = formikInBoundCall.values.questionResponse.find(q => q.linkId === 2)?.answer || []; 
    formik.setFieldValue("phone_number", phoneNumberAnswer[0]?.answer || "")
    
    getSingleUser({ orgId: formikInBoundCall.values.orgId , type: "Patient" })
      .then((res) => {
        // formik.setFieldValue("general_practitioner",res?.data?.generalPractitioner?.map((list) => list?.reference.split("/")[1]) || []);
        // setDefaultAssignedPractitioner(res?.data?.generalPractitioner?.map((list) => list?.reference.split("/")[1]));
        let selected_org = res?.data?.managingOrganization?.[0]?.reference?.split("/")?.[1];
        let scopeOfPrac = res?.data?.address?.[0]?.state
        // getPractitionerList(formikInBoundCall.values.orgId, scopeOfPrac)
        // .then((res) => {
        //   setGeneralPractitionerData(res?.result);
        //   setGeneralPractitionerOptions(
        //     res?.result?.map((list) => ({
        //       name: decryptData(list?.name?.[0]?.text),
        //       value: list?.id,
        //       id: list?.id,
        //     }))
        //   );
        // })
        // .catch((res) => {
        //   failed(res?.response?.data?.message || res?.response?.data?.error || res?.message);
        // });
      })
      .catch((res) => {
        console.log("error fetching practioner list", res);
      });
  }, [])

  const handleExt = (event) => {
    let key = event.key;
    let regex = new RegExp("^[0-9]+$");
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };  
  return (
    <Modal
      show={modalShow}
      onHide={handleShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Patient
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-card p-4">
          <form className="common-form border-fields">
            <Row>
              <Col>
                <TextInput
                  keyField={"orgName"}
                  label={"Organization"}
                  formik={formik}
                  placeholder={"Organization"}
                  disabled={true}
                />
              </Col>
              <Col>
                <SelectFiled
                  keyField={"create_account"}
                  label={"Create Account"}
                  formik={formik}
                  placeholder={"Create Account"}
                  options={createAccountOptions}
                  onChange={handleCreateAccountChange}
                />
                </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  keyField={"first_name"}
                  label={"First Name"}
                  formik={formik}
                  placeholder={"First Name"}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"last_name"}
                  label={"Last Name"}
                  formik={formik}
                  placeholder={"Last Name"}
                />
              </Col>
              <Col>
                <DateSelectorWithoutFormik
                  formik={formik}
                  keyField={'date_of_birth'}
                  label="Date of birth"
                  value={moment(formik.values.date_of_birth)}                 
                  handleChange={handleDateChange}                
                  maxDate={moment().format("YYYY-MM-DD")}              
                  isError={
                    isClick === true && formik.errors.date_of_birth 
                      ? formik.errors.date_of_birth
                      : formik.values.date_of_birth !== "" && <p style={{ fontSize: '14px',color:"black" }}>{safeAgeString(formik.values.date_of_birth)}</p>
                  }
                />               
               {/* {formik.values.date_of_birth !== "" && <p style={{ fontSize: '12px',color:"black" }}>{safeAgeString(formik.values.date_of_birth)}</p>} */}
              </Col>
              <Col>
                <SelectFiled
                  keyField={"gender"}
                  label={"Select Gender"}
                  formik={formik}
                  options={genderOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CountrySelect
                  keyField={"country"}
                  label={"Country"}
                  formik={formik}
                />
              </Col>
              <Col>
                <RegionSelect
                  defaultOptionLabel={
                    formik?.values?.country === "US"
                      ? "Select State"
                      : "Select Province"
                  }
                  keyField={"region"}
                  label={
                    formik?.values?.country === "US" ? "State" : "Province"
                  }
                  formik={formik}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"city"}
                  label={"City"}
                  formik={formik}
                  placeholder={"city"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  keyField={"address"}
                  label={"Address"}
                  formik={formik}
                  placeholder={"Address"}
                />
              </Col>
              <Col>
                <TextInput
                  onChange={handlePostalCodeChange}
                  keyField={"postalCode"}
                  label={
                    formik?.values?.country === "US"
                      ? "Zip Code"
                      : "Postal Code"
                  }
                  formik={formik}
                  placeholder={
                    formik?.values?.country === "US"
                      ? "Zip Code"
                      : "Postal Code"
                  }
                  maxLength={formik?.values?.country === "US" ? "5" : "7"}
                />
              </Col>
              <Col>
              <TextInput
                keyField={"hcn"}
                label={"HCN"}
                formik={formik}
                placeholder={"HCN"}
                required={true}
              /></Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  keyField={"email"}
                  label={"Email Address"}
                  formik={formik}
                  placeholder={"Email Address"}
                  hideRequired= {!createAccountValue}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"phone_number"}
                  type="phone"
                  label={"Phone Number"}
                  formik={formik}
                  placeholder={"Phone Number"}
                  required={false}
                />
              </Col>
                <Col>
                  <TextInput
                    keyField={"ext"}
                    label={"Ext."}
                    type="text"
                    maxlength="4"
                    formik={formik}
                    placeholder={"Ext."}
                    required={false}
                    onKeyPress={handleExt}
                    autocomplete="off"
                  />
                </Col>
            </Row>
            {/* {formik?.values?.region && <Row>
            <Col>
              <MultiSelect
                options={generalPractitionerOptions?.map((option) => ({
                  ...option,
                  disabled: true,
                  checked: defaultAssignedPractitioner?.includes(option?.value),
                }))}
                keyField={"general_practitioner"}
                formik={formik}
                label={"Assign Practitioner"}
                nodata={"No Data Found"}
                required={false}
                defaultValue={defaultAssignedPractitioner}
                isSelectAll={defaultAssignedPractitioner?.length === generalPractitionerOptions?.length}
                isSelectAllEnable={false}
                />
                </Col>
            </Row>} */}
            <div className="btn-wrap" style={{ display: "flex", gap: "16px" }}>
              <Button type="button" isLoading={btnLoading}  onClick={() => [formik.handleSubmit(), setIsClick(true) ]}>
                Create
              </Button>
              <Button
                onClick={() => !btnLoading && handleShow()}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPatient;
