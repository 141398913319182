import React, { useContext, useEffect, useRef, useState , } from "react";
import './message.css';
import './audio-video.css';
import AddUserIcon from '../../assets/images/add-user.png';
import AddNoteIcon from '../../assets/images/add-note.png';
import labIcon from '../../assets/images/chemistry.png';
import medicalIcon from '../../assets/images/medical-prescription.png';
import diagnosticIcon from '../../assets/images/analytics.png';
import referralIcon from '../../assets/images/referral.png';
import ExtendIcon from '../../assets/images/extend.svg';
import CallIcon from '../../assets/images/call-border.png';
import CallPark from '../../assets/images/callPark.png';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from "../../ContextsAndProvider/Zoom-Context";
import { BindEvents, toggleFullscreen } from "./functions";
import { failed, info, success } from "../common/Toastify";
import { Tooltip, IconButton, Menu, MenuItem, MenuList, ClickAwayListener, ListItemIcon } from "@mui/material";
import { KeyboardArrowDown, CallEndOutlined, Fullscreen, FullscreenExit, MicOffOutlined, MicOutlined, PhoneForwardedOutlined, VideocamOffOutlined, VideocamOutlined, MenuTwoTone, ArrowDownward, Check, Subject } from "@mui/icons-material";
import { InviteInSeesion } from "./InviteInSession";
import { Button } from "../common/Button";
import { AddNote } from "./AddNote";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FullPageSpinner } from "../common/Spinner/FullPageSpinner";
import { decryptData } from "../EncryptDecrypt";
import { useBeforeUnload } from "react-use";
import { endWaitingRoomMeeting } from "../apps/api/WaitingRoom";
import { updateAppointmentRequest, getEncounterRecord } from "../apps/api/AppointmentRequests";
import { ZoomChatBox } from "./ZoomChatBox";
import QuestionnaireBox from "./QuestionnaireBox";
import { axios } from "../../lib/axios";
import { useGetOrgLicensedProducts } from "../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { getOrganization } from "../api/Organization";
import { getSingleUser } from "../api/Individual";
import moment from "moment";
import EncounterDetail from "../apps/Patient/EncounterDetail";
import ScheduleAppointmentWR from '../apps/Queue/ScheduleAppointmentWR';
import ConfirmationModal from "../common/ConfirmationModal";

/**
 * 
 * @param {string} name 
 * @returns string with prefix removed if any
 */
const getUnPrefixedName = (name) => {
    return (name.indexOf(" - ") >= 0) ? name.substring(name.indexOf(" - ") + 3).trim(): name;
}
/**
  * getCameras
  * 
  * retrieves a list of system cameras without a zoom stream.
  * @returns array of camera objects if any
  */
const getCameras = async () => {
    let devices = await navigator.mediaDevices.enumerateDevices();
    let filtered = devices.filter(item => item.kind == "videoinput" && item.label.indexOf("(Virtual)") < 0); // video devices
    // remove duplicates
    let cameras = filtered.reduce((acc, current) => {
        if(!acc.find((item) => (item.groupId === current.groupId && item.label.includes(getUnPrefixedName(current.label))))) {
            acc.push(current);
        }
        return acc;
    }, [])
    return cameras;    
}
/**
  * getMicrophones
  * 
  * retrieves a list of system cameras without a zoom stream.
  * @returns array of microphone objects if any
  */
const getMicrophones = async () => {
  let devices = await navigator.mediaDevices.enumerateDevices();
  let filtered = devices.filter(item => item.kind == "audioinput" && item.label.indexOf("(Virtual)") < 0); // video devices
  // remove duplicates
  let microphones = filtered.reduce((acc, current) => {
      if(!acc.find((item) => (item.groupId === current.groupId && item.label.includes(getUnPrefixedName(current.label))))) {
          acc.push(current);
      }
      return acc;
  }, [])
  return microphones;    
}

function ZoomCreate() {
    const navigate = useNavigate()
    const { state } = useLocation();
    const [isInMeeting, setisInMeeting] = useState(false);
    useBeforeUnload(isInMeeting, 'You are in onGoing meeting, exit will end your meeting!');
    const { topic, sessionToken, password, appointmentData , patientName, questionnairInfo, encounterId} = state
    const [modalShow, setModalShow] = useState(false);
    const [encounterDetailsShow, setEncouterDetailsShow]= useState(false);
    const [addNoteModal, setAddNoteModal] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [chatClient, setChatClient] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const [participants, setParticipants] = useState([]);
    const [isCameraLoading, setIsCameraLoading] = useState(false);
    const [isAudioLoading, setIsAudioLoading] = useState(false);
    const [currentSession, setCurrentSession] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('');
    const [sessionChat, setSessionChat] = useState([]);
    const [showChat, setShowChat] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [isMicOn, setIsMicOn] = useState(false);
    const chatInput = useRef();
    const [isAudioStreaming, setIsAudioStreaming] = useState(false);
    const [encounterFinished, setEncounterFinished] = useState(false);
    const [orgnization, setOrganizations] = useState({})
    const [isFull, setIsFull] = useState(false);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const zmClient = useContext(ZoomContext);
    const audioDecode = useRef();
    const audioEncode = useRef();
    const user = useSelector((state) => state?.auth?.user);
    const practitionerName = appointmentData?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.display;
    const practitionerId = appointmentData?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.reference?.split("/")?.[1];
    const patientId = appointmentData?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.reference?.split("/")?.[1];
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const admin = userRoles?.includes('Super-Admin');
    const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare
    const [practionerData, setPractionerData] = useState({})
    const [encounterRecord, setEncounterRecord] = useState({});

    const [scheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
    const [savedQuestionResponseId, setSavedQuestionResponseId] = useState("");

    // multi-camera and mic logic
    const [availableCameras, setAvailableCameras] = useState(null)
    const [cameraCount, setCameraCount] = useState(0);
    const [camAnchor, setCamAnchor] = useState(null);
    const camOpen = Boolean(camAnchor);
    const [selectedCamera, setSelectedCamera] = useState(null);
    
    const [availableMicrophones, setAvailableMicrophones] = useState(null)
    const [micCount, setMicCount] = useState(0);
    const [micAnchor, setMicAnchor] = useState(null);
    const micOpen = Boolean(micAnchor);
    const [selectedMicrophone, setSelectedMicrophone] = useState(null);
    const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] = useState(false);
    const [confirmmodalShow, setconfirmmodalShow] = useState(false);
    const formikRef = useRef(null);

    const startVideo = () => {
        setIsCameraLoading(true)
        if (mediaStream.isCameraTaken() && mediaStream.isCaptureForbidden()) {
            failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
        } else {
            // console.log(mediaStream?.isSupportVirtualBackground());
            mediaStream.startVideo({ videoElement: document.querySelector('#self-view-video'), virtualBackground: mediaStream?.isSupportVirtualBackground() ? { imageUrl: 'blur' } : false }).then(() => {
                // console.log("----------------current user started video----------");
                // console.log(zmClient.getAllUser());
                setIsCameraOn(true);
                setIsCameraLoading(false)
            }).catch((error) => {
                console.log("---------camera error----------", error);
                setIsCameraLoading(false)
                failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
            })
        }
    }

    const startAudio = () => {
        setIsAudioLoading(true)
        var isSafari = window.safari !== undefined
        if (isSafari) {
            if (audioEncode.current && audioDecode.current) {
                mediaStream.startAudio()
                setIsAudioStreaming(true);
                setIsAudioLoading(false);
            } else {
                // console.log('safari audio has not finished initializing')
            }
        } else {
            mediaStream.startAudio()
            setIsAudioStreaming(true);
            setIsAudioLoading(false);
        }
    }

    useEffect(() => {
        getCameras().then(data => {
            setAvailableCameras(data);
            setCameraCount(data.length);
            if (data.length > 0) {
                setSelectedCamera(data[0].deviceId) // set first one as default;
                console.log("USING ", data[0].label);
            }
        });
        getMicrophones().then(data => {
            setAvailableMicrophones(data);
            setMicCount(data.length)
            if(data.length > 0) {
                setSelectedMicrophone(data[0].deviceId)
                console.log("USING ", data[0].label);
            }
        });

        const init = async () => {
            // get system cameras manually, before zoom session in started
            await zmClient.init('en-US');
            try {
                if (!currentSession) {
                    setIsLoading(true);
                    setLoadingText('Joining the session...');
                    // if the state variable has cameras, use them, otherwise
                    // user the existing manual retrieve, as setState sometimes has a 
                    // lag.
                    setisInMeeting(true)
                    await zmClient.join(decryptData(topic), sessionToken, decryptData(user?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), decryptData(password)).then((res) => {
                        setCurrentSession(res);
                        setCurrentUser(zmClient.getCurrentUserInfo());
                        setIsLoading(false);
                        
                        const chat = zmClient.getChatClient();
                        setSessionChat(chat.getHistory());
                        const stream = zmClient.getMediaStream();
                        setParticipants(zmClient.getAllUser())
                        zmClient.on('chat-on-message', (payload) => {
                            setSessionChat(payload?.sender?.userId === zmClient?.getCurrentUserInfo()?.userId ? chat.getHistory() : [...chat.getHistory(), payload])
                        })

                        zmClient.on('media-sdk-change', (payload) => {
                            if (payload.type === 'audio' && payload.result === 'success') {
                                if (payload.action === 'encode') {
                                    audioEncode.current = true
                                } else if (payload.action === 'decode') {
                                    audioDecode.current = true
                                }
                            }
                        })

                        // if any user joins the session
                        zmClient.on('user-added', (payload) => {
                            localStorage.setItem("userJoined",1)
                            if(payload[0]?.isHost === true) {
                                if(localStorage.getItem("userJoined") !== 1 || localStorage.getItem("userJoined") === null){
                                   payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                                }
                            }else{
                                payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                            }
                            setParticipants(zmClient.getAllUser())
                            // console.log("----- User Joined ----->", payload[0].userId, payload[0].displayName);
                        })

                        // if user left or removed from the session
                        zmClient.on('user-removed', (payload) => {
                            //info(`${payload[0]?.userId} left the session`)
                            setParticipants(zmClient.getAllUser())
                            // console.log(payload[0]?.userId, ' left the session', zmClient.getAllUser());
                        })

                        zmClient.on('device-change', async () => {
                            let cameras = await getCameras() //stream.getCameraList();
                            setAvailableCameras(cameras);
                            setCameraCount(cameras.length);

                            let microphones = await getMicrophones() // stream.getMicList();
                            setAvailableMicrophones(microphones);
                            setMicCount(microphones.length);
                        })

                        BindEvents(zmClient, stream)
                        stream.switchCamera(selectedCamera);
                        stream.switchMicrophone(selectedMicrophone);
                        setMediaStream(stream);
                        setChatClient(chat);
                    }).catch((err) => {
                        setIsLoading(false);
                        failed(err.reason || err.type)
                        navigate(-1)
                    });
                }
            } catch (e) {
                setIsLoading(false);
                failed(e.reason);
                navigate(-1)
            }
        };
        if (topic && sessionToken && password) init();
        return () => {
            ZoomVideo.destroyClient();
            zmClient.off('user-removed', () => { })
            zmClient.off('user-added', () => { })
            zmClient.off('media-sdk-change', () => { })
            zmClient.off('chat-on-message', () => { })
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, sessionToken, topic, user.name, zmClient]);

    useEffect(() => {
        const fetchEncounterRecord = async () => {
            if (encounterId) {
                try {
                    const response = await getEncounterRecord({encounterId: encounterId}); 
                    setEncounterRecord(response.data);
                    console.log("Encounter Record:", encounterRecord);
                } catch (error) {
                    console.error("Failed to fetch encounter record:", error);
                }
            }
        };
        fetchEncounterRecord();
    }, [encounterId]);

    useEffect(() => {
        const apiBody = {
          id: user?.["custom:unique_id"],
          type: "Practitioner",
          userOrgId: adminOrgs?.[0]?.id
        };
        getSingleUser(apiBody)
          .then((res) => {
            setPractionerData(res?.data)
          })
          .catch((res) => {
            console.log("error in image upload",res)
          })
        return () => {
            localStorage.removeItem("questionResponseId");
            localStorage.removeItem("questionnaireResponse");
        }
    }, []);

    const handleCamera = (event) => {
        if(cameraCount > 1 && !isCameraOn && !selectedCamera) 
            switchCamera(event);     
        else {    
            isCameraOn ? mediaStream.stopVideo() : startVideo()
            isCameraOn && setIsCameraOn(false)
        }
    }
    const handleMic = (event) => {
        if(micCount > 1 && !isMicOn && !selectedMicrophone) 
            switchMicrophone(event);
        else {
            !isAudioStreaming && startAudio()
            isMicOn ? mediaStream.muteAudio() : mediaStream.unmuteAudio();
            setIsMicOn(!isMicOn)
        }
    }

    const switchCamera = (event, deviceId) => {
        if(cameraCount > 1 && !deviceId) {
            setCamAnchor(event.currentTarget)
        }
        else {
            mediaStream.switchCamera(deviceId)
            setSelectedCamera(deviceId)
            if(!isCameraOn) handleCamera(event);
        }      
    }

    const switchMicrophone = (event, deviceId) => {
        if(micCount > 1 && !deviceId) {
            setMicAnchor(event.currentTarget);
        }
        else {
            mediaStream.switchMicrophone(deviceId)
            setSelectedMicrophone(deviceId)
            if(!isMicOn) handleMic(event)
        }
    }

    const handleEndSession = () => {
        if(encounterFinished){
            setEncounterFinished(true);
            return;
        }
        setIsLoading(true)
        setLoadingText("Ending Session")
        localStorage.removeItem("userJoined")
        zmClient.leave(true).then(() => {
            document.getElementById("room").replaceChildren();
            document.getElementById("room").style.backgroundColor = "#fff";
            state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
                .then((res) => success(res.message))
                .catch((err) => console.log(err))
            
            if (state?.appointmentData) {
                const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                return updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                    .then(async(res) => { 
                        if(res?.status === true){
                            setEncounterFinished(true);
                        }
                    })
                    .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
            }
        }).catch((err) => console.log(err)).finally(() => {
            setIsLoading(true)
            setLoadingText("Ending Session")
        }).finally(() =>    setIsLoading(false)
        )
    }

    const handleProceedSessionConfirmation = () => {
        if (!isQuestionnaireCompleted) {
            failed("Save questionnaire before proceeding");
            return;
        } 
        if (!encounterFinished) {
            setconfirmmodalShow(true);
        } else {
            formikRef.current && formikRef.current();
            handleProceedSession();
        }
    };
    
    const handleConfirm = async () => { 
        formikRef.current && formikRef.current();
        handleProceedSession();
    }; 
    
    const handleCancel = () => {
        setconfirmmodalShow(false);
    };

    const handleProceedSession = () => { 
        if(!isQuestionnaireCompleted){
            failed("Save questionnaire before proceeding");
            return;
        }
        if(!encounterFinished){
            setIsLoading(true)
            setLoadingText("Ending Session")
            localStorage.removeItem("userJoined")
            zmClient.leave(true).then(() => {
                document.getElementById("room").replaceChildren();
                document.getElementById("room").style.backgroundColor = "#fff";
                state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
                    .then((res) => success(res.message))
                    .catch((err) => console.log(err))
                
                if (state?.appointmentData) {
                    const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                    return updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                        .then(async(res) => { 
                            if(res?.status === true){ 
                                setEncouterDetailsShow(true);
                                setEncounterFinished(true);
                            }
                        })
                        .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                }
            }).catch((err) => console.log(err)).finally(() => {
                setIsLoading(true)
                setLoadingText("Ending Session")
            }).finally(() =>    setIsLoading(false))
            
        }
        else{
            setLoadingText("Proceed Ending Session")
            setEncouterDetailsShow(true);
            setEncounterFinished(true);
        }
        
    }
    const handleSendMsg = (e) => {
        e.preventDefault();
        chatInput.current.value ? chatClient.sendToAll(chatInput.current.value) : chatInput.current.focus();
        chatInput.current.value = ""
    }
    useEffect(() => {
        participants.forEach((participant) => {
            if (participant.userId === currentUser.userId) return null
            const CanvasElem = document.querySelector(`#participant-canvas-${participant.userId}`)
            const AvatarElem = document.querySelector(`#participant-avatar-${participant.userId}`)
            if (participant.bVideoOn) {
                AvatarElem.style.display = "none";
                mediaStream.renderVideo(CanvasElem, participant.userId, 960, 540, 0, 0, 2)
            } else {
                CanvasElem.style.display = "none";
            }
        })
    }, [currentUser.userId, mediaStream, participants])

    useEffect(() => {
        getOrganization(adminOrgs?.[0]?.id)
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
      }, []);

    const tempStyleParticipants = {
        gridTemplateColumns: "repeat(2,1fr)",
        gridTemplateRows: "repeat(2,1fr)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "0",
    }

    const handleView = (data) => {
        const baseUrl = window.location.origin;
        const patientId = data?.patientId;
        const patientName = data?.participant[0]?.actor?.display;
        const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
        window.open(url, "_blank");
    };

    const handleServiceRequest = (requestType) => {
        window.open(`/app/encounter-service-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&patientName=${patientName}&patientId=${appointmentData?.patientId}&requestType=${requestType}&appointmentId=${appointmentData?.id}&fromPatient=false`, '_blank')
    }
    const handleMedicationRequest = () => {
        window.open(`/app/encounter-medication-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&practitionerId=${practitionerId}&patientName=${patientName}&patientId=${patientId}&fromPatient=false`, '_blank')
    }

    if (!(topic && sessionToken && password)) {
        return <h1>Do not have the meeting details!</h1>
    }

    const handleParkCallClick = () => {
        if(!isQuestionnaireCompleted){
            failed("Save questionnaire before proceeding");
            return;
        }
        const utcString = new Date().toISOString().split(".")[0] + ".00Z";
        try {
            if (state?.appointmentData) {
                const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                    .then(async(res) => { 
                        if(res?.status === true){
                            const actor = {
                                display: practionerData.name[0].text,
                                reference: `Practitioner/${practitionerId}`,
                            };
                            let questionnareResponses = appointmentData?.intakeQuestions;
                            let newResponseAdded;
                            if (questionnareResponses) {
                                newResponseAdded = {
                                    ...questionnareResponses,
                                    intakeCommunicationChannel: "phone",
                                    intakeReason: appointmentData?.intakeQuestions?.reason,
                                    intakeAssignedPractitioner: {
                                        individual: {
                                            display: practionerData?.name[0]?.text,
                                            reference: `Practitioner/${practitionerId}`,
                                            type: "Practitioner"
                                        },
                                        period: {
                                            start: utcString,
                                            end: null
                                        }
                                    }
                                }
                            }
                            await axios.put(`encounter/${state?.encounterId}`, { status: "parked", actor: actor, requestedPeriod, class: "NULL", intakeQuestions: newResponseAdded }) 
                            success("Appointment successfully parked");
                        }
                    })
                .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))    
            }
            
            if(encounterFinished){ 
                navigate("/app/dashboard");
            }
            else{
                setIsLoading(true)
                setLoadingText("Ending Session")
                localStorage.removeItem("userJoined")
                zmClient.leave(true).then(() => {
                    document.getElementById("participants-view").replaceChildren();
                    state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
                        .then((res) => success(res.message))
                        .catch((err) => console.log(err)) 
                    navigate("/app/dashboard");
                }).catch((err) => console.log(err)).finally(() => {
                    setIsLoading(true)
                    setLoadingText("Ending Session")
                }).finally(() =>    setIsLoading(false)
                ) 
            }
        } catch (err) {
            console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message);
        } finally {
            setIsLoading(false);
        } 
    }

    const handleIsQuestionnaireComplete = (isComplete) => {
        if (isComplete) { 
            setIsQuestionnaireCompleted(isComplete); 
        }  
      };

    const handleFollowUpCallClick = () => {
        if(!isQuestionnaireCompleted){
            failed("Save questionnaire before proceeding");
            return;
        }
        try {
            if (state?.appointmentData) {
                const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                    .then(async(res) => { 
                        if(res?.status === true){
                            const actor = {
                                display: practionerData.name[0].text,
                                reference: `Practitioner/${practitionerId}`,
                            };
                            let questionnareResponses = appointmentData?.intakeQuestions;
                            let newResponseAdded;
                            if (questionnareResponses) {
                                newResponseAdded = {
                                    ...questionnareResponses,
                                    intakeCommunicationChannel: "phone",
                                    intakeReason: appointmentData?.intakeQuestions?.reason
                                }
                            }
                            await axios.put(`encounter/${state?.encounterId}`, { status: "follow-up", actor: actor, requestedPeriod, class: "NULL", intakeQuestions: newResponseAdded })
                            success("Appointment added to Follow-Up");
                        }
                    })
                    .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
            }
            if(encounterFinished){ 
                navigate("/app/dashboard");
            } else{
                setIsLoading(true)
                setLoadingText("Ending Session")
                localStorage.removeItem("userJoined")
                zmClient.leave(true).then(() => {
                    document.getElementById("participants-view").replaceChildren();
                    state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
                        .then((res) => success(res.message))
                        .catch((err) => console.log(err)) 
                    navigate("/app/dashboard");
                }).catch((err) => console.log(err)).finally(() => {
                    setIsLoading(true)
                    setLoadingText("Ending Session")
                }).finally(() =>    setIsLoading(false)
                )
            } 
        } catch (err) {
            console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message);
        } finally {
            setIsLoading(false);
        } 
        
    }
    const handleScheduleAppointmentClick = () => {
        setScheduleAppointmentModalOpen(true);
    }
    
    return (
        encounterDetailsShow ?  
        <EncounterDetail  setEncouterDetailsShow = {setEncouterDetailsShow} EncounterId = {state?.encounterId} PatientId= {patientId} PractionerData= {practionerData} PractitionerId = {practitionerId} ShowCompleteButton = {true} />
        :
        
        <section className="common-listing">
            {isLoading && <FullPageSpinner loadingText={loadingText} />}
            {scheduleAppointmentModalOpen && <ScheduleAppointmentWR onShow={scheduleAppointmentModalOpen} onHide={() => setScheduleAppointmentModalOpen(false)} encounterId={state?.encounterId} questionnaireData={questionnairInfo} patientName={patientName} patientId={patientId} questionnaireResponseId={savedQuestionResponseId} />}
                <div className="heading-wrap diff-style">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* Patient info */}
                            <div className="head-wrap" style={{marginLeft: "-40px"}}>
                                <h1>
                                    <span className="big-head" style={{fontSize: "24px", textTransform: "capitalize", fontWeight: "400" }}>
                                        {patientName}
                                    </span>
                                </h1>
                                <div style={{marginRight : "20px", marginBottom: "-15px"}}>
                                    <img src={CallIcon} alt='call'/>
                                    <span>{questionnairInfo?.callerNumber}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* Zoom Buttons */}
                {state?.isWaitingRoom ? null : <div className="right-wrap">
                    <Button className="custom-btn" title="Open Dashboard in a new tab" variant="primary" onClick={() => handleView(appointmentData)}>Patient Dashboard</Button>
                    <Button className="custom-btn" title="Schedule Appointment" variant="primary" onClick={() => handleScheduleAppointmentClick()}>Schedule Appointment</Button>
                    <Button className="custom-btn" title="Invite" variant="primary" onClick={() => setModalShow(true)}> <img src={AddUserIcon} alt="Prev Arrow" />Invite</Button>
                    <InviteInSeesion
                        show={modalShow}
                        topic={decryptData(topic)}
                        password={decryptData(password)}
                        onHide={() => setModalShow(false)}
                    />
                    <Button className="custom-btn ms-2" title="Add Note" variant="primary" style={{ padding: "0" }} onClick={() => setAddNoteModal(true)}>+<img src={AddNoteIcon} alt="Prev Arrow" /></Button>
                    <AddNote
                        show={addNoteModal}
                        encounterId={state?.encounterId}
                        patientName={currentUser.displayName}
                        onHide={() => setAddNoteModal(false)}
                    />
                    {isVirtualCare && permissions.includes("Create Prescriptions") && (<Button className="custom-btn ms-2" title="Create Prescription" variant="primary" style={{ padding: "0" }} onClick={()=>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ?  handleMedicationRequest() : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={medicalIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Lab Requisitions") && (<Button className="custom-btn ms-2" title="Add Lab service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Lab") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.") }><img src={labIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Diagnostic Imaging Requests") && (<Button className="custom-btn ms-2" title="Add Diagnostic service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Diagnostic") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={diagnosticIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Service Referrals") && (<Button className="custom-btn ms-2" title="Add Referral service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Referral") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={referralIcon} alt="Prev Arrow" /></Button>)}
                    <Button className="custom-btn ms-2" title={`Open ${showChat ? "Questionnaire" : "Chat"}`} variant="primary" onClick={() => setShowChat(!showChat)}>Open {showChat ? "Questionnaire" : "Chat"}</Button>
                    <Button className="custom-btn ms-2" title={"Proceed"} variant="primary" onClick={handleProceedSessionConfirmation}>Proceed</Button>
                    <ConfirmationModal
                        show={confirmmodalShow}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel} 
                        message="Are you sure you want to end the video/chat call?"
                    />
                </div>}
            </div>
                {/* Caller Info */}
                {encounterRecord?.intakeQuestions?.callerRelationship !== "Self" && (
                    <div style={{ marginTop: "10px", display: "flex", alignItems: "center", gap: "20px" }}>
                        <div>
                            <span style={{ fontWeight: "600", marginLeft: "5px" }}>Caller Name: </span>
                            <span>{encounterRecord?.intakeQuestions?.callerName}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: "600" }}>Caller Relationship: </span>
                            <span>{encounterRecord?.intakeQuestions?.callerRelationship}</span>
                        </div>
                    </div>
                )}

            <div className="call-chat-outer">
                <div className="call-screen-wrap" style={{ aspectRatio: "16 / 9", width: "60%"}} id={'room'}>

                    <div className="my-view-block d-flex h-100 position-relative">
                        {/* -----------user's video------------- */}
                        <div className="my-view-video flex-fill d-flex justify-content-center">
                            <div className="inner">
                                <video className={`${participants.length > 1 ? "video-wrap" : ""} main-video-wrap`} id="self-view-video"></video>
                                {/*<canvas width="1920" height="1080" className="main-video-wrap" id="self-view-canvas"></canvas>*/}
                            </div>
                        </div>
                    </div>
                    {/* -----------participant's video------------- */}
                    <div id="participants-view" style={{ width: "100%" }}>
                        <div id="participants-view-inner" style={participants.length > 2 ? tempStyleParticipants : null}>
                            {participants.map((participant) => {
                                const name = participant?.displayName?.split(' ');
                                if (participant.userId === currentUser.userId) return null
                                return (
                                    <div className="participant-view" id={`${participant.userId}`} key={participant.userId}>
                                        <div className="dropdown-container" style={{ display: "none" }}>
                                            <div className="three-dots"></div>
                                            <div className="dropdown">
                                                <div id={`pin-${participant.userId}`}>Pinned</div>
                                            </div>
                                        </div>
                                        <canvas style={{ display: participant.bVideoOn ? "block" : "none" }} className="participant-canvas" id={`participant-canvas-${participant.userId}`}></canvas>
                                        <div style={{ display: participant.bVideoOn ? "none" : "flex" }} className="participant-avatar" id={`participant-avatar-${participant.userId}`}><div>{name?.[0]?.[0] || ""}{name?.[1]?.[1] || ""}</div></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* -----------Tool bar for handling the session actions------------- */}
                    <ClickAwayListener onClickAway={() => {setCamAnchor(null);setMicAnchor(null)}}>
                    <div className="tool-wrap">
                        {/* <Tooltip title="Speaker" onClick={handleMic}><span>{isMicOn ? <MicOutlined sx={{ color: "grey" }} /> : <MicOffOutlined sx={{ color: "grey" }} />}</span></Tooltip> */}
                        {/* <Tooltip title="Video Cam" onClick={() => !isCameraLoading && handleCamera()}><span>{isCameraOn ? <VideocamOutlined sx={{ color: "grey" }} /> : <VideocamOffOutlined sx={{ color: "grey" }} />}</span></Tooltip> */}
                        {/*<Tooltip title="Screen Share" onClick={handleScreenShare}><span>{isScreenShareOn ? <CancelPresentationOutlined sx={{ color: "grey" }} /> : <PresentToAllOutlined sx={{ color: "grey" }} />}</span></Tooltip>*/}
                        {/*{state?.isWaitingRoom ? null : <Tooltip title="Chat" onClick={() => setShowChat(!showChat)}><span><img src={ChatIcon} alt="Chat" /></span></Tooltip>}*/}
                        {/* <Tooltip title="Full Screen" onClick={toggleFullscreen}><span><img src={ExtendIcon} alt="Full Screen" /></span></Tooltip> */}
                        {/* <Tooltip className="call-wrap" title="End Call" onClick={handleEndSession}><span><img src={CallIcon} alt="Call" /></span></Tooltip> */}
                        <span style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><Tooltip placement="top-start" title={isMicOn ? "Mute": "Un-Mute"}><IconButton 
                            onClick={(event) => !isAudioLoading && handleMic(event)} 
                            className="button">{isMicOn ? <MicOutlined sx={{ color: "grey" }} fontSize="large" /> : <MicOffOutlined sx={{ color: "grey" }} fontSize="large" />}</IconButton>                  
                        </Tooltip>
                        {(micCount > 1) ? <Tooltip placement="bottom" title="Select Microphone" className="sub-button"><IconButton style={{margin: 0,padding:0,marginRight: 15}} 
                            onClick={(event) => !isAudioLoading && switchMicrophone(event)}><KeyboardArrowDown 
                            sx={{ color: "white" }} 
                            fontSize="small" /></IconButton></Tooltip>:<div style={{color:"rgba(0,0,0,0)"}}>&\00A0;</div>}
                        </span>
                        <span style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><Tooltip placement="top-start" title={isCameraOn ? "Turn Camera On":"Turn Camera Off"}><IconButton 
                            onClick={(event) => !isCameraLoading && handleCamera(event)} 
                            className="button">{isCameraOn ? <VideocamOutlined sx={{ color: "grey" }} fontSize="large" /> : <VideocamOffOutlined sx={{ color: "grey" }} fontSize="large" />}</IconButton>
                        </Tooltip>
                        {(cameraCount > 1) ? <Tooltip placement="bottom" title="Select Camera" className="sub-button"><IconButton style={{margin: 0,padding:0,marginRight: 15}} 
                            onClick={(event) => !isCameraLoading && switchCamera(event)}><KeyboardArrowDown 
                            sx={{ color: "white" }} 
                            fontSize="small" /></IconButton></Tooltip>:<div style={{color:"rgba(0,0,0,0)"}}>&\00A0;</div>}
                        </span>
                        <Tooltip placement="top-start" title={isFull ? "Exit Full Screen" : "Enter Full Screen"}><IconButton onClick={() => {setIsFull(!isFull);toggleFullscreen()}} className="button">{isFull ? <FullscreenExit sx={{color: "grey"}} fontSize="large" /> : <Fullscreen sx={{color: "grey"}} fontSize="large" />}</IconButton></Tooltip>
                        <Tooltip placement="top-start" title="End Call" className="call-wrap"><IconButton onClick={handleEndSession} className="button"><CallEndOutlined sx={{ color: "white" }} fontSize="large" /></IconButton></Tooltip>
                    </div>
                    </ClickAwayListener>
                </div>
                <ZoomChatBox show={showChat} currentUser={currentUser} sessionChat={sessionChat} chatInput={chatInput} handleSendMsg={handleSendMsg} handleParkCallClick={handleParkCallClick} handleFollowUpClick={handleFollowUpCallClick}/>
                <QuestionnaireBox show={!showChat} onHide={() => setShowChat(true)} encounterId={state?.encounterId} patientId={state?.appointmentData?.patientId} appointmentId={appointmentData?.id} slotStart={appointmentData?.requestedPeriod?.[0]?.start} slotEnd={appointmentData?.requestedPeriod?.[0]?.end} patientName={appointmentData?.participant?.[0]?.actor?.display} handleParkCallClick={handleParkCallClick} handleFollowUpClick={handleFollowUpCallClick} handleIsQuestionnaireComplete={(isComplete) => handleIsQuestionnaireComplete(isComplete)}  ref={formikRef}/>
            </div>
            {
                // MENU FOR CAMERA SELECTION IF NEEDED
                availableCameras?.length > 1 && <Menu anchorEl={camAnchor} open={camOpen}
                    anchorOrigin={{
                        vertical: "middle",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}>
                        <MenuList style={{padding:0,margin:0}} dense>{                                        
                            availableCameras?.length > 1 && availableCameras?.map((cam, index) => {
                                return (<MenuItem 
                                    key={index} 
                                    value={cam.deviceId} 
                                    selected={cam.deviceId === selectedCamera}
                                    onClick={(event) => {switchCamera(event, cam.deviceId); setCamAnchor(null)}} 
                                    //children={[cam.label.substring(0, cam.label.indexOf("(")-1).trim()]}
                                    className="camPicker" >
                                    {cam.label.substring(0, cam.label.indexOf("(")-1).trim()} 
                                    {cam.deviceId === selectedCamera && <ListItemIcon><Check /></ListItemIcon> }
                                </MenuItem>)
                            })
                        }
                        </MenuList>
                </Menu>}
                {
                // MENU FOR MICROPHONE SELECTION IF NEEDED
                availableMicrophones?.length > 1 && <Menu anchorEl={micAnchor} open={micOpen}
                    anchorOrigin={{
                        vertical: "middle",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}>
                        <MenuList style={{padding:0,margin:0}} dense>{                                        
                            availableMicrophones?.length > 1 && availableMicrophones?.map((mic, index) => {
                                return (<MenuItem 
                                    key={index} 
                                    value={mic.deviceId} 
                                    selected={mic.deviceId === selectedMicrophone}
                                    onClick={(event) => { switchMicrophone(event, mic.deviceId); setMicAnchor(null)}} 
                                    //children={[mic.label.substring(0, mic.label.indexOf("(")-1).trim(), <ListItemIcon><Check /></ListItemIcon>]}
                                    className="camPicker">
                                        {mic.label.substring(0, mic.label.indexOf("(")-1).trim()} 
                                        {mic.deviceId === selectedMicrophone && <ListItemIcon><Check /></ListItemIcon> }
                                    </MenuItem>)
                            })
                        }
                        </MenuList>
                </Menu>}
        </section>
    );
}

export default ZoomCreate;