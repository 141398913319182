import moment from "moment";
import * as Yup from "yup";
export const PractitonerHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        nested: { key: "name", value: { index: 0, key: "text" } },
    },
    {
        id: 'phone',
        label: 'Phone Number',
        type: 'text',
        isDecrypted: true,
        nested: { key: "telecom", value: { index: 0, key: "value" } },
        style: { whiteSpace: "nowrap" }
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        isDecrypted: true,
        nested: { key: "address", value: { index: 0, key: "line" } },
    },
];
export const PractitonerDataFields = [
    'name',
    'phone',
    'Address',
];

export const PatientHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        nested: { key: "name", value: { index: 0, key: "text" } },
    },
    {
        id: 'condition',
        label: 'Condition',
        type: 'text',
        isDecrypted: true,
        style: { textAlign: "center" },
        nested: { key: "diseaseName", },
    },
    {
        id: 'symptoms',
        label: 'Symptoms',
        type: 'text',
        isDecrypted: true,
        style: { textAlign: "center" },
        nested: { key: "diseaseDesc", },
    },
];
export const PatientDataFields = [
    'name',
    'condition',
    'symptoms',
];

export const genderOptions = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    { value: "other", name: "Other" },
];

export const patientListTitle = ["Name", "Gender", "DOB", "HCN", "Address", "City", "State/Province"]

export const FormikObjInboundCall = {
    initialValues: {
        questionResponse: [],
        orgId: "",
        orgName: "",
        questionnaire: "",
        patientId : "",
        patientName: "",
        howToProcced: "",
        encounterId: "",
        questionnaireId:""
    },
    validationSchema: Yup.object({

    }),
}

export const patientSelectionFormikObject = {
    validationSchema: Yup.object({
    firstName:  Yup.string().max(15, "Must be 15 characters or less").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    lastName: Yup.string().max(15, "Must be 15 characters or less").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    birthDate: Yup.string().matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    healthCardNumber: Yup.string().matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
  }),
  validate: values => {
    const errors = {};
    if (!values.firstName && !values.lastName && !values.birthDate && !values.healthCardNumber) {
        errors.allSearchFieldsEmpty = "At least one field must be filled out.";
    }
    return errors;
  }
}

export const createPatientObjFormik = {
    initialValues: {
        orgId: "",
        orgName: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender : "",
        country: "",
        city:"",
        region: "",
        address: "",
        postalCode: "",
        email: "",
        phone_number: "",
        general_practitioner: "",
        hcn: "",
        ext: "",
        create_account : ""
    },
}