import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import {
  splitContactNumber,
  createContactNumber,
  splitHomeContactNumber
} from "../../../utils/phoneNumberSupport";
import {
  caregions,
  usregions,
  countryList,
  createAccountOptions,
  FormikObjEdit,
  relationsList,
  editBasicFields,
  editBasicFieldsPractionerRowOne,
  genderOptions,
  editBasicFieldsPractionerRowThree,
  editBasicFieldsPractionerRowEight
} from "./Constants";
import { useCustomRolesQuery } from "../../../hooks/ReactQueryHooks/useCustomRolesQuery";
import MultiSelect from "../../common/textfield/MultiSelect";
import Checkbox from "../../common/textfield/Checkbox";
import {
  editUser,
  getCustomRoles,
  getPractitionerList,
  getRelatedPerson,
  getSingleUser,
} from "../../api/Individual";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import Fields from "./Fields";
import { useSelector } from 'react-redux';
import { Tooltip } from "@mui/material";
import Switch from '@mui/material/Switch';
import ReactDatePicker from "../../common/textfield/ReactDatePicker";
import UpdateDetailsIcon from "../../../assets/images/update.svg"
import { ViewDate } from "../../../utils/DateSupport";

export function EditIndividual({
  modalShow,
  handleShow,
  refreshTable,
  isReadOnly,
  typeOfUser,
  organizations,
  organizationsData,
  handleDelete,
  handleEnableClick
}) { 
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([{ name: "-----" }]);
  //For Setting scope of jurisdictions
  const [regionOptions, setRegionOptions] = useState([]);
  //For selecting role and showing jurisdictions
  const [roleSelected, setRoleSelected] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  //For selecting org and showing roles  of that org
  const [defaultValue, setDefaultValue] = useState([]);
  // const [defaultAssignedPractitioner, setDefaultAssignedPractitioner] = useState([]);
  const [typeRelatedPerson, setTypeRelatedPerson] = useState(false);
  const [typePractitioner, setTypePractitioner] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [showJurisdiction, setShowJurisdiction] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [createAcctFlag, setCreateAcctFlag] = useState(false);
  const [createAccountValue, setCreateAccountValue] = useState(false);

  //For getting general Practiotioner data and setting the list and options
  // const [generalPractitionerOptions, setGeneralPractitionerOptions] = useState([]);
  // const [generalPractitionerData, setGeneralPractitionerData] = useState([]);

  const permissions = useSelector((state) => state?.auth?.user?.permissions);
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const practitioner = userRoles?.includes('Practitioner');
  const hasAccess = ((permissions?.includes('Manage Individuals') && practitioner) || userRoles?.includes("Super-Admin"));
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

  if(!hasAccess) {
    isReadOnly = true;
    typeOfUser = ""
  }

  const onSuccessCustomRoles = (data) => {
    setRolesOptions(
      data?.map(
        (role) => (role = { ...role, name: role?.display, value: role?.id })
      )
    );
  };


  const { refetch: cutomRolesRefetch } = useCustomRolesQuery({
    orgIds: [modalShow?.managingOrganization?.[0]?.reference?.split("/")?.[1]],
    onSuccess: onSuccessCustomRoles,
  });

  //Handle change function for org selection and showing roles w.r.to selected org
  const handleOrgChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("organization", value);
    const arr = [];
    arr.push(value);
    getCustomRoles(arr)
      .then((res) => {
        setRolesOptions(
          res?.data?.map(
            (role) =>
            (role = {
              ...role,
              name: role?.display,
              value: role?.id,
            })
          )
        );
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
          res?.response?.data?.error ||
          res.message
        );
      });
  };

  //Handle change function for role selection and showing scope of jurisdiction w.r.to selected role
  const handleRoleChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("role", value);
    const roleType = rolesOptions?.filter((role) => role?.id === value);
    if (
      roleType?.[0]?.type === "Clinical" ||
      roleType?.[0]?.type === "Administrative_Clinical"
    ) {
      setRoleSelected(true);
      setShowJurisdiction(true);
      setShowRole(false);
    } else {
      formik.setFieldValue("countrylist", "");
      formik.setFieldValue("jurisdiction", []);
      setRoleSelected(false);
      setDefaultValue([]);
      setShowJurisdiction(false);
      setShowRole(true);
    }
  };

  //Handle change function for Couuntry selection and showing region
  const handleCountryChange = (event) => {
    const value = event?.target?.value;
    if (value === "CA") {
      setRegionOptions(caregions);
    } else {
      setRegionOptions(usregions);
    }
    formik.setFieldValue("countrylist", value);
    formik.setFieldValue("jurisdiction", []);
  };
  const handleRelationshipChange = (event) => {
    formik.setFieldValue("relationship", event?.target?.value);
  };
  const formik = useFormik({
    ...FormikObjEdit,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      last_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required last name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      date_of_birth: Yup.string().when("type", (type, schema) =>{
        if (modalShow.type[0] === 'Practitioner') {
          return schema
            .required('Required date of birth field')
            .test('min-age', 'Age cannot be below 16 years. Please provide a valid age.', function (value) {
              const currentDate = moment();
              const birthDate = moment(value, /* 'YYYY-MM-DD' */  'MM-DD-YYYY'); 
              const age = moment.duration(currentDate.diff(birthDate)).years();
              return age >= 16;
            });
        }
        else {
          return schema.required('Required date of birth field');
        }
      }
        // type !== "Device"
        //   ? schema.required("Required date of birth field")
        //   : schema
      ),
      phone_number: Yup.string()
        //.matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        .matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Phone Number."),

      // home_phone_number: Yup.string()
      home_phone_number: Yup.string()
        //.matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        .matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Home Phone Number."),

      // TODO Multilingual Support
      ext: Yup.string().matches(/^\d{0,4}$/, "Must be 4 digit or less."),
      address: Yup.string()
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      city: Yup.string()
        .required("Required city field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."), 
      email: createAccountValue 
        ? Yup.string()
          .email("Invalid email address")
          .required("Required email field")
          .matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
        : Yup.string()
          .email("Invalid email address") ,
      organization: Yup.string()
        .required("Required organization field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      gender: Yup.string()
        .required("Required gender field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      country: Yup.string()
        .required("Required country field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      region: Yup.string()
        .required("Required region field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      postalCode: Yup.string().when(
        ["country"],
        (country) =>
          country === "US"
            ? Yup.number()
              .typeError("Zip code must be a number")
              .required("Required field")
            : Yup.string()
              .required("Required field")
              .matches(
                /^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/,
                "Postal code must be in A1A 1A1 format."
              )
      ),
      isPatient: Yup.boolean(),
      related_to: Yup.string(),
      role: typeOfUser === "Practitioner" && typePractitioner
        ? Yup.string().required("Required Role field")
        : Yup.string(),
      countrylist: Yup.string().when("role", (role, schema) => {
        if (role && roleSelected && defaultValue?.length) {
          return schema.required("Required Country field");
        }
        return schema;
      }),
      jurisdiction: Yup.array().when("role", (role, schema) => {
        if (role && roleSelected && defaultValue?.length) {
          return schema
            .min(1, "Required Jurisdictions field")
            .required("Required Jurisdiction field");
        }
        return schema;
      }),
      create_account: createAccount
        ? Yup.string().required("Required Create Account field")
        : Yup.string(),
      relationship: typeOfUser === "RelatedPerson" && typeRelatedPerson
        ? Yup.string().required("Required Relationship field")
        : Yup.string(),
      // general_practitioner: Yup.array(),
      hcn: modalShow?.type?.[0] == "Patient" ? Yup.string().when("type", (type, schema) =>
         schema.required("HCN is required")
      ) : Yup.string(),
    }),
    onSubmit: (values) => {
      const type = rolesOptions?.filter((role) => role?.id === values?.role);
      const state = values?.country + "-" + values?.region;
      // const generalPractitionersSelected = generalPractitionerData?.filter(
      //   (data) => values?.general_practitioner?.includes(data.id)
      // );
      let phoneNumber = createContactNumber(values);
      let encryptedData = {
        first_name: encryptData(values?.first_name?.toLowerCase()),
        last_name: encryptData(values?.last_name?.toLowerCase()),
        full_name: encryptData(
          [
            values?.first_name?.toLowerCase(),
            values?.last_name?.toLowerCase(),
          ].join(" ")
        ),
        address: encryptData(values?.address),
        date_of_birth: encryptData(moment(values?.date_of_birth).format("MM-DD-YYYY")),
        phone_number: encryptData(phoneNumber),
        postalCode: encryptData(values?.postalCode?.toUpperCase()),
        city: encryptData(values?.city),
        gender: encryptData(values?.gender),
        email: encryptData(values?.email?.toLowerCase()),
        relationship: encryptData(values?.relationship),
        healthCardNumber: encryptData(values?.hcn)
      };
      setBtnLoading(true);

      editUser(
        values,
        encryptedData,
        organizationsData,
        state,
        type,
        [],
        null,
        modalShow?.type,
        createAcctFlag,
        adminOrgs?.[0]?.id
      )
        .then((res) => {
          if (res?.status === true) {
            navigate("/app/manage-individuals");
            success(res?.message);
            handleShow();
            refreshTable();
          } else {
            failed(res?.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });
 
  const handleCreateAccountChange = (e) => {
    const value = e?.target?.value;  
    if (value == "true") { 
      setCreateAccountValue(true);
    }
    else{ 
      setCreateAccountValue(false);
    }   
    formik?.setFieldValue("create_account", value);
  };

  //validation if Country is selected but region not selected
  const handleFormChange = (e) => {
    if (e?.target?.id === "region" || e?.target?.id === "organization") {
      const selected_region =
        e?.target?.id === "region" ? e?.target?.value : formik?.values?.region;
      const selected_org =
        e?.target?.id === "organization"
          ? e?.target?.value
          : formik?.values?.organization;
      // if (selected_org && selected_region) {
      //   const scopeOfPrac = formik?.values?.country + "-" + selected_region;
      //   getPractitionerList(selected_org, scopeOfPrac)
      //     .then((res) => {
      //       setGeneralPractitionerData(res?.result);
      //       setGeneralPractitionerOptions(
      //         res?.result?.map((list) => ({
      //           name: decryptData(list?.name?.[0]?.text),
      //           value: list?.id,
      //           id: list?.id,
      //         }))
      //       );
      //     })
      //     .catch((res) => {
      //       failed(
      //         res?.response?.data?.message ||
      //         res?.response?.data?.error ||
      //         res.message
      //       );
      //     });
      // }
    } else if (e?.target?.id === "country") {
      formik.setFieldValue("region", "");
      formik.setFieldValue("postalCode", "");
      formik.setFieldValue("city", "");
      formik.setFieldValue("address", "");
    }
  };
  const handlePostalCodeChange = (e) => {
    const { value } = e?.target;
    let spacedValue = value;
    if (formik?.values?.country !== "US") {
      // Remove spaces and non-alphanumeric characters
      const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
      // Add a space after every 3 letters
      spacedValue = formattedValue.replace(
        /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
        "$1 $2"
      );
    }
    // Set the formatted value in the formik
    formik?.setFieldValue("postalCode", spacedValue);
  };

  useEffect(() => {   
    if (modalShow?.id) {
      setLoadingUserData(true);
      if(modalShow?.type?.[0] == 'Practitioner'){ typeOfUser = 'Practitioner'}else{typeOfUser = typeOfUser} ;      
      const apiBody = {
        id: modalShow?.id,
        type: typeOfUser,
        userOrgId: adminOrgs?.[0]?.id
      };
      getSingleUser(apiBody)
        .then((res) => {              
          let typeObj = {};
          // if( res?.data?.type != "Practitioner"){
          //   res?.data?.type?.forEach((element) => {
          //     typeObj[element] = element;
          //   });
          // }
          res?.data?.type?.forEach((element) => {
            typeObj[element] = element;
          });
          setCreatedAt(res?.data?.createdAt);
          //Setting common fields for all user type
          let countryRegion = res?.data?.address?.[0]?.state?.split("-");
          let organization =
            res?.data?.managingOrganization?.[0]?.reference?.split("/");
          cutomRolesRefetch({
            orgIds: organization?.[1],
            onSuccessCustomRoles,
          });
          formik.setFieldValue(
            "first_name",
            decryptData(res?.data?.name?.[0]?.given?.[0] || "")
          );
          formik.setFieldValue(
            "last_name",
            decryptData(res?.data?.name?.[0]?.family) || ""
          );
          res?.data?.birthDate &&
            formik.setFieldValue(
              "date_of_birth",
              decryptData(moment(res?.data?.birthDate).format("MM-DD-YYYY")) || ""
            );
          formik.setFieldValue(
            "phone_number",
            splitContactNumber(
              decryptData(
                res?.data?.telecom?.find((ele) => ele?.system === "phone")
                  ?.value || ""
              )
            ).phone_number.replace(/^\+1/, '')
          );
          formik.setFieldValue(
            "ext",
            splitContactNumber(
              decryptData(
                res?.data?.telecom?.find((ele) => ele?.system === "phone")
                  ?.value || ""
              )
            ).ext
          );
          formik.setFieldValue(
            "email",
            decryptData(
              res?.data?.telecom?.find((ele) => ele?.system === "email")
                ?.value || ""
            )
          );
          formik.setFieldValue("id", res?.data?.id || "");
          formik.setFieldValue(
            "address",
            decryptData(res?.data?.address?.[0]?.line?.[0] || "")
          );
          formik.setFieldValue(
            "postalCode",
            decryptData(res?.data?.address?.[0]?.postalCode || "")
          );
          formik.setFieldValue(
            "city",
            decryptData(res?.data?.address?.[0]?.city || "")
          );
          formik.setFieldValue("gender", decryptData(res?.data?.gender || ""));
          formik.setFieldValue("country", countryRegion?.[0] || "");
          formik.setFieldValue("region", countryRegion?.[1] || "");
          formik.setFieldValue("organization", organization?.[1] || "");
          // formik.setFieldValue(
          //   "general_practitioner",
          //   res?.data?.generalPractitioner?.map(
          //     (list) => list?.reference.split("/")[1]
          //   ) || []
          // );
          // setDefaultAssignedPractitioner(
          //   res?.data?.generalPractitioner?.map(
          //     (list) => list?.reference.split("/")[1]
          //   )
          // );
          //end common fields
          //setting fields if user type is Practitioner
            
          if (res?.data?.type == "Super-Admin" || modalShow?.type?.[0] == "Super-Admin") {
            res?.data?.qualification && formik.setFieldValue(
              "qualification",
              decryptData(res?.data?.qualification?.[0]?.code?.coding?.[0]?.text || "")
            );
            res?.data?.qualification && formik.setFieldValue(
              "license",
              decryptData(res?.data?.qualification?.[0]?.identifier?.[0]?.value || "")
            );
            res?.data?.designation && formik.setFieldValue(
              "designation",
              decryptData(res?.data?.designation || "")
            );

            formik.setFieldValue(
              "home_phone_number",
              splitHomeContactNumber(
                decryptData(
                  res?.data?.telecom?.find((ele) => ele?.use === "home")
                    ?.value || ""
                )
              ).home_phone_number.replace(/^\+1/, '')
            ); 
            formik.setFieldValue("userType", "Super-Admin"); 
          } else {
            setTypePractitioner(false);
            setRoleSelected(false);
          }
          
          if (typeObj.Practitioner || res?.data?.type == "Practitioner" || modalShow?.type?.[0] == "Practitioner") {              
            res?.data?.qualification && formik.setFieldValue(
              "qualification",
              decryptData(res?.data?.qualification?.[0]?.code?.coding?.[0]?.text || "")
            );
            res?.data?.qualification && formik.setFieldValue(
              "license",
              decryptData(res?.data?.qualification?.[0]?.identifier?.[0]?.value || "")
            );
            res?.data?.designation && formik.setFieldValue(
              "designation",
              decryptData(res?.data?.designation || "")
            );

            formik.setFieldValue(
              "home_phone_number",
              splitHomeContactNumber(
                decryptData(
                  res?.data?.telecom?.find((ele) => ele?.use === "home")
                    ?.value || ""
                )
              ).home_phone_number.replace(/^\+1/, '')
            );

            formik.setFieldValue("userType", "Practitioner");
            setTypePractitioner(true);
            setRoleSelected(true);
            let countryScope = res?.data?.scopeOfPractice?.[0]?.split("-");
            formik.setFieldValue("countrylist", countryScope?.[0]);
            countryScope?.[0] === "US"
              ? setRegionOptions(usregions)
              : setRegionOptions(caregions);
            setDefaultValue(res?.data?.scopeOfPractice);
            formik.setFieldValue("role", res?.data?.role?.coding?.[0]?.id);
            formik.setFieldValue("jurisdiction", res?.data?.scopeOfPractice);
            if ((typeObj.Practitioner || res?.data?.type === "Practitioner") && typeObj.Patient) {
              formik.setFieldValue("isPatient", true);
            } else {
              formik.setFieldValue("isPatient", false);
            }
          } else {
            setTypePractitioner(false);
            setRoleSelected(false);
          }
          //setting fields if user type is patient
          if (
            typeOfUser === "Patient" ||
            (typeObj.Patient &&
              !((typeObj.Practitioner || res?.data?.type === "Practitioner") || typeObj.RelatedPerson))
          ) {
            formik.setFieldValue("userType", "Patient");
            setCreateAccount(true);
            if(res?.data?.accountCreated === false) {
              setCreateAcctFlag(true);
            } 
            if(res?.data?.accountCreated === true) {
              setCreateAccountValue(true);
            } 
            formik.setFieldValue(
              "create_account",
              res?.data?.accountCreated === true ? "true" : "false"
            );
            formik.setFieldValue("hcn", decryptData(res?.data?.patient_identifier?.find((ele) => ele?.type === "HCN")?.value || ""));
          } else {
            setCreateAccount(false);
          }

          //setting fields if user type is related Person
          if (typeObj.RelatedPerson) {
            formik.setFieldValue("userType", "RelatedPerson");
            setTypeRelatedPerson(true);
            getRelatedPerson(res?.data?.patientRefId)
              .then((res) => {
                formik.setFieldValue(
                  "relationship",
                  decryptData(res?.data?.[0]?.relationship || "")
                );
                formik.setFieldValue(
                  "related_to",
                  decryptData(res?.data?.[0]?.patientName?.[0]?.text)
                );
              })
              .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
                );
              });
            if (typeObj.RelatedPerson && typeObj.Patient) {
              formik.setFieldValue("isPatient", true);
            } else {
              formik.setFieldValue("isPatient", false);
            }
          } else {
            setTypeRelatedPerson(false);
          }
          return {
            selected_org: organization?.[1],
            scopeOfPrac: res?.data?.address?.[0]?.state,
          };
        })
        .then((res) => {
          let isSelfOrg = true;
          
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
          );
        })
        .finally(() => setLoadingUserData(false));
    }   
  }, [modalShow]);

  if (loadingUserData) return <FullPageSpinner />;

  const handleSwitch = () => {
    modalShow?.active ? handleDelete(modalShow?.id, modalShow.name?.[0]?.text) 
    : handleEnableClick(modalShow?.id, modalShow.name?.[0]?.text)
  }

  //Make Email field optinal or required based on Create Account selection
  const flattenedArray = editBasicFields.flatMap(section => section);
  const emailField = flattenedArray.find(field => field.keyField === 'email');
  if (emailField) {
      emailField.hideRequired = !createAccountValue;
      emailField.isReadOnly = !createAcctFlag;
  }

  const computePractitionerFields = () => {
    return (
      <>
                <Fields fields={editBasicFieldsPractionerRowOne} formik={formik} isReadOnly={isReadOnly} handlePostalCodeChange={handlePostalCodeChange} />
                <Row style = {{borderBottom: '1px solid rgba(0, 0, 0, 0.14)', marginBottom:'15px'}}>
                  <Col xs lg="4">
                    <ReactDatePicker
                      keyField={"date_of_birth"}
                      label={"Date of Birth"}
                      formik={formik}
                      placeholder={"Select Date"}
                      hasValue={formik?.values?.["date_of_birth"]}
                      displayAge={formik.errors.date_of_birth ? false  : true}
                    />
                  </Col>
                  <Col xs lg="3">
                    <SelectFiled
                      keyField={"gender"}
                      label={"Select Gender"}
                      formik={formik}
                      options={genderOptions}
                    />
                  </Col>
                  <Col>
                      <SelectFiled
                          keyField={"role"}
                          label={"Role"}
                          formik={formik}
                          options={rolesOptions}
                          onChange={handleRoleChange}
                          readOnly={isReadOnly}
                      />
                  </Col>
                </Row>
                <Fields fields={editBasicFieldsPractionerRowThree} formik={formik} isReadOnly={isReadOnly} />
                <Row style = {{borderTop: '1px solid rgba(0, 0, 0, 0.14)', paddingTop:'15px'}}>
                  <Col>
                    <SelectFiled
                      keyField={"organization"}
                      label={"Select Organization"}
                      formik={formik}
                      options={organizations}
                      onChange={handleOrgChange}
                      readOnly={true}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      style={{ textTransform:"capitalize" }}
                      keyField={"license"}
                      label={"License Number"}
                      formik={formik}
                      placeholder={"License Number"}                                               
                      hideRequired={true}
                    />
                    </Col>
                </Row>
                <Fields fields={editBasicFieldsPractionerRowEight} formik={formik} isReadOnly={isReadOnly}/>
                <Row> 
                  <Col>
                    <SelectFiled
                        keyField={"countrylist"}
                        label={"Scope Of Practice"}
                        formik={formik}
                        options={countryList}
                        onChange={handleCountryChange}
                        readOnly={isReadOnly}
                      />
                  </Col>
                  <Col>
                      {isReadOnly ? (
                        <MultiSelect
                          options={regionOptions?.map((option) => ({
                            ...option,
                            disabled: true,
                            checked: defaultValue?.includes(option?.value),
                            readOnly: isReadOnly
                          }))}
                          keyField={"jurisdiction"}
                          formik={formik}
                          label={"Scope Of Clinical Jurisdictions"}
                          defaultValue={defaultValue}
                          isSelectAll={isReadOnly && defaultValue?.length > 0 && defaultValue?.length === regionOptions?.length}
                          isSelectAllEnable={false}
                        />
                        // </div>
                      ) : (
                        <MultiSelect
                          options={regionOptions}
                          keyField={"jurisdiction"}
                          formik={formik}
                          label={"Scope Of Clinical Jurisdictions"}
                          defaultValue={defaultValue}
                          readOnly={isReadOnly}
                          isSelectAll={
                            defaultValue?.length > 0 &&
                            defaultValue?.length === regionOptions?.length
                          }
                          isSelectAllEnable={true}
                        />
                      )}
                  </Col>
                </Row>
              </>
    )
  }

  const computePatientFields = () => {
    return (
      <>
        <Fields fields={editBasicFields} formik={formik} isReadOnly={isReadOnly} handlePostalCodeChange={handlePostalCodeChange} typeOfUser ={modalShow?.type?.[0]} />
        <Row style = {{borderTop: '1px solid rgba(0, 0, 0, 0.14)', paddingTop:'15px'}}>
        <Col>
            <SelectFiled
              keyField={"organization"}
              label={"Select Organization"}
              formik={formik}
              options={organizations}
              onChange={handleOrgChange}
              readOnly={true}
            />
        </Col>
        <Col>
          {/* {(typeOfUser === "Patient" || modalShow?.type?.[0] == "Patient" || formik?.values?.isPatient) &&
            <MultiSelect
              // options={generalPractitionerOptions}
              options={generalPractitionerOptions?.map((option) => ({
                ...option,
                disabled: true,
                checked: defaultAssignedPractitioner?.includes(option?.value),
                readOnly: isReadOnly
              }))}
              keyField={"general_practitioner"}
              formik={formik}
              label={"Assign Practitioner"}
              nodata={"No Data Found"}
              hideRequired={true}
              defaultValue={defaultAssignedPractitioner}
              // readOnly={isReadOnly}
              isSelectAll={isReadOnly && defaultAssignedPractitioner?.length > 0 && defaultAssignedPractitioner?.length === generalPractitionerOptions?.length}
              isSelectAllEnable={false}
            />} */}
        </Col>
        
        </Row>
          <Row>
            {createAccount &&
              <Col sm lg = "4">
                <SelectFiled
                  keyField={"create_account"}
                  label={"Create Account"}
                  formik={formik}
                  placeholder={"Create Account"}
                  options={createAccountOptions}
                  readOnly={ !createAcctFlag }
                  onChange={handleCreateAccountChange}
                />
              </Col>}
        </Row>
      </>
    )
  }

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={modalShow}
      onHide={handleShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isReadOnly ? "View" : "Edit"} User's Details
        </Modal.Title>
        <div className="" ><Switch
            checked={modalShow?.active}
            onChange={()=> handleSwitch()} // Use the callback function
            color="primary"
        /></div>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields userAccount editUserModal"
          onSubmit={formik.handleSubmit}
          onChange={handleFormChange}
        >
            
          { modalShow?.type?.[0] == "Practitioner" || typeOfUser === "Practitioner" ? 
            computePractitionerFields()
          :
            computePatientFields()
          }

          <Row>
            {!isReadOnly ? (
            <Col>
              <Button style={{marginRight: "20px"}}
                onClick={() => {
                  handleShow();
                }}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
                <Button type="submit" isLoading={btnLoading}><img src={UpdateDetailsIcon}/> Update</Button>
            
            </Col>
            ) : null}
            <Col style={{ display: "flex", justifyContent:"flex-end"}}>
                {/* <div style={{ display: "flex", justifyContent:"flex-end"}}>
                  {(typeOfUser === "Practitioner" || modalShow?.type?.[0] == "Practitioner") && typePractitioner || typeRelatedPerson ? (
                  <Checkbox
                    keyField={"isPatient"}
                    label={"Is Patient"}
                    formik={formik}
                    readOnly={isReadOnly}
                    checked={formik.values.isPatient}
                  />
                ) : <div style={{height: "26px"}}></div>}
                </div> */}
                <div style={{alignSelf:"flex-end"}}>
                  User Created on {ViewDate(createdAt)}
                </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}