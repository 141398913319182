import { useSelector } from "react-redux";
import { Button } from "../../common/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import TextArea from "../../common/textfield/TextArea";
import { useState } from "react";
import { addAddendumToEncounter } from "../api/AppointmentRequests";
import { failed, success } from "../../common/Toastify";

const AddendumModal = ({ data, onShow, onHide }) => {
    console.log("Data in modal: ", data)
    const user = useSelector((state) => state?.auth?.user)
    const [btnLoading, setBtnLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
          note: "",
        },
        validationSchema: Yup.object({
          note: Yup.string()
            .max(250, "Maximum length is 250 characters.")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
            .required("Addendum field is required"),
        }),
        onSubmit: async (values) => {
            setBtnLoading(true);
            let utcString = new Date().toISOString().split(".")[0] + ".00Z";
            let oldEncounterNote = data?.note;
            let newNote = {
                authorReference: {
                display: `${user?.name[0]?.text}`,
                reference: `Practitioner/${user?.["custom:practitioner_id"]}`
                },
                text: values?.note,
                time: utcString,
                type: "external" 
            }
            let updatedNote;
            if(!oldEncounterNote) {
                
                let emptyNote = [];
                emptyNote.push(newNote);
                updatedNote = emptyNote;
            } else {
                oldEncounterNote.push(newNote);
                updatedNote = oldEncounterNote
            }
            addAddendumToEncounter({encounterId: data?.id, addenda: updatedNote})
            .then((res) => {
                success("Addendum added successfully to encounter")               
            })
            .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res?.message
                );
              })
              .finally(() => {
                setBtnLoading(false);
                onHide();
              });
        }
      });
        


    return (
        <Modal
          backdropClassName
          backdrop={"static"}
          show={onShow}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-dialog"
        >
          <Modal.Header closeButton className="border-0">
            <span className="header-text">Patient Name:</span>
            &nbsp;&nbsp;
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ textTransform: "capitalize" }}
            >
              {data?.subject?.display}
            </Modal.Title>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Modal.Header>
          <Modal.Body>
            <form
                className="common-form border-fields"
                onSubmit={formik.handleSubmit}
            >
                <Row>
                    <Col>
                        <TextArea
                            keyField={"note"}
                            label={"Add Addendum"}
                            formik={formik}
                            placeholder={"Type here..."}
                            required={true}
                        />
                    </Col>
                </Row>
                <div className="btn-wrap">
                    <Button onClick={() => onHide()} variant="secondary" title="Cancel">
                        Cancel
                    </Button>
                    <Button type="submit" isLoading={btnLoading}>
                        Submit
                    </Button>
                </div>
            </form>
            
          </Modal.Body>
        </Modal>
      );
};

export default AddendumModal;