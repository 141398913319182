import { TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState } from 'react';

function TimeSelector({ keyField, error, readOnly = false, onChange, min = "", max= "", label, minutesStep = 5, required = true, value = false, ...props }) {
    const [open, setOpen] = useState(false)

    /**
     * 
     * @param {string} error 
     * @param {string} keyField 
     * @returns a div containing an error message or null
     * 
     * In the case of a multi-line form, so unavailability, or for example a dynamic length form, where you 
     * can add duplicate rows, the keyfields will be set in a groupname.rowindex.fieldname, however the formik
     * errors don't translate gracefully to this mult-row setup, and come as a JSON object like follows:
     * 
     * formik.errors = {
     *      groupname: [
     *          fieldname
     *      ]
     * }
     * where the rowindex is the index of the field in the array.
     */
    const renderError = (error, keyField) => {
        let keyFieldParts = keyField?.split('.');
        
        if(keyFieldParts?.length === 3 && error) {
            return   <div>
                        { error ? <div className="error-text">{error}</div>: null }
                     </div>
        }
        else {
            return <div>{error ? <div className="error-text">{error}</div> : null}</div>
        }
    }
    
    return (
        <div className="field-wrap createPatientDOB">
            {label ? <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    value={value}
                    onChange={onChange}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    minutesStep={minutesStep}
                    readOnly={readOnly}
                    minTime={min}
                    maxTime={max}
                    renderInput={(params) => <TextField onClick={() => !readOnly && setOpen(true)} {...params} error={false} />}
                    {...props}
                />
            </LocalizationProvider>
            {
                renderError(error, keyField)
            }
        </div>
    )
}

export default TimeSelector