import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import {  QuestionCategoryFormikObj,questionnaireForOptions} from "./Constant";
import { Skeleton, Tooltip, } from "@mui/material";
import { ArrowBack, NavigateNext } from "@mui/icons-material";
import { createQuestionCategory } from "../../api/Questionnaire";
import { useQuestionQuery } from "../../../hooks/ReactQueryHooks/useQuestionQuery";
import { useSelector } from "react-redux";

const CreateCategory = () => {
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const userOrg = useSelector((state) => state?.auth?.user?.["custom:orgId"]);
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);    
    const { isLoading, isFetching, data } = useQuestionQuery({ orgId: userOrg, onSuccess: () => { } });

    const formik = useFormik({
        ...QuestionCategoryFormikObj,
        onSubmit: (values) => {  
            setBtnLoading(true);
            createQuestionCategory({ ...values,}).then((res) => {
                navigate("/app/manage-category");
                success(res.message);
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });   

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"categoryName"} label={"Category Name"} formik={formik} placeholder={"Category Name"} />
                        </Col>
                        <Col>
                            <SelectFiled
                                keyField={"categoryType"}
                                label={"Category Type"}
                                formik={formik}
                                placeholder={"Select"}
                                options={questionnaireForOptions}
                            />
                        </Col>
                    </Row>                    
                    <div className="btn-wrap" style={{ display: "flex", marginTop: "2rem" }}>
                        <Button variant="secondary" title="Cancel" onClick={() => navigate("/app/manage-category")}>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading} disabled={isLoading || isFetching}>
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default CreateCategory;
