import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useGetQuestionsByCategoryQuery({ purpose, orgId, ...props }) {

    const fetch = () => {
        return axios.get(`/question?categoryWiseQuestion=true&purpose=${purpose}&orgId=${orgId}`)
    }

    const query = useQuery(
        [purpose, "questions-by-category--wise-list"],
        () => fetch(),
        {
            select: (data) => data.data,
            onError,
            ...props
        }
    );
    return { ...query };
}
