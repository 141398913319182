import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { QuestionnaireFormikObjEdit, questionnaireForOptions } from "./Constant";
import { editQuestionnaire, deleteQuestionnaire } from "../../api/Questionnaire";
import { NavigateNext, ExpandMore, ArrowBack } from "@mui/icons-material";
import { Skeleton, Tooltip } from "@mui/material";
import "./style.css"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useGetQuestionsByCategoryQuery } from "../../../hooks/ReactQueryHooks/useGetQuestionsByCategoryQuery";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import DeleteIcon from "../../../assets/images/cross-icon.png";
import { ViewDate } from "../../../utils/DateSupport";
import { useLocation, useNavigate } from "react-router-dom";

const EditQuestionnaire = () => {
  const location = useLocation();
  const { initialState, isReadOnly } = location.state || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const [categorySelectedQuestions, setCategorySelectedQuestions] = useState([]);
  const [intialCategorySelectedQuestions, setIntialCategorySelectedQuestions] = useState([]);
  const [questionsCategoryList, setQuestionsCategoryList] = useState();
  const [err, setErr] = useState('');
  const { getConfirmation } = useConfirmDialog();
  const [deleting, setDeleting] = useState(false);
  const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const userOrg = useSelector((state) => state?.auth?.user?.["custom:orgId"]);
  const hasAccess = userType?.includes("Super-Admin") ? true : !!!initialState?.isDefault;
  const navigate = useNavigate();

  const formik = useFormik({
    ...QuestionnaireFormikObjEdit,
    onSubmit: (values) => {
      const tempQuestionsArr = []
      const questionCounts = {
        LOCATION: 0,
        REASON: 0,
        TELEPHONE: 0,
        CHANNEL: 0,
        RELATIONSHIP: 0,
        SELFCALLING: 0,
        CONSENT: 0,
        CALLER: 0
      };

      categorySelectedQuestions.forEach((question) => {
        tempQuestionsArr.push({
          text: question.question,
          id: question.linkId,
          required: !!question.required,
          question: question.question,
          answerType: question.answerType,
          answerOption: question.answerOption || question.options,
          note: question.note,
          tag: question.tag || "",
        });

        if (questionCounts.hasOwnProperty(question.tag)) {
          questionCounts[question.tag] += 1;
        }
      });

      if (formik.values?.purpose.includes("getcare")) {
        const requiredTags = ["REASON", "LOCATION", "TELEPHONE", "CHANNEL"];
        const missingQuestions = validateMissingQuestions(requiredTags, questionCounts);

        if (missingQuestions.length > 0) {
          setErr(`Must Select These Questions: ${missingQuestions.join(', ')}`);
          return;
        }

        if (!validateDuplicateQuestions(requiredTags, questionCounts)) return;
      }

      if (formik.values?.purpose.includes("telephone-intake")) {
        const requiredTags = ["REASON", "LOCATION", "TELEPHONE", "RELATIONSHIP", "SELFCALLING", "CONSENT", "CALLER"];
        const missingQuestions = validateMissingQuestions(requiredTags, questionCounts);

        if (missingQuestions.length > 0) {
          setErr(`Must Select These Questions: ${missingQuestions.join(', ')}`);
          return;
        }

        if (!validateDuplicateQuestions(requiredTags, questionCounts)) return;
      }

      if (formik.values?.purpose.includes("Video Visit") && categorySelectedQuestions.length === 0) {
        setErr("Must Select a Question");
        return;
      }

      setBtnLoading(true);
      values.isDefault = values.isDefault ? values.isDefault : false
      editQuestionnaire(initialState?.id, {
        ...values,
        question: tempQuestionsArr
      }).then((res) => {
        success(res.message);
      }).catch(res => {
        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
      }).finally(() => setBtnLoading(false));
    },
  });

  const validateMissingQuestions = (requiredTags, questionCounts) => {
    const missingQuestions = [];

    requiredTags.forEach(tag => {
      if (questionCounts[tag] === 0) {
        const readableTag = {
          LOCATION: "Patient Location",
          REASON: "Reason for visit",
          TELEPHONE: "Return Telephone Number",
          CHANNEL: "Preferred Channel",
          RELATIONSHIP: "Relationship to Patient",
          SELFCALLING: "Calling for Yourself",
          CONSENT: "Patient Consent",
          CALLER: "Caller Name"
        }[tag];
        missingQuestions.push(readableTag);
      }
    });

    return missingQuestions;
  };

  const validateDuplicateQuestions = (tags, questionCounts) => {
    for (const tag of tags) {
      if (questionCounts[tag] > 1) {
        const readableTag = {
          LOCATION: "Patient Location",
          REASON: "Reason for visit",
          TELEPHONE: "Return Telephone Number",
          CHANNEL: "Preferred Channel",
          RELATIONSHIP: "Relationship to Patient",
          SELFCALLING: "Calling for Yourself",
          CONSENT: "Patient Consent",
          CALLER: "Caller Name"
        }[tag];
        setErr(`You Have Selected More Than One Question Marked With ${readableTag}`);
        return false;
      }
    }
    return true;
  };

  const onSuccess = (data) => {
    setQuestionsCategoryList(data);
    setCategorySelectedQuestions(intialCategorySelectedQuestions?.length ? intialCategorySelectedQuestions : [])
    intialCategorySelectedQuestions?.length && setIntialCategorySelectedQuestions([])
    setErr('');
  };
  const { isLoading, isFetching } = useGetQuestionsByCategoryQuery({ onSuccess, purpose: formik?.values?.purpose, orgId: userOrg, refetchOnWindowFocus: false, });

  useEffect(() => {
    if (initialState?.id) {
      formik.setFieldValue("title", initialState?.title || "");
      formik.setFieldValue("purpose", initialState?.purpose || "");
      formik.setFieldValue("time", initialState?.duration?.value);
      formik.setFieldValue("isDefault", initialState?.isDefault || "");
      setIntialCategorySelectedQuestions(initialState?.item?.map((item) => ({ ...item, question: item?.text })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const handleSelectedQuestions = (question) => {
    setErr('')
    if (!isReadOnly) {
      if (!categorySelectedQuestions?.some(item => item?.question === question?.question)) {
        const newArr = [question, ...categorySelectedQuestions]
        setCategorySelectedQuestions(newArr)
        setErr('');
      } else {
        const newArr = categorySelectedQuestions.filter(item => item?.question !== question?.question)
        setCategorySelectedQuestions(newArr)
        setErr('');
      }
    }
  };

  if (!initialState) {
    return null;
  }

  const handleDrop = (droppedItem) => {
    setErr('');
    if (!droppedItem.destination) return;
    var updatedList = [...categorySelectedQuestions];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setCategorySelectedQuestions(updatedList);
    setErr('');
  };

  const handleDelete = async (id, entryName, purposeData) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Delete",
    });
    if (confirmed) {
      setDeleting(initialState?.id);
      let payload = {
        "status": "retired",
        "purpose": purposeData,
        "type": "questionnaire"
      }
      deleteQuestionnaire(initialState?.id, payload).then(() => {
        success("Questionnaire Deleted!");
      }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
    }
  };

  const handleDeleteQuestion = (index) => {
    setErr('');
    const updatedList = [...categorySelectedQuestions];
    updatedList.splice(index, 1);
    setCategorySelectedQuestions(updatedList);
  };

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                disabled={isReadOnly}
                keyField={"title"}
                label={"Title"}
                formik={formik}
                placeholder={"Title"}
              />
            </Col>
            <Col>
              <SelectFiled
                disabled={isReadOnly}
                keyField={"purpose"}
                label={"Purpose"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireForOptions}
                value={formik.values?.purpose}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Available Categories</h6>
              {isLoading || isFetching ? (
                <ul className="questionnaire-list">
                  {[1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                    <li key={val}>
                      <Skeleton width={"100%"} animation="wave" />
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="questionnaire-list">
                  {questionsCategoryList?.map((category) =>
                    <Accordion key={category?.id}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{category?.categoryName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <ul className="questionnaire-list">
                          {category?.questions?.map((question) =>
                            <li key={question?.linkId} onClick={() => handleSelectedQuestions(question, category)}>
                              {question?.question}
                              <NavigateNext style={{ float: "right" }} />
                            </li>
                          )}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </ul>
              )}
            </Col>
            <Col>
              <h6>Selected Questions</h6>
              {categorySelectedQuestions?.length ? null : (
                <div>No available question selected</div>
              )}
              <Tooltip title={"Drag to Reorder Questions"}>
                <ul className="questionnaire-list">
                  {!isReadOnly ? <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {categorySelectedQuestions.map((item, index) => (
                            <Draggable
                              key={item?.question}
                              draggableId={item?.question}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div><span> {item?.question} </span></div>
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    onClick={() => handleDeleteQuestion(index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> : categorySelectedQuestions?.map((item, key) => (
                    <li key={key} onClick={() => handleSelectedQuestions(item)}>
                      <div> <span> {item.text} </span></div>
                    </li>
                  ))
                  }
                </ul>
              </Tooltip>
              <div style={{ fontSize: "14px", color: "#dc3545", paddingTop: "5px" }}>{err}</div>
            </Col>
          </Row><hr />
          <Row>
            <Col>
              <div>
                <h6>Date Created : {ViewDate(initialState?.createdAt)}</h6>
                <h6>Date Last Updated : {ViewDate(initialState?.updatedAt)} </h6>
              </div>
            </Col>
            <Col>
              <div>
                {!isReadOnly ? (<>
                  <div>
                    <Row >
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button variant="secondary" title="Cancel" onClick={() => navigate(-1)} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Cancel
                        </Button>
                      </Col>
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button type="submit" isLoading={btnLoading} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Update
                        </Button>
                      </Col>
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button variant="secondary" title="Delete" onClick={() => hasAccess && (deleting ? null : handleDelete(initialState?.id, initialState?.title, initialState?.purpose))} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div>
            {formik?.touched["question"] && formik?.errors["question"] ? (
              <div className="error-text">{formik?.errors["question"]}</div>
            ) : null}
          </div>
        </form>
      </div>
    </section>
  );
}

export default EditQuestionnaire;
