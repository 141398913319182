import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPractitionerList, getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { webSocketEndpoint } from "../../../environment";
import { editQueueItem, getActiveQueue } from "../api/WaitingRoom";
import { Col, Row, Table } from "react-bootstrap";
import { InputLabel, Skeleton, TableCell, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  AddCircleOutline,
  InfoOutlined,
  VideocamOutlined,
  AddIcCall,
  DescriptionOutlined,
  History,
} from "@mui/icons-material";
import { Button } from "../../common/Button";
import ViewIcon from "../../../assets/images/view.png";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import AddNoteModal from "./AddNoteModal";
import ViewNotesModal from "./ViewNotesModal";
import AssignPractitionerModal from "./AssignPractitionerModal";
import PriorityHistoryModal from "./PriorityHistoryModal";
import SearchBar from "./SearchBar";
import { ViewEncounterModal } from "./ViewEncounterModal";
import MultiSelect from "../../common/textfield/MultiSelect";
import { useFormik } from "formik";
import moment from "moment";
import { ViewDate } from "../../../utils/DateSupport";

function Queue() {
  const practitionerId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const [myUserId, setMyUserId] = useState("");
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(false);
  const [userScopeOfPractice, setUserScopeOfPractice] = useState("");
  const [initiateWebsocketConnection, setInitiateWebsocketConnection] =
    useState(false);
  const token = localStorage.authentication;
  const userOrganizationId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );
  const [queuePatients, setQueuePatients] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const totalRows = queuePatients?.length;
  const [currentTime, setCurrentTime] = useState(new Date());
  const [addNoteModalShow, setAddNoteModalShow] = useState(false);
  const [viewNoteModalShow, setViewNoteModalShow] = useState(false);
  const [addNoteModalData, setAddNoteModalData] = useState(null);
  const [assignPractData, setAssignPractData] = useState(null);
  const [assignPractModalShow, setAssignPractModalShow] = useState(false);
  const [newRecordId, setNewRecordId] = useState(null);
  const [encounterModal, setEncounterModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [priorityHistoryModal, setPriorityHistoryModal] = useState(false);
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [practitionersUnderOrg, setPractitionersUnderOrg] = useState([]);
  const [selectedPractitionerFilter, setSelectedPractitionerFilter] =
    useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    data: "arrived",
    value: "arrived",
    label: "Arrived",
  });
  const [myUserData, setMyUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getSingleUser({ id: practitionerId, type: "Practitioner" })
      .then((res) => {
        setMyUserData(res?.data);
        setUserScopeOfPractice(res?.data?.scopeOfPractice);
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
        setInitiateWebsocketConnection(true);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    getPractitionerList(userOrganizationId)
      .then((res) => {
        if (res.status === true) {
          setPractitionersUnderOrg(res?.result);
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    if (initiateWebsocketConnection) {
      fetchData();
      const wsUrl = `wss://${webSocketEndpoint}?token=${token}&organizationId=${userOrganizationId}&scopeOfPractice=${JSON.stringify(
        userScopeOfPractice
      )}`;
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        console.log("WebSocket connection opened successfully");
        //setLoading(false);
      };
      ws.onmessage = (event) => {
        const newRecord = JSON.parse(event.data);

        switch (newRecord.messageType) {
          case "INSERT":
            setQueuePatients((prevTableData) => {
              const updatedTableData = [...prevTableData, newRecord];
              updatedTableData.sort((a, b) => a.priority - b.priority);
              setNewRecordId(newRecord.id);
              setTimeout(() => {
                setNewRecordId(null);
              }, 500);

              return updatedTableData;
            });
            break;

          case "MODIFY":
            if (newRecord?.status === "arrived" ||
                newRecord?.status === "parked" ||
                newRecord?.status === "follow-up" ||
                newRecord?.status === "in-progress-waitingroom" ||
                newRecord?.status === "in-progress-appointment") {
              setQueuePatients((prevTableData) => {
                const existingIndex = prevTableData.findIndex(
                  (item) => item?.id === newRecord?.id
                );
                if (existingIndex !== -1) {
                  const updatedTableData = [...prevTableData];
                  updatedTableData[existingIndex] = newRecord;
                  updatedTableData.sort((a, b) => a.priority - b.priority);
                  return updatedTableData;
                }
                return prevTableData;
              });
            }
            if ((
              newRecord?.status === "cancelled" ||
              newRecord?.status === "in-progress-waitingroom" ||
              newRecord?.status === "in-progress-appointment") && (selectedStatus?.value === "arrived" || selectedStatus?.value === "parked" || selectedStatus?.value === "follow-up")
            ) {
              setQueuePatients((prevTableData) => {
                const existingIndex = prevTableData.findIndex(
                  (item) => item?.id === newRecord?.id
                );

                if (existingIndex !== -1) {
                  const updatedTableData = [...prevTableData];
                  updatedTableData.splice(existingIndex, 1);
                  updatedTableData.sort((a, b) => a.priority - b.priority);
                  return updatedTableData;
                }
                return prevTableData;
              });
            }
            if (newRecord?.status === "finished") {
              setQueuePatients((prevTableData) => {
                const existingIndex = prevTableData.findIndex(
                  (item) => item?.id === newRecord?.id
                );

                if (existingIndex !== -1) {
                  const updatedTableData = [...prevTableData];
                  updatedTableData.splice(existingIndex, 1);
                  updatedTableData.sort((a, b) => a.priority - b.priority);
                  return updatedTableData;
                }
                return prevTableData;
              });

            }
            break;
          case "REMOVE":
            if (newRecord?.status === "finished" || newRecord?.status === "cancelled") {
              setQueuePatients((prevTableData) => {
                const existingIndex = prevTableData.findIndex(
                  (item) => item?.id === newRecord?.id
                );

                if (existingIndex !== -1) {
                  const updatedTableData = [...prevTableData];
                  updatedTableData.splice(existingIndex, 1);
                  updatedTableData.sort((a, b) => a.priority - b.priority);
                  return updatedTableData;
                }
                return prevTableData;
              });

            }
            break;
          default:
            break;
        }

        //console.log("MESSAGE: ", newRecord);
      };

      ws.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
      };

      ws.onerror = (error) => {
        console.error("WebSocket encountered an error:", error);
      };

      return () => {
        if (ws) {
          ws.close();
        }
      };
    }
  }, [initiateWebsocketConnection, selectedStatus]);

  const fetchData = () => {
    setIsLoading(true);
    const encodedScopeOfPractice = encodeURIComponent(
      JSON.stringify(userScopeOfPractice)
    );
    getActiveQueue({
      scopeOfPractice: encodedScopeOfPractice,
      status: selectedStatus?.value,
      orgId: userOrganizationId,
    })
      .then((res) => {
        const sortedData = res?.result?.sort((a, b) => {
          const priorityComparison = a.priority - b.priority;
          if (priorityComparison === 0) {
            return new Date(a.period.start) - new Date(b.period.start);
          }
          return priorityComparison;
        });
        setQueuePatients(sortedData);
        setSearchFilterData(sortedData);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setIsLoading(false));
  };

  const calculateWaitTime = (arrivalTimestamp) => {
    const adjustedArrivalTimestamp = arrivalTimestamp + 10000;
    const currentTime = new Date();
    const currentTimestamp = currentTime.getTime();
    const waitTimeInMinutes = Math.floor(
      (currentTimestamp - arrivalTimestamp) / (1000 * 60)
    );
    const hours = Math.floor(waitTimeInMinutes / 60);
    const minutes = waitTimeInMinutes % 60;

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute
    return () => clearInterval(intervalId);
  }, []);

  const handleUpdatePriority = (data, newPriority) => {
    setFullScreenLoader(true);
    const currentPriorityHistory = data?.priorityHistory || [];
    let actorPriorityChange = {
      actor: {
        display: practUserName,
        reference: `Practitioner/${myUserId}`,
        type: "Practitioner",
      },
      oldPriority: data?.priority,
      newPriority: newPriority,
      updatedAt: Date.now(),
    };
    const updatedPriorityHistory = [
      ...currentPriorityHistory,
      actorPriorityChange,
    ];
    editQueueItem(
      {
        id: data?.id,
        priority: newPriority,
        priorityHistory: updatedPriorityHistory,
      },
      userOrganizationId
    )
      .then((res) => {
        success(
          `Priority updated for ${capitalizeWords(
            data?.subject?.display
          )} to ${newPriority}`
        );
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setFullScreenLoader(false));
  };

  const handleAddNoteClick = (data) => {
    setAddNoteModalData(data);
    setAddNoteModalShow(true);
  };

  const handleViewNotesClick = (data) => {
    setAddNoteModalData(data);
    setViewNoteModalShow(true);
  };

  const handleViewEncounterClick = (data) => {
    setSelectedRecord(data);
    setEncounterModal(true);
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };

  const handleAssignPractitionerClick = (data) => {
    setAssignPractData(data);
    setAssignPractModalShow(true);
  };

  const handlePriorityHistoryView = (data) => {
    setSelectedRecord(data);
    setPriorityHistoryModal(true);
  };

  const handlePatientView = (data) => {
    const baseUrl = window.location.origin;
    const patientIdView = data?.subject?.reference?.split("/")?.[1];
    const patientNameView = data?.subject?.display;
    const url = `${baseUrl}/app/patients-details?patientId=${patientIdView}&patientName=${patientNameView}`;
    window.open(url, "_blank");
  };

  const handleFilterData = (data) => {
    setSearchFilterData(data);
    page !== 0 && setPage(0);
  };

  const statusOptions = [
    { status: "arrived", value: "arrived" },
    { status: "in-progress-waitingroom", value: "in-progress-waitingroom" },
    { status: "in-progress-appointment", value: "in-progress-appointment"},
    { status: "parked", value: "parked" },
    { status: "follow-up", value: "follow-up" }
  ].map((option) => ({
    value: option.value,
    label: capitalizeWords(option.status),
    data: option.status,
  }));

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const priorityOptions = [
    [1, 2, 3, 4, 5].map((option) => ({
      name: option,
      value: option,
      id: option,
    })),
  ];

  const formik = useFormik({
    initialValues: {
      selectedPriorityLevels: [],
    },
  });

  const handlePriorityFilterChange = (filterArray) => {
    if (formik?.values?.selectedPriorityLevels.length === 0) {
      setSearchFilterData(queuePatients);
      return;
    }

    const prioritySet = new Set(filterArray);

    const filteredData = queuePatients.filter((data) =>
      prioritySet.has(data.priority)
    );

    setSearchFilterData(filteredData);
  };

  useEffect(() => {
    handlePriorityFilterChange(formik.values.selectedPriorityLevels);
  }, [formik?.values?.selectedPriorityLevels]);

  const practitionerOptions = practitionersUnderOrg.map((practitioner) => ({
    value: practitioner.name?.[0]?.text,
    label: capitalizeWords(practitioner.name?.[0]?.text),
    data: practitioner,
  }));

  const handlePractitionerFilterChange = (selectedOption) => {
    setSelectedPractitionerFilter(selectedOption);
    if (selectedOption !== null) {
      const filteredData = queuePatients?.filter(
        (data) =>
          data?.practitionerHistory &&
          data?.practitionerHistory[data?.practitionerHistory?.length - 1]?.actor?.reference?.split("/")[1] === selectedOption?.data?.id
      );

      setSearchFilterData(filteredData);
    } else {
      setSearchFilterData(queuePatients);
    }
  };
  const handlePreviewEncounterClick = (data, from = "draft") => {
    const baseUrl = window.location.origin;
    const encounterId = data?.id;
    const patientId = data?.subject?.reference?.split("/")?.[1];
    const url = `${baseUrl}/app/encounter-report?encounterId=${encounterId}&from=${from}`;
    window.open(url, "_blank");
    
  };

  return (
    <>
      {fullScreenLoader && <FullPageSpinner loadingText="Loading..." />}
      <section className="common-listing">
        <div className="heading-wrap h-change">
          <Row>
            <Col>
              <InputLabel
                id="mutiple-select-label"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {"Search Patient"}
              </InputLabel>
              <SearchBar
                list={queuePatients || []}
                filterData={handleFilterData}
              />
            </Col>
            <Col>
              <InputLabel
                id="mutiple-select-label"
                style={{ fontSize: "0.75rem" }}
              >
                {"Status"}
              </InputLabel>

              <Select
                onChange={handleStatusChange}
                isMulti={false}
                name="status"
                options={statusOptions}
                className="search-select"
                classNamePrefix="select"
                value={selectedStatus}
                isClearable={false}
                isLoading={statusOptions?.length > 0 ? false : true}
              />
            </Col>

            <Col>
              <MultiSelect
                options={priorityOptions[0]}
                keyField={"selectedPriorityLevels"}
                formik={formik}
                label={"Filter by Priority Level"}
                nodata={"No Data Found"}
                required={false}
              />
            </Col>

            <Col>
              <InputLabel
                id="mutiple-select-label"
                style={{ fontSize: "0.75rem" }}
              >
                {"Filter by Practitioner (Optional)"}
              </InputLabel>
              <Select
                onChange={handlePractitionerFilterChange}
                isMulti={false}
                name="practitioner-filter"
                options={practitionerOptions}
                className="search-select"
                classNamePrefix="select"
                value={selectedPractitionerFilter}
                isClearable={true}
                isLoading={practitionerOptions?.length > 0 ? false : true}
              />
            </Col>
          </Row>
        </div>
        <div className="table-wrap">
          <Table>
            <thead>
              <tr>
                <th>Priority Reason</th>
                <th>Priority</th>
                <th style={{ textAlign: "center" }}>Patient Name</th>
                <th style={{ textAlign: "center" }}>Location</th>
                <th style={{ textAlign: "center" }}>Reason for Call</th>
                {selectedStatus?.value !== "in-progress-appointment" ? (
                  <th style={{ textAlign: "center" }}>Wait Time</th>
                ) : (
                  <th style={{ textAlign: "center" }}>Arrival Time</th>
                )}
                <th style={{ textAlign: "center", marginLeft: "15px" }}>
                  Notes
                </th>
                <th style={{ textAlign: "center" }}>Practitioner</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((val) => (
                  <tr key={val}>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                ))
              ) : searchFilterData?.length > 0 ? (
                searchFilterData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data) => {
                    const waitTime = calculateWaitTime(data?.createdAt);

                    return (
                      <tr key={data?.id}>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.intakeQuestions?.intakePriorityReason
                            ? data?.intakeQuestions?.intakePriorityReason
                            : "-"}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {selectedStatus.value === "arrived" ||
                            selectedStatus.value === "parked" ||
                            selectedStatus.value === "follow-up" ? (
                              <Select
                                value={
                                  data?.priority !== null
                                    ? {
                                        label: data?.priority,
                                        value: data?.priority,
                                      }
                                    : data.priority
                                    ? {
                                        label: data.priority,
                                        value: data.priority,
                                      }
                                    : null
                                }
                                options={[1, 2, 3, 4, 5].map((value) => ({
                                  label: value,
                                  value,
                                }))}
                                onChange={(selectedOption) => {
                                  handleUpdatePriority(
                                    data,
                                    selectedOption ? selectedOption.value : null
                                  );
                                }}
                              />
                            ) : (
                              data?.priority
                            )}
                            <Tooltip
                              title={"View Priority History"}
                              style={{ marginLeft: "5px" }}
                            >
                              <div>
                                <Link
                                  variant="primary"
                                  className="view-btn"
                                  onClick={() =>
                                    handlePriorityHistoryView(data)
                                  }
                                >
                                  <History style={{ color: "#005ba8" }} />
                                </Link>
                              </div>
                            </Tooltip>
                          </div>
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          <span
                            style={{
                              color: "#005ba8",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePatientView(data)}
                          >
                            {data?.subject?.display}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {data?.patientLocation}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {data?.intakeQuestions?.intakeReason || data?.intakeQuestions?.reason ? (
                            <Tooltip
                              title={<>{data?.intakeQuestions?.intakeReason || data?.intakeQuestions?.reason}</>}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Link variant="primary" className="view-btn">
                                  <InfoOutlined style={{ color: "#005ba8" }} />
                                </Link>
                              </div>
                            </Tooltip>
                          ) : (
                            " "
                          )}
                        </td>
                        {selectedStatus?.value !== "finished" && (
                          <td style={{ textAlign: "center" }}>{waitTime} m</td>
                        )}
                        {selectedStatus?.value === "finished" && (
                          <td style={{ textAlign: "center" }}>
                            {ViewDate(data?.createdAt)}
                          </td>
                        )}
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data?.notes?.length > 0 ? (
                            <Tooltip
                              title={
                                <>
                                  Note:{" "}
                                  {data.notes[data.notes.length - 1]?.note}
                                  <br />
                                  <br /> {/* Empty line */}
                                  Added by:{" "}
                                  {capitalizeWords(
                                    data.notes[data.notes.length - 1]?.actor
                                      ?.display
                                  )}
                                  <br />
                                  Timestamp:{" "}
                                  {moment(
                                    data.notes[data.notes.length - 1]?.updatedAt
                                  ).format("DD-MMM-YY HH:mm")}
                                </>
                              }
                            >
                              <div
                                className="action-wrap"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Link
                                  onClick={() => handleViewNotesClick(data)}
                                  style={{
                                    backgroundColor: "#fff",
                                    border: "1px solid #cee0ef",
                                    borderRadius: "50%",
                                    padding: "5px",
                                  }}
                                >
                                  <DescriptionOutlined
                                    style={{
                                      color: "#005da8",
                                      width: "24px",
                                      height: "20px",
                                    }}
                                  />
                                </Link>
                              </div>
                            </Tooltip>
                          ) : (
                            " "
                          )}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {data?.practitionerHistory && data?.practitionerHistory?.[
                              data?.practitionerHistory?.length - 1
                            ]?.actor?.display &&
                            data?.practitionerHistory?.[
                              data?.practitionerHistory?.length - 1
                            ]?.actor?.display !== "-" ? (
                              <div>
                                <span>
                                  {capitalizeWords(
                                    data?.practitionerHistory[
                                      data?.practitionerHistory.length - 1
                                    ]?.actor?.display
                                  )}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  title="Assign Practitioner"
                                  onClick={() =>
                                    handleAssignPractitionerClick(data)
                                  }
                                >
                                  Assign
                                </Button>
                              </div>
                            )}
                            {data?.practitionerHistory?.length > 0 && (
                              <Tooltip
                                title={"View Assigned Practitioner History"}
                                style={{ marginLeft: "5px" }}
                              >
                                <div>
                                  <Link
                                    variant="primary"
                                    className="view-btn"
                                    onClick={() =>
                                      handleAssignPractitionerClick(data)
                                    }
                                  >
                                    <History style={{ color: "#005ba8" }} />
                                  </Link>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </td>

                        <td>
                          <div
                            className="action-wrap"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {data?.status !== "finished" && (
                              <Button
                                title="Assign Practitioner"
                                onClick={() => handleViewEncounterClick(data)}
                              >
                                View
                              </Button>
                            )}
                            {data?.status === "finished" && (
                              <div
                                onClick={() =>
                                  handlePreviewEncounterClick(data, "final")
                                }
                              >
                                <Tooltip title="View Encounter Report">
                                  <Link to="" className="success-btn">
                                    <DescriptionOutlined
                                      style={{
                                        color: "white",
                                        fontSize: "large",
                                      }}
                                    />
                                  </Link>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <TableCell colSpan={"8"} style={{ textAlign: "center" }}>
                    No Data Available
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {searchFilterData?.length > 0 ? (
          <CustomPagination
            tableData={searchFilterData}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handlePage={(pageNo) => setPage(pageNo)}
          />
        ) : null}
      </section>
      {addNoteModalShow && (
        <AddNoteModal
          data={addNoteModalData}
          onShow={addNoteModalShow}
          onHide={() => {
            setAddNoteModalShow(false)
            if (selectedStatus?.value === "follow-up" || selectedStatus?.value === "parked") {
              fetchData()
            }
          }}
        />
      )}

      {viewNoteModalShow && (
        <ViewNotesModal
          recordData={addNoteModalData}
          onShow={viewNoteModalShow}
          onHide={() => {
            setViewNoteModalShow(false)
            if (selectedStatus?.value === "follow-up" || selectedStatus?.value === "parked") {
              fetchData()
            }
            
          }}
        />
      )}
      {assignPractModalShow && (
        <AssignPractitionerModal
          data={assignPractData}
          onShow={assignPractModalShow}
          onHide={() => {
            setAssignPractModalShow(false)
            if (selectedStatus?.value === "follow-up" || selectedStatus?.value === "parked") {
              fetchData()
            }
          }}
        />
      )}
      {priorityHistoryModal && (
        <PriorityHistoryModal
          data={selectedRecord}
          onShow={priorityHistoryModal}
          onHide={() => {
            setPriorityHistoryModal(false)
            if (selectedStatus?.value === "follow-up" || selectedStatus?.value === "parked") {
              fetchData()
            }
          }}
        />
      )}
      {encounterModal && (
        <ViewEncounterModal
          data={selectedRecord}
          onShow={encounterModal}
          onHide={() => {
            setEncounterModal(false)
            if (selectedStatus?.value === "follow-up" || selectedStatus?.value === "parked") {
              fetchData()
            }
          }}
        />
      )}
    </>
  );
}

export default Queue;
