export const priorityReason = [
  {
    reason: "DT-Triage Escalation 1hr",
    defaultPriority: 1,
  },
  {
    reason: "DT-Triage Escalation 4hr",
    defaultPriority: 1,
  },
  {
    reason: "DT-Triage Escalation 24hr",
    defaultPriority: 1,
  },
  {
    reason: "Schedule Urgent Lab/DI Follow-up",
    defaultPriority: 1,
  },
  {
    reason: "Schedule Routine Lab/DI Follow-up",
    defaultPriority: 5,
  },
  {
    reason: "Schedule Appointment - Urgent",
    defaultPriority: 1,
  },
  {
    reason: "Schedule Appointment - Non-Urgent",
    defaultPriority: 5,
  },
  {
    reason: "Reschedule Appointment",
    defaultPriority: 3,
  },
  {
    reason: "Controlled Drug Substance",
    defaultPriority: 2,
  },
  {
    reason: "ER",
    defaultPriority: 4,
  },
  {
    reason: "Other - Urgent",
    defaultPriority: 1,
  },
  {
    reason: "Other - Non-Urgent",
    defaultPriority: 5,
  },
];
