import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetUsersByOrg({ onSuccess, orgId, type, limit = null, pageNumber = null, active = null, searchQuery = null, sortOrder = null, sortBy = null, ...props }) {

    const fetch = () => {
        if (limit && pageNumber && type) {
            return axios.get(`/users?orgId=${orgId}&type=${type}&limit=${limit}&pageNumber=${pageNumber}&active=${active}&searchQuery=${searchQuery}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
        }
        return orgId
            ? type
                ? axios.get(`/users?orgId=${orgId}&type=${type}`)
                : axios.get(`/users?orgId=${orgId}`)
            : null
    };
    const query = useQuery(
        ["users", orgId],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}
