import { axios } from "../../../lib/axios";

export const createGroup = (data) => {
    return axios.post(`group?orgId=${data?.orgId}`, data)
}

export const editGroup = (data) => {
    return axios.put(`group`, data)
}

export const getGroup = (groupId) => {
    return axios.get(`group?groupId=${groupId}`)
}

export const deleteGroup = (groupId) => {
    return axios.delete(`group/${groupId}`)
}