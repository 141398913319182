import { BorderColor, LocalFireDepartmentTwoTone, RemoveCircleOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { Button } from "../../common/Button";
import TimeSelector from "../../common/textfield/TimeSelector";
import TextInput from "../../common/textfield/TextInput";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { failed } from "../../common/Toastify";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { commonDisableAPI } from "../../api/CommonApis";
import { unavailabilityStatusTypes } from "./constants";
import { useSelector } from "react-redux";
import CreateUnavailability from "./CreateUnavailability";
import EditIcon from "../../../assets/images/editIcon.svg";

export function UnAvailability({ unAvailability, scheduleCategory, setUnAvailability, scheduleId, endDate, startDate, endTime, startTime, currentDate, refetch }) {
    const { getConfirmation } = useConfirmDialog();
    const [deleting, setDeleting] = useState(false);
    const [date, setDate] = useState(moment(currentDate));
    const [editData, setEditData] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isPast, setIsPast] = useState((moment().isAfter(startDate, 'hour') && moment().isAfter(endDate, 'hour')) || moment().isAfter(moment(currentDate, 'hour')))
    const loggedInUserData = useSelector((state) => state?.auth?.user);

    const localTime = (ms) => {
       let sourceDate =  new Date(ms);
       let hrs = sourceDate.getHours();
       let mins = sourceDate.getMinutes();
       let suffix = hrs >= 12 ? 'PM':'AM';
       hrs = hrs % 12;
       hrs = hrs ? hrs : 12;
       hrs = hrs < 10 ? `0${hrs}`: hrs;
       mins = mins < 10 ? `0${mins}`: mins;
       return `${hrs}:${mins} ${suffix}`;    
    }

    const handleUnAvailabilityChange = (fieldName, val, key) => {
        setUnAvailability({
            ...unAvailability,
            [key]: {
                ...unAvailability?.[key],
                [fieldName]: val,
                isNotValid: false,
            },
        });
    };

    const handleRemoveUnAvailability = async (id) => {
        const { [id]: removedSlot, ...rest } = unAvailability;
        const confirmed = await getConfirmation({
            title: "Attention!",
            message: "Are you sure you would like to remove the slot?",
            actionBtnTitle: "Remove"
        });
        if (confirmed) {
            setDeleting(id);
            if (id.indexOf(' ') >= 0) {
                setUnAvailability(rest);
                setDeleting(false)
            } else {
                commonDisableAPI({ key: "slot", id, userOrgId: loggedInUserData["custom:orgId"] })
                    .then(() => setUnAvailability(rest))
                    .catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setDeleting(false));
            }
        }
    }

    return (
        <div style={{ paddingTop: "1rem", pointerEvents: deleting ? "none" : "all" }}>
            {showCreateModal ? <CreateUnavailability
                scheduleCategory = {scheduleCategory}
                modalShow={showCreateModal}
                startDate={startDate}
                editData={editData}
                handleHide={() => { setShowCreateModal(false); editData && setEditData(false) }}
                scheduleId={scheduleId}
                refresh={refetch}
                endDate={endDate}
                startTime={startTime}
                endTime={endTime}
            /> : null}
            <h5>Unavailability date and time</h5>
            <Row>
                <Col>
                    <DateSelectorWithoutFormik  style={{ width: "clamp(2rem, 3.5vw, 5rem)" }} value={date} handleChange={(val) =>{setDate(val)}} label="Select Date" minDate={startDate} hideRequired={true} maxDate={endDate} />
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Reason</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

            {Object.keys(unAvailability)
                .filter((key) => moment(unAvailability?.[key]?.date)?.format("MM-DD-YYYY") === date?.format("MM-DD-YYYY") 
                        && unavailabilityStatusTypes.find(item => {return item.id === unAvailability?.[key]?.status}) != null) 
                .map(key => 
                <tr key={key}>
                    <td><span style={{border: "none", paddingTop: 5, paddingBottom: 5, fontSize: 12}}>{localTime(unAvailability?.[key]?.from)}</span></td>
                    <td><span style={{border: "none", paddingTop: 5, paddingBottom: 5, fontSize: 12}}>{localTime(unAvailability?.[key]?.to)}</span></td>
                    <td><span style={{border: "none", paddingTop: 5, paddingBottom: 5, fontSize: 12}}>{unavailabilityStatusTypes.find(item => item.id === unAvailability?.[key]?.status)?.name === "Reserved" 
            ? `${unavailabilityStatusTypes.find(item => item.id === unAvailability?.[key]?.status)?.name} for ${unAvailability?.[key]?.serviceCategory?.map((category => category.name)).join(", ")}` 
            : unavailabilityStatusTypes.find(item => item.id === unAvailability?.[key]?.status)?.name || "--------"}</span></td>
                    <td xs={2}>
                        {!isPast && !moment().isAfter(unAvailability?.[key]?.from, 'minute') ? <Tooltip title="Remove" onClick={() => handleRemoveUnAvailability(key)}>
                        {deleting === key
                            ? <Spinner style={{ color: "red",  }} />
                            : <RemoveCircleOutline sx={{ color: "red",  }} />
                        }
                        </Tooltip> : null}
                        {/* { unAvailability?.[key]?.status != "busy-reserved" ? <Tooltip title="Edit" onClick={() => { setEditData({ ...unAvailability?.[key], key }); setShowCreateModal(true) }}>
                        <img src={EditIcon} style={{width: 24}} />
                        </Tooltip> : null } */}
                        <Tooltip title="Edit" onClick={() => { setEditData({ ...unAvailability?.[key], key }); setShowCreateModal(true) }}>
                        <img src={EditIcon} style={{width: 24}} />
                        </Tooltip>
                    </td>
                </tr>
                ).sort( (a, b) => {
                   let aFrom = unAvailability?.[a.key]?.from;
                   let bFrom = unAvailability?.[b.key]?.from; 
                   return aFrom < bFrom ? -1 : (aFrom > bFrom ? 1:0);
                })}
                    </tbody>
                </Table>
            </div>
            <div className="btn-wrap" style={{ display: "flex", gap: "3rem" }}>
                {scheduleId ? <Button onClick={() => setShowCreateModal(true)} disabled={isPast && moment().isAfter(endDate, 'hour')}>Create</Button> : null}
            </div>
        </div>
    );
}
