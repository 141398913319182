import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useQuestionnaireResponseQuery({ onSuccess, id = "E9PSPzIyvg", serviceRequestId, ...props }) { // we have to set "id" as static value because on createServiceRequest API we have pass questionnaireResponseId = null (in other words, we dont have questionnaireResponseId for the first time)

    const fetch = () => {
        return axios.get(`/questionnaireresponse/${id}?serviceRequestId=${serviceRequestId}`)
    }

    const query = useQuery(
        ["questionnaires-response", id, serviceRequestId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}

export function useEncounterQuestionnaireResponseQuery({ onSuccess, encounterId }) { // we have to set "id" as static value because on createServiceRequest API we have pass questionnaireResponseId = null (in other words, we dont have questionnaireResponseId for the first time)

    const fetch = () => {
        return axios.get(`/questionnaireresponse?encounterId=${encounterId}`)
    }

    const query = useQuery(
        ["encounter-questionnaires-response", encounterId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
        }
    );

    return { ...query };
}