import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getPractitionerList, getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import { useSelector } from "react-redux";
import { editQueueItem } from "../api/WaitingRoom";
import moment from "moment";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { Table } from "react-bootstrap";
import { TableCell } from "@mui/material";
import "./QueueStyles.css";

const AssignPractitionerModal = ({ data, onShow, onHide }) => {
  const [practitionerList, setPractitionerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const practitionerId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );
  const [myUserId, setMyUserId] = useState("");
  const practitionerHistory = data?.practitionerHistory;
  const [page, setPage] = useState(0);
  const rowsPerPage = 3;
  const reversedPractitionerHistory = [...practitionerHistory]?.reverse();
  const totalRows = reversedPractitionerHistory?.length;
  const userOrganizationId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };

  useEffect(() => {
    getPractitionerList(data?.orgId, data?.patientLocation)
      .then((res) => {
        setPractitionerList(res?.result);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getSingleUser({ id: practitionerId, type: "Practitioner" })
      .then((res) => {
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  const practitionerOptions = practitionerList.map((practitioner) => ({
    value: practitioner.name?.[0]?.text,
    label: capitalizeWords(practitioner.name?.[0]?.text),
    data: practitioner,
  }));

  const currentPractitioner =
    reversedPractitionerHistory?.[0]?.actor?.display || null;

  const handlePractitionerChange = (selectedOption) => {
    setSelectedPractitioner(selectedOption);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (selectedPractitioner) {
      const currentPractitionerHistory = data?.practitionerHistory || [];
      let newPractitioner = {
        actor: {
          display: selectedPractitioner?.data?.name?.[0]?.text,
          reference: `Practitioner/${selectedPractitioner?.data?.id}`,
          type: "Practitioner",
        },
        assignedBy: {
          actor: {
            display: practUserName,
            reference: `Practitioner/${myUserId}`,
            type: "Practitioner",
          },
        },
        updatedAt: Date.now(),
      };

      const updatedPractitionerHistory = [
        ...currentPractitionerHistory,
        newPractitioner,
      ];
      editQueueItem(
        {
          id: data?.id,
          practitionerHistory: updatedPractitionerHistory,
        },
        userOrganizationId
      )
        .then((res) => {
          if (res.status === true) {
            success(
              `Practitioner assigned successfully for ${capitalizeWords(
                data?.subject?.display
              )}`
            );
            onHide();
          } else {
            failed(res?.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      failed("Please select a practitioner from the list.");
      setIsLoading(false);
    }
  };

  const handleUnAssign = () => {
    setIsLoading(true);
    const currentPractitionerHistory = data?.practitionerHistory || [];
    let newPractitioner = {
      actor: {
        display: "-",
        reference: `-`,
        type: "-",
      },
      assignedBy: {
        actor: {
          display: practUserName,
          reference: `Practitioner/${myUserId}`,
          type: "Practitioner",
        },
      },
      updatedAt: Date.now(),
    };
    const updatedPractitionerHistory = [
      ...currentPractitionerHistory,
      newPractitioner,
    ];

    editQueueItem(
      {
        id: data?.id,
        practitionerHistory: updatedPractitionerHistory,
      },
      userOrganizationId
    )
      .then((res) => {
        if (res.status === true) {
          success(
            `Practitioner Unassigned successfully for ${capitalizeWords(
              data?.subject?.display
            )}`
          );
          onHide();
        } else {
          failed(res?.error);
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <span className="header-text">Practitioner History:</span>
        &nbsp;&nbsp;
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textTransform: "capitalize" }}
        >
          {data?.subject?.display}
        </Modal.Title>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </Modal.Header>
      <Modal.Body>
        {!currentPractitioner || currentPractitioner === "-" ? (
          <div className="select-submit-container">
            <div className="select-container">
              <span>Select Practitioner:</span>
              <Select
                onChange={handlePractitionerChange}
                isMulti={false}
                name="practitionerId"
                options={practitionerOptions}
                className="search-select"
                classNamePrefix="select"
                value={selectedPractitioner}
                isClearable={true}
                autoFocus
                isLoading={practitionerList?.length > 0 ? false : true}
              />
            </div>
            <div className="btn-wrap-assign">
              <Button
                type="submit"
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Assign
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span>Assigned Practitioner:</span>&nbsp;&nbsp;
              <span style={{ fontWeight: "bold", color: "#015ba8" }}>
                {capitalizeWords(currentPractitioner)}
              </span>
            </div>
            <div className="btn-wrap-unassign">
              <Button
                title="Unassign"
                onClick={handleUnAssign}
                isLoading={isLoading}
              >
                Unassign
              </Button>
            </div>
          </div>
        )}

        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Assigned Practitioner</th>
                <th>Assigned By</th>
              </tr>
            </thead>
            <tbody>
              {reversedPractitionerHistory?.length > 0 ? (
                reversedPractitionerHistory
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {moment(data?.updatedAt).format("DD-MMM-YY HH:mm")}
                        </td>{" "}
                        <td style={{ textTransform: "capitalize" }}>
                          {data?.actor?.display}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {data?.assignedBy?.actor?.display}
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <TableCell colSpan={"8"} style={{ textAlign: "center" }}>
                    No Data Available
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
          {reversedPractitionerHistory?.length > 0 ? (
            <CustomPagination
              tableData={reversedPractitionerHistory}
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handlePage={(pageNo) => setPage(pageNo)}
            />
          ) : null}
        </div>

        <div className="btn-wrap">
          <Button onClick={() => onHide()} variant="secondary" title="Cancel">
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignPractitionerModal;
