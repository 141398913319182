import moment from "moment";
import * as Yup from "yup";

export const FormikObj = {
    initialValues: {
        start: "",
        end: "",
        startTime: "",
        endTime: "",
    },
    validationSchema: Yup.object().shape({
        start: Yup.string().required("Required start date field"),
        end: Yup.string().required("Required end date field"),
        startTime: Yup.string().required("Required start time field"),
        endTime: Yup.string().required("Required end time field"),
    }),
}

export function daysBetweenDates(startDate, endDate) {
    let date = []
    while (moment(startDate) <= moment(endDate)) {
        date.push(startDate);
        startDate = moment(startDate).add(1, 'days');
    }
    return date;
}
export const intialData = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
};

export const unavailabilityStatusTypes = [
    {
        value: "busy-break", 
        id: "busy-break",
        name: "Break"
    },
    {
        value: "busy-meal", 
        id: "busy-meal", 
        name: "Meal"
    },
    {
        value: "busy-meeting", 
        id: "busy-meeting", 
        name: "Meeting"
    },
    {
        value: "busy-training", 
        id: "busy-training", 
        name: "Training"
    },
    {
        value: "busy-documentation", 
        id: "busy-documentation", 
        name: "Documentation Review"
    },
    {
        value: "busy-administration", 
        id: "busy-administration", 
        name: "Administrative Work"
    },
    {
        value: "busy-unscheduled",
        id: "busy-unscheduled",
        name: "Not Scheduled"
    },
    {
        value: "busy-reserved", 
        id: "busy-reserved", 
        name: "Reserved"
    },
    {
        value: "busy-other", 
        id: "busy-other", 
        name: "Other"
    }
];

export const intialUnAvailabilityData = {
    [moment()]: {
        date: "",
        from: "",
        to: "",
        status: ""
    }
}
export const FormikObjOfCreateUnavailibilty = {
    initialValues: {
            unavailability: [{
            date: "",
            endDate: "",
            from: "",
            to: "",
            status: "",
            type: [],
        }]
    },
    validationSchema: Yup.object().shape({
        unavailability: Yup.array().of(
            Yup.object().shape({
                date: Yup.string().required("Required Date field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                endDate: Yup.string().required("Required Date field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                from: Yup.string().required("Required From field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                to: Yup.string().required("Required To field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                status: Yup.string().required("Required Type field").matches(/^$|^\S+.*/, 'Please select an option.'),
                type: Yup.array().when("status", (status, schema) => {
                    if(status == "busy-reserved") return schema.min(1, "You must specify at least one type.").required("You must specify at least one type.");
                })
            })
        ),
    }),
}
export const FormikObjOfEditUnavailibilty = {
    initialValues: {
            unavailability: [{
            date: "",
            from: "",
            to: "",
            status: "",
            type: [],
        }]
    },
    validationSchema: Yup.object().shape({
        unavailability: Yup.array().of(
            Yup.object().shape({
                date: Yup.string().required("Required Date field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                from: Yup.string().required("Required From field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                to: Yup.string().required("Required To field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                status: Yup.string().required("Required Type field").matches(/^$|^\S+.*/, 'Please select an option.'),
                type: Yup.array().when("status", (status, schema) => {
                    if(status == "busy-reserved") return schema.min(1, "You must specify at least one type.").required("You must specify at least one type.");
                })
            })
        ),
    }),
}

export const convertVitalsValueToBaseValue = (value, currentUnitOfValue) => {
    switch (currentUnitOfValue) {
        case "kPa":   //Blood pressure convert to mmHg
            return parseFloat(value) * 7.50062
        case "psi":   // Blood pressure convert to mmHg
            return parseFloat(value) * 51.7149
        case "mmol/L":   //Blood glucose convert to mg/dL
            return parseFloat(value) * 18
        case "°F":   // Temperature convert to °C
            return (parseFloat(value) - 32) / 1.8
        case "lbs":   // Weight convert to kg
            return parseFloat(value) * 0.45359237
        default:
            return parseFloat(value)
    }
}

export const convertVitalsBaseValueToOrgLevelUnit = (value, currentUnitOfValue) => {
    switch (currentUnitOfValue) {
        case "kPa":   //Blood pressure convert from mmHg
            return parseFloat(parseFloat(value) / 7.50062).toFixed(1)
        case "psi":   // Blood pressure convert from mmHg
            return parseFloat(parseFloat(value) / 51.7149).toFixed(1)
        case "mmol/L":   //Blood glucose convert from mg/dL
            return parseFloat(parseFloat(value) / 18).toFixed(1)
        case "°F":   // Temperature convert from °C
            return parseFloat((parseFloat(value) * 9 / 5) + 32).toFixed(1)
        case "lbs":   // Weight convert from kg
            return parseFloat(parseFloat(value) / 0.45359237).toFixed(1)
        default:
            return parseFloat(value).toFixed(1)
    }
}