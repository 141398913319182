import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../common/Button";
import { useSelector } from "react-redux";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { useGetPatientById } from "../../../hooks/ReactQueryHooks/useGetPatientById";
import { useLocation,useNavigate } from "react-router";
import { useQuestionnaireResponseQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireResponseQuery";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { getSingleUser } from "../../api/Individual";

const QuestionField = ({ item, elementLenght}) => {
  switch (item?.questionType) {
    case "radio":
      return (
        <>
          {item?.answer.map((ans, index) => (
            <div className="table-cell" key={index}>
              {ans?.option}
              <Form.Check disabled checked={ans?.valueBoolean} type={item?.questionType} />
            </div>
          ))}
        </>
      );
    case "checkbox":
      return (
        <>
          {item?.answer.map((ans, index) => (
            <div className="table-cell" key={index}>
              {ans?.option}
              <Form.Check disabled checked={ans?.valueBoolean} type={item?.questionType} />
            </div>
          ))}
        </>
      );
    default:
      let elements = [];
      for (let index = 1; index < elementLenght; index++) {
        elements.push(<div key={index} className="table-cell"></div>);
      }
      return (
        <>
          <div className="table-cell">
            <span
              data-toggle="tooltip"
              data-placement="top"
              title={item?.answer?.[0]?.valueString}
            >
              <p className="dataCell">{item?.answer?.[0]?.valueString}</p>
            </span>
          </div>
          {elements}
        </>
      );
  }
};

const ViewServiceRequest = () => {
    const [orgData, setOrgData] = useState(null);
    const [patientData, setPatientData] = useState(null);
    const [questionResponse, setQuestionResponse] = useState([]);
    const [practioner, setPractioner] = useState({})
    const {state} = useLocation();
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const { patientId, serviceRequestId, encounterId,date,requestType, note, practitionerId } = state
    const organizationLogo = useSelector((state) => state?.auth?.user?.organizationLogo)
    const practitionerOrgId = useSelector((state) => state?.auth?.user?.organizations?.[0]?.id);
    const navigate = useNavigate()
    let elementLenght = 0

    const onSuccessOrg = (data) => {
        setOrgData(data);
    };

    useOrgQuery({ onSuccess: onSuccessOrg, orgId: practitionerOrgId });

    const onSuccessPatient = (data) => {
        setPatientData(data);
    };

    useGetPatientById({ onSuccess: onSuccessPatient, patientId });

    const questionnaireResponseSuccess = (data) => {
        setQuestionResponse(data?.item)
    };

    useQuestionnaireResponseQuery({ onSuccess: questionnaireResponseSuccess, serviceRequestId });

    questionResponse?.forEach((element) => {
        elementLenght = element?.answer?.length > elementLenght ? element?.answer?.length : elementLenght
    });
    
    useEffect(() => {
      const apiBody = {
        id: practitionerId,
        type: "Practitioner",
        userOrgId: adminOrgs?.[0]?.id
      };
      getSingleUser(apiBody)
        .then((res) => {
          setPractioner(res?.data)
        })
        .catch((res) => {
          console.log("error in image upload",res)
        })
    }, []);

  return (
    <section className="common-listing">
      <Row className="align-items-center">
        <Col sm={5}>
          <img width={80} src={organizationLogo} />
        </Col>
        <Col>
          <h5>{requestType} {requestType == "Referral" ? "" : "Requisition"}  Form</h5>
        </Col>
      </Row>
      <hr />
      <div className="view-service-request-container">
        <Row>
          <Col className="mb-3">
            <div className="info-wrapper">
              <span>
                {" "}
                <span className="fw-bold">Encounter Id:</span> {encounterId}
              </span>
              <span className="info-item">
                {" "}
                <span className="fw-bold">Date & Time:</span> {`${moment(date).format("MM/DD/YYYY")}  ${moment(date).format("hh:mm A")}`}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h6 className="leftBorderHeading ">Orgnization Information</h6>
            <Row>
              <Col>
                <label htmlFor="">Name:</label>
                <p className="c-black-6"> {orgData?.name}</p>
              </Col>
              <Col>
                <label htmlFor="">Address:</label>
                <p className="c-black-6">{orgData?.address?.[0].line?.[0]}</p>
              </Col>
              <Col>
                <label htmlFor="">Contact:</label>
                <p className="c-black-6">
                  {
                    orgData?.telecom?.find((ele) => ele?.system === "phone")
                      ?.value
                  }
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <h6 className="leftBorderHeading">Practitioner Information</h6>
            <div className="info-wrapper">
              <Row>
                <Col>
                  <label htmlFor="">Name:</label>
                  <p className="c-black-6">{practioner?.name?.[0]?.text}</p>
                </Col>
                <Col>
                  <label htmlFor="">Designation:</label>
                  {practioner?.designation == "nurse" ? <p className="c-black-6">Nurse</p> : ""}
                  {practioner?.designation == "nursePractitioner" ? <p className="c-black-6">Nurse Practitioner</p> : ""}
                  {practioner?.designation == "physician" ? <p className="c-black-6">Physician</p> : ""}
                </Col>
                <Col>
                  <label htmlFor="">License No:</label>
                  <p className="c-black-6">{practioner?.qualification?.[0]?.identifier?.[0]?.value}</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <h6 className="leftBorderHeading">Patient Information</h6>
            <Row>
              <Col>
                <label htmlFor="">Name:</label>
                <p className="c-black-6">{patientData?.name?.[0].text}</p>
              </Col>
              <Col>
                <label htmlFor="">Address:</label>
                <p className="c-black-6">
                  {patientData?.address?.[0].line?.[0]}
                </p>
              </Col>
              <Col>
                <label htmlFor="">Contact:</label>
                <p className="c-black-6">
                  {
                    patientData?.telecom?.find((ele) => ele?.system === "phone")
                      ?.value
                  }
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <h6 className="text-light">..</h6>
            <Row>
              <Col>
                <label htmlFor="">Sex:</label>
                <p className="c-black-6">{patientData?.gender}</p>
              </Col>
              <Col>
                <label htmlFor="">DOB:</label>
                <p className="c-black-6">{patientData?.birthDate}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="table-container">
        <h5 className="table-heading">Testing Order List</h5>
        <div className="table-wrapper">
          {questionResponse.map((item, index) => (
            <div className="table-row" key={index}>
              <div className="table-cell highlight">
                <p>{item?.text}</p>
              </div>
              <QuestionField item={item} elementLenght={elementLenght} />
            </div>
          ))}
        </div>
      </div>
      {note ? <div className="note-container">
        <span>Note</span>
        <div className="note-wrapper">
          <p className="c-black-6">
            {note}
          </p>
        </div>
      </div> : null}
      <div className="sign-wrapper">
        <div className="sign-data">
          <div className="dataBox">
          {practioner?.signatureURL && practioner?.signatureURL?.url ? <img src={practioner?.signatureURL?.url} alt="sign" /> : null}
          </div>
          <div className="datalabel text-center">
            <p className="mb-0">Practitioner Signature</p>
            <p className="c-black-5 mb-0">{practioner?.name?.[0]?.text}</p>
            {practioner?.designation == "nurse" ? <p className="c-black-5 mb-0">(Nurse)</p> : ""}
            {practioner?.designation == "nursePractitioner" ? <p className="c-black-5 mb-0">(Nurse Practitioner)</p> : ""}
            {practioner?.designation == "physician" ? <p className="c-black-5 mb-0">(Physician)</p> : ""}
            <p className="c-black-5 mb-0">{practioner?.qualification?.[0]?.identifier?.[0]?.value}</p>
            <p className="c-black-5 mb-0">{practioner?.telecom?.find((ele) => ele?.system === "phone" && ele?.use === "work")?.value}</p>
          </div>
        </div>
        <div className="sign-data">
          <div className="dataBox">{date}</div>
          <div className="dataLabel">
            <p className="mb-0">Date</p>
          </div>
        </div>
      </div>
      <div className="btn-box">
        <Button onClick={() => navigate(-1)}>Done</Button>
      </div>
    </section>
  );
};

export default ViewServiceRequest;
