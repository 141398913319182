import moment from "moment";
import * as Yup from "yup";
import { convertVitalsBaseValueToOrgLevelUnit } from "../../Admin/Schedule/constants";

export const PatientsAssignedHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        nested: { key: "name", value: ({ index: 0, key: "text" }) },
        style: { textTransform: "capitalize" },
    },
    {
        id: 'hcn',
        label: 'HCN',
        type: 'function',
        isDecrypted: false,
        nested: {
            key: "identifier",
            value: (identifiers) => {
                const hcnIdentifier = identifiers?.find(identifier => identifier.type === "HCN");
                return hcnIdentifier ? hcnIdentifier.value : '-';
            }
        }
    },
    {
        id: 'birthDate',
        label: 'Date of Birth',
        type: 'date',
        isDecrypted: true,
        nested: { key: "birthDate" }
    },
    {
        id: 'age',
        label: 'Age',
        type: 'age',
        isDecrypted: true,
        nested: { key: "birthDate" }
    },
    {
        id: 'organization',
        label: 'Organization',
        type: 'text',
        style: { textAlign: "center" },
        nested: { key: "managingOrganization", value: { index: 0, key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];

export const PatientsAssignedDataFields = [
    'name',
    'hcn',
    'birthDate',
    'age',
    'organization',
    "actions"
];
export const dashboardPatientsAssignedHeadCells = PatientsAssignedHeadCells.filter((fields) => fields.id != 'organization' && fields.id != 'actions');

export const dashboardPatientsAssignedDataFields = PatientsAssignedDataFields.filter((fields) => fields != 'organization'&& fields != 'actions');

// date-slider
export const settings = {
    dots: false,
    arrow: true,
    speed: 500,
    infinite: false,
    slidesToShow: 14,
    slidesToScroll: 5,
    focusOnSelect: true,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 12,
                slidesToScroll: 8,
            },
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 7,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 6,
            },
        },
        {
            breakpoint: 950,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 5,
            },
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            },
        },
    ],
};

export const FormikObj = {
    initialValues: {
        start: moment(),
        end: moment().add(1, 'days'),
        name: "",
        dose: "",
        repeats: "",
        reason: "",
    },
    validationSchema: Yup.object({
        start: Yup.string()
            .required("Required start date field"),
        end: Yup.string()
            .required("Required end date field"),
        name: Yup.string()
            .required("Required medication name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        dose: Yup.string()
            .required("Required dose field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        repeats: Yup.string()
            .required("Required medication repeats field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        reason: Yup.string()
            .required("Required reason for use field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    }),
}
export const FormikDiseaseObj = {
    initialValues: {
        diseaseName: "",
        diseaseDesc: "",
    },
    validationSchema: Yup.object({
        diseaseName: Yup.string()
            .required("Required disease name")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        diseaseDesc: Yup.string()
            .required("Required description of disease")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    }),
}
export const initialChartData = {
    series: [
        {
            name: "",
            data: [],
        },
    ],
    options: {
        chart: {
            type: "line",
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: [],
        }
    }
}


// data tables constants
export const MedicationHeadTitle = ["Time", "Medicine Name", "Status", "Edit"]
export const DiseaseHeadTitle = ["Disease Name", "Disease Description", "Edit"]

export const EncounterHistoryTitle = ["Date/Time", "Practitioner", /* "Description", "Practitioner Note","Reason", */  /* "Attachments", */ "Appointment", "Report"]
export const serviceRequestTitle = ["Date/Time", "Practitioner",  "Encounter Id", "Status", "Action"]
export const medicationRequestTitle =  ["Date/Time", "Practitioner",  "Encounter Id", "Status", "Action"]
export const medicationRequestViewTitle = ["Medication Name", "Dosage", "Units", "Route", "Frequency", "Direction", "Duration", "Quantity", "Refills", "Reason", "Substitution", "Instruction/Notes"]
export const medicalHistoryCondition = ["Condition", "Diagnosis Date",  "Practitioner", "Recorded Date", "Status", "Notes(s)", "Action"]
export const medicalHistorySurgeries = ["Procedure", "Date", "Practitioner",  "Recorded Date", "Notes(s)", "Action"]
export const medicalHistoryFamilyHistory = ["Family History", "Age of Onset",  "Relationship", "Practitioner", "Date", "Notes(s)", "Action"]
export const medicalHistoryObservations = ["Observation", "Practitioner", "Date", "Notes(s)"]
export const viewNoteTilte = ["Date", "Practitioner", "Notes(s)"]
export const downloadBtnText = "Download";
export const noDataAvailText = "No Data Available";
export const appoinmentsTitle = ["Date/Time", "Practitioner",  "Type", "Status", "Encounter", "Action"]

export const practitionerNoteTitle = "Practitioner Note";

export const getOptiionsForChart = (data) => {
    return {
        chart: {
            type: "line",
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: data.map((data) => data?.effectiveDateTime),
        }
    }
}

export const filterOptions = [
    'Blood Pressure',
    'Blood Glucose',
    'Weight',
    'Temperature',
    'Oxygen',
    'Heart Rate'
];
export const intialVitalOptions = {
    'Blood Pressure': true,
    'Blood Glucose': true,
    'Weight': true,
    'Temperature': true,
    'Oxygen': true,
    'Heart Rate': true,
};

export const filterStatusOptions = [
    { name: "All", "value": "all" },
    { name: "Completed", "value": "Completed" },
    { name: "In Progress", "value": "In Progress" },
    { name: "Cancelled", "value": "Cancelled" }
]

export const selectedRequestType = [
    { name: "Lab", "id": "15220000" },
    { name: "Diagnostic", "id": "103693007" },
    { name: "Referral", "id": "306206005" },
]

export const customThresholdFormikObj = {
    initialValues: {
        condition: "blood_pressure",
        diastolicBelowM: "",
        diastolicAboveM: "",
        systolicBelowM: "",
        systolicAboveM: "",
        diastolicBelowH: "",
        diastolicAboveH: "",
        systolicBelowH: "",
        systolicAboveH: "",
        below: "",
        above: "",
        belowH: "",
        aboveH: "",
    },
    validationSchema: Yup.object({
        condition: Yup.string().required("Required group name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        diastolicBelowM: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        diastolicAboveM: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        systolicBelowM: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        systolicAboveM: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        diastolicBelowH: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        diastolicAboveH: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        systolicBelowH: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        systolicAboveH: Yup.string().when('condition', (type, schema) => type === "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        below: Yup.string().when('condition', (type, schema) => type !== "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        above: Yup.string().when('condition', (type, schema) => type !== "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowH: Yup.string().when('condition', (type, schema) => type !== "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveH: Yup.string().when('condition', (type, schema) => type !== "blood_pressure" ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
    })
}

export const conditionOptions = [
    { value: "blood_pressure", name: "Blood Pressure" },
    { value: "blood_glucose", name: "Blood Glucose" },
    { value: "weight", name: "Weight Scale" },
    { value: "oxygen", name: "Oxygen" },
    { value: "heart_rate", name: "Heart Rate" },
    { value: "temperature", name: "Temperature" },
]

const now = new Date();

export const patientsDetailsOptions = {
    initialValues: {
        startDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7,
        )
    }
}

export const conditionNameOptions = {
    initialValues: {
        conditionName: "blood_pressure"
    }
}

export const ConditionValueByOrgUnits = ({ condition, conditionData, orgVitalsUnit }) => {
    switch (condition) {
        case "blood_pressure":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic)).toFixed(1) % 1 === 0  
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic)).toFixed(1)  
                        || "-")
        case "weight":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight)).toFixed(1) % 1 === 0
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight)).toFixed(1) 
                        || "-")
        case "blood_glucose":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose)).toFixed(1) % 1 === 0
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose)).toFixed(1) 
                        || "-")
        case "oxygen":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen)).toFixed(1) % 1 === 0
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen)).toFixed(1) 
                        || "-")
        case "temperature":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature)).toFixed(1) % 1 === 0
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature)).toFixed(1) 
                        || "-")
        case "heart_rate":
            return (parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate)).toFixed(1) % 1 === 0
                        ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate))
                        : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate)).toFixed(1) 
                        || "-")
        default:
            return (<>{""}</>)
    }
}