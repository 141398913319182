import { ArrowDropDown, ArrowDropUp, } from "@mui/icons-material";
import { Skeleton, Tooltip, Link } from "@mui/material";
import Table from "react-bootstrap/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import moment from "moment";
import * as React from "react";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import ViewIcon from "../../../assets/images/view.png";
import TickIcon from "../../../assets/images/tick.png";
import CloseIcon from "../../../assets/images/close.png";
import { Spinner } from "react-bootstrap";
import { decryptData } from "../../EncryptDecrypt";
import CustomPagination from "../components/Pagination/CustomPagination";
import { useLocation } from "react-router-dom";
import { safeAgeString } from "../../../../src/utils/DateSupport";
import { useSelector } from "react-redux";
import { handlePatientClick } from "../../../utils/NavigationSupport";
import { useNavigate } from "react-router-dom";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function getNestedVal(data, nested, isDecrypted) {
    if (nested?.value) {
        if (Array.isArray(data)) {
            return getNestedVal(data?.[nested.index], nested?.value, isDecrypted);
        } else if (typeof data === "object") {
            return getNestedVal(data?.[nested?.key], nested?.value, isDecrypted);
        }
    } else {
        if (Array.isArray(data)) {
            if (typeof data?.[nested.index] === "string") return isDecrypted ? decryptData(data?.[nested.index]) : data?.[nested.index];
            return isDecrypted ? typeof (data?.[nested.index]?.[nested?.key]) === "string" ? decryptData(data?.[nested.index]?.[nested?.key] || "") : decryptData(data?.[nested.index]?.[nested?.key]?.[0]) : data?.[nested.index]?.[nested?.key];
        } else return isDecrypted ? decryptData(data?.[nested?.key]) : data?.[nested?.key];
    }
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const Icon = (order, headCellId) => {
        return (
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "Center",
                    alignItems: "Center",
                    marginLeft: "5px",
                }}
            >
                {order === "asc" && orderBy === headCellId ? <ArrowDropUp /> : <ArrowDropDown />}
            </span>
        );
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell?.id}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={
                            headCell?.sort
                                ? orderBy === headCell?.id
                                    ? order
                                    : false
                                : false
                        }
                        style={{
                            ...{ pointerEvents: headCell?.sort ? "auto" : "none" },
                            ...headCell?.style,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell?.id}
                            direction={
                                headCell?.sort
                                    ? orderBy === headCell?.id
                                        ? order
                                        : "asc"
                                    : order
                            }
                            onClick={headCell?.sort ? createSortHandler(headCell?.id) : null}
                            // IconComponent={headCell?.sort ? Icon : ""}
                            IconComponent={
                                headCell?.sort
                                    ? () => Icon(order, headCell?.id)
                                    : null
                            }
                        >
                            {headCell?.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CustomTable({
    tableData,
    headCells,
    dataFields,
    rowClick =false,
    selectedUserAction,
    loading,
    fetching,
    deletingItem = false,
    canView = false,
    canEdit = true,
    canDelete = true,
    canStatus = false,
    rowsPerPage = 10,
    hidePagination = false,
    page,
    setPage,
    isSortDisable = false,
    sortingHeadCellIndex = 0,
    sortingHeadOrder = 'asc'
}) {
    const [order, setOrder] = React.useState(sortingHeadOrder);
    const [orderBy, setOrderBy] = React.useState(headCells[sortingHeadCellIndex]?.id);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const totalRows = tableData?.length;
    const location = useLocation();
    const pathname = location?.pathname;
    const navigate = useNavigate();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const column = (columnData, index) => {
        // for hcn column
        if (headCells[index]?.id === "hcn" && headCells[index]?.type === "function") {
            return headCells[index].nested.value(columnData[headCells[index].nested.key]) || "-";
        }

        switch (headCells[index]?.type) {
            case "text":
                return getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted) || "-";
            case "length":
                return getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted).length || "-";
            case "date":
                return (
                    moment(getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted)).format(
                        "MMM-DD-YYYY"
                    ).toUpperCase() || "-"
                );
            case "dateTime":
                return (
                    moment(getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted)).format(
                        "MMM-DD-YYYY | HH:mm A"
                    ).toUpperCase() || "-"
                );
            case "dateTimeAmPm":
                const dateTimeAmPm = getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted);
                if (dateTimeAmPm) {
                    const dateObj = moment(dateTimeAmPm);
                    const formattedDateTime = dateObj.format("MMM-DD-YYYY | hh:mm A").toUpperCase(); // Include date and time
                    return formattedDateTime || "-";
                }
                return "-";
            case "boolean":
                return getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted)
                    ? "Yes"
                    : "No";
            case "active":
                return getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted)
                    ? "Active"
                    : "Inactive";
            case "age":
                return safeAgeString(getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted)?.replace(/-/g, '/'));
            default:
                return getNestedVal(columnData, headCells[index]?.nested, headCells[index]?.isDecrypted) || "-";
        }
    };

    const checkAccess = (rowData) => {
        if (pathname === "/app/manage-groups") {
            return !rowData.isDefault
        }
        if (pathname === "/app/manage-schedule-category") {
            return userRoles?.includes('Super-Admin') ? true : !rowData.isRootLevel
        }
        return true
    }

    const sortAndSliceTableData = () => {
        return isSortDisable
            ? tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tableData
                ?.sort(getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);            
    }

    return (
        <>
            <div className="table-wrap">
                <Table responsive>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={totalRows}
                        headCells={headCells}
                    />
                    <tbody>
                        {loading || fetching
                            ? [1, 2, 3, 4, 5, 6].map((val) => (
                                <tr key={val}>
                                    {dataFields?.map((index) =>
                                    (<td key={index}>
                                        <Skeleton animation="wave" />
                                    </td>)
                                    )}
                                </tr>
                            ))
                            :
                            tableData?.length > 0
                                ? sortAndSliceTableData()
                                    ?.map((row) => {
                                        const hasAccess = checkAccess(row)
                                        return (
                                            <tr onClick={() => rowClick && handlePatientClick(row, navigate)} key={row?.id}>
                                                {dataFields.map((fieldName, index) => {
                                                    return (
                                                        <td
                                                            className={index === 0 ? "name-text" : ""}
                                                            key={fieldName}
                                                            style={headCells?.[index]?.style ?? {}}
                                                        >
                                                            {(fieldName !== "actions") && (fieldName !== "status") ? (
                                                                column(row, index)
                                                            ) : (
                                                                <div className="action-wrap">
                                                                    {canView ? (
                                                                        <Tooltip title="View">
                                                                            <div
                                                                                onClick={() =>
                                                                                    selectedUserAction({
                                                                                        action: "view",
                                                                                        data: row,
                                                                                    })
                                                                                }
                                                                                className="view-btn"
                                                                            >
                                                                                <img src={ViewIcon} alt="View" />
                                                                            </div>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                    {canEdit && (row?.name !== "Default") ? (
                                                                        <Tooltip title={hasAccess ? "Edit" : "You dont have permission"}>
                                                                            <Link
                                                                                to=""
                                                                                onClick={() => hasAccess &&
                                                                                    selectedUserAction({
                                                                                        action: "edit",
                                                                                        data: row,
                                                                                    })
                                                                                }
                                                                                className="success-btn"
                                                                                style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                                            >
                                                                                <img src={EditIcon} alt="Edit" />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                    {canDelete && (row?.name !== "Default") ? (
                                                                        <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                                            <Link
                                                                                className="delete-btn"
                                                                                onClick={() => hasAccess &&
                                                                                    (deletingItem
                                                                                        ? null
                                                                                        : selectedUserAction({
                                                                                            action: "delete",
                                                                                            data: row
                                                                                        }))
                                                                                }
                                                                                style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                                            >
                                                                                {deletingItem === row.id ? (
                                                                                    <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                                                ) : (
                                                                                    <img src={DeleteIcon} alt="Delete" />
                                                                                )}
                                                                            </Link>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                    {canStatus ? (
                                                                        (() => {
                                                                            if (column(row, index) === 'Success') {
                                                                                return (
                                                                                    <Tooltip title="Success"> 
                                                                                        <div className="success-btn">
                                                                                            <img src={TickIcon} alt="Success" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <Tooltip title="Failed"> 
                                                                                        <div className="delete-btn">
                                                                                            <img src={CloseIcon} alt="Failed" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                );
                                                                            }

                                                                            
                                                                        })()
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })
                                : <tr>
                                    <TableCell colSpan={dataFields.length} style={{ textAlign: "center" }}>No Data Available</TableCell>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
            {hidePagination ? null
                : <CustomPagination tableData={tableData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} />}
        </>
    );
}
