import React, { useEffect } from 'react';
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { Button } from '../../common/Button';

/**
 * Conflicts
 * This component generates a modal dialog listing any scheduling conflicts.
 * It re-renders if the conflict argument updates
 * 
 * @param {boolean} modalShow indicates where to hide or show this model
 * @param {array of type object} array of objects of all conflicts to display 
 * @param {function} function to call on close of dialog.
 * @returns 
 */
const Conflicts = ({ modalShow, conflicts, handleHide, partialSuccess }) => {
    let last_type = "";
    useEffect(() => {},[conflicts]);

    return (
        <Modal
            dialogClassName='med'
            show={modalShow}
            onHide={handleHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={"static"}
            centered
            className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Scheduling Conflicts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive style={{width: '80%'}}>                    
                { 
                    conflicts.map(conflict => {
                            /* 
                                check of the category type has changed, if it has add some 
                                CSS padding to visually separate them
                            */
                            let insert_break = false; 
                            if(conflict.type != last_type)  {
                                if(last_type != "") 
                                    insert_break = true;
                                last_type = conflict.type;
                            }  
                            return (<Row style={(insert_break) ? { paddingTop: 15 } : { paddingBottom: 0}}><Col style={{whiteSpace: 'nowrap', color: 'red'}}>{conflict.type} on {conflict.time}</Col></Row>)                       
                            
                    })
                }
                {partialSuccess && 
                    <Row style={{paddingTop:15}}><Col style={{whiteSpace: 'nowrap'}}>All other proposed unavailability successfully created</Col></Row>
                }
                </Table>
            <div className="btn-wrap" style={{ display: "flex", gap: "1rem" }}>
                <Button onClick={handleHide}>Ok</Button>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default Conflicts;