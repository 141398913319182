import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { surveyForOptionsOrganization, SurveyFormikAssign } from "./Constants";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import { assignedQuestionnaireToOrganization } from "../../api/Questionnaire";
import { getOrganization } from "../../api/Organization";
import { useSurveyQueryData } from "../../../hooks/ReactQueryHooks/useSurveyQueryData";
import { Skeleton, TableCell, Tooltip, Switch } from "@mui/material";
import { debounce } from 'lodash';

const AssignSurvey = ({ modalData, handleHide, refreshTable }) => {
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [getCareAptId, setGetCareAptId] = useState();
  const [getCareWaitingId, setGetCareWaitingId] = useState();
  const [questionnaireItem, setQuestionnaireItem] = useState();
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const [questionnaireApt, setQuestionnaireApt] = useState([{ name: "", value: "" }]);
  const [questionnaireWroom, setQuestionnaireWroom] = useState([{ name: "", value: "" }]);
  const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const admin = userType?.includes('Super-Admin');
  const [orgID, setOrgID] = useState(admin ? "All" : adminOrgs?.[0]?.id);
  const debouncedGetOrganization = debounce((data, orgId) => {
    getOrganization(orgId)
      .then((res) => {
        const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
        if (assignedQuestionnaire["survey-appointment"]) {
          const foundAptItem = assignedQuestionnaire["survey-appointment"]
            ? data?.find((item) => item?.id === assignedQuestionnaire["survey-appointment"])
            : '';
          foundAptItem ? setGetCareAptId(foundAptItem?.title) : setGetCareAptId('');
          if (foundAptItem) {
            formik.setFieldValue("questionnaireAptField", foundAptItem?.id || "");
          }
        }

        if (assignedQuestionnaire["survey-waitingroom"]) {
          const foundWaitingItem = assignedQuestionnaire["survey-waitingroom"]
            ? data?.find((item) => item?.id === assignedQuestionnaire["survey-waitingroom"])
            : '';
          foundWaitingItem ? setGetCareWaitingId(foundWaitingItem?.title) : setGetCareWaitingId('');
          if (foundWaitingItem) {
            formik.setFieldValue("questionnaireWroomField", foundWaitingItem?.id || "");
          }
        }
      })
      .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res?.message));
  }, 500);
  const onSuccess = (data) => {
    debouncedGetOrganization(data, adminOrgs?.[0]?.id);
  };
  const debouncedSuccess = debounce(onSuccess, 500);
  const { isLoading, refetch, isFetching, data: programList } = useSurveyQueryData({ orgID, onSuccess: debouncedSuccess });
  const formik = useFormik(
    {
      ...SurveyFormikAssign,
      onSubmit: (values) => {
        let payload = {};
        payload = {
          "assignedQuestionnaire": {
            "survey-appointment": values.questionnaireAptField,
            "survey-waitingroom": values.questionnaireWroomField,
          }
        }
        setBtnLoading(true);
        assignedQuestionnaireToOrganization(adminOrgs?.[0]?.id, payload)
          .then((res) => {
            getOrganization(adminOrgs?.[0]?.id)
              .then((res) => {
                const foundAptItem = programList.find((item) => item?.id === res?.data?.assignedQuestionnaire["survey-appointment"]);
                foundAptItem ? setGetCareAptId(foundAptItem.title) : setGetCareAptId('');

                const foundWaitingItem = programList.find((item) => item?.id === res?.data?.assignedQuestionnaire["survey-waitingroom"]);
                foundWaitingItem ? setGetCareWaitingId(foundWaitingItem?.title) : setGetCareWaitingId('');

              })
           // refreshTable();
            success("Survey Assigned Successfully.");
            handleHide();
            refreshTable();

          })
          .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
          .finally(() => setBtnLoading(false));
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (programList) {
          let filteredQuestionnaireItem = [];
          filteredQuestionnaireItem = programList
            .filter((item) => (item?.purpose === formik?.values?.purpose && item?.status === 'active'))
            .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
          filteredQuestionnaireItem.unshift({ name: "Select Survey", value: "" })
          setQuestionnaireItem(filteredQuestionnaireItem);
          formik.setFieldValue("questionnaire", "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [formik.values?.purpose]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (programList) {
          const foundObjectSwating = programList.some(item => item.purpose === "survey-waitingroom");
          const foundObjectSApt = programList.some(item => item.purpose === "survey-appointment");


          if (foundObjectSApt === true) {
            const filteredQuestionnaireApt = programList
              .filter((item) => (item?.purpose === 'survey-appointment' && item?.status === 'active'))
              .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
            if (filteredQuestionnaireApt.length > 0) {
              setQuestionnaireApt(filteredQuestionnaireApt);
            }
          }
          if (foundObjectSwating === true) {
            const filteredQuestionnaireWroom = programList
              .filter((item) => (item?.purpose === 'survey-waitingroom' && item?.status === 'active'))
              .map((dataItem) => ({ name: dataItem?.title, value: dataItem?.id }));
            if (filteredQuestionnaireWroom.length > 0) {
              setQuestionnaireWroom(filteredQuestionnaireWroom);
            }
          }
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [programList]);


  const handlePurposeChange = (fieldName, e) => {
    formik.setFieldValue(fieldName, e.target.value);
  }
  return (
    <Modal
      show={modalData}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
      backdropClassName
      backdrop={"static"}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Assign Survey Questionnaire

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {getCareWaitingId || getCareAptId ? <>
          <h6>
            Assigned Survey
          </h6>
          <div className="table-wrap">
            <Table responsive>
              {getCareAptId ?
                <thead>
                  <tr>
                    <th><b>Get Care - Appointment : </b></th>
                    {isLoading || isFetching ?
                      (
                        [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                          <th key={val} > <Skeleton animation="wave" /></th>)))
                      :
                      <th> {getCareAptId} </th>}
                  </tr>
                </thead> : null}
              {getCareWaitingId ?
                <thead>
                  <tr>
                    <th><b>Get Care - Waiting Room :  </b></th>
                    {isLoading || isFetching ?
                      (
                        [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                          <th key={val} > <Skeleton animation="wave" /></th>)))
                      :
                      <th> {getCareWaitingId} </th>}
                  </tr>
                </thead> : null}
            </Table>
          </div>
          <hr />
        </> : null} */}
        {/* <h6>
          Assign Survey
        </h6> */}

        <Row style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>

          <Col>
            {/* <h6>Questionnaire</h6> */}
            <form
              className="common-form border-fields"
              onSubmit={formik.handleSubmit}
            >



              <SelectFiled
                keyField={"questionnaireAptField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireApt}
                onChange={(e) => handlePurposeChange('questionnaireAptField', e)}
              />


              <div
                className="btn-wrap"
                style={{ display: "flex", marginTop: "2rem" }}
              >
                <Button variant="secondary" title="Cancel" onClick={handleHide}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                // disabled={isLoading || isFetching}
                >
                  Save
                </Button>
              </div>
            </form>
          </Col>


        </Row>

        {/* <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row style={{ width:'100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
              Get Care - Appointment
            </Col>
            <Col>
              <SelectFiled
                keyField={"questionnaireAptField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireApt}
                onChange={(e) => handlePurposeChange('questionnaireAptField', e)}
              />
            </Col>
          </Row>
          <Row style={{ width:'100%', marginBottom: '10px', padding: '5px 10px' }}>
            <Col>
              Get Care - Waiting Room
            </Col>
            <Col>
              <SelectFiled
                keyField={"questionnaireWroomField"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireWroom}
                onChange={(e) => handlePurposeChange('questionnaireWroomField', e)}
              />
            </Col>
          </Row>
          <div
            className="btn-wrap"
            style={{ display: "flex", marginTop: "2rem" }}
          >
            <Button variant="secondary" title="Cancel" onClick={handleHide}>
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={btnLoading}
            // disabled={isLoading || isFetching}
            >
              Save
            </Button>
          </div>
        </form> */}
      </Modal.Body>
    </Modal>
  );
}

export default AssignSurvey;
