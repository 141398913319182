import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { appoinmentsTitle, downloadBtnText, noDataAvailText } from "../Constants";
import moment from "moment";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { encryptData } from "../../../EncryptDecrypt";
import { useAppointmentsbyPatientIdQuery } from "../../../../hooks/ReactQueryHooks/useAppointmentsRequestsQuery";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ViewIcon from "../../../../assets/images/view.png";
import { ViewAppointmentModal } from "../../AppointmentRequests/ViewAppointmentModal";
import { failed, success } from "../../../common/Toastify";
import { getPatientData } from "../../../api/Individual";
import  RescheduleAppointment  from '../../ManageProviderSchedule&Appointments/Reschedule';
import { getSignature } from "../../../Zoom/functions";
import { createEncounter, updateAppointmentRequest } from "../../api/AppointmentRequests";
import { generatePass } from "../../AppointmentRequests/Constants";
import { axios } from "../../../../lib/axios";
import { ViewDateTime } from "../../../../utils/DateSupport";
import { buildSessionTopic } from "../../../../utils/ZoomSessionSupport";
import { FullPageSpinner } from "../../../common/Spinner/FullPageSpinner";
import { Buffer } from "buffer";

export function AppointmentList({ patientId, practionerData, orgnization }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [showAppointment, setShowAppointment] = useState(false); 
    const [scheduleModal, setScheduleModal] = useState(false);
    const user = useSelector((state) => state?.auth?.user);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const onSuccess = (data) => {       
        setAppointmentsData(data); 
    };
    const { isLoading, isFetching, refetch } = useAppointmentsbyPatientIdQuery({ onSuccess, patientId: patientId , refetchOnWindowFocus: false});
    
    const handleConnect = async (data) => {
        const utcString = new Date().toISOString().split(".")[0] + ".00Z";
        let intakeQuestionsOld = data?.intakeQuestions;
        let intakeAssignedPractitioner = {
            individual: {
                display: `${user?.name[0]?.text}`,
                reference: `Practitioner/${user?.["custom:practitioner_id"]}`,
                type: "Practitioner"
            },
            period: {
                start: utcString,
                end: null
            }
        }
        let updatedIntakeQuestions = {
            ...intakeQuestionsOld,
            intakeAssignedPractitioner: intakeAssignedPractitioner
        }
        if(data?.sessionToken){
            setLoading(true)
            const base64Url = await data?.sessionToken?.split(".")[1],
            base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
            buff = await Buffer.from(base64, "base64"),
            payloadinit = await buff.toString("ascii"),
            parseddata = JSON.parse(payloadinit || "{}"),
            patient = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor,
            practitioner = data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
            let encounterId = data?.encounterId ? data?.encounterId : null;
            let actor = {
                display: practitioner.display,
                reference: practitioner.reference
            }
            
            if(!data?.encounterId){
                await createEncounter({ patient, practitioner, appointmentId: data?.id, appointmentTime: data?.requestedPeriod?.[0]?.start, orgId: user?.organizations?.[0]?.id  })
                    .then((res) => {
                        encounterId = res?.data?.id
                        updateAppointmentRequest({ isHostJoined: true, sessionToken: data?.sessionToken, encounterId: res?.data?.id, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                            .then((res) => res)
                            .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                            .finally(() => setLoading(false));
                    })
                    .catch((error) => console.log("encounter error", { error }))
                    .finally(() => setLoading(false))  
            }else{
                updateAppointmentRequest({ isHostJoined: true, sessionToken: data?.sessionToken, encounterId: data?.encounterId, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                    .then(async(res) => {
                        const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                        await axios.put(`encounter/${res?.result?.encounterId}`, { status: "in-progress-appointment", requestedPeriod: requestedPeriod, actor: actor, active: true, class: "NULL", intakeQuestions: updatedIntakeQuestions }) 
                    })
                    .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                    .finally(() => setLoading(false));
                }  
                let topic=parseddata?.tpc.trim();
                await getSignature({ topic: topic, role: 1, password: parseddata?.password })
                
                    .then(async (res) => {
                        console.log("Session token is this: ", res.data.sessionToken)
                        updateAppointmentRequest({ isHostJoined: true, sessionToken: res?.data?.sessionToken, encounterId: data?.encounterId, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                            .then(async(response) => {
                                await navigate("/app/zoom-meeting-create", { state: { encounterId, appointmentData: data, topic: encryptData(parseddata?.tpc), sessionToken: res?.data?.sessionToken, password: encryptData(parseddata?.password), patientName: patient?.display, questionnairInfo: data?.intakeQuestions } });
                                await success("connected successfully");
                            })
                    }).catch((error) => console.log("getsignature error", { error }))
                    .finally(() => setLoading(false))
        }else{ failed("Session token does not exist!") }
    }

    const handleCall = async(data) =>{
        const patient = await data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor
        const practitioner = await data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
        const encounterId = data?.encounterId ? data?.encounterId : null;
        const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
        
        let actor = {
            display: practitioner.display,
            reference: practitioner.reference
        }
        const utcString = new Date().toISOString().split(".")[0] + ".00Z";
        let intakeQuestionsOld = data?.intakeQuestions;
        let intakeAssignedPractitioner = {
            individual: {
                display: `${user?.name[0]?.text}`,
                reference: `Practitioner/${user?.["custom:practitioner_id"]}`,
                type: "Practitioner"
            },
            period: {
                start: utcString,
                end: null
            }
        }
        let updatedIntakeQuestions = {
            ...intakeQuestionsOld,
            intakeAssignedPractitioner: intakeAssignedPractitioner
        }
        setLoading(true)
        axios.put(`encounter/${encounterId}`, { status: "in-progress-appointment", requestedPeriod: requestedPeriod, actor: actor, class: "NULL", intakeQuestions: updatedIntakeQuestions }).then((res)=>{
            if(res.status === true){
                // navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.questionnaireResponse } });
                navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.intakeQuestions } });
                setLoading(false)
            }
        }).catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
        .finally(() => setLoading(false)); 
    }

    const handleUpdateRequest = async (data, status) => {
        setLoading(true)
        const patient = await data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor
        const practitioner = await data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
            
        const patientId = patient.reference?.split("/")?.[1];
        const practitionerId = practitioner.reference?.split("/")?.[1];
        let actor = {
            display: practitioner.display,
            reference: practitioner.reference
        }
        let sessionToken;
        if (status === "booked") {                  
            let topic = buildSessionTopic(user?.name[0]?.text);
            let password= generatePass();
            await getSignature({ topic: topic, role: 0,  password:password})
                .then(async (res) => {
                    sessionToken = res.data.sessionToken;
                }).catch((error) => console.log("getsignature error", { error }))
        }
        await updateAppointmentRequest({ id: data?.id, status, patientId, practitionerId, sessionToken, actor })
            .then((res) => { success(status === "booked" ? "Appointment booked!" : "Appointment cancelled!"); refetch() })
            .catch((err) =>{ failed(err?.response?.data?.message || err?.response?.data?.error || err?.message)})
            .finally(() => setLoading(false));
        setShowAppointment(false);
    }; 
    
    return (
        <>
        {loading ? <FullPageSpinner /> : null}
        {showAppointment ? <ViewAppointmentModal modalShow={showAppointment} setScheduleModal = {setScheduleModal} handleShow={() => setShowAppointment(false)} handleConnect={handleConnect} handleCall={handleCall} handleUpdateRequest={handleUpdateRequest} appointmentsList={appointmentsData} /> : null}
        {scheduleModal ? <RescheduleAppointment modalShow={scheduleModal} handleShow={() => setScheduleModal(false)}  /> : null}    
        <Tab.Pane eventKey="appoinments"> 
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            {appoinmentsTitle?.map(title => <th key={title} style={{
                                textAlign: title === "Edit" ? "center" : "auto"
                            }}>{title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching
                            ? [1, 2, 3, 4, 5, 6].map(val => (
                                <tr key={val}>
                                    {appoinmentsTitle?.map(index => <td key={index}>
                                        <Skeleton animation="wave" />
                                    </td>)}
                                </tr>
                            ))
                            : appointmentsData?.length > 0
                                ? appointmentsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(({ id, practitionerId, requestedPeriod, participant, serviceCategory, status, encounterId}) => {
                                    
                                    const practitionerDisplay = participant
                                        .find(participant => participant.actor.reference.includes(practitionerId))
                                        ?.actor.display.split(" ")
                                        .map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
                                        .join(' ') || "-";
                                    const baseUrl = window.location.origin;                             
                                    return (
                                        <tr key={id}>
                                            <td>{ViewDateTime(requestedPeriod[0]?.start)}</td>
                                            <td>{practitionerDisplay}</td>
                                            <td>{serviceCategory && serviceCategory[0]?.coding[0]?.display || "-"}</td>
                                            <td>{status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() || "-"}</td>
                                            <td>
                                                {status === 'completed' ? (
                                                    <div className="action-wrap" style={{justifyContent: "start"}}>
                                                        <Tooltip title="View">
                                                            <Link
                                                                to="/app/encounter-details"
                                                                state={{ encounterId: encounterId}}
                                                                className="view-btn"
                                                            >
                                                            <img src={ViewIcon} alt="View" />
                                                            </Link>
                                                        </Tooltip> 
                                                    </div>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </td>
                                            <td>
                                                <div className="action-wrap" style={{justifyContent: "start"}}>
                                                <button type="button"  onClick={() => setShowAppointment(id)}  class="btn btn-success"> View Details</button>
                                                </div>
                                            </td> 
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <TableCell colSpan={appoinmentsTitle.length} style={{
                                        textAlign: "center"
                                    }}>
                                        {noDataAvailText}
                                    </TableCell>
                                </tr>}
                    </tbody>
                </Table>
                {
                    appointmentsData?.length > 0 ? <div>
                        <CustomPagination tableData={appointmentsData} totalRows={appointmentsData?.length} rowsPerPage={rowsPerPage} page={page} handlePage={pageNo => setPage(pageNo)} />
                    </div> : null
                }
            </div>
        </Tab.Pane>
        </>
    );
}