import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPatientById } from "../../../hooks/ReactQueryHooks/useGetPatientById";
import { useSelector } from "react-redux";
import {
  getEncounterQuestionnaireResponses,
  getEncounterRecord,
  getPractitionerDataForQueue,
  getQueueRecord,
} from "../api/AppointmentRequests";
import { failed } from "../../common/Toastify";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "../../common/Button";
import { Col, Row } from "react-bootstrap";
import { ViewDate, ViewTime, ViewDateTime, } from "../../../utils/DateSupport";
import moment from "moment";
import { getPractitionerForReport, getSingleUser } from "../../api/Individual";
import ServiceLocationModal from "../ServiceRequest/ServiceLocationModal";
import html2pdf from "html2pdf.js";
import { getOrganization } from "../../api/Organization";

const EncounterReportWR = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [patientId, setPatientId] = useState(null);
  const encounterId = urlParams.get('encounterId');
  const from = urlParams.get('from');
 
  const navigate = useNavigate();
  const { state } = useLocation();
  const practitionerCognitoId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const userOrganizationId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );
  const [encounterData, setEncounteredData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [practitionerData, setPractionerData] = useState(null);
  const [questionareData, setQuestionnaireData] = useState(null);
  const user = useSelector((state) => state?.auth?.user);
  const [queueRecordData, setQueueRecordData] = useState(null);
  const { organizations, organizationLogo } = useSelector(
    (state) => state?.auth?.user
  );
  const [base64OrgImg, setBase64OrgImg] = useState("");
  const [base64SignImg, setBase64SignImg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [base64Url, setBase64Url] = useState("");
  const [organization, setOrganizations] = useState({});
  const [encounterPractitionerName, setEncounterPractitionerName] =
    useState(null);
  const [encounterPractitionerID, setEncounterPractitionerID] = useState(null);
  const [encounterPractitionerSign, setEncounterPractitionerSign] =
    useState("");
  const [encounterPractitionerData, setEncounterPractitionerData] =
    useState(null);
  const [encounterCompleteTime, setEncounterCompleteTime] = useState("");
  const [patientIdForDocument, setPatientIdForDocument] = useState("");

  const findPatientId = (data) => {
    const patientId = data?.link.filter((data) => data?.target?.type === "Patient")?.[0]?.target?.reference.split("/")[1];
    setPatientIdForDocument(patientId);
  }

  useEffect(() => {
    getEncounterRecord({ encounterId: encounterId })
      .then((res) => {
        if (res?.status === true) {
          setEncounteredData(res?.data);
          setBase64OrgImg(organizationLogo);
          let patientId = res?.data?.subject?.reference ? res?.data?.subject?.reference?.split("/")[1] : ''
          if(patientId){
            setPatientId(patientId)
            getSingleUser({ id: patientId, type: "Patient" })
            .then((res) => {
              if (res?.status === true) {
                setPatientData(res?.data);
                findPatientId(res?.data)
              }
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
           });
          }
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      );
  }, []);

  useEffect(() => {
    getQueueRecord({ encounterId: encounterId, userOrganizationId: userOrganizationId })
      .then((res) => {
        if (res?.status === true) {
          setQueueRecordData(res?.data);
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      );
  }, []);

  // useEffect(() => {
  //   getEncounterQuestionnaireResponses({ encounterId: encounterId })
  //     .then((res) => {
  //       if (res?.status === true && res?.data) {
  //         setQuestionnaireData(res?.data);
  //         const updatedData = await updatePublisher(data);
  //         setEncounterQuestionairData(updatedData);
  //       }
  //     })
  //     .catch((res) =>
  //       failed(
  //         res?.response?.data?.message ||
  //           res?.response?.data?.error ||
  //           res.message
  //       )
  //     );
  // }, []);

  useEffect(() => {
    const fetchAndUpdateData = async () => {
      try {
        const res = await getEncounterQuestionnaireResponses({ encounterId: encounterId });
        if (res?.status === true && res?.data) {
          setQuestionnaireData(res?.data);
          const updatedData = await updatePublisher(res?.data);
          setQuestionnaireData(updatedData);
        }
      } catch (error) {
        failed(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          error.message
        );
      }
    };
  
    fetchAndUpdateData();
  }, []);
  

  useEffect(() => {
    getSingleUser({ id: practitionerCognitoId, type: "Practitioner" })
      .then((res) => {
        if (res?.status === true && res?.data) {
          setPractionerData(res?.data);
          setBase64SignImg(res?.data?.signatureURL?.base64Data);
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      );
  }, []);

  useEffect(() => {
    getOrganization(user?.organizations?.[0]?.id)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    if (queueRecordData) {
      let found = false;
      for (const entry of queueRecordData?.statusHistory) {
        if (entry?.status === "parked") {
          setEncounterPractitionerName(entry?.actor?.display);
          setEncounterPractitionerID(entry?.actor?.reference?.split("/")?.[1]);
          found = true;
          break;
        }
      }

      if (!found) {
        for (const entry of queueRecordData?.statusHistory) {
          if (entry?.status === "finished") {
            setEncounterPractitionerName(entry?.actor?.display);
            setEncounterPractitionerID(
              entry?.actor?.reference?.split("/")?.[1]
            );
            found = true;
            break;
          }
        }
      }
    }
  }, [queueRecordData]);

  async function updatePublisher(data) {
    return Promise.all(data.map(async (entry) => {
      if (entry?.publisher) {
        try {
          const result = await getSingleUser({ 
            id: entry?.publisher, 
            type: "Practitioner", 
            userOrgId: user?.organizations?.[0]?.id 
          });
          const newPublisher = capitalizeWords(result?.data?.name?.[0]?.text);
  
          return { ...entry, publisherName: newPublisher };
        } catch (error) {
          failed(error?.response?.data?.message || error?.response?.data?.error || error.message);
        }
      }
  
      return entry;
    }));
  }

  const handleSendDocument = () => {
    const element = document.getElementById("encounter-detail");

    html2pdf()
      .from(element)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: `Encounter/${encounterId}`,
        image: { type: "jpeg", quality: 2 },
        html2canvas: { scale: 2, useCORS: true, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: {
          before: [".service-info-block", ".questions-wrapper"],
          avoid: [".header", ".common-listing"],
        },
      })
      .toPdf()
      .output("datauristring")
      .then((pdfDataUri) => {
        const base64String = pdfDataUri.split(",")[1];
        setBase64Url(base64String);
        setOpenModal(encounterData);
      });
  };

  const capitalizeWords = (str) => {
    if(str){
      return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
    }else{
      return ''
    }
    
  };

  useEffect(() => {
    if (encounterPractitionerID) {
      getPractitionerDataForQueue({
        practitionerId: encounterPractitionerID,
      }).then((res) => {
        setEncounterPractitionerData(res?.result);
        setEncounterPractitionerSign(res?.result?.signatureURL?.base64Data);
      });
    }
  }, [encounterPractitionerID]);


  useEffect(() => {
    if (encounterData) {
      const completeTime = encounterData?.statusHistory?.filter(
        (val) => val.status === "finished"
      );

      if (completeTime) {
        setEncounterCompleteTime(completeTime[0]?.period?.end);
      } else {
        setEncounterCompleteTime("-");
      }
    }
  }, [encounterData]);

  return (
    <>
      {openModal ? (
        <ServiceLocationModal
          onHide={() => setOpenModal(false)}
          modalShow={openModal}
          paramsObj={{
            patientIdForDocument,
            orgId: organizations?.[0]?.id,
            base64: base64Url,
          }}
          practitionerData={user}
          requestType={"Encounter Report"}
          categoryVar={"Encounter Report"}
          patientData={patientData}
          orgName={organizations?.[0]?.name}
          fromServiceRequest={false}
          fromMedicationRequest={false}
          fromEncounter={true}
          encounterId={encounterId}
          documentName={`Encounter/${encounterId}`}
        />
      ) : null}
      <section className="common-listing">
        <div className="heading-wrap mb-3 d-flex align-items-center justify-content-between">
          <div className="page-title-block d-flex align-items-center">
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/app/patients-details", {
                  state: {
                    id: patientId,
                    name: patientData?.name?.[0].text,
                    email: patientData?.telecom.find(
                      (element) => element.system == "email"
                    ).value,
                  },
                })
              }
            ></span>
          </div>
          {from !== "draft" && (
            <Button
              onClick={() =>
                organization?.sft?.enabled &&
                organization?.sft?.locationDelivery
                  ? handleSendDocument()
                  : organization?.sft?.enabled &&
                    !organization?.sft?.locationDelivery
                  ? failed(
                      "Please enable delivery to location to send document"
                    )
                  : failed("Please enable SFT to send document.")
              }
            >
              Send Document
            </Button>
          )}
        </div>

        <section id="encounter-detail">
          <div className="header">
            <Row className="pt-4">
              <Col sm={2} className="ps-4">
                <div className="circular-container">
                  <img src={base64OrgImg} className="circular-image" />
                </div>
                <h6
                  className="user-name-title fw-bold"
                  style={{ textAlign: "center" }}
                >
                  {organizations?.[0]?.name}
                </h6>
              </Col>
              <Col lg={6} className="border-left ps-3 pe-5">
                <Row>
                  <Col sm={5}>
                    <h6>Encounter Date</h6>{" "}
                    {ViewDate(encounterData?.period?.end) || "-"}{" "}
                  </Col>
                  <Col sm={4}>
                    <h6>Completion Time</h6>{" "}
                    {(encounterCompleteTime &&
                      moment(encounterCompleteTime).format("HH:mm")) ||
                      "-"}{" "}
                  </Col>
                  <Col sm={3}>
                    <h6>Encounter Id</h6> {encounterId || "-"}{" "}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm={5} style={{ textTransform: "capitalize" }}>
                    <h6>Provider Name</h6> {encounterPractitionerName}{" "}
                  </Col>
                  <Col sm={7}>
                    <h6>Provider Designation</h6>{" "}
                    {practitionerData?.designation === "nursePractitioner"
                      ? "Nurse Practitioner"
                      : practitionerData?.designation
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ") || "-"}{" "}
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="border-left ps-5">
                <h6>CONFIDENTIAL: </h6>
                <p className="label-grey">
                  This information may have been disclosed to you from records
                  whose confidentiality is protected by Federal law, which
                  prohibits you from further disclosing it without the written
                  consent of the person to whom it pertains or as otherwise
                  permitted by Federal Regulations. If you receive this in
                  error, please notify our office immediately at 1-877-870-8068.
                </p>
              </Col>
            </Row>
          </div>{" "}
          <br />
          {/* Patient Details */}
          <b>Patient Details</b> <br />
          <br />
          <Row className="">
            <Col sm={2}>
              <h6 className="label-grey">Name</h6>{" "}
              {patientData?.name?.[0].text
                ?.split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ") || "-"}
            </Col>
            <Col sm={2}>
              <h6 className="label-grey">DOB-(Age)</h6>
              {patientData?.birthDate ? (
                <>
                  {ViewDate(patientData?.birthDate)} -{" "}
                  {"(" +
                    moment().diff(
                      moment(patientData?.birthDate, "MM-DD-YYYY"),
                      "years"
                    ) +
                    ")"}
                </>
              ) : (
                "-"
              )}
            </Col>
            <Col sm={2}>
              <h6 className="label-grey">Gender</h6>
              {patientData?.gender
                ?.split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ") || "-"}
            </Col>
            <Col sm={2}>
              <h6 className="label-grey">HCN</h6>
              {patientData?.identifier?.find((ele) => ele?.type === "HCN")
                ?.value || "-"}
            </Col>
            <Col sm={2}>
              <h6 className="label-grey">Phone</h6>{" "}
              {patientData?.telecom?.find((ele) => ele?.system === "phone")
                ?.value || "-"}
            </Col>
            <Col sm={2}>
              <h6 className="label-grey">Address</h6>
              {patientData?.address?.[0].line?.[0] || "-"}
            </Col>
          </Row>
          <hr />
          {questionareData?.length > 0 ? (
            <h6>
              <b>Questionnaire</b>
            </h6>
          ) : null}
          {encounterData?.intakeQuestions && (
            <Row className="align-items-center mt-3">
              {encounterData?.intakeQuestions?.callerName &&
              encounterData?.intakeQuestions?.callerRelationship !== "Self" ? (
                <Col sm={2}>
                  <h6 className="label-grey">Caller Name</h6>
                  {encounterData?.intakeQuestions?.callerName}
                </Col>
              ) : null}
              {encounterData?.intakeQuestions?.callerRelationship &&
              encounterData?.intakeQuestions?.callerRelationship !== "Self" ? (
                <Col sm={3}>
                  <h6 className="label-grey">Relationship to Patient</h6>
                  {encounterData?.intakeQuestions?.callerRelationship}
                </Col>
              ) : null}
              {encounterData?.intakeQuestions?.callerNumber && (
                <Col sm={3}>
                  <h6 className="label-grey">Return Telephone Number</h6>
                  {encounterData?.intakeQuestions?.callerNumber}{" "}
                  {encounterData?.intakeQuestions?.callerNumberExt &&
                  encounterData?.intakeQuestions?.callerNumberExt != ""
                    ? "ext. " + encounterData?.intakeQuestions?.callerNumberExt
                    : ""}
                </Col>
              )}
              {questionareData?.length > 0 &&
                questionareData?.map((encounter, index) => (
                  <div key={`encounter-${index}`}>
                    <div className="fw-bold mt-3" style={{display:"flex", justifyContent:"space-between"}}>
                      <span>{encounter?.questionnaireTitle}</span>
                      <span style={{ fontWeight: '400',color: "#8b8b8b", marginLeft: '10px'}}>
                        {encounter?.publisherName && encounter?.publisherName} {ViewDateTime(encounter?.updatedAt)}
                      </span>
                    </div>
                    {encounter?.item?.map((data) => (
                      <>
                        {data?.answer?.[0]?.valueString && (
                          <div
                            className="accordion-view-block mt-2"
                            key={data?.linkId}
                          >
                            <div className="header-accordion p-3 pt-2 pb-2">
                              {data?.text}
                            </div>
                            <div className="body-accordion p-3">
                              <div
                                className="m-0"
                                dangerouslySetInnerHTML={{
                                  __html: `${data?.answer?.[0]?.valueString}`,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {data?.questionType === "radio" &&
                          data?.answer?.some(
                            (ele) => ele?.valueBoolean === true
                          ) && (
                            <div
                              className="accordion-view-block mt-3"
                              key={data?.linkId}
                            >
                              <div className="header-accordion p-3 pt-2 pb-2">
                                {data?.text}
                              </div>
                              <div className="body-accordion p-3">
                                <div className="m-0">
                                  {data?.answer?.map(
                                    (ele) =>
                                      ele?.valueBoolean === true && (
                                        <div key={ele.id}>{ele?.option}</div>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        {data?.questionType === "checkbox" &&
                          data?.answer?.some(
                            (ele) => ele?.valueBoolean === true
                          ) && (
                            <div
                              className="accordion-view-block mt-3"
                              key={data?.linkId}
                            >
                              <div className="header-accordion p-3 pt-2 pb-2">
                                {data?.text}
                              </div>
                              <div className="body-accordion p-3">
                                <div className="m-0">
                                  {data?.answer?.map(
                                    (ele) =>
                                      ele?.valueBoolean === true && (
                                        <div key={ele.id}>{ele?.option}</div>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    ))}
                  </div>
                ))}
              {encounterData?.documentDelivery &&
                encounterData?.documentDelivery.some(
                  (delivery) => delivery?.type !== "HOSP"
                ) && (
                  <>
                    {/* Service Request sent */}
                    {encounterData?.documentDelivery.some(
                      (delivery) => delivery?.type !== "PHARM"
                    ) ? (
                      <div className="service-info-block">
                        <div className="additional-info-block mt-3">
                          <div className="title-header p-3">
                            <h5 className="m-0">Service request sent to...</h5>
                          </div>
                          {encounterData?.documentDelivery
                            ?.filter(
                              (delivery) =>
                                delivery?.type !== "PHARM" &&
                                delivery?.type !== "HOSP"
                            )
                            ?.map((ele, index) => (
                              <div
                                className="body-info p-3 pt-0 pb-2"
                                key={index}
                              >
                                <Row>
                                  <Col md={2}>
                                    <p className="info-title m-0 mb-2">Name</p>
                                    <p className="info-details m-0">
                                      {ele?.display
                                        ?.split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                    </p>
                                  </Col>
                                  <Col md={2}>
                                    <p className="info-title m-0 mb-2">
                                      Fax Number
                                    </p>
                                    <p className="info-details m-0">
                                      {ele?.telecom?.find(
                                        (data) => data?.system === "fax"
                                      )?.value || "-"}
                                    </p>
                                  </Col>
                                  <Col md={2}>
                                    <p className="info-title m-0 mb-2">Type</p>
                                    <div className="type-list-block">
                                      {ele?.type === "MBL" && <span>Lab</span>}
                                      {ele?.type === "RADDX" && (
                                        <span>Diagnostics</span>
                                      )}
                                      {ele?.type === "DX" && (
                                        <span>Referral</span>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : null}

                    {/* Medication Request sent */}
                    {encounterData?.documentDelivery.some(
                      (delivery) => delivery?.type === "PHARM"
                    ) ? (
                      <div className="additional-info-block mt-3">
                        <div className="title-header p-3">
                          <h5 className="m-0">Prescription sent to...</h5>
                        </div>
                        {encounterData?.documentDelivery
                          ?.filter((delivery) => delivery?.type === "PHARM")
                          ?.map((ele, index) => (
                            <div
                              className="body-info p-3 pt-0 pb-2"
                              key={index}
                            >
                              <Row>
                                <Col md={2}>
                                  <p className="info-title m-0 mb-2">Name</p>
                                  <p className="info-details m-0">
                                    {ele?.display
                                      ?.split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                  </p>
                                </Col>
                                <Col md={2}>
                                  <p className="info-title m-0 mb-2">
                                    Fax Number
                                  </p>
                                  <p className="info-details m-0">
                                    {ele?.telecom?.find(
                                      (data) => data?.system === "fax"
                                    )?.value || "-"}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </div>
                    ) : null}
                  </>
                )}

              <h6 className="mt-3">
                {encounterPractitionerName ? <b>Practitioner</b> : null}
              </h6>
              <div className="body-info pt-0 pb-2">
                <Row>
                  <Col md={4}>
                    {encounterPractitionerSign && from !== "draft" && (
                      <img
                        width={80}
                        src={encounterPractitionerSign}
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                    {encounterPractitionerName && (
                      <h6
                        className="info-title mt-2 mb-2"
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {encounterPractitionerName}
                      </h6>
                    )}
                    {encounterPractitionerData &&
                      encounterPractitionerData?.designation && (
                        <h5
                          className="info-details m-0"
                          style={{ fontSize: "14px", color: "#878686" }}
                        >
                          {encounterPractitionerData?.designation ===
                          "nursePractitioner"
                            ? "Nurse Practitioner"
                            : encounterPractitionerData?.designation
                                ?.split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                        </h5>
                      )}
                  </Col>
                </Row>
              </div>
              <Col sm={4}></Col>
            </Row>
          )}
        </section>
      </section>
    </>
  );
};

export default EncounterReportWR;
