import Modal from "react-bootstrap/Modal";
import "./QueueStyles.css";
import { Button } from "../../common/Button";
import moment from "moment";
import { Table } from "react-bootstrap";
import { useState } from "react";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import ViewNoteModal from "./ViewNoteModal";
import AddNoteModal from "./AddNoteModal";

const ViewNotesModal = ({ recordData, onShow, onHide }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 3;
  const notesData = recordData?.notes;
  const reversedNotesData = [...notesData]?.reverse();
  const totalRows = notesData?.length;
  const [addNoteModalShow, setAddNoteModalShow] = useState(false);

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };

  const handleAddNoteClick = (data) => {
    setAddNoteModalShow(true);
  };

  return (
    <>
      <Modal
        backdropClassName
        backdrop={"static"}
        show={onShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog"
      >
        <Modal.Header closeButton className="border-0">
          <span className="header-text">Patient Notes:</span>&nbsp;&nbsp;
          <Modal.Title id="contained-modal-title-vcenter">
            {capitalizeWords(recordData?.subject?.display)}
          </Modal.Title>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrap">
            <Table responsive>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Added By</th>
                  <th style={{ textAlign: "center" }}>Note</th>
                </tr>
              </thead>
              <tbody>
                {reversedNotesData?.length > 0 &&
                  reversedNotesData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((data, index) => {
                      const truncatedNote =
                        data?.note.length > 50
                          ? `${data.note.substring(0, 30)}...`
                          : data.note;
                      return (
                        <tr key={index}>
                          <td>
                            {moment(data?.updatedAt).format("DD-MMM-YY HH:mm")}
                          </td>{" "}
                          <td>{capitalizeWords(data?.actor?.display)}</td>
                          <td
                            style={{
                              textAlign: "left",
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                            }}
                          >
                            {data?.note}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
            {reversedNotesData?.length > 0 ? (
              <CustomPagination
                tableData={reversedNotesData}
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePage={(pageNo) => setPage(pageNo)}
              />
            ) : null}
          </div>

          <div className="btn-wrap">
            <div style={{ marginRight: "10px" }}>
              <Button
                onClick={() => handleAddNoteClick(recordData)}
                className="custom-btn"
                title="Add Document"
              >
                Add Note
              </Button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button
                onClick={() => onHide()}
                variant="secondary"
                title="Close"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {addNoteModalShow && (
        <AddNoteModal
          data={recordData}
          onShow={addNoteModalShow}
          onHide={() => setAddNoteModalShow(false)}
        />
      )}
    </>
  );
};

export default ViewNotesModal;
