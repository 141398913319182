import { useContext } from "react";
import { ConfirmationDialogContext } from "../ContextsAndProvider/ConfirmDialog";

export const useConfirmDialog = () => {
    const { openDialog, resetDialog } = useContext(ConfirmationDialogContext);

    const getConfirmation = ({ ...options }) =>
        new Promise((res) => {
            openDialog({ actionCallback: res, ...options });
        });

    const closeConfirmation = () => {
        resetDialog();
    }

    return { getConfirmation, closeConfirmation };
};