import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from './Routes';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirmDialog } from "./hooks/useConfirmDialog";
import { logout } from './components/api/AuthService';
import { userlogin, userlogout } from './components/slice/authService';
import { axios } from './lib/axios';
import { apiUrl } from './environment';

function App() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
    const refreshTokenString = useSelector((state) => state?.auth?.refreshToken);
    const userDetails = useSelector((state) => state?.auth?.user);
    const { getConfirmation, closeConfirmation } = useConfirmDialog();
    const intervalRef = useRef(null);
    const tokenRefreshMinutes = 10;
    const inactivityMinutes = 20;
    const promptMinutes = 2;

    const loginAPICall = async (data) => {
        try {
            if (data) {
                const response = await axios.post(`${apiUrl}auth/admin-login`, data);
                if (response?.status === true) {
                    let data = {
                        ...userDetails,
                        exp: response?.exp,
                    };
                    dispatch(userlogin(data));
                    localStorage.setItem("authentication", response?.token);
                } else {
                    throw new Error("Login Failed: Response status not true.");
                }
            }
        } catch (error) {
            clearInterval(intervalRef.current);
            localStorage.clear();
            dispatch(userlogout());
            console.error("Token Refresh Error:", error);
        }
    }


    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (refreshTokenString) {
            intervalRef.current = setInterval(() => loginAPICall({ refresh_token: refreshTokenString }), 1000 * 60 * tokenRefreshMinutes);
        }
    }, [refreshTokenString]);

    // Handle when user is inactive
    const handleOnIdle = () => {
        if (isLoggedIn) {
            clearInterval(intervalRef.current);
            closeConfirmation();
            logout();
            dispatch(userlogout());
        }
    };

    const handlePrompt = async () => {
        if (isLoggedIn) {
            const confirmed = await getConfirmation({
                title: "Attention!",
                message: "You will be logged out unless you press continue.",
                actionBtnTitle: "Continue",
                showCancel: false
            });

            if (confirmed) {
                activate(); // stop the idle call
            }
        }
        else { // user is not logged in, no need to logout
            activate();
        }
    }

    //setup idle hook
    const { getRemainingTime, activate } = useIdleTimer({
        timeout: 1000 * 60 * inactivityMinutes, 
        promptBeforeIdle: 1000 * 60 * promptMinutes, 
        onIdle: handleOnIdle,
        onPrompt: handlePrompt
    });

    return (
        <div className="App" >
            <Router>
                <AppRoutes />
            </Router>
            <ToastContainer />
        </div>
    );
}

export default App;
