import { axios } from "../../../../lib/axios";
import { encryptData } from "../../../EncryptDecrypt";

export const enrollPatientIntoWaitingRoom = ({
  email,
  selectedLocation,
  priority,
  note,
  registeredById,
  registeredByName,
}) => {
  const payload = {
    email: encryptData(email?.toLowerCase()),
    location: selectedLocation,
    priority,
    registeredById,
    registeredByName,
    note: encryptData(note),
  };
  return axios.post("waitingRoom/request", payload);
};
export const startWaitingRoomMeeting = ({
  id,
  patientId,
  practitionerId,
  practitionerName,
}) => {
  const payload = {
    id,
    patientId,
    practitionerId,
    practitionerName,
  };

  return axios.post("waitingRoom", payload);
};

export const endWaitingRoomMeeting = ({ id, patientId }) => {
  const payload = { id, patientId };
  return axios.put("waitingRoom/leave", payload);
};

export const joinWaitingRoomMeeting = ({ email, password }) => {
  return axios.post("waitingRoom/check", { email, password });
};

export const editEnrolledPatient = (payload) => {
  return axios.put(`waitingRoom/request/${payload.id}`, payload);
};

export const deleteEnrolledPatient = (id) => {
  return axios.delete(`waitingRoom/request/${id}`);
};

export const getActiveQueue = ({ scopeOfPractice, status, orgId }) => {
  return axios.get(
    `queue?scopeOfPractice=${scopeOfPractice}&status=${status}&orgId=${orgId}`
  );
};

export const editQueueItem = (payload, userOrgID) => {
  return axios.put(`queue?orgId=${userOrgID}`, payload);
};
