import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { noDataAvailText, serviceRequestTitle, filterStatusOptions } from "../Constants";
import moment from "moment";
import { Link, Skeleton, TableCell, Tooltip } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import ViewIcon from "../../../../assets/images/view.png";
import EditIcon from "../../../../assets/images/edit.png";
import AddNoteIcon from '../../../../assets/images/add-note.png';
import { useServiceRequestQuery } from "../../../../hooks/ReactQueryHooks/useServiceRequestQuery";
import SelectFiled from "../../../common/textfield/SelectFiled";
import EditServiceRequestModal from "../../ServiceRequest/EditServiceRequestModal";
import { useConfirmDialog } from "../../../../hooks/useConfirmDialog";
import { failed, success } from "../../../common/Toastify";
import { editServiceRequest } from "../../api/ServiceRequest";
import { FullPageSpinner } from "../../../common/Spinner/FullPageSpinner";
import { useNavigate } from "react-router";
import PdfServiceRequestModal from "../../ServiceRequest/PdfServiceRequestModal";
import RefreshIcon from '@mui/icons-material/Refresh';
import ServiceLocationModal from "../../ServiceRequest/ServiceLocationModal";
import { getPatientData } from "../../../api/Individual";
import { ViewDateTime } from "../../../../utils/DateSupport";

export function ServiceRequestList({ patientId, requestType, practionerData, orgnization }) {
  const [isListLoading, setIsListLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [serviceRequestModal, setserviceRequestModal] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [serviceRequestData, setServiceRequestData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [PermissionGranted, setPermissionGranted] = useState(false);
  const [PermissionGrantedToView, setPermissionGrantedToView] = useState(false);
  const [filteredServiceRequest, setFilteredServiceRequest] = useState(serviceRequestData?.filter((obj) => obj?.category?.[0].coding?.[0].code == requestType))
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const user = useSelector((state) => state?.auth?.user);
  const permissions = useSelector((state) => state?.auth?.user?.permissions);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations) 
  const { getConfirmation } = useConfirmDialog();
  const navigate = useNavigate()
  localStorage.setItem("requestTypeId", requestType)
  let ServiceRequestType
  if(requestType === "15220000") ServiceRequestType = "Lab"
  else if(requestType === "103693007") ServiceRequestType = "Diagnostic"
  else ServiceRequestType = "Referral"

  const onSuccess = (data) => {
    setServiceRequestData(data);
    setFilteredServiceRequest(data?.filter((obj) => obj?.category?.[0].coding?.[0].code == requestType))
  };

  const { isLoading, isFetching, refetch } = useServiceRequestQuery({ onSuccess, patientId: patientId, refetchOnWindowFocus: false });


  const handleStatus = (event) => {
    if (event.target.value != "all") setFilteredServiceRequest(serviceRequestData.filter((obj) => obj?.status == event.target.value))
    else setFilteredServiceRequest(serviceRequestData)
  }

  const handleShow = (data, isReadOnly) => {
    setModalShow(modalShow ? false : data);
    setReadOnly(isReadOnly);
  };

  const showServiceRequestModal = (data, isReadOnly) => {
    setserviceRequestModal(modalShow ? false : data);
    // setReadOnly(isReadOnly);
  };

  const handleStatusServiceRequest = async (status, serviceRequestId, patientId) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName: false,
      actionBtnTitle: "Yes",
      message: "Are you sure want to update the status of "
    });
    if (confirmed) {
      setIsListLoading(true)
      editServiceRequest({ id: serviceRequestId, patientId, status })
        .then((res) => {
          setIsListLoading(false)
          success("Status updated successfully!");
          refetch()
        })
        .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
    }
  }

  useEffect(() => {
    setFilteredServiceRequest(serviceRequestData.filter((obj) => obj?.category?.[0].coding?.[0].code == requestType))
    if (requestType == "15220000") {
      if (permissions.includes("Create Lab Requisitions")) setPermissionGranted(true)
      else setPermissionGranted(false)
    } else if (requestType == "103693007") {
      if (permissions.includes("Create Diagnostic Imaging Requests")) setPermissionGranted(true)
      else setPermissionGranted(false)
    } else {
      if (permissions.includes("Create Service Referrals")) setPermissionGranted(true)
      else setPermissionGranted(false)
    }

    if (requestType == "15220000") {
      if (permissions.some(permission => permission === 'View Lab Requisitions' || permission === 'Create Lab Requisitions')) setPermissionGrantedToView(true)
      else setPermissionGrantedToView(false)
    } else if (requestType == "103693007") {
      if (permissions.some(permission => permission === 'View Diagnostic Imaging Requests' || permission === 'Create Diagnostic Imaging Requests')) setPermissionGrantedToView(true)
      else setPermissionGrantedToView(false)
    } else {
      if (permissions.some(permission => permission === 'View Service Referrals' || permission === 'Create Service Referrals')) setPermissionGrantedToView(true)
      else setPermissionGrantedToView(false)
    }
  }, [requestType])

  useEffect(() => {
    getPatientData(patientId)
        .then((res) => {
            setPatientData(res?.result)
        })
}, [])

  return (
    <>
      {isListLoading ? <FullPageSpinner /> : null}
      {serviceRequestModal ? <ServiceLocationModal
                onHide={() => setserviceRequestModal(false)}
                modalShow={serviceRequestModal}
                paramsObj={{patientId, fromListing: true}}
                practitionerData={user}
                requestType={ServiceRequestType}
                categoryVar={ServiceRequestType == 'Lab' ? 'Lab Requisition' : ( ServiceRequestType == 'Diagnostic' ?  'Diagnostic Requisition': 'Referral')}
                patientData={patientData}
                orgName={adminOrgs?.[0]?.name}
            /> : null}
      {modalShow ? <PdfServiceRequestModal modalShow={modalShow} handleShow={handleShow} isReadOnly={readOnly} refetch={refetch} /> : null}
      <Tab.Pane eventKey="seventh">
      {requestType && <div style={{marginTop: "8px"}}><span style={{color: "grey"}}>Service Request&gt;</span><span style={{fontWeight: "bold", color: "#0064a6"}}>{requestType === "15220000" ? "Lab" : requestType === "103693007" ? "Diagnostic" : "Referral"}</span></div>}
        {/* <div className="common-form" style={{ justifyContent: "end", display: "flex", marginTop: "10px" }}>
          <SelectFiled
            keyField={"org"}
            label={false}
            selectLabel={"Status"}
            options={filterStatusOptions}
            onChange={handleStatus}
            required={false}
            optional={false}
          />
        </div> */}
        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
                {serviceRequestTitle?.map((title) => (
                  <th
                    key={title}
                    style={{
                      textAlign: title === "Edit" ? "center" : "auto",
                    }}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading || isFetching ? (
                [1, 2, 3, 4, 5, 6].map((val) => (
                  <tr key={val}>
                    {serviceRequestTitle?.map((index) => (
                      <td key={index}>
                        <Skeleton animation="wave" />
                      </td>
                    ))}
                  </tr>
                ))
              ) : filteredServiceRequest?.length > 0 ? (
                filteredServiceRequest
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data) => {                    
                    return (
                      <tr key={data?.id}>
                        <td>
                          {ViewDateTime(data?.updatedAt)}
                        </td>
                        <td>{data?.practitionerName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                        <td>
                           <Tooltip  title="View Encounter Details">
                              <span style={{cursor: 'pointer', color: "#0064a6", textDecoration: "underline" }} 
                              onClick={() => navigate("/app/encounter-details",
                                      { state: { encounterId: data?.encounter?.reference.split("/")?.[1], practionerData, from: "fromServiceReq" } })}>
                                        {data?.encounter?.reference.split("/")?.[1] || "-"} 
                              </span>
                            </Tooltip>
                        </td>
                        <td
                          style={
                            (data?.status == "active"
                              ? { color: "green" }
                              : null) ||
                            (data?.status == "In Progress"
                              ? { color: "grey" }
                              : null) ||
                            (data?.status == "Cancelled"
                              ? { color: "red" }
                              : null)
                          }
                        >
                          {
                          data.status == "draft" ? data.status = "In Progress".split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 
                        data?.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          {/* <select value={data?.status} id="status" disabled={ user?.["custom:unique_id"] !== data?.requester?.reference.split("/")[1] || data?.status === "Cancelled"} style={{cursor : "pointer"}} onChange={(e)=> handleStatusServiceRequest(e.target.value,data?.id,data?.subject?.reference.split("/")[1])}>
                          <option value="In Progress">In Progress</option>
                          <option value="Cancelled">Cancelled</option>
                        </select> */}
                        </td>
                        <td>
                          <div className="action-wrap">
                            <Tooltip title={"View"}>
                              <Link
                                to=""
                                variant="primary"
                                className="view-btn"
                                // style={{ opacity: user?.["custom:unique_id"] === data?.requester?.reference.split("/")[1] }}
                                onClick={() => handleShow(data, false)}
                              >
                                <img src={ViewIcon} alt="View PDF" />
                              </Link>
                            </Tooltip>
                            <Tooltip title={"Resend Document"}>
                              <Link
                                to=""
                                variant="primary"
                                className="view-btn"
                                // style={{ opacity: user?.["custom:unique_id"] === data?.requester?.reference.split("/")[1] }}
                                onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ? showServiceRequestModal(data, false) : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}
                              >
                                <RefreshIcon style={{color : 'white'}}/>
                              </Link>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <TableCell
                    colSpan={serviceRequestTitle.length}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {noDataAvailText}
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
          {filteredServiceRequest?.length > 0 ? (
            <div>
              <CustomPagination
                tableData={filteredServiceRequest}
                totalRows={filteredServiceRequest?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePage={(pageNo) => setPage(pageNo)}
              />
            </div>
          ) : null}
        </div>
      </Tab.Pane>
    </>
  );
}