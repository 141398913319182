import { axios } from "../../../lib/axios";

// ---------------questions--------------------------------
export const createQuestion = (payload) => {
    return axios.post(`/question`, payload);
};

export const editQuestion = (questionId, payload) => {
    return axios.put(`/question/${questionId}`, payload);
};

export const deleteQuestion = (questionId) => {
    return axios.put(`/question/${questionId}`, {
        "active": false
    });
};

// ---------------questionnaires--------------------------------
export const createQuestionnaire = (payload) => {
    return axios.post(`/questionnaire`, payload);
};
export const editQuestionnaire = (questionnaireId, payload) => {
    return axios.put(`/questionnaire/${questionnaireId}`, payload);
};

export const deleteQuestionnaire = (questionnaireId, payload ) => { 
    return axios.put(`/questionnaire/${questionnaireId}`, payload );
};
// ---------------questionCategory--------------------------------
export const createQuestionCategory = (payload) => {
    return axios.post(`/question/category`, payload);
};
export const editQuestionCategory = (questionnaireId, payload) => {
    return axios.put(`/question/category/${questionnaireId}`, payload);
};

export const deleteQuestionCategory = (questionnaireId) => {
    return axios.put(`/question/category/${questionnaireId}`, {
        "active": false
    });
};

export const getQuestionCategory = (orgId) => {
    return axios.get(`/question/category?orgId=${orgId}`)
};

// ---------------questionnaire response--------------------------------
export const createQuestionnaireResponse = (payload) => {
    return axios.post(`/questionnaireresponse`, payload);
};
export const editQuestionnaireResponse = (id, payload) => {
    return axios.put(`/questionnaireresponse/${id}`, payload);
};

export const deleteQuestionnaireResponse = (id) => {
    return axios.put(`/questionnaireresponse/${id}`, {
        "active": false
    });
};

// --------------- Assigned questionnaire --------------------------------
export const assignedQuestionnaireToOrganization = (orgID, payload) => {
    return axios.put(`/organization/${orgID}`, payload);
};

// -------------------- Video Visit API ----------- 

export const getVideoVisitQuestionnaire = (id = null, orgId = null) => {
    return axios.get(`questionnaire?type=video-visit&ids=${id}&orgId=${orgId}`)    
};

export const getSavedQuestionnaireResponses = (encounterId) => {
    return axios.get(`/questionnaireresponse?encounterId=${encounterId}`)
}