import React from "react";
import ConfirmMsg from "../components/common/components/ConfirmMsg";

export const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogConfig, setDialogConfig] = React.useState({});

    const openDialog = ({ title, actionCallback, ...props }) => {
        setDialogOpen(true);
        setDialogConfig({ title, actionCallback, ...props });
    };

    const resetDialog = () => {
        setDialogOpen(false);
        setDialogConfig({});
    };

    const onConfirm = () => {
        resetDialog();
        dialogConfig.actionCallback(true);
    };

    const onDismiss = () => {
        resetDialog();
        dialogConfig.actionCallback(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog, resetDialog }}>
            <ConfirmMsg
                onConfirm={onConfirm}
                onDismiss={onDismiss}
                open={dialogOpen}
                {...dialogConfig}
            />
            {children}
        </ConfirmationDialogContext.Provider>
    );
};
export default ConfirmationDialogProvider;