import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton, TableCell, Tooltip, Switch } from "@mui/material";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { Spinner } from "react-bootstrap";
import EditQuestionnaire from "./EditQuestionnaire";
import AssignQuestionnaire from "./AssignQuestionnaire";
import { deleteQuestionnaire } from "../../api/Questionnaire";
import { useQuestionnaireQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireQuery";
import moment from "moment";
import { useSelector } from "react-redux";
import ViewIcon from "../../../assets/images/view.png";
import { ArrowDropDown, ArrowDropUp, CopyAll } from "@mui/icons-material";
import CreateCopyOfDefaultQuestionnaire from "./CreateCopyOfDefaultQuestionnaire";
import { questionnaireForOptions } from "./Constant";
import { editQuestionnaire } from "../../api/Questionnaire";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { useQuestionnaireSortingQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireSortingQuery";
import { getOrganization } from "../../api/Organization";

function QuestionnaireList() {
    const { getConfirmation } = useConfirmDialog();
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userType?.includes('Super-Admin');
    let isDisabled;
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const [orgID, setOrgID] = useState(admin ? "All" : adminOrgs?.[0]?.id);

    const [modalShow, setModalShow] = useState(false);
    const [createCopy, setCreateCopy] = useState(false);
    const [assignData, setAssignData] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState("AscName");
    const [organizations, setOrganizations] = useState([]);
    const [organizationData, setOrganizationData] = useState(null);

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const { isLoading, refetch, isFetching, data: programList } = useQuestionnaireSortingQuery({ orgID });

    const handleFilterData = (data) => {
        setFilterData(data);
        setPage(0)
    };

    const handleShow = (data, isReadOnly) => {
        setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
    };

    const handleDelete = async (id, entryName, purposeData) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            let payload = {
                "status": "retired",
                "purpose": purposeData,
                "type": "questionnaire"
            }
            deleteQuestionnaire(id, payload).then(() => {
                setFilterData(filterData.filter(data => data.id !== id));
                success("Questionnaire Deleted!");
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    };

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                setFilterData(filterData?.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)))
                break;
            case "DscName":
                setFilterData(filterData?.sort((a, b) => (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0)))
                break;
            default:
                setFilterData(filterData)
                break;
        }
    }

    const getCategoryTypeName = (categoryType) => {
        const category = questionnaireForOptions.find(option => option.value === categoryType);
        return category ? category.name : categoryType;
    };

    const handleStatusToggle = async (id, currentStatus, purposeData) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            actionBtnTitle: "Change Status",
            message: `Are you sure you want to change the status to ${currentStatus === "active" ? "Inactive" : "Active"
                }?`
        });
        if (confirmed) {
            try {
                const newStatus =
                    currentStatus === "active"
                        ? { status: "draft", "purpose": purposeData, "type": "questionnaire" }
                        : { status: "active", "purpose": purposeData, "type": "questionnaire" };

                const updatedData = await editQuestionnaire(id, newStatus);
                setFilterData((prevData) =>
                    prevData.map((item) =>
                        item.id === id
                            ? { ...item, status: updatedData.status }
                            : item
                    )
                );
                success(`Status updated successfully!`);
                refetch();
            } catch (error) {
                failed(
                    error?.response?.data?.message ||
                    error?.response?.data?.error ||
                    error.message
                );
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            org: admin ? "All" : adminOrgs?.[0]?.id,
            type: "All"
        },
    });
    useOrgQuery({
        onSuccess: (data) => {
            setOrganizations(data?.map((org) => ({ name: org?.name, value: org?.id })))
        },
        refetchOnWindowFocus: false,
    })
    const handleOrgChange = (event) => {
        const value = event?.target?.value;
        formik.setFieldValue("org", value);
        if (admin) {
            if (value == "All") {
                setOrgID("All");
            } else {
                setOrgID(value);
            }
        } else {
            setOrgID(adminOrgs?.[0]?.id);
        }
    };
    const handleChildSubmit = () => {
        setAssignData(true);
    };

    useEffect(() => {
        const fetchOrganizationData = async () => {
            try {
                const response = await getOrganization(orgID);
                setOrganizationData(response.data);
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };
        fetchOrganizationData();
    }, [isLoading, refetch, isFetching, assignData]);

    let assignArr = []
    if (organizationData?.assignedQuestionnaire !== undefined) {
        assignArr = [...organizationData?.assignedQuestionnaire["video-visit"],
        organizationData?.assignedQuestionnaire["getcare-appointment"], organizationData?.assignedQuestionnaire["getcare-waitingroom"],
        organizationData?.assignedQuestionnaire["telephone-intake"]]
    }

    const startIndex = page * rowsPerPage;
    return (
        <React.Fragment>
            <section className="common-listing">
                {assignData ? <AssignQuestionnaire modalData={assignData} handleHide={() => setAssignData(false)} refreshTable={refetch} /> : null}
                {modalShow ? <EditQuestionnaire modalShow={modalShow} handleShow={handleShow} refreshTable={refetch} isReadOnly={readOnly} /> : null}
                {createCopy ? <CreateCopyOfDefaultQuestionnaire modalData={createCopy} handleHide={() => setCreateCopy(false)} refreshTable={refetch} /> : null}

                <div className="heading-wrap h-change">
                    <SearchBar page={page} setPage={setPage} list={programList} filterData={handleFilterData} />
                    {admin ?
                        <div className="right-wrap ml-auto" style={{ margin: "left", paddingTop: "25px" }}>
                            <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                                <SelectFiled
                                    keyField={"org"}
                                    label={false}
                                    formik={formik}
                                    selectLabel={"Select Organization"}
                                    options={admin ? [{ name: "MY RECORDS", value: "All" }, ...organizations] : organizations}
                                    onChange={handleOrgChange}
                                    required={false}
                                    optional={false}
                                    readOnly={isLoading}
                                />
                            </form>
                        </div>
                        :
                        <div className="right-wrap ml-auto" style={{ margin: "right" }}>
                            <Button onClick={() => setAssignData(true)} className="custom-btn" title="Assign Questionnaire" style={{ width: "auto" }}>
                                Assign
                            </Button>
                        </div>}

                    <div className="right-wrap ml-auto m-0" style={{ margin: "left" }}>
                        <Button onClick={() => navigate("/app/create-questionnaire")} className="custom-btn" title="Create Questionnaire" style={{ width: "auto" }}>
                            Create
                        </Button>
                    </div>
                </div>

                <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>Questionnaire Name
                                    {sortBy === "AscName" ? <span>
                                        <ArrowDropUp />
                                    </span> : <span>
                                        <ArrowDropDown />
                                    </span>}
                                </th>
                                <th>Purpose</th>

                                {/* <th>Status</th>
                                <th>Date created</th>
                                <th>Date last edited</th> */}
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        {/* <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td> */}
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) :

                                filterData?.length > 0 ? (
                                    filterData.slice(startIndex, startIndex + rowsPerPage).map((data, index) => {
                                        const hasAccess = userType?.includes("Super-Admin") ? true : !!!data?.isDefault;
                                        const serialNumber = startIndex + index + 1;
                                        let resultArray = filterData.filter(item => assignArr.includes(item?.id));                                        
                                        const foundObject = resultArray.find(obj => { return obj?.id == data?.id; });
                                        
                                        return (
                                            <tr key={data?.id}>
                                                {/* { (userType == 'Super-Admin' && (data.questionnaireFor == 'Get Care' || data.questionnaireFor == 'Service Request' ||  data.questionnaireFor == 'Video Visit' )) 
                                           || (userType == 'Practitioner' && ( data.questionnaireFor == 'Service Request' ||  data.questionnaireFor == 'Video Visit' )) 
                                           ?   */}
                                                <> {(foundObject != undefined) ? <td className="name-text"><b>{serialNumber}</b></td> : <td className="name-text">{serialNumber}</td>}
                                                    {(foundObject != undefined) ? <td><b>{data?.title}</b></td> : <td>{data?.title}</td>}
                                                    {(foundObject != undefined) ? <td><b>{getCategoryTypeName(data?.purpose)}</b></td> : <td>{getCategoryTypeName(data?.purpose)}</td>}

                                                    {/* <td> {hasAccess ? isDisabled = false : isDisabled = true}
                                                    <Switch disabled={isDisabled} name="status" checked={data?.status === "active" ? true : false} onChange={() => handleStatusToggle(data?.id, data?.status, data?.purpose)} />
                                                </td>
                                                <td>{moment(data?.createdAt).format("MM/DD/YYYY")}</td>
                                                <td>{moment(data?.updatedAt).format("MM/DD/YYYY")}</td> */}
                                                    <td className="action-wrap-container">
                                                        <div className="actions-styles action-wrap">
                                                            {hasAccess ? null : <Tooltip title={"Copy Default Questionnaire"}>
                                                                <Link
                                                                    to=""
                                                                    variant="primary"
                                                                    onClick={() => setCreateCopy(data)}
                                                                    className="delete-btn"
                                                                >
                                                                    <CopyAll style={{ color: "#fff", width: "60%" }} />
                                                                </Link>
                                                            </Tooltip>}
                                                            {hasAccess ? (
                                                                <Tooltip title={"Edit"}>
                                                                    <Link
                                                                        to=""
                                                                        variant="primary"
                                                                        onClick={(e) => {
                                                                          e.preventDefault();
                                                                          navigate("/app/edit-questionnaire", { state: {initialState: data, isReadOnly: false} });
                                                                        }}
                                                                        className="success-btn"
                                                                    >
                                                                        <img src={EditIcon} alt="Edit" />
                                                                    </Link>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title={"View"}>
                                                                    <Link
                                                                        to=""
                                                                        variant="primary"
                                                                        onClick={(e) => {
                                                                          e.preventDefault();
                                                                          navigate("/app/edit-questionnaire", { state: { initialState: data, isReadOnly: true } });
                                                                        }}
                                                                        className="success-btn"
                                                                    >
                                                                        <img src={ViewIcon} alt="View" />
                                                                    </Link>
                                                                </Tooltip>
                                                            )}
                                                            {/* <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                            <Link
                                                                to=""
                                                                variant="primary"
                                                                onClick={() => hasAccess && (deleting ? null : handleDelete(data?.id, data?.title,  data?.purpose))}
                                                                className="delete-btn"
                                                                style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                            >
                                                                {deleting === data?.id ? (
                                                                    <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                                ) : (
                                                                    <img src={DeleteIcon} alt="Delete" />
                                                                )}
                                                            </Link>
                                                        </Tooltip> */}
                                                        </div>
                                                    </td>
                                                </>
                                                {/* : null } */}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </TableCell>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </div>
                {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
            </section>
        </React.Fragment>
    );
}

export default QuestionnaireList;
