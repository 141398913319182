import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { Button } from "../../common/Button";
import moment from "moment";

const PriorityHistoryModal = ({ data, onShow, onHide }) => {
  console.log("DATA RECEIVED: ", data);
  const [page, setPage] = useState(0);
  const rowsPerPage = 3;
  const priorityHistoryData = data?.priorityHistory;
  const reversedPriorityHistoryData = [...priorityHistoryData]?.reverse();
  const totalRows = reversedPriorityHistoryData?.length;
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <span className="header-text">Priority History:</span>&nbsp;&nbsp;
        <Modal.Title id="contained-modal-title-vcenter">
          {capitalizeWords(data?.subject?.display)}
        </Modal.Title>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </Modal.Header>
      <Modal.Body>
        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Updated By</th>
                <th style={{ textAlign: "center" }}>Old Priority</th>
                <th style={{ textAlign: "center" }}>&rarr;</th>
                <th style={{ textAlign: "center" }}>New Priority</th>
              </tr>
            </thead>
            <tbody>
              {reversedPriorityHistoryData?.length > 0 &&
                reversedPriorityHistoryData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {moment(data?.updatedAt).format("DD-MMM-YY HH:mm")}
                        </td>{" "}
                        <td style={{ textTransform: "capitalize" }}>
                          {data?.actor?.display}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {data?.oldPriority || "-"}
                        </td>
                        <td style={{ textAlign: "center" }}>&rarr;</td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {data?.newPriority}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          {reversedPriorityHistoryData?.length > 0 ? (
            <CustomPagination
              tableData={reversedPriorityHistoryData}
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handlePage={(pageNo) => setPage(pageNo)}
            />
          ) : null}
        </div>

        <div className="btn-wrap">
          <Button onClick={() => onHide()} variant="secondary" title="Close">
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PriorityHistoryModal;
