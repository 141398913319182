import { useFormik } from "formik";
import './signature.css';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleUser } from "../api/Individual";
import { failed, success } from "../common/Toastify";
import TextInput from "../common/textfield/TextInput";
import DateSelector from "../common/textfield/DateSelector";
import CountrySelect from "../common/textfield/CountrySelect";
import RegionSelect from "../common/textfield/RegionSelect";
import { Button } from "../common/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { decryptData, encryptData } from "../EncryptDecrypt";
import InputFile from "../common/textfield/InputFile";
import { useNavigate } from "react-router-dom";
import { editUserProfile } from "../api/Profile";
import CircularProgress from "@mui/material/CircularProgress";
import { FormikObj } from "./Constants";
import { splitContactNumber, createContactNumber, splitHomeContactNumber } from "../../utils/phoneNumberSupport";
import { handleFileUpload } from "../../utils/ImageToBase64";
import ReactDatePicker from "../common/textfield/ReactDatePicker";
import SelectFiled from "../common/textfield/SelectFiled";
import SignatureCapture from './SignatureCapture';
import { caregions, usregions, countryList } from '../Admin/Individuals/Constants';
import MultiSelect from "../common/textfield/MultiSelect";
import moment from "moment";


const UserProfile = () => {
  const navigate = useNavigate();
  const id = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [file, setFile] = useState();
  const userData = useRef();
  const [inputValue, setInputValue] = useState("");
  const [designationOptions, setDesignationOptions] = useState([
    { value: "nurse", name: "Nurse" },
    { value: "nursePractitioner", name: "Nurse Practitioner" },
    { value: "physician", name: "Physician" },
  ]);
  const [signatureImage, setSignatureImage] = useState("");
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const practitioner = userRoles?.includes('Practitioner');
  const permissions = useSelector((state) => state?.auth?.user?.permissions);
  const hasAccess = ((permissions?.includes('Manage Individuals') && practitioner) || userRoles?.includes("Super-Admin"));
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)




  useEffect(() => {
    setBtnLoading(true);
    const apiBody = {
      id,
      type: "Practitioner",
      userOrgId: adminOrgs?.[0]?.id
    };
    getSingleUser(apiBody)
      .then((res) => { 
        userData.current = res?.data;
        let countryRegion = res?.data?.address?.[0]?.state?.split("-") || null;
        formik.setFieldValue(
          "first_name",
          decryptData(res?.data?.name?.[0]?.given?.[0] || "")
        );
        formik.setFieldValue(
          "last_name",
          decryptData(res?.data?.name?.[0]?.family || "")
        );
        res?.data?.birthDate &&
          formik.setFieldValue(
            "date_of_birth",
            decryptData(moment(res?.data?.birthDate).format("MM-DD-YYYY")) || ""
          );
        formik.setFieldValue(
          "phone_number",
          splitContactNumber(decryptData(res?.data?.telecom?.find((ele) => ele?.system === "phone")?.value || "")).phone_number);
        formik.setFieldValue(
          "ext",
          splitContactNumber(decryptData(res?.data?.telecom?.find((ele) => ele?.system === "phone")?.value || "")).ext);
        formik.setFieldValue(
          "address",
          decryptData(res?.data?.address?.[0]?.line?.[0])
        );
        formik.setFieldValue(
          "postalCode",
          decryptData(res?.data?.address?.[0]?.postalCode)
        );
        setInputValue(decryptData(res?.data?.address?.[0]?.postalCode))
        formik.setFieldValue(
          "city",
          decryptData(res?.data?.address?.[0]?.city)
        );
        formik.setFieldValue("gender", decryptData(res?.data?.gender));
        formik.setFieldValue("id", res?.data?.id || "");
        formik.setFieldValue("country", countryRegion?.[0]);
        formik.setFieldValue("region", countryRegion?.[1]);

        let countryScope = res?.data?.scopeOfPractice?.[0]?.split("-");

        countryScope && formik?.setFieldValue("countrylist", countryScope?.[0]);
        res?.data?.scopeOfPractice && countryScope?.[0] === "US"
          ? setRegionOptions(usregions)
          : setRegionOptions(caregions);
          res?.data?.scopeOfPractice && setDefaultValue(res?.data?.scopeOfPractice);
        res?.data?.scopeOfPractice && formik?.setFieldValue("jurisdiction", res?.data?.scopeOfPractice);

        res?.data?.designation && formik.setFieldValue(
          "designation",
          decryptData(res?.data?.designation || "")
        );

        res?.data?.qualification && formik.setFieldValue(
          "qualification",
          decryptData(res?.data?.qualification[0]?.code?.coding[0]?.text || "")
        );
        res?.data?.qualification && formik.setFieldValue(
          "license",
          decryptData(res?.data?.qualification[0]?.identifier[0]?.value || "")
        );

        formik.setFieldValue(
          "home_phone_number",
          splitHomeContactNumber(
            decryptData(
              res?.data?.telecom?.find((ele) => ele?.use === "home")
                ?.value || ""
            )
          ).home_phone_number.replace(/^\+1/, '')
        );

        formik.setFieldValue(
          "email",
          res?.data?.telecom?.find((ele) => ele?.system == "email")?.value || "");

        const designation = res?.data?.designation;
        const updatedDesignationOptions = designationOptions?.map(option => {
          return {
            ...option,
            selected: option?.value === designation,
          };
        });
        setDesignationOptions(updatedDesignationOptions);

        setCountry(countryRegion?.[0]);
        setRegion(countryRegion?.[1]);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
          res?.response?.data?.error ||
          res.message
        );
      })
      .finally(() => setBtnLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getImageExtension(base64Data) {
    const base64String = base64Data.replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
    const byteCharacters = atob(base64String);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    if (byteArray[0] === 0x89 && byteArray[1] === 0x50) {
      return 'png';
    } else if (byteArray[0] === 0xFF && byteArray[1] === 0xD8) {
      return 'jpeg';
    } else if (byteArray[0] === 0x47 && byteArray[1] === 0x49) {
      return 'gif';
    } else if (byteArray[0] === 0x42 && byteArray[1] === 0x4D) {
      return 'bmp';
    } else {
      return 'unknown';
    }
  }

  const formik = useFormik({
    ...FormikObj(practitioner),
    onSubmit: async (values) => {
      let base64ProfileImage = "";
      let base64LicenseImage = "";
      if (values?.profileImage) {
        base64ProfileImage = await handleFileUpload(values?.profileImage);
      }
      let profileExtension = values?.profileImage?.type?.split("/")[1];
      if (values?.file) {
        base64LicenseImage = await handleFileUpload(values?.file);
      }
      let fileExtension = values?.file?.type?.split("/")[1];
      const state = country + "-" + region;
      let phoneNumber = createContactNumber(values);
      let encryptedData = {
        first_name: encryptData(values?.first_name),
        last_name: encryptData(values?.last_name),
        full_name: encryptData(
          [values?.first_name, values?.last_name].join(" ")
        ),
        address: encryptData(values?.address),
        date_of_birth: encryptData(values?.date_of_birth),
        phone_number: encryptData(phoneNumber),
        postalCode: encryptData(values?.postalCode.toUpperCase()),
        city: encryptData(values?.city),
      };
      setBtnLoading(true);
      const signExtension = getImageExtension(signatureImage);
      
      editUserProfile(
        values,
        encryptedData,
        country,
        state,
        base64ProfileImage,
        profileExtension,
        base64LicenseImage,
        fileExtension,
        userData?.current,
        signatureImage,
        signExtension,
        adminOrgs?.[0]?.id
      )
        .then((res) => {
          if (res?.status === true) {
            navigate("/");
            success(res?.message);
          } else {
            failed(res?.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });
  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
    if (newCountry !== formik.values.country) {
      formik.setFieldValue("region", ""); // Reset Formik state only if the country is changing
      formik.setFieldValue("postalCode", "");
      formik.setFieldValue("city", "");
      setRegion(""); // Reset component state only if the country is changing
      setInputValue("");
    }
    if (newCountry === "CA") {
      setRegionOptions(caregions);
    } else {
      setRegionOptions(usregions);
    }
    formik.setFieldValue("jurisdiction", []);
  };

  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    // Remove spaces and non-alphanumeric characters
    const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
    // Add a space after every 3 letters
    const spacedValue = formattedValue.replace(
      /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
      "$1 $2"
    );
    // Set the formatted value in the state or formik
    setInputValue(spacedValue);
    formik?.setFieldValue("postalCode", spacedValue);
  };
  const handleSignatureCapture = (imageData) => {
    setSignatureImage(imageData);
  };

  const openSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  const closeSignatureModal = () => {
    setIsSignatureModalOpen(false);

  };

  useEffect(() => {
    if (signatureImage !== "") {
      const encodedImageData = signatureImage;
      const img = new Image();
      img.src = encodedImageData;
      const imageContainer = document.getElementById("image-container");
      if (imageContainer) {
        imageContainer.appendChild(img);
      }
    }
  }, [signatureImage]);

  return (
    <section className="common-listing">
      <div className="heading-wrap">
      </div>
      {btnLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <div className="custom-card p-4">
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            <Row>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  style={{ textTransform: "capitalize" }}
                  keyField={"first_name"}
                  label={"First Name"}
                  formik={formik}
                  placeholder={"First Name"}
                />
              </Col>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  style={{ textTransform: "capitalize" }}
                  keyField={"last_name"}
                  label={"Last Name"}
                  formik={formik}
                  placeholder={"Last Name"}
                />
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col>

                <SelectFiled
                  readOnly = {!hasAccess}
                  keyField={"designation"}
                  label={"Designation"}
                  formik={formik}
                  options={designationOptions?.map((option) => ({
                    ...option,
                    selected: option?.value === formik?.values?.designation,
                  }))}
                  required={false}
                />
              </Col>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"qualification"}

                  label={"College"}
                  formik={formik}
                  placeholder={"College "}
                  required={false}
                />
              </Col>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"license"}
                  label={"License Number"}
                  formik={formik}
                  placeholder={"License Number"}
                  required={false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactDatePicker
                  readOnly={!hasAccess}
                  keyField={"date_of_birth"}
                  label={"Date of Birth"}
                  formik={formik}
                  placeholder={"Select Date"}
                  hasValue={formik?.values?.date_of_birth}
                  displayAge={practitioner ? true : false}
                />
              </Col>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"phone_number"}
                  type="phone"
                  label={"Work Phone Number"}
                  formik={formik}
                  placeholder={"Work Phone Number"}
                  required={false}
                />
              </Col>
              <Col>
                <TextInput
                  disabled ={!hasAccess} keyField={"ext"} type="ext" label={"Ext."} formik={formik} placeholder={"Ext"} required={false} />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"home_phone_number"}
                  type="phone"
                  label={"Home Phone Number"}
                  formik={formik}
                  placeholder={"Home Phone Number"}
                  required={false}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"email"}
                  label={"Email"}
                  formik={formik}
                  placeholder={"Email"}
                  disabled={true}
                />
              </Col>
              <Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"address"}
                  label={"Address"}
                  formik={formik}
                  placeholder={"Address"}
                />
              </Col>
              <Col>
                {formik?.values?.country === "US" ? (
                  <TextInput
                    disabled ={!hasAccess}
                    keyField={"postalCode"}
                    label={"Postal Code"}
                    formik={formik}
                    placeholder={"Postal Code"}
                    // max length for both zip code and postal code(plus space)
                    maxLength={formik?.values?.country === "US" ? "5" : "7"}
                  />
                ) : (
                  <TextInput
                    disabled ={!hasAccess}
                    onChange={handlePostalCodeChange}
                    value={inputValue}
                    keyField={"postalCode"}
                    label={"Postal Code"}
                    formik={formik}
                    placeholder={"Postal Code"}
                    // max length for both zip code and postal code(plus space)
                    maxLength={formik?.values?.country === "US" ? "5" : "7"}
                  />
                )}
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <CountrySelect
                  readOnly={!hasAccess}
                  country={country}
                  setCountry={handleCountryChange}
                  keyField={"country"}
                  label={"Country / Scope Of Practice"}
                  formik={formik}
                />
              </Col>
              <Col>
                <RegionSelect
                  disabled={!hasAccess}
                  country={country}
                  setRegion={setRegion}
                  region={region}
                  keyField={"region"}
                  defaultOptionLabel={
                    formik?.values?.country === "US"
                      ? "Select State"
                      : "Select Province"
                  }
                  label={formik?.values?.country === "US" ? "State" : "Province"}
                  required
                  formik={formik}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  disabled ={!hasAccess}
                  keyField={"city"}
                  label={"City"}
                  formik={formik}
                  placeholder={"City"}
                />
              </Col>
              <Col>
                <Col>
                  <MultiSelect
                    readOnly={!hasAccess}
                    options={regionOptions}
                    keyField={"jurisdiction"}
                    formik={formik}
                    label={"Scope Of Clinical Jurisdictions"}
                    defaultValue={defaultValue}

                    isSelectAll={
                      // defaultValue.length > 0 &&
                      defaultValue.length === regionOptions.length
                    }
                    isSelectAllEnable={true}
                  />
                </Col>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              {hasAccess && <Col>
                <InputFile
                  keyField={"profileImage"}
                  label={"Upload Profile Image"}
                  formik={formik}
                  placeholder={"Upload Profile Image"}
                  setFile={setFile}
                  file={file}
                  imageUrl={userData?.current?.photo?.base64Data}
                />
              </Col>}
                {!hasAccess && <Col>
                <div className="field-wrap">
                  <div >
                      {userData?.current?.photo?.base64Data && <img height={100} width={200} src={userData?.current?.photo?.base64Data} alt="selected file" />}
                  </div>
                </div>
                </Col>}
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                {signatureImage != '' ? <img src={signatureImage} alt="Signature" /> : <>  <img src={userData?.current?.signatureURL?.base64Data} alt="Signature" /></>}
              </Col>
              <Col>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              {hasAccess && <div>
                <button className="btn-wrap" type="button" onClick={openSignatureModal}>
                  Click Here To Set Up Signature
                </button>
              </div>}

              {isSignatureModalOpen && (
                <div className="signature-modal">
                  <div className="signature-modal-content">
                    <span className="close" onClick={closeSignatureModal}>
                      &times;
                    </span>
                    <SignatureCapture onSignatureCapture={handleSignatureCapture} closeSignatureModal={closeSignatureModal} />
                  </div>
                </div>
              )}
            </Row>
            <div className="btn-wrap">
              {hasAccess && 
              <Button type="submit" isLoading={btnLoading}>
                Update Details
              </Button>
              }
              
              <Button onClick={() => navigate("/app/dashboard")} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default UserProfile;
