import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPractitionerList } from "../../api/Individual";
import { failed } from "../../common/Toastify";
import {EncounterFormikObj, statusOptions, encounterTableTitle, encounterOptions,noDataAvailText, isOpenDisclaimer} from "./constant";
import TextInput from "../../common/textfield/TextInput";
import moment from "moment";
import { useFormik } from 'formik'
import TextArea from "../../common/textfield/TextArea";
import { Col, Row, Table } from "react-bootstrap";
import { InputLabel, Skeleton, TableCell, Tooltip } from "@mui/material";
import { Button } from "../../common/Button";
import SelectFiled from "../../common/textfield/SelectFiled";
import DateSelector from "../../common/textfield/DateSelector";
import Select from "react-select";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import './EncounterSearch.css'
import { searchEncounters, getEncounterRecord } from "../api/AppointmentRequests";
import { ViewDateTime } from "../../../utils/DateSupport";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";

const EncounterSearch = () => {
    const navigate = useNavigate();
    const [practitionersUnderOrg, setPractitionersUnderOrg] = useState([]);
    const [practitionerId, setPractitionerId] = useState("");
    const [encounterData, setEncounterData] = useState("");
    const [encounterCount, setEncounterCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPractitioner, setSelectedPractitioner] = useState(null);
    const [page, setPage] = useState(0);
    const [isPagination, setIsPagination] = useState(false)
    const rowsPerPage = 8;
    const userOrganizationId = useSelector(
        (state) => state?.auth?.user?.["organizations"][0].id
    );

    const formik = useFormik({
      ...EncounterFormikObj,
      onSubmit: (values) => {
        setIsLoading(true)
        if (values?.id){
          getEncounterRecord({
            encounterId: values?.id,
          })
          .then((res)=> {
            setEncounterData([res?.data]);
            setEncounterCount(1);
          })
          .catch((res) => {
            failed("No Encounter Found");
            setEncounterData([]);
            setEncounterCount(0);
          })
          .finally(() => setIsLoading(false));
        }else{
          searchEncounters({
            orgId: userOrganizationId,
            start: moment(values?.start).format("YYYY-MM-DD"),
            end: moment(values?.end).format("YYYY-MM-DD"), 
            type: values?.isOpen, 
            encounterId: values?.id !== '' ? values?.id : null,
            status: values?.status !== '' ? values?.status : null,
            practitionerId: selectedPractitioner?.value !== null ? selectedPractitioner?.value : null,
            page: isPagination ? (page+1) : 1 , // page is 0-indexed so we must add one
            pageSize: rowsPerPage
         })
         .then((res)=> {
          const sortedEncounters = sortByDate(res?.data, values?.isOpen);
          setEncounterData(sortedEncounters);
          setEncounterCount(res?.count.Count);
         })
         .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
         .finally(() => setIsLoading(false));
        }  
      }
    })

    const capitalizeWords = (str) => {
      if(str){
        return str
        .toLowerCase()
        .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
      }else{
        return ''
      }
      
    };
    
    const practitionerOptions = practitionersUnderOrg.map((practitioner) => ({
        value: practitioner.id,
        label: capitalizeWords(practitioner.name?.[0]?.text),
        data: practitioner,
    }));

    useEffect(()=>{
      formik.setFieldValue("isOpen", "closed");
    },[])

    useEffect(() => {
        getPractitionerList(userOrganizationId)
          .then((res) => {
            if (res.status === true) {
              setPractitionersUnderOrg(res?.result);
            }
          })
          .catch((res) => {
            failed(
              res?.response?.data?.message ||
                res?.response?.data?.error ||
                res.message
            );
          });
    }, []);

    const handlePractitionerChange = (event) => {
      setSelectedPractitioner(event);
    }
    const handleView = (patientId, patientName) => {
      const baseUrl = window.location.origin;
      const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
      window.open(url, "_blank");
    };

    const handlePreviewEncounterClick = (encounterId) => {
      navigate("/app/encounter-details", {
        state: { encounterId: encounterId, notPatientDashboard: true },
    })
      
    };

    const handleReset = () =>{
      formik.resetForm();
      formik.setFieldValue("isOpen", "closed");
      setSelectedPractitioner(null)
    }

    const sortByDate = (encounters, type) => {
      return encounters.sort((a, b) => {
        const dateA = new Date(a?.period?.start);
        const dateB = new Date(b?.period?.start);
        const dateC = new Date(a?.period?.end);
        const dateD = new Date(b?.period?.end);
        if (type === "open") {
          return dateB - dateA;
        } else {
          return dateD - dateC;
        }
      });
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();
      setPage(0);
      setIsPagination(false);
      formik.handleSubmit();
    }

    return(
      <>
      <form
        className="common-form border-fields encounterSearch"
        onSubmit={handleFormSubmit}
        style={{margin:"20px"}}
      >
        <Row>
        <Col>
          <TextInput
            keyField={"id"}
            label={"Id"}
            formik={formik}
            placeholder={"Encounter Id"}
            hideRequired={true}
          />
        </Col>
        {formik.values.id  === "" &&  <>
        <Col>
          <SelectFiled
            keyField={"status"}
            label={"Status"}
            formik={formik}
            options={statusOptions}
            hideRequired={true}
          />
        </Col>
        <Col>
          <InputLabel
            id="encounterSearchLabel"
          >
            {"Select Practitioner"}
          </InputLabel>
          <Select
            onChange={handlePractitionerChange}             
            isMulti={false}
            name="practitioner-filter"
            value={selectedPractitioner}
            options={practitionerOptions}
            className="search-select"
            classNamePrefix="select"
            isClearable={true}
            isLoading={practitionerOptions?.length > 0 ? false : true}
          />
        </Col>
        <Col>
          <DateSelector
            keyField={"start"}
            label={"Start Date"}
            formik={formik}
            hasValue={moment()}
            placeholder={"Start Date"} 
            onDateChange={(date) => {
              formik.setFieldValue('end', moment(date).add(2, 'days').format("MM/DD/YYYY"));
            }}
          />
        </Col>
        <Col>
          <DateSelector
            keyField={"end"}
            label={"End Date"}
            formik={formik}
            minDate={moment(formik.values.start)}
            maxDate={moment(formik.values.start).add(7, "days")}
            hasValue={moment()}
            placeholder={"End Date"}
          />
        </Col>
        <Col>
          <SelectFiled
            keyField={"isOpen"}
            label={"Start Date/End Date"}
            formik={formik}
            options={encounterOptions}
            tooltip={true}
            tooltipText={isOpenDisclaimer}
          />
        </Col></>} 
        <Col md={3}>
          <div className="btn-wrap">
              <Button type="submit" isLoading={isLoading}>
                <SearchOutlinedIcon/> Search
              </Button>
              <Button onClick={handleReset} style={{marginLeft: "20px", marginRight: "0px"}}variant="secondary" title="Clear">
                <ClearIcon/> Clear
              </Button>
          </div>
        </Col>
      </Row>
      </form>

<div className="table-wrap" style={{margin:"20px"}}>
<Table responsive>
    <thead>
        <tr>
            {encounterTableTitle?.map(title => <th key={title} >{title}</th>)}
        </tr>
    </thead>
    <tbody>
        {isLoading
            ? [1, 2, 3, 4, 5, 6].map(val => (
                <tr key={val}>
                    {encounterTableTitle?.map(index => <td key={index}>
                        <Skeleton animation="wave" />
                    </td>)}
                </tr>
            ))
            : encounterData?.length > 0
                ? encounterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(({ id, period, participant, subject, status}) => {
                    // let EncData = encounterData?.find((data) => data?.id == id);
                    let patientId = subject?.reference ? subject?.reference?.split("/")[1] : ''
                    let patientName = subject?.display || null;
                    let practitioners = participant?.filter(participant => participant.individual && participant.individual.type === "Practitioner") || ""
                    let lastPractionerId = practitioners?.[practitioners.length -1]?.individual?.reference?.split("/")[1] || ""               
                    let practitionersNameList = practitioners?.map(participant => capitalizeWords(participant?.individual?.display))
                                                .reverse()
                                                .join(', ');
                    return (
                        <tr key={id}>
                            <td> <div style={{ cursor: 'pointer', textDecoration: "underline" }} onClick={() => handlePreviewEncounterClick(id) }>{id}</div></td>
                            <td>{patientName ? 
                              <div style={{ cursor: 'pointer', textDecoration: "underline" }} onClick={() => handleView(patientId,patientName) }>
                                {patientName}</div>
                                 : "-" 
                            }</td>
                            <td>{practitionersNameList || '-'}</td>
                            <td>{period?.start ? ViewDateTime(period?.start) : '-'}</td>
                            <td>{period?.end? ViewDateTime(period?.end) : '-' }</td>
                            <td>{status}</td>
                            {/* <td>
                                <div className="action-wrap" style={{justifyContent: "start"}}>
                                    <div onClick={() => navigate("/app/encounter-details", { state: { encounterId: id, patientId: patientId, practionerData, practitionerId: participant?.[0]?.individual?.reference?.split("/")?.[1], from: "pastEncounter" } })} style={{ cursor: "pointer" }}>
                                    <Tooltip title={"View"}>
                                        <Link
                                            to=""
                                            variant="primary"
                                            className="view-btn"
                                        >
                                            <img src={ViewIcon} alt="View" />
                                        </Link>
                                    </Tooltip>
                                    </div>
                                </div>
                            </td> */}
                        </tr>
                    )
                })
                : <tr>
                    <TableCell colSpan={encounterTableTitle.length} style={{
                        textAlign: "center"
                    }}>
                        {noDataAvailText}
                    </TableCell>
                </tr>}
    </tbody>
</Table>
{
    encounterData?.length > 0 ? <div>
        <CustomPagination tableData={encounterData} totalRows={encounterCount} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)}  />
    </div> : null
}
</div>
</>
      
    )
}

export default EncounterSearch;