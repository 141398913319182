import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { Button } from '../Button'

const ConfirmMsg = ({
    open,
    title,
    message = "Are you sure you would like to delete ",
    entryName = null,
    onConfirm,
    onDismiss,
    actionBtnTitle = "yes",
    cancelBtnTitle = null,
    showCancel = true,
}) => {
    return (
        <Dialog open={open} onClose={onDismiss}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message} {entryName ? <b style={{ textTransform: "capitalize" }}>{entryName}?</b> : ""}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {showCancel && < Button onClick={onDismiss} variant="secondary">{cancelBtnTitle || "Cancel"}</Button>}
                <Button onClick={onConfirm}>
                    {actionBtnTitle}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmMsg