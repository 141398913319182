import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Button } from "../../common/Button";
import { FilePresentOutlined, VideocamOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { AttachementModal } from "./AttachementModal";
import { getSingleUser } from "../../api/Individual";
import { failed } from '../../common/Toastify';
import { useSelector } from 'react-redux';


const QueueModal = ({ show, onHide, record, handleConnect }) => {
    const patientName = record?.subject?.display;
    const arrivalTime = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }).format(new Date(record.createdAt));
    const attachment = record.document;
    const note = record.note;
    const patientLocation = record.patientLocation;
    const priority = record.priority;
    const reason = record.reason;
    const patientId = record.subject.reference.split("/")[1]
    const id = record.id;
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [patientDetails, setPatientDetails] = useState({});
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const handleConnectClick = () => {
        handleConnect(record);
        onHide();
    };

    const handleShowFile = async (data) => {
        setShowAttachmentModal(true)
        setAttachmentUrl(data?.url)
    }

    useEffect(() => {
        if (patientId) {
            getSingleUser({
                id: patientId,
                type: 'Patient',
                userOrgId: adminOrgs?.[0]?.id
            })
                .then((res) => setPatientDetails(res.data))
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }

    }, [patientId]);
    return (
        <section>
            {showAttachmentModal ? <AttachementModal modalShow={showAttachmentModal} url={attachmentUrl} handleShow={() => setShowAttachmentModal(false)} /> : null}
            <Modal show={show} onHide={onHide} size="md" centered className='custom-dialog'>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                        {patientName}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <div><b>Arrival Time</b><br />{arrivalTime}</div>
                        </Col>
                        <Col>
                            <div><b>Attachments</b><br />{Array.isArray(attachment) && attachment.length > 0 ? (
                                attachment.map((pdfUrl, index) => (
                                    <Tooltip key={index} title={"View"}>
                                        <Link
                                            onClick={() => handleShowFile(pdfUrl)}
                                            variant="primary"
                                            className="view-btn"
                                        >
                                            <FilePresentOutlined />
                                        </Link>
                                    </Tooltip>
                                ))
                            ) : attachment?.url ? (
                                <Tooltip title={"View"}>
                                    <Link
                                        onClick={() => handleShowFile(attachment.url)}
                                        variant="primary"
                                        className="view-btn"
                                    >
                                        <FilePresentOutlined />
                                    </Link>
                                </Tooltip>
                            ) : (
                                <div>-</div>
                            )}
                            </div>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col><div><b>Reason</b><br />{reason || "-"}</div></Col>
                        <Col><div><b>Encounter ID</b><br />{id || "-"}</div></Col>
                    </Row>

                    <Row className='my-3'>
                        <Col><div><b>Patient Location</b><br />{patientLocation || "-"}</div></Col>
                        <Col><div><b>Priority</b><br />{priority || "-"}</div></Col>
                    </Row>

                    <Row className='my-3'>
                        <Col><div><b>Gender</b><br />{patientDetails.gender || "-"}</div></Col>
                        <Col><div><b>Date of Birth</b><br />{patientDetails.birthDate || "-"}</div></Col>
                    </Row>

                    <Row className='my-3'>
                        <Col><div><b>Note</b><br />{note || "-"}</div></Col>
                        <Col><div><b>Managing Organization</b><br />{patientDetails?.managingOrganization?.[0]?.display || "-"}</div></Col>
                    </Row>

                    <Row className="my-3">
                        <Col><div><b>Address</b><br />{patientDetails?.address?.[0]?.line?.[0] || "-"}</div></Col>
                        <Col><div><b>Postal Code</b><br />{patientDetails?.address?.[0]?.postalCode || "-"}</div></Col>
                    </Row>

                    <Row >
                        <Col><div><b>Province</b><br />{patientDetails?.address?.[0]?.state?.split("-")[1] || "-"}</div></Col>
                        <Col><div><b>City</b><br />{patientDetails?.address?.[0]?.city || "-"}</div></Col>
                    </Row>

                    <Row className="my-3">
                        <Col><div><b>Assigned Practitioner</b><br />{patientDetails?.generalPractitioner?.[0]?.display || "-"}</div></Col>
                        <Col><div><b>Contact</b><br />{patientDetails?.telecom?.[0]?.value || "-"}</div></Col>
                    </Row>

                    <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around", marginTop: "1.5rem" }}>
                        <Button variant="primary" className="success-btn" onClick={() => handleConnectClick(id)} startIcon={<VideocamOutlined style={{ color: "white" }} />}>Connect</Button></div>



                </Modal.Body>
            </Modal>
        </section>
    )
}


export default QueueModal;