import React, { useState } from 'react'
import FullCalendarComp from '../../common/textfield/FullCalendar'
import moment from 'moment'
import { intialData } from './constants'
import { useGetPractScheduleByDate } from '../../../hooks/ReactQueryHooks/useGetPractScheduleByDate'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDaysBetweenDates } from '../../common/functions'
import { ViewScheduleDayModal } from './ViewScheduleDayModal'
import { Button } from '../../common/Button'
import { decryptData } from '../../EncryptDecrypt'

const CalendarView = () => {
    const navigate = useNavigate();
    const [scheduleDaysCategory, setScheduleDaysCategory] = useState(JSON.parse(JSON.stringify(intialData)));
    const [showShedule, setShowShedule] = useState(false);
    const [recentSchedule, setRecentSchedule] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const location = useLocation();
    const logedInUserData = useSelector((state) => state?.auth?.user);
    const userID = location?.state?.data ? location?.state?.data?.id : logedInUserData?.["custom:unique_id"];

    useGetPractScheduleByDate({
        onSuccess: (data) => {
            data?.data && currentRecentSchedule(data?.data)
        },
        practId: userID,
        isGetAll: true,
    })

    const setDateAndSelectedSchedule = (info) => {
        let date = info.startStr; // YYYY-MM-DD format
        let dateParts = date.split("-");
        let id = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
        
        let event = info.view.calendar.getEventById(id);
        if (event?.extendedProps?.scheduleId) {
            // if there is an event on that day, that means there is a schedule, so you can edit the schedule on this date
            location.date = `${date}`;
            setSelectedDate(`${date}`);
        }
        else {
            location.date = null;
            setSelectedDate(null);
        }
    }

    const currentRecentSchedule = (data) => {
        setRecentSchedule(true)
        const colors = ['rgb(0, 93, 168)', 'rgb(0, 71, 171)', 'rgb(0, 0, 139)', 'rgb(111, 143, 175)', 'rgb(25, 25, 112)', '	rgb(96, 130, 182)', 'rgb(100, 149, 237)'];
        let scheduleColors = {};
        const categoryUpdate = scheduleDaysCategory
        data?.forEach((schedule) => {       
            scheduleColors[schedule.id] = colors[Math.floor(Math.random() * (colors.length))];    
            schedule?.serviceCategory?.map((categoryData) => {
                const day = moment(categoryData?.date).format("dddd")
                categoryUpdate?.[day]?.push(categoryData?.id)
                return categoryData
            }
        )})
        setScheduleDaysCategory(categoryUpdate)
        const tempEventsList = data?.map((schedule) => getDaysBetweenDates(moment(schedule?.planningHorizon?.start?.split("T")[0]), moment(schedule?.planningHorizon?.end?.split("T")[0]))
            .map((appointmentData) => ({
                id: appointmentData,
                scheduleId: schedule.id,
                title: appointmentData,
                start: moment(appointmentData).format("YYYY-MM-DD"),
                end: moment(appointmentData).format("YYYY-MM-DD"),
                backgroundColor: scheduleColors[schedule.id],
                date: moment(appointmentData).format("YYYY-MM-DD")
            }))).flat()
        setEventsList(tempEventsList)
    }

    return (
        <section className="common-listing">
            {showShedule ? <ViewScheduleDayModal modalShow={showShedule} handleShow={() => setShowShedule(false)} userID={userID} /> : null}

            <div className="heading-wrap h-change mb-4">
                <h1>{location?.state?.data
                    ? <>Schedule of <span style={{ textTransform: 'capitalize' }}>{decryptData(location?.state?.data?.name?.[0]?.text)}</span></>
                    : "My Schedule"}</h1>
                <div className="right-wrap">
                    {recentSchedule && selectedDate
                        ? <Button disabled={selectedDate == null}  onClick={() => navigate("/app/edit-schedule", {state: { ...location?.state, date: location?.date }})} className="custom-btn" style={{ width: 'auto' }}>Edit</Button>
                        : null}
                    <Button onClick={() => navigate("/app/create-schedule", { state: location?.state })} className="custom-btn" style={{ width: 'auto' }}>Create</Button>
                </div>
            </div>
            <FullCalendarComp eventsList={eventsList}
                selectable={true}
                eventDisplay="block"
                eventClick={(e) => setShowShedule(e?.event?.id)}
                select={(e) => setDateAndSelectedSchedule(e)}
            />
        </section>
    )
}

export default CalendarView