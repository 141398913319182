import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { noDataAvailText, medicationRequestTitle } from "../Constants";
import moment from "moment";
import { Link, Skeleton, TableCell, Tooltip } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import ViewIcon from "../../../../assets/images/view.png";
import EditIcon from "../../../../assets/images/edit.png";
import { useNavigate } from "react-router";
import EditMedicationRequest from "../../MedicationRequest/EditMedicationRequest";
import { useMedicationRequestQuery } from "../../../../hooks/ReactQueryHooks/useMedicationRequestQuery";
import PdfServiceRequestModal from "../../ServiceRequest/PdfServiceRequestModal";
import ServiceLocationModal from "../../ServiceRequest/ServiceLocationModal";
import RefreshIcon from '@mui/icons-material/Refresh';
import { getPatientData } from "../../../api/Individual";
import { failed } from "../../../common/Toastify";
import { ViewDateTime } from "../../../../utils/DateSupport";

export function MedicationRequestList({ patientId, practionerData, orgnization}) {
  const [modalShow, setModalShow] = useState(false);
  const [medicationRequestData, setMedicationRequestData] = useState([]);
  const [medicationRequestModal, setMedicationRequestModal] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const user = useSelector((state) => state?.auth?.user);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
  const navigate = useNavigate()

  const onSuccess = (data) => {
    setMedicationRequestData(data);
  };

  const { isLoading, isFetching, refetch } = useMedicationRequestQuery({ onSuccess, patientId: patientId, refetchOnWindowFocus: false });

  const handleShow = (data) => {
    setModalShow(modalShow ? false : data);
  };

  const showMedicationRequestModal = (data, isReadOnly) => {
    setMedicationRequestModal(modalShow ? false : data);
  };

  useEffect(() => {
    getPatientData(patientId)
        .then((res) => {
            setPatientData(res?.result)
        })
}, [])

  return (
    <>
      {/* {modalShow ? <EditMedicationRequest modalShow={modalShow} handleShow = {handleShow} refetch={refetch} practitionerName= {user?.name?.[0]?.text} patientId={patientId} practitionerId= {user?.["custom:unique_id"]} practionerData={practionerData} /> : null} */}
      {modalShow ? <PdfServiceRequestModal modalShow={modalShow} handleShow={handleShow} refetch={refetch} /> : null}
      {medicationRequestModal ? 
      <ServiceLocationModal
            onHide={() => setMedicationRequestModal(false)}
            modalShow={medicationRequestModal}
            paramsObj={{patientId, fromListing: true}}
            practitionerData={user}
            categoryVar={"prescription"}
            patientData={patientData}
            orgName={adminOrgs?.[0]?.name}
        /> : null}
      <Tab.Pane eventKey="eighth">
        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
                {medicationRequestTitle?.map((title) => (
                  <th
                    key={title}
                    style={{
                      textAlign: title === "Edit" ? "center" : "auto",
                    }}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading || isFetching ? (
                [1, 2, 3, 4, 5, 6].map((val) => (
                  <tr key={val}>
                    {medicationRequestTitle?.map((index) => (
                      <td key={index}>
                        <Skeleton animation="wave" />
                      </td>
                    ))}
                  </tr>
                ))
              ) : medicationRequestData?.length > 0 ? (
                medicationRequestData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data) => {
                    return (
                      <tr key={data?.id}>
                        <td>{ViewDateTime(data?.updatedAt)}</td>
                        <td>{data?.requester?.display.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                        <td>
                           <Tooltip  title="View Encounter Details">
                              <span style={{cursor: 'pointer', color: "#0064a6", textDecoration: "underline" }} 
                              onClick={() => navigate("/app/encounter-details",
                                      { state: { encounterId: data?.encounter?.reference.split("/")?.[1], practionerData, from: "fromMedicationReq" } })}>
                                        {data?.encounter?.reference.split("/")?.[1] || "-"} 
                              </span>
                            </Tooltip>
                        </td>
                        <td
                          style={
                            (data?.status == "active"
                              ? { color: "green" }
                              : null) ||
                            (data?.status == "In Progress"
                              ? { color: "grey" }
                              : null) ||
                            (data?.status == "Cancelled"
                              ? { color: "red" }
                              : null)
                          }
                        >{data?.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}</td>
                        <td>
                          <div className="action-wrap">
                            <Tooltip title={"View"}>
                              <Link
                                to=""
                                variant="primary"
                                className="view-btn"
                                // style={{ opacity: user?.["custom:unique_id"] === data?.requester?.reference.split("/")[1] }}
                                onClick={() => handleShow(data, false)}
                              >
                                <img src={ViewIcon} alt="View PDF" />
                              </Link>
                            </Tooltip>
                            <Tooltip title={"Resend Document"}>
                              <Link
                                to=""
                                variant="primary"
                                className="view-btn"
                                // style={{ opacity: user?.["custom:unique_id"] === data?.requester?.reference.split("/")[1] }}
                                onClick={() => orgnization?.sft?.enabled  && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ? showMedicationRequestModal(data, false) : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}
                              >
                                <RefreshIcon style={{color : 'white'}}/>
                              </Link>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <TableCell
                    colSpan={medicationRequestTitle.length}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {noDataAvailText}
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
          {medicationRequestData?.length > 0 ? (
            <div>
              <CustomPagination
                tableData={medicationRequestData}
                totalRows={medicationRequestData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePage={(pageNo) => setPage(pageNo)}
              />
            </div>
          ) : null}
        </div>
      </Tab.Pane>
    </>
  );
}