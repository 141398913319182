import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { Spinner } from "react-bootstrap";
import { useQuestionQuery } from "../../../hooks/ReactQueryHooks/useQuestionQuery";
import moment from "moment";
import EditQuestion from "./EditQuestion";
import { deleteQuestion } from "../../api/Questionnaire";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { decryptData } from "../../EncryptDecrypt";
import { useSelector } from "react-redux";
import ViewIcon from "../../../assets/images/view.png";
import { useQuestionCategoryQuery } from "../../../hooks/ReactQueryHooks/useQuestionCategoryQuery";

function QuestionsList() {
    const { getConfirmation } = useConfirmDialog();
    const userOrg = useSelector((state) => state?.auth?.user?.["custom:orgId"]);
    //React Query Test
    const onSuccess = (data) => {
        setProgramList(data);
    };
    const { isLoading, refetch, isFetching } = useQuestionQuery({orgId: userOrg, onSuccess });

    // for calling second API  calling - Start
    const onCategorySuccess = (data) => {
        setCategoryList(data);
    };
    const { isLoading: categoryLoading, refetch: categoryRefetch, isFetching: categoryFetching } = useQuestionCategoryQuery({ orgId: userOrg, onSuccess: onCategorySuccess });
    const [categoryList, setCategoryList] = useState([]);
    // END 
    const [programList, setProgramList] = useState([]);
    const [questionDataItem, setQuestionDataItem] = useState([]);
    const [sortBy, setSortBy] = useState("AscName");


    useEffect(() => {
        setQuestionDataItem(
            programList.map((question) => {
                const categoryObj = categoryList.find((category) => category.id === question.category);
                const categoryName = categoryObj ? categoryObj.categoryName : null;
                return {
                    ...question,
                    categoryName: categoryName,
                };
            })
        );
        // Apply sorting
    }, [programList, categoryList, sortBy]);

    const resultDataQuestion = programList.map((question) => {
        const categoryObj = categoryList.find((category) => category.id === question.category);
        const categoryName = categoryObj ? categoryObj.categoryName : null;
        return {
            ...question,
            categoryName: categoryName,
        };
    });

    const [modalShow, setModalShow] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userType?.includes('Super-Admin');
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const handleFilterData = (data) => {
        setFilterData(data);
        setPage(0)
    };

    const handleShow = (data, isReadOnly) => {
        setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteQuestion(id).then(() => {
                setFilterData(filterData.filter(data => data.id !== id));
                success("Question Deleted!");
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    };

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                setFilterData(filterData?.sort((a, b) => (a.question > b.question) ? 1 : ((b.question > a.question) ? -1 : 0)))
                break;
            case "DscName":
                setFilterData(filterData?.sort((a, b) => (a.question < b.question) ? 1 : ((b.question < a.question) ? -1 : 0)))
                break;
            default:
                setFilterData(filterData)
                break;
        }
    }

    return (
        <React.Fragment>
            <section className="common-listing">
                {modalShow ? <EditQuestion modalShow={modalShow} handleShow={handleShow} refreshTable={refetch} isReadOnly={readOnly} /> : null}
                <div className="heading-wrap h-change">
                    <SearchBar page={page} setPage={setPage} list={questionDataItem} filterData={handleFilterData} />
                    <div className="right-wrap ml-auto" style={{ margin: "left" }}>
                        <Button onClick={() => navigate("/app/create-questions")} className="custom-btn" title="Multiple assign rpm" style={{ width: "auto" }}>
                            Create
                        </Button>
                    </div>
                </div>

                <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>

                                {/* <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>Question
                                    {sortBy === "AscName" ? <span>
                                        <ArrowDropUp />
                                    </span> : <span>
                                        <ArrowDropDown />
                                    </span>}
                                </th> */}
                                <th>Question</th>
                                <th>Category</th>
                                <th>Answer Type</th>
                                <th>Mandatory</th>
                                {/* <th>Date created</th>
                                <th>Date last edited</th> */}
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                        {/* <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td> */}
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) : filterData?.length > 0 ? (
                                filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => {
                                    const hasAccess = userType?.includes("Super-Admin") ? true : !!!data?.isDefault;
                                    return (
                                        <tr key={data?.id}>
                                            <td>{data?.question}</td>
                                            <td>{data?.categoryName}</td>
                                            <td>{data?.answerType === 'freeText' ? 'Free Text' : (data?.answerType === 'dropDown' ? "Dropdown" : (data?.answerType === 'checkbox' ? "Checkbox" : (data?.answerType === 'radio' ? "Radio" : '')))}</td>
                                            <td>{data?.required ? "Yes" : "No"}</td>
                                            {/* <td>{moment(data?.createdAt).format("MM/DD/YYYY")}</td>
                                            <td>{moment(data?.updatedAt).format("MM/DD/YYYY")}</td> */}
                                            <td>
                                                <div className="action-wrap">
                                                    {/* <Tooltip title={"Edit"}>
                                                        <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                            <img src={EditIcon} alt="Edit" />
                                                        </Link>
                                                    </Tooltip> */}
                                                    {hasAccess ? (
                                                        <Tooltip title={"Edit"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                                <img src={EditIcon} alt="Edit" />
                                                            </Link>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={"View"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, true)} className="success-btn">
                                                                <img src={ViewIcon} alt="View" />
                                                            </Link>
                                                        </Tooltip>
                                                    )}
                                                    {/* <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => hasAccess && (deleting ? null : handleDelete(data?.id, data?.title))}
                                                            className="delete-btn"
                                                            style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                        >
                                                            {deleting === data?.id ? (
                                                                <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                            ) : (
                                                                <img src={DeleteIcon} alt="Delete" />
                                                            )}
                                                        </Link>
                                                    </Tooltip> */}
                                                    {/* <Tooltip title={"Delete"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => handleDelete(data?.id, data?.title)}
                                                            className="delete-btn"
                                                        >
                                                            {deleting === data?.id ? (
                                                                <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                            ) : (
                                                                <img src={DeleteIcon} alt="Delete" />
                                                            )}
                                                        </Link>
                                                    </Tooltip> */}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
            </section>
        </React.Fragment>
    );
}

export default QuestionsList;
