import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { useSelector } from "react-redux";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
// import { SurveyMessageDataFields, SurveyMessageHeadCells, SurveyQuestionDataFields, SurveyQuestionHeadCells, SurveyThankYouDataFields, SurveyThankYouHeadCells } from "./Constants";
// import { commonDisableAPI } from "../../api/CommonApis";
import { failed, success } from "../../common/Toastify";
import { Skeleton, Switch, TableCell, Tooltip } from "@mui/material";
import { Spinner, Table } from "react-bootstrap";
import EditIcon from "../../../assets/images/edit.png";
import ViewIcon from "../../../assets/images/view.png";
import DeleteIcon from "../../../assets/images/delete.png";
import { EditSurveys } from "./EditSurveys";
import AssignSurvey from "./AssignSurvey";
import { useSurveyQueryData } from "../../../hooks/ReactQueryHooks/useSurveyQueryData";
import SearchBar from "../../common/components/SearchBar";
import { editQuestionnaire } from "../../api/Questionnaire";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import moment from "moment";
import { CopyAll } from "@mui/icons-material";
import CreateCopySurvey from "./CreateCopySurvey";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { deleteQuestionnaire } from "../../api/Questionnaire";
import { getOrganization } from "../../api/Organization";

function SurveysList() {
  //React Query 
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmDialog();
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const [page, setPage] = React.useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [filterData, setFilterData] = React.useState([]);
  const [dataList, setDataList] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [createCopy, setCreateCopy] = useState(false);
  const [assignData, setAssignData] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false)
  const rowsPerPage = 10;
  const totalRows = filterData.length;
  const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const [organizations, setOrganizations] = useState([]);
  const admin = userType?.includes('Super-Admin');
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
  const [orgID, setOrgID] = useState(admin ? "All" : adminOrgs?.[0]?.id);
  const [organizationData, setOrganizationData] = useState(null);
  const permissions = useSelector((state) => state?.auth?.user?.permissions);

  const { isLoading, refetch, isFetching, data: programList } = useSurveyQueryData({ orgID });

  const handleShow = (data, isReadOnly) => {
    setModalShow(modalShow ? false : data);
    setReadOnly(isReadOnly);
  };

  const handleDelete = async (id, entryName, purposeData) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Delete",
    });
    if (confirmed) {
      setDeleting(id);
      let payload = {
        "status": "retired",
        "purpose": purposeData,
        "type": "survey"
      }
      deleteQuestionnaire(id, payload).then(() => {
        setFilterData(filterData.filter(data => data.id !== id));
        success("Survey Deleted!");
      }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
    }
  };

  const handleStatus = async (event, status, surveyId, purpose) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName: status === "active" ? "Inactive" : "Active",
      actionBtnTitle: "Yes",
      message: "Are you sure you want to update the status to"
    });
    if (confirmed) {
      setIsListLoading(true)
      editQuestionnaire(surveyId, {
        status: status === "active" ? "draft" : "active",
        purpose: purpose,
        type: "questionnaire"
      }).then((res) => {
        success(res.message);
        setIsListLoading(false)
        refetch()
      }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
        .finally(() => setIsListLoading(false));
    }
  }
  const formik = useFormik({
    initialValues: {
      org: admin ? "All" : adminOrgs?.[0]?.id,
      type: "All"
    },
  });
  useOrgQuery({
    onSuccess: (data) => {
      setOrganizations(data?.map((org) => ({ name: org?.name, value: org?.id })))
    },
    refetchOnWindowFocus: false,
  })
  const handleOrgChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("org", value);
    if (admin) {
      if (value == "All") {
        setOrgID("All");
      } else {
        setOrgID(value);
      }
    } else {
      setOrgID(adminOrgs?.[0]?.id);
    }
  };

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await getOrganization(orgID);
        setOrganizationData(response.data);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrganizationData();
  }, [isLoading, refetch, isFetching, assignData]);
  let assignArr = []
  if (organizationData?.assignedQuestionnaire) { assignArr = [organizationData?.assignedQuestionnaire["survey-appointment"]] }

  const startIndex = page * rowsPerPage;
  return (
    <>
      {isListLoading ? <FullPageSpinner /> : null}
      <section className="common-listing">
        {modalShow ? <EditSurveys modalShow={modalShow} refreshTable={refetch} isReadOnly={readOnly} handleShow={handleShow} selectedSurvey={selectedSurvey} /> : null}
        {createCopy ? <CreateCopySurvey modalData={createCopy} handleHide={() => setCreateCopy(false)} refreshTable={refetch} /> : null}
        {assignData ? <AssignSurvey modalData={assignData} handleHide={() => setAssignData(false)} refreshTable={refetch} /> : null}
        <div className="heading-wrap h-change  mb-3">
          <SearchBar
            page={page}
            setPage={setPage}
            list={programList}
            filterData={(data) => setFilterData(data)}
          />
          {admin ?
            <div className="right-wrap ml-auto" style={{ margin: "left", paddingTop: "25px" }}>
              <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                <SelectFiled
                  keyField={"org"}
                  label={false}
                  formik={formik}
                  selectLabel={"Select Organization"}
                  options={admin ? [{ name: "MY RECORDS", value: "All" }, ...organizations] : organizations}
                  onChange={handleOrgChange}
                  required={false}
                  optional={false}
                  readOnly={isLoading}
                />
              </form>
            </div>
            :
            <div className="right-wrap ml-auto" style={{ margin: "left" }}>
              {( permissions.includes("Manage Questionnaire") &&  permissions.includes("Manage Organization") )  &&  ( <Button onClick={() => setAssignData(true)} className="custom-btn" title="Assign Survey" style={{ width: "auto" }}>Assign</Button>)}
            </div>}
          <div className="right-wrap m-0" style={{ margin: 'left' }}>
            <div>
            { permissions.includes("Manage Questionnaire") && ( <Button onClick={() => navigate("/app/create-surveys")} className="custom-btn" title="Create Survey" style={{ width: 'auto' }}>Create</Button>)}
            </div>
          </div>
        </div>
        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Survey Title</th>
                <th> &nbsp; </th>
                <th> &nbsp; </th>
                {/* <th>Status</th>
                <th>Date created</th>
                <th>Date late edited</th> */}
                <th style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                  <tr key={val}>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    {/* <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td> */}
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                ))
              ) : filterData?.length > 0 ? (
                // filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => { 
                //   const hasAccess = userType?.includes("Super-Admin") ? true : !!!data?.isDefault;
                //   const { id, title, status, purpose } = data;            
                filterData.slice(startIndex, startIndex + rowsPerPage).map((data, index) => {
                  const hasAccess = userType?.includes("Super-Admin") ? true : permissions.includes("Manage Questionnaire") ? true : false;
                  const serialNumber = startIndex + index + 1;
                  const { id, title, status, purpose } = data;
                  let resultArray = filterData.filter(item => assignArr.includes(item?.id));
                  const foundObject = resultArray.find(obj => { return obj?.id == data?.id; });

                  return (
                    <tr key={id}>
                      {foundObject != undefined ? <td className="name-text"><b>{serialNumber}</b></td> : <td className="name-text">{serialNumber}</td>}
                      {foundObject != undefined ?
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "17px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title={title}>
                            <span><b>{title}</b></span>
                          </Tooltip>
                        </td> :
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "17px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title={title}>
                            <span>{title}</span>
                          </Tooltip>
                        </td>
                      }
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      {/* <td>
                        {purpose === "survey-appointment"
                          ? "Get Care - Appointment"
                          : "Get Care - Waiting Room"}
                      </td> */}
                      {/* <td>
                        <Switch
                          name="status"
                          checked={status === "active" ? true : false}
                          onChange={(e) =>
                            handleStatus(e, status, id, purpose)
                          }
                          disabled={hasAccess ? false : true}
                        />
                      </td>
                      <td>
                        {moment(data?.createdAt).format("MM/DD/YYYY")}
                      </td>
                      <td>
                        {moment(data?.updatedAt).format("MM/DD/YYYY")}
                      </td> */}
                      <td className="action-wrap-container">
                        <div className="actions-styles action-wrap">
                          {hasAccess ? (
                            <Tooltip title={"Copy Survey"}>
                              <Link
                                to=""
                                variant="primary"
                                onClick={() => setCreateCopy(data)}
                                className="delete-btn"
                              >
                                <CopyAll
                                  style={{
                                    color: "#fff",
                                    width: "60%",
                                  }}
                                />
                              </Link>
                            </Tooltip>
                          ): null }
                          {hasAccess ? (
                            <Tooltip title={"Edit"}>
                              <Link
                                to=""
                                variant="primary"
                                onClick={() =>
                                  handleShow(data, false)
                                }
                                className="success-btn"
                              >
                                <img src={EditIcon} alt="Edit" />
                              </Link>
                            </Tooltip>
                          ) : (
                            <Tooltip title={"View"}>
                              <Link
                                to=""
                                variant="primary"
                                onClick={() => handleShow(data, true)}
                                className="success-btn"
                              >
                                <img src={ViewIcon} alt="View" />
                              </Link>
                            </Tooltip>
                          )}
                          {/* <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                            <Link
                              to=""
                              className="delete-btn"
                              onClick={() => hasAccess && (deleting ? null : handleDelete(data?.id, data?.title,  data?.purpose))}
                              style={{ opacity: hasAccess ? "1" : "0.2" }}
                            >
                              {deleting === id ? (
                                <Spinner
                                  size="lg"
                                  className="text-current"
                                  style={{
                                    color: "#ffff",
                                    background: "transparent",
                                    margin: "0",
                                  }}
                                />
                              ) : (
                                <img src={DeleteIcon} alt="Delete" />
                              )}
                            </Link>
                          </Tooltip> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <TableCell colSpan={"7"} style={{ textAlign: "center" }}>
                    No Data Available
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
      </section >
    </>
  );
}

export default SurveysList;