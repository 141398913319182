// ConfirmationModal.js
import React from 'react';
import { Button } from "../../common/Button";
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ConfirmationModal = ({ show, onConfirm, onCancel, message, data }) => {
  const handleConfirm = async () => {
    if (onConfirm) {
        if (data) {
            await onConfirm(data);
        } else {
            await onConfirm();
        }
}
    onCancel(); // Close the modal after confirming
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onCancel}
      backdrop="static"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message || 'Are you sure you want to proceed?'}</p>
      </Modal.Body>
      <Modal.Footer> 
        <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around"}}>
            <Button variant="secondary" onClick={onCancel} style={{margin: "0"}} > No </Button>
        </div> 
        <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around"}}>
            <Button variant="primary"  onClick={handleConfirm} className="delete-btn" style={{margin: "0"}}>Yes</Button> 
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
