import React, { useEffect, useState } from "react";
import "./header.css"
import FonemedLogo from '../../../assets/images/FoneMed-Logo.jpg';
import DownArrow from '../../../assets/images/down-arrow.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../api/AuthService";
import { userlogin, userlogout } from "../../slice/authService";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { failed, success } from "../Toastify";
import { decryptData } from "../../EncryptDecrypt"
import { Notifications } from "@mui/icons-material";
import { useGetNotificationsQuery } from "../../../hooks/ReactQueryHooks/useGetNotificationsQuery";
import { Badge, Box, Menu, MenuList, Tooltip } from "@mui/material";
import { getSingleUser } from "../../api/Individual";
import { headerTitles } from "./constants";

function Header({ hideBody = false }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const id = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const organizationProfile = useSelector((state) => state?.auth?.user?.organizationLogo)
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const userName = useSelector((state) => state?.auth?.user?.name?.[0]?.text);
    const tokenExpiry = useSelector((state) => state?.auth?.user?.exp);
    const isExpired = Math.round(Date.now() / 1000) >= tokenExpiry;
    const [data, setData] = useState([])
    const [unreadNotifications, setUnreadNotifications] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [profilePhoto, setProfilePhoro] = useState(null)
    const open = Boolean(anchorEl);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const onSuccess = (data) => {
        setData(data?.data)
        setUnreadNotifications(data?.unreadNotifications)
    }

    useGetNotificationsQuery({ onSuccess, callMe: userRoles?.includes('Practitioner') })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Logout = () => {
        logout();
        dispatch(userlogout());
        /* isExpired ? failed("Token has expired, Please login again!", 5000) : */ success("Successfully Logged Out");
        navigate('/login');
    };

    // useEffect(() => {
    //     if (isExpired) {
    //         Logout();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isExpired])

    useEffect(() => {
        const apiBody = {
            id,
            type: "Practitioner",
            userOrgId: adminOrgs?.[0]?.id
        };
        getSingleUser(apiBody)
            .then((res) => {
                setProfilePhoro(res?.data?.photo?.base64Data)
            })
            .catch((res) => {
                console.log("error in image upload", res)
            })
    }, []);

    return (
        <>
            <section className="header-wrap">
                <div className="logo" title="Fonemed A Healthier Tomorrow" onClick={() => navigate("/app/dashboard")}>
                    <img src={FonemedLogo} alt="Fonemed A Healthier Tomorrow" />
                </div>
                {userRoles[0] == "Practitioner" ? <div className="logo1">
                    {organizationProfile ? <img src={organizationProfile} /> : ""}
                </div> : ""}

                {hideBody ? null : <div className="header-body">
                    <div className="left-wrap d-flex align-items-center title">
                        {headerTitles[location.pathname] || ""}
                    </div>
                    <div className="right-wrap">
                        {userRoles?.includes("Practitioner") ? <div>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: "pointer" }}>
                                <Tooltip title="Notifications">
                                    <Badge badgeContent={unreadNotifications} color="primary">
                                        <Notifications
                                            onClick={handleClick}
                                            style={{ color: "#005da8", fontSize: "clamp(1.5rem,3.5vw,2.5rem" }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        />
                                    </Badge>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'hidden',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        width: '80%',
                                        maxWidth: '500px',
                                        padding: "0 1rem",
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {data
                                    ? data?.splice(0, 5)?.map((option) => (
                                        <div key={option?.notificationId}>
                                            <MenuList onClick={handleClose} style={{
                                                display: "inline-block",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "100%"
                                            }}>
                                                {decryptData(option?.message)}
                                            </MenuList>
                                        </div>
                                    ))
                                    : <MenuList onClick={handleClose}>
                                        no notification
                                    </MenuList>
                                }
                                {data ? <MenuList onClick={handleClose}>
                                    <Link to={"/app/user-notifications"}>
                                        View all
                                    </Link>
                                </MenuList> : null}
                            </Menu>
                        </div> : null}
                        <Dropdown className="user-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="user-toggle">
                                {profilePhoto ? (<div className="user-name" style={{ textTransform: "uppercase" }}>
                                    <img src={profilePhoto} alt="" />
                                </div>) :
                                    (<div className="user-name" style={{ textTransform: "uppercase" }}>
                                        {`${decryptData(userName)?.split(" ")[0]?.charAt(0)}${decryptData(userName)?.split(" ")[1] ? decryptData(userName)?.split(" ")[1]?.charAt(0) : ""}`}
                                    </div>)}
                                <img src={DownArrow} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {userRoles?.includes('Practitioner') ? <Dropdown.Item onClick={() => navigate("/app/user-profile")}>Profile</Dropdown.Item> : null}
                                {userRoles?.includes('Practitioner') ? <Dropdown.Item onClick={() => navigate("/app/schedule")}>Schedule Management</Dropdown.Item> : null}
                                <Dropdown.Item onClick={() => navigate("/app/change-password")}>Change Password</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate("/app/mfa-settings")}>MFA Settings</Dropdown.Item>
                                <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>}
            </section>
        </>
    );
}

export default Header;