import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { usePatientsBySOPQuery } from "../../../hooks/ReactQueryHooks/usePatientsBySOPQuery";
import { useScheduleCategoryQuery } from "../../../hooks/ReactQueryHooks/useScheduleCategoryQuery";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { CircularProgress, Skeleton } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import moment from "moment";
import { bookAppointment, checkSchedule, getSlots, rescheduleAppointment, getlistPractitioners } from "../api/AppointmentRequests";
import { getScheduleSlots, getSlotById, updateScheduleSlot} from "../../api/Schedule";
import { failed, success } from "../../common/Toastify";
import "../AppointmentRequests/style.css"
import { generatePass } from "../AppointmentRequests/Constants";
import { useSelector } from "react-redux";
import { getSignature } from "../../Zoom/functions";
import { updateAppointmentRequest } from '../api/AppointmentRequests';
import { Form } from "react-bootstrap";

const ReschduleAppointment = ({ modalShow, handleShow }) => {
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [slotUnavailable, setSlotUnavailable] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [serviceCategory, setServiceCategory] = useState([]);
    const [categoriesByDay, setCategoriesByDay] = useState({});
    const [reservedCategories, setReservedCategories] = useState ({})
    const [practitionersList, setpractitionersList] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const userOrg = useSelector((state) => state?.auth?.user?.organizations)
    const userOrgId = userOrg?.[0]?.id;
    const preferredChannelType = modalShow?.intakeQuestions?.preferredChannel === "phone" || modalShow?.intakeQuestions?.preferredChannel === "Phone";
   /* 
    useScheduleCategoryQuery({
        onSuccess: (data) => setServiceCategory(data.map((item) => ({
            name: `${item?.name} (${item?.timeSlot?.value} min)`,
            value: item.id,
            id: item.id,
            timeSlot: item.timeSlot,
        }))),
        refetchOnWindowFocus: false
    })
    */ 
    const formik = useFormik({
        initialValues: {
            title: "",
            patient: "",
            // description: "",
            date: "",
            serviceCategory: "",
            availableSlot: "",
            preferredChannel: modalShow?.intakeQuestions?.preferredChannel,
            returnTelephoneNumber: modalShow?.intakeQuestions?.callerNumber,
            returnTelephoneNumberExt: modalShow?.intakeQuestions?.callerNumberExt,
            practitionerPreference: modalShow?.practitionerId ? "select practitioner" : "",
            practitioner: modalShow?.practitionerId,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required Reason for visit field"),
            patient: Yup.string().required("Required Patient field"),
            // description: Yup.string().required("Required Description field"),
            date: Yup.string().required("Required Date field"),
            serviceCategory: Yup.string().required("Required Appointment type field"),
            practitioner: Yup.mixed().when(["practitionerPreference"], {
                is: (practitionerPreference) => practitionerPreference !== "no preference",
                then: Yup.string().required("Required Practitioner field."),
                otherwise: Yup.mixed().notRequired(),
            }),
            availableSlot: Yup.object().required("Required slots field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let slot = null;

            // let start =`${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.start)}:00Z`;
            // let localStartTime = moment(start).format("HH:mm")
            // let localStart = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localStartTime)}`;
            // let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            // let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.end)}:00Z`;
            // let localEndTime = moment(end).format("HH:mm")
            // let localEnd = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localEndTime)}`;
            // let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
            let utcStart = values?.availableSlot?.start;
            let utcEnd = values?.availableSlot?.end;
            let selectedServiceCategory = values?.serviceCategory;
            let intakeQuestions =modalShow?.intakeQuestions;
            intakeQuestions["preferredChannel"] = values?.preferredChannel;


            // if(selectedServiceCategory.endsWith("busyReserved")){
            //     selectedServiceCategory = selectedServiceCategory.split("-")?.[0];
            // }
            
            // this filter returns an object as a REFERENCE, so you need to structuredClone the resulting value if there is one
            selectedServiceCategory = serviceCategory?.filter((categoryData) => categoryData.value === selectedServiceCategory)?.[0]
            if(selectedServiceCategory?.value?.endsWith("busyReserved")) {
                selectedServiceCategory = structuredClone(selectedServiceCategory);
                selectedServiceCategory.id = selectedServiceCategory?.value?.split("-")[0];
                selectedServiceCategory.name = selectedServiceCategory.name.substring(12).trim();
            }
            let serviceCategoryPayload = [
                {
                  coding: [
                    {
                      system: "http://florizel.com/AppointmentServiceCategory",
                      code: selectedServiceCategory?.id,
                      display: selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name
                    }
                  ],
                  text: `Appointment for ${selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name}`
                }
            ]
            /* if(values?.availableSlot.status === "busy-reserved"){
                utcStart= values?.availableSlot?.start
                utcEnd = values?.availableSlot?.end
                slot = values?.availableSlot;
            }
            else */ if(formik?.values?.practitionerPreference === "no preference") {
                values.practitioner = values?.availableSlot?.actorId;
                values.scheduleId = values?.availableSlot?.scheduleId;
            }
            else values.scheduleId = values?.availableSlot?.scheduleId;
            
            const requestedPeriod = [{start: utcStart, end: utcEnd}];
            let keepOldSlot = false;
            getSlotById(modalShow?.slotId)
            .then((response) =>{
            let data = response?.data?.[0]
            // if(data?.serviceCategory?.[0]?.id){
            //     keepOldSlot = true;
            // }
            rescheduleAppointment({serviceCategory: serviceCategoryPayload, intakeQuestions, keepOldSlot: false, slot,  appointmentId: modalShow?.id,  requestedPeriod , practitionerId:  values?.practitioner, scheduleId: values?.scheduleId, description: values?.title })
                .then((res) => {
                    success("Appointment Rescheduled");
                    if(keepOldSlot){
                        updateScheduleSlot({
                            slotId : data?.id,
                            start: data?.start,
                            end: data?.end,
                            status: "busy-reserved",
                            serviceCategories: data?.serviceCategory
                        })
                        .catch(()=>{})
                    }
                    handleShow();

                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            })
            
            .catch(() => {})
        },
    });
    
    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("title",  modalShow.description);
            formik.setFieldValue("patient", modalShow.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display) ;
            formik.setFieldValue("description", modalShow.comment || "");
            formik.setFieldValue("returnTelephoneNumber", modalShow?.intakeQuestions?.callerNumber || "");
            formik.setFieldValue("returnTelephoneNumberExt", modalShow?.intakeQuestions?.callerNumberExt || "");
            formik.setFieldValue("practitioner", modalShow?.practitionerId || "");
            formik.setFieldValue("preferredChannel", modalShow?.intakeQuestions?.preferredChannel || "");
            formik.setFieldValue("serviceCategory", modalShow?.serviceCategory[0]?.coding[0]?.code);            
        }
    }, [modalShow])

    useEffect(() => {
        if (modalShow?.practitionerId) {
            getlistPractitioners({ orgId: modalShow?.orgId, scopeOfPractice: modalShow?.state })
                .then((res) => {
                    const practitioners = res.result.map(practitioner => ({
                        value: practitioner.id,
                        name: practitioner.name[0].text.charAt(0).toUpperCase() + practitioner.name[0].text.slice(1).toLowerCase()
                    })); 
                    setpractitionersList(practitioners);  
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }
    }, [modalShow])
   
    
    useEffect(() => {
        let startDate = (formik?.values?.date?.length > 0) ? moment(formik?.values?.date) : moment();
        startDate = moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        // if (formik?.values?.date) {
        let start = startDate.split("T")?.[0];
        if(formik?.values?.practitionerPreference &&
            ((formik?.values?.practitionerPreference === "select practitioner" && formik?.values?.practitioner)
               || formik?.values?.practitionerPreference === "no preference" )){
                checkSchedule({ actorId: formik?.values?.practitioner ? formik.values.practitioner:  user["custom:unique_id"], date: startDate, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : null }) 
            .then((res) => { 
                if(!res?.data) {
                   failed(res?.message);
                   setServiceCategory([]);
                   setCategoriesByDay({});
                   return;
                }
                
                let categories = [];
                if (!Array.isArray(res.data)) res.data = [res.data];
                let tempByDay = {};
                res.data.forEach(schedule => {
                
                    schedule.serviceCategory.forEach(category => {
                        let key = category?.date?.split('T')?.[0];
                        if(moment(key).isSameOrAfter(moment(start)) && 
                            !categories.some(item => item.value === category.id)) {
                            categories.push({...category, value: category?.id});                                
                        }
                        if(moment(key).isSameOrAfter(moment(start))) {
                            if(tempByDay[key]) {
                                if(!tempByDay[key].some(id => id === category.id )) 
                                    tempByDay[key].push(category.id)
                                }
                            else tempByDay[key] = [category.id];   
                        }
                    })
                
            })
            setCategoriesByDay(Object.assign(categoriesByDay, tempByDay))
            let reservedSlots ={}
            let newData = [];
            res.data.forEach(schedule => 
                getScheduleSlots(schedule.id, "busy-reserved")
                .then((resp) => {
                    resp.data?.filter((slot)=> moment(slot?.start).isSameOrAfter(moment(start)))
                    ?.map((slot) => {
                        slot.serviceCategory?.forEach(cat => {
                            let serviceCategoryId = cat.id;
                            if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                                reservedSlots[serviceCategoryId].push({
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "serviceCategory": [cat],
                                    "scheduleId": slot?.scheduleId,
                                    "actorId": schedule.actorId,
                                    "status": "busy-reserved"
                                });
                            }    
                            else {
                                reservedSlots[serviceCategoryId] = [{
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "serviceCategory": [cat],
                                    "scheduleId": slot?.scheduleId,
                                    "actorId": schedule.actorId,
                                    "status": "busy-reserved"
                                }];
                            }
                        })
                        // let serviceCategoryId = slot?.serviceCategory?.[0]?.id

                        // if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                        //     reservedSlots[serviceCategoryId].push({
                        //         "start": slot?.start,
                        //         "end": slot?.end,
                        //         "id": slot?.id,
                        //         "serviceCategory": slot.serviceCategory,
                        //         "status": "busy-reserved"
                        //     });
                        // }    
                        // else {
                        //     reservedSlots[serviceCategoryId] = [{
                        //         "start": slot?.start,
                        //         "end": slot?.end,
                        //         "id": slot?.id,
                        //         "serviceCategory": slot.serviceCategory,
                        //         "status": "busy-reserved"
                        //     }];
                        // }
                    })
                    if(Array.isArray(reservedSlots))
                        reservedSlots = removePastSlots(reservedSlots)
                    else {
                        Object.keys(reservedSlots).forEach(key => {
                            reservedSlots[key] = removePastSlots(reservedSlots[key])
                        })
                    }
                    Object.keys(reservedSlots).forEach(key => {
                        if(reservedSlots[key].length > 0 
                            && reservedSlots[key][0]?.serviceCategory?.[0]?.id
                            && !newData.some(item => item.value === `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`))
                            newData.push({
                                name: `Reserved for ${reservedSlots[key]?.[0]?.serviceCategory[0]?.name}`,
                                value: `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`,
                                status: "busy-reserved"
                            });                                
                        });
                        setReservedCategories(reservedSlots);
                        setServiceCategory([...categories, ...newData])
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        setServiceCategory([]);
                        setCategoriesByDay({});
                    })
                );
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                setServiceCategory([]);
                setCategoriesByDay({});
            });}
    }, [modalShow?.id, formik?.values?.practitioner, formik?.values?.practitionerPreference])


    const loadSlotData = (selectedDate, selectedType) => {
        setSlotsLoading(true);
        let date = (selectedDate) ? selectedDate : formik?.values?.date;
        let type = (selectedType) ? selectedType : formik?.values?.serviceCategory;
        date = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"
        if(type?.endsWith("busyReserved")){
            let currentTime = moment(date);
            let slots = reservedCategories[type.split("-")[0]];
            slots = slots?.filter(slot => moment(slot.start).isAfter(currentTime) && moment(slot.start).isSame(currentTime, "day"))
            slots = sortedSlots(slots);
            if (slots.length < 1) {
                setSlotUnavailable(true)
            }
            setAvailableSlots(slots)
            setSlotsLoading(false)
        } else if(type) {
            /** retrieve all slots here */
            let params = { actorId: formik?.values?.practitioner || user["custom:unique_id"], date, serviceCategoryId: type, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : userOrgId }
            if(formik?.values?.practitionerPreference === "no preference") {
                if(modalShow?.patientLocation)
                    params.scopeOfPractice = modalShow?.patientLocation
                else{
                    let temp = modalShow?.intakeQuestions?.filter(el => el.tag === "LOCATION")[0].answer.find((val) => val.answer.length > 0).answer;
                    params.scopeOfPractice = temp;
                }
            }
            getSlots(params)
                .then((res) => {
                    // remove early slots
                    let currentSlots = removePastSlots(res?.data?.availableSlots)
                    setAvailableSlots(sortedSlots(currentSlots))
                    if (currentSlots < 1) {
                        setSlotUnavailable(true)
                }
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    setAvailableSlots([])
                }).finally(() => { setSlotsLoading(false) })
        }
        else setSlotsLoading(false);  
    } 

    const handleDateSelection = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        setAvailableSlots([])
        if(isValidDate){
            formik.setFieldValue("date", val.format("YYYY-MM-DD"));
            // load slots here
            loadSlotData(val.format("YYYY-MM-DD"))
        }else{
            formik.setFieldError('date', 'Invalid date format')
        }       
    }    

    const removePastSlots = (slots) => {
        let now = new Date().getTime();
        if(Array.isArray(slots))
            return slots?.filter(slot => new Date(slot.start).getTime() > now);
        else return slots
    }

    const sortedSlots = (data) => {
        return data.sort((a, b) => {
          let atime = (moment(a.start).isValid()) ? `${a.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${a?.start}:00Z`;
          let btime = (moment(b.start).isValid()) ? `${b.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${b?.start}:00Z`;
          let aHr = moment(atime).hour();
          let bHr = moment(btime).hour();
          let aMin = moment(atime).minute();
          let bMin = moment(btime).minute();
          return aHr < bHr || (aHr == bHr && aMin < bMin) ? -1 : 1;
        });
    };

    const sortedObj = data => {
        return data.sort((a,b) => {
            return a.name > b.name ? 1 : -1
        });
    }

    const findUpcoming = (event) => {
        setSlotUnavailable(false);        
        let targetVal = event?.target?.value;
        formik.setFieldValue("serviceCategory", targetVal);
        setAvailableSlots([])
        formik.setFieldValue("date", "");
    }

    const highLightDates = () => {
        setAvailableDays([]);
        let today = moment();
        let toHighlight = [];
        const isBusyReserved = formik.values.serviceCategory.endsWith('busyReserved');
        if (!isBusyReserved) {
            for (const date in categoriesByDay) {
                if (moment(date).isSameOrAfter(today, 'day')) {
                    if (categoriesByDay[date].some(item => item === formik.values.serviceCategory)) {
                        toHighlight.push(date);
                    }
                }
            }
        } else {
            for (const reservedDate in reservedCategories) {
                const reservations = reservedCategories[reservedDate];
                reservations.forEach(reservation => {
                    if (reservation.serviceCategory.some(category => formik.values.serviceCategory.includes(category.id))) {
                        const reservationStart = moment(reservation.start);
                        if (reservationStart.isSameOrAfter(today, 'day')) {
                            toHighlight.push(reservationStart.format('YYYY-MM-DD'));
                        }
                    }
                });
            }
        }
        setAvailableDays(toHighlight);
    }

    const renderDay = (props) => {
        const { selectedDate, day, dayComponent, ...other} = props;
        const today = moment();
        const isAvailable = availableDays.includes(day.format("YYYY-MM-DD"));
        const isSelected = day.isSame(selectedDate, "day");
        const isToday = day.isSame(today, "day");
        const isPast = day.isBefore(today, "day")

        return (
            <PickersDay {...dayComponent} 
                disabled={isPast || !isAvailable}
                style={isAvailable ? {background: "#accfeb"}: {background: "white" }} />
        );
    }

    const handlePractitionerPreferenceChange = (e) => {
        formik.setFieldValue("practitioner", "");
        formik.setFieldValue("serviceCategory", ""); 
        formik.setFieldValue("date", "");
        setServiceCategory([]);
        setAvailableSlots([]);
        setCategoriesByDay([]);
        formik.setFieldValue("practitionerPreference", e.target.id);
    };
    
    const handlePractitionerChange = (event) => {   
        formik.setFieldValue("practitioner", event?.target?.value );
        formik.setFieldValue("serviceCategory", ""); 
        formik.setFieldValue("date", "");
        setServiceCategory([]);
        setAvailableSlots([]);
        setCategoriesByDay([]);
    } 
    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="xl" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Rescheduling Appointment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    
                    <>
                        <Row>
                            <Col>
                                <TextInput keyField={"title"} label={"Reason for visit"} formik={formik} placeholder={"Reason for visit"} />
                            </Col>
                            <Col>
                                <TextInput disabled = {true} keyField={"patient"} label={"Patient Name"} formik={formik} placeholder={"Patient Name"} style={{textTransform: "capitalize"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"practitioner preference ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                {["select practitioner", "no preference"].map((val) => (
                                    <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "45px"}} inline checked={formik.values.practitionerPreference === val} type={"radio"} label={val} id={val} name="practitioner preference"  onChange={handlePractitionerPreferenceChange} />
                                    ))}
                                    {formik?.touched["practitionerPreference"] && formik?.errors["practitionerPreference"] ? (<div className="error-text">{formik?.errors["practitionerPreference"]} </div>) : null}
                            </Col>
                            {formik?.values?.practitionerPreference === "select practitioner" ? (
                                <Col>
                                    <SelectFiled keyField={"practitioner"} label={"Select Practitioner"} formik={formik} options={sortedObj(practitionersList)} onChange={handlePractitionerChange} />
                                </Col>
                            ) : <Col></Col>}
                                
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <SelectFiled keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={sortedObj(serviceCategory)} disabled={!formik.values.practitionerPreference} onChange={findUpcoming} />
                            </Col>
                            <Col>
                                <DateSelectorWithoutFormik disabled={!formik?.values?.serviceCategory} formik={formik} keyField={'date'} label="Date" value={(formik?.values?.date?.length > 0) ? moment(formik?.values?.date) : null} handleChange={(value) => { handleDateSelection(value)}} minDate={moment()} defaultValue={moment()} onMonthChange={highLightDates} onOpen={highLightDates} renderDay={(day, selectedDate, dayComponent) => renderDay({day, selectedDate, dayComponent})} />
                            </Col>
                        </Row>
                        {availableSlots?.length > 0 ?
                                <>
                                    <Row>
                                        <Col>Select Slot</Col>
                                    </Row>
                                    <Row style={{/*  justifyContent: "center", */ rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                        {availableSlots?.map((slot, index) => {
                                        let localTime = moment(slot?.start).format("HH:mm");
                                        
                                        return ( <Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                        })}
                                    </Row>
                                </>
                                : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}
                        <Row>
                            {/* <Col>
                                <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description"} />
                            </Col> */}
                            {modalShow?.intakeQuestions?.preferredChannel && (
                              <Col>
                                <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"preferred channel ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                {["phone", "video/chat"].map((val) => (
                                    <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "65px"}} inline checked={formik.values.preferredChannel === val} type={"radio"} label={val} id={val} name="preferred channel" onChange={e => formik.setFieldValue("preferredChannel", e.target.id)} />
                                ))}
                            </Col>  
                            )}
                            
                            {modalShow?.intakeQuestions?.callerNumber && (
                              <Col>
                                <Row>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumber"} type="phone" label={"Return Telephone Number"} formik={formik} disabled={true} placeholder={"Return Telephone Number"} required={true} />
                                    </Col>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumberExt"} label={"Ext."} formik={formik} disabled={true} placeholder={"Ext."} required={false} />
                                    </Col>
                                </Row>
                            </Col>  
                            )}
                        </Row>
                    </>
                    
                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                handleShow();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Done
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ReschduleAppointment;
