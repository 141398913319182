import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { RemoveCircleOutline, Add, Remove } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Col, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { FormikObjOfCreateUnavailibilty, FormikObjOfEditUnavailibilty, unavailabilityStatusTypes } from './constants';
import { iconStyle } from "../Questionnaire/Constant";
import { failed, success, warn } from '../../common/Toastify';
import { createScheduleSlots, updateScheduleSlot } from '../../api/Schedule';
import moment from 'moment';
import { DateSelectorWithoutFormik } from '../../common/textfield/DateSelector';
import TimeSelector from '../../common/textfield/TimeSelector';
import SelectFiled from "../../common/textfield/SelectFiled";
import MultiSelect from "../../common/textfield/MultiSelect";
import { getDaysBetweenDates } from '../../common/functions';
import { Button } from '../../common/Button';
import { useSelector } from "react-redux";
import  Conflicts  from './Conflicts';

const CreateUnavailability = ({ modalShow, scheduleCategory, editData, handleHide, scheduleId, refresh, startDate, endDate }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [statusTypes, setStatusTypes] = useState(unavailabilityStatusTypes);
    // const [reserved, setReserved] = useState(editData?.status == "busy-reserved");
    const [isPast, setIsPast] = useState(moment().isAfter(moment(endDate)) || moment().isAfter(editData.date));
    const loggedInUserData = useSelector((state) => state?.auth?.user);
    const [showConflicts, setShowConflicts] = useState(false);
    const [partialSuccess, setPartialSuccess] = useState(false);
    const [totalConflicts, setTotalConflicts] = useState([]);
    
    let conflicts = [];

    const openConflicts = () => {
        setShowConflicts(true)
    }
    const hideConflicts = () => {
        setShowConflicts(false)
    }
    const formik = useFormik({
        ...(editData ? FormikObjOfEditUnavailibilty : FormikObjOfCreateUnavailibilty),
        onSubmit: (values) => {
            // validate each unavailablity.
            values.unavailability.forEach((item, index) => {
            
                if(moment(item?.date).isAfter(moment((item?.endDate) ? item?.endDate : item?.to))) {
                    // this should never occur unless it is free text entry.
                    formik.setFieldError(`unavailability?.[${index}].date`, "Start date must be before end date");
                    return false;
                }

                if((moment(item?.from).hour() > moment(item?.to).hour()) 
                    || (moment(item?.from).hour() == moment(item?.to).hour()
                    && moment(item?.from).minute() > moment(item?.to).minute()) ) {
                    // this should never occur unless it is free text entry.
                    formik.setFieldError("from", "Start time must be before end time");
                    return false;
                }

                if(item.status == null || item.status == "") {
                    formik.setFieldError(`unavailability.${index}.status`, "You must select a reason");
                    return false;
                }
                else if(item.status == "busy-reserved" && item.type.length == 0) {
                    formik.setFieldError(`unavailability.${index}.status`, "You must select a reserved type");
                    return false;
                }
            });

            // if we get here, all items validate, proceed with submits, create an array or promises, based on unavailability item, and then each day it is applied, and 
            // process.
            setBtnLoading(true); 
            let promises =  [...values?.unavailability?.map(item => {  //set end date to start date in the case of single day edits.
                return [...getDaysBetweenDates(moment(item.date), moment(item.endDate || item.date))?.map((val) => {

                    /**
                     * create UTC date strings based on the selected date and times, and 
                     */
                    let start = moment(moment(val)?.format("YYYY-MM-DD") + ' ' + item?.from?.format("HH:mm:00")).format();
                    let end = moment(moment(val)?.format("YYYY-MM-DD") + ' ' + item?.to?.format("HH:mm:00")).format();
                    
                    if(start.length === 19) start += "Z";
                    if(end.length === 19) end += "Z"
                
                    start = moment.utc(start).format();
                    end = moment.utc(end).format();
                    
                    let status = item.status;
                    let serviceCategory = null;
                    if(item.type){
                        serviceCategory = scheduleCategory.filter(val => item?.type?.includes(val?.value))
                    }
                    else {
                        // this is unedited categories, so leave as-is
                        serviceCategory = item?.serviceCategory;
                    }
                    // edits should ONLY ever be a single row and for the specific day in question.
                    if (editData) {
                        return updateScheduleSlot({
                            scheduleId,
                            slotId: editData.key,
                            start,
                            end,
                            status,
                            serviceCategory,
                            orgId: loggedInUserData["custom:orgId"]
                        })
                    }
                    else { // else statement is redundant, leaving for read-ability
                        return createScheduleSlots({
                            scheduleId,
                            start,
                            end,
                            status,
                            serviceCategory,
                        })
                    }
                })]
            })]; 
            // the array of promises, because it's a map inside a map this becomes a 2D array or Promises. In order to make it a 1D array you need to "flatten it"         
            Promise.allSettled(promises.flat()).then((res) => {
                let someFail = res.filter(item => item.status == "rejected");
                someFail.forEach(failure => {
                    let data = JSON.parse(failure.reason.config.data); // this is the failed payload

                    //create an error object create a type string and a localized timestamp.
                    let error = { type: `${statusTypes.filter(type => type.value == data.status)[0]?.name}${data.status == "busy-reserved"? ` for ${data.serviceCategory.map(cat => cat.name).join(", ")}` : ''}`,
                                  time:  `${moment(data.start).format('YYYY-MM-DD [from] HH:mm')} to ${moment(data.end).format('HH:mm')}`}
                    conflicts.push(error);

                })
                setTotalConflicts(conflicts);
                if(someFail.length == res.length) { // all responses failed.
                    // all fail
                    failed(`${editData ? "Slot is " : "All slots are "}in conflict`)
                }
                else if(someFail.length > 0) { // some succeeded.
                    setPartialSuccess(true);
                    warn("Some slots are in conflict")
                }
                else // succeeded
                    success(`Slot${!editData ? "s updated" : " edited"} successfully`);

            }).catch((err) => {
                // this is a server error.
                failed(err?.response?.data?.message || err?.response?.data?.error || err?.message)
            })
            .finally(() => {
                // turn off the loading user feedback
                setBtnLoading(false);
                if(conflicts.length > 0) {
                    //open the conflict dialog
                    openConflicts();
                }
                else {
                    // hide the main dialog and refresh the data.
                    handleHide();                
                    refresh();
                }
            })
        },
    });

    /**
     * addRow
     * @param {object} formik data so you can add a new unavailability value to re-render the form. 
     */
    const addRow = ({ formik}) => {
        let previousRow = (formik.values?.unavailability) ? formik.values.unavailability.length - 1  : -1;
        const newRow = {
                date: (previousRow >= 0) ? formik.values?.unavailability?.[previousRow]?.date : "",
                endDate: (previousRow >= 0) ? formik.values?.unavailability?.[previousRow]?.endDate : "",
                from: "",
                to: "",
                status: "",
                type: "",
            };
        formik.setFieldValue("unavailability", [...formik.values.unavailability, newRow]);
    };
    
    /**
     * removeRow
     * @param {object} formik data so you can add a new unavailability value to re-render the form. 
     * @param {int} index of the row to remove.
     */
    const removeRow = ({ index, formik}) => {
        const updatedOptions = formik.values.unavailability.filter((_, i) => i !== index);
        formik.setFieldValue("unavailability", updatedOptions);
    };

    /**
     * handleUnAvailabilityChange
     * @param {string} fieldName to update.
     * @param {integer} index of the fiedl to udpate
     * @param {*} val the value to set.
     * This function updates the proper index of the formik array of values.
     */
    const handleUnAvailabilityChange = (fieldName, index, val) => {
        if(fieldName === "type") {
            formik.setFieldValue(`unavailability.${index}.${fieldName}`, val)
        }
        else{
            if(fieldName.toLowerCase().includes("date")) {
                if(val == null || val == "") {
                    formik.setFieldValue(`unavailability.${index}.${fieldName}`, val || "");
                }
                else {
                    let isValidDate = moment(val, 'MMM-DD-YYYY').isValid();
                    let errorString = "";
            
                    if(!isValidDate) 
                        errorString = "Invalid date format";
            
                    (isValidDate) ? formik.setFieldValue(`unavailability.${index}.${fieldName}`, val) : formik.setFieldError(`unavailability.${index}.${fieldName}`, errorString)
                }
            }
            else 
                formik.setFieldValue(`unavailability.${index}.${fieldName}`, val)
        }
    };

    useEffect(() => {
        /** 
         * if there is editData, the user is editing an individual unavailablity. 
         * From here you can edit times. And the type, tho if it is reserved, you can only edit the appt types.
         */
        if (editData) {
            let serviceCategory = {...editData.serviceCategory};
            //delete editData.serviceCategory;
            //formik.values.unavailability[0] = editData;
            formik.setFieldValue("unavailability.0.date", editData.date)
            formik.setFieldValue("unavailability.0.from", editData.from)
            formik.setFieldValue("unavailability.0.to", editData.to)
            formik.setFieldValue("unavailability.0.status", editData.status)
            formik.setFieldValue("unavailability.0.key", editData.key)
            if(serviceCategory){
                //setReserved(true)
                formik.setFieldValue("unavailability.0.type", Object.keys(serviceCategory).map(key => serviceCategory[key].id))
            }
        }
    }, [editData])

    return (
        <div>
        <Modal            
            show={modalShow}
            onHide={handleHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={'static'}
            dialogClassName='xxl'
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {editData ? "Edit" : "Create" } Unavailability Slots
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" style={{whiteSpace: 'nowrap'}} onSubmit={formik.handleSubmit}>
                <div className="table-wrap">
                    <Table responsive style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th style={{minWidth: 220, maxWidth: 250}}>Start Date <span style={{ color: "red" }}> *</span></th>
                                {!editData && <th style={{minWidth: 220, maxWidth: 250}}>End Date <span style={{ color: "red" }}> *</span></th>}
                                <th style={{width: 180}}>From <span style={{ color: "red" }}> *</span></th>
                                <th style={{width: 180}}>To <span style={{ color: "red" }}> *</span></th>
                                <th style={{minWidth: 200, maxWidth: 250}}>Type <span style={{ color: "red" }}> *</span></th>
                                <th style={{minWidth: 200, maxWidth: 300}} />
                               {!editData &&  <th style={{minWidth: 100, maxWidth: 100}} /> }
                            </tr>
                        </thead>
                        <tbody>
                            {formik.values?.unavailability?.map((row, index) => {
                                const disableRemove = formik.values?.unavailability?.length < 2;

                                return <tr key={row.id} style={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
                                    <td style={{verticalAlign: 'top', minWidth: 200, maxWidth: 250, height:110}}>
                                        {editData
                                        ? <DateSelectorWithoutFormik formik={formik} disabled={isPast} keyField={`unavailability.${index}.date`} 
                                            type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("date", index, val)} 
                                            value={editData.date || formik.values?.unavailability?.[index]?.date}  minDate={moment().format()} maxDate={endDate} 
                                            isError={formik?.touched?.unavailability?.[index]?.date && formik?.errors.unavailability?.[index]?.date} />
                                        : <DateSelectorWithoutFormik  formik={formik} disabled={isPast} keyField={`unavailability.${index}.date`} 
                                            type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("date", index, val)} 
                                            value={formik.values?.unavailability[index]?.date} minDate={moment().isAfter(formik.values?.unavailability[index]?.date) ? moment(): startDate} 
                                            maxDate={endDate} 
                                            isError={formik?.touched?.unavailability?.[index]?.date && formik?.errors.unavailability?.[index]?.date}/>}
                                    </td>
                                    {!editData && <td style={{verticalAlign: 'top', minWidth: 200, maxWidth: 250, height:110}}>{editData ? null :
                                         <DateSelectorWithoutFormik disabled={isPast} formik={formik} 
                                            keyField={`unavailability.${index}.endDate`} type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("endDate", index, val)} 
                                            value={(formik.values?.unavailability?.[index]?.endDate) ? formik.values?.unavailability[index]?.endDate : ""} minDate={formik.values?.unavailability?.[index]?.date || moment().format()} 
                                            maxDate={endDate} isError={formik?.touched?.unavailability?.[index]?.endDate && formik?.errors.unavailability?.[index]?.endDate}  />}</td>}
                                    <td style={{verticalAlign: 'top', minWidth: 150, maxWidth: 150, height:110}}>    
                                        <TimeSelector minutesStep={5} minTime={startDate} keyField={`unavailability.${index}.from`} maxTime={moment(endDate.format()).subtract(5, 'm')} 
                                        disabled={isPast} value={formik.values?.unavailability?.[index]?.from} onChange={val => handleUnAvailabilityChange("from", index, val)} 
                                        isError={formik?.touched?.unavailability?.[index]?.from && formik?.errors?.unavailability?.[index]?.from}
                                        formik={formik} />
                                    </td>
                                    <td style={{verticalAlign: 'top', minWidth: 150, maxWidth: 150, height:110}}>
                                        <TimeSelector minTime={moment(formik.values?.unavailability?.[index]?.from).add(5,"m")} keyField={`unavailability.${index}.to`} maxTime={endDate} 
                                        disabled={isPast} minutesStep={5} value={formik.values?.unavailability?.[index]?.to} onChange={val => handleUnAvailabilityChange("to", index, val)} 
                                        isError={formik?.touched?.unavailability?.[index]?.to && formik?.errors?.unavailability?.[index]?.to}
                                        formik={formik} />
                                    </td>
                                    <td style={{verticalAlign: 'top', minWidth: 200, maxWidth: 250, height:110}}>
                                        <SelectFiled keyField={`unavailability.${index}.status`} disabled={isPast || editData?.status == "busy-reserved" }  
                                        formik={formik} value={formik.values?.unavailability?.[index]?.status} options={unavailabilityStatusTypes} 
                                        onChange={val => handleUnAvailabilityChange("status", index, val.target.value)}  
                                        isError={formik?.touched?.unavailability?.[index]?.status && formik?.errors?.unavailability?.[index]?.status} />
                                    </td>
                                    <td style={{verticalAlign: 'top', overflow: 'hidden', minWidth: 200, maxWidth: 300, height:110}}>
                                {formik.values?.unavailability?.[index]?.status == "busy-reserved" &&
                                    <MultiSelect
                                        keyField={`unavailability.${index}.type`}
                                        options={scheduleCategory}
                                        formik={formik}
                                        required={false}
                                        optional={false}
                                        label={null}
                                        nodata={"No Data Found"}
                                        defaultValue={(editData && editData.serviceCategory) ? Object.keys(editData?.serviceCategory).map(key => editData.serviceCategory[key].id) : formik.values.unavailability?.[index].type}
                                        isError={formik?.touched?.unavailability?.[index]?.type && formik?.errors?.unavailability?.[index]?.type}
                                        isSelectAllEnable={false}
                                        style={{verticalAlign: 'top', overflow: 'hidden'}}
                                    />
                                }
                                </td>
                               {!editData && <td style={{verticalAlign: 'top', minWidth: 100, maxWidth:100, height:110, display: 'flex', flexDirection: 'row'}}>
                                    <div style={iconStyle}>
                                        <Add
                                            style={{
                                                width: "inherit",
                                                height: 'inherit',
                                                color: "rgba(0, 93, 168, 1)"
                                            }}
                                            onClick={() => addRow({ formik: formik })}
                                        />
                                    </div>
                                    <div style={{ ...iconStyle, cursor: disableRemove ? "auto" : "pointer" }}>
                                        <Remove
                                            style={{
                                                width: "inherit",
                                                height: 'inherit',
                                                color: "rgba(0, 93, 168, 1)",
                                                opacity: disableRemove ? "0.3" : "1"
                                            }}
                                            onClick={() => !disableRemove && removeRow({ index, formik: formik })}
                                        />
                                    </div>
                                    
                                </td> }
                            </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
                    <div className="btn-wrap" style={{ display: "flex", gap: "1rem" }}>
                        <Button type="submit" disabled={isPast} isLoading={btnLoading}>{editData ? 'Update' : 'Create'}</Button>
                        <Button onClick={handleHide}>Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        <Conflicts modalShow={showConflicts} conflicts={totalConflicts} partialSuccess={partialSuccess} handleHide={() => {hideConflicts(); handleHide(); refresh()}} />
        </div>
    )
}

export default CreateUnavailability