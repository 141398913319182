import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import TextArea from "../../common/textfield/TextArea";
import { Button } from "../../common/Button";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { editQueueItem } from "../api/WaitingRoom";
import { failed, success } from "../../common/Toastify";
import { getSingleUser } from "../../api/Individual";

const AddNoteModal = ({ data, onShow, onHide }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const practitionerId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const userOrganizationId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );
  const [myUserId, setMyUserId] = useState("");
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };

  useEffect(() => {
    getSingleUser({ id: practitionerId, type: "Practitioner" })
      .then((res) => {
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: Yup.object({
      note: Yup.string()
        .max(250, "Maximum length is 250 characters.")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
        .required("Note field is required"),
    }),
    onSubmit: async (values) => {
      setBtnLoading(true);
      const currentNotes = data?.notes || [];
      let newNote = {
        actor: {
          display: practUserName,
          reference: `Practitioner/${myUserId}`,
          type: "Practitioner",
        },
        note: values?.note,
        updatedAt: Date.now(),
      };
      const updatedNotes = [...currentNotes, newNote];
      editQueueItem(
        {
          id: data?.id,
          notes: updatedNotes,
        },
        userOrganizationId
      )
        .then((res) => {
          if (res.status === true) {
            success(
              `Note added successfully for ${capitalizeWords(
                data?.subject?.display
              )}`
            );
            onHide();
          } else {
            failed(res?.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <span className="header-text">Add Note for patient:</span>&nbsp;&nbsp;
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textTransform: "capitalize" }}
        >
          {data?.subject?.display}
        </Modal.Title>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextArea
                keyField={"note"}
                label={"Add Note"}
                formik={formik}
                placeholder={"Type here..."}
                required={true}
              />
            </Col>
          </Row>
          <div className="btn-wrap">
            <Button onClick={() => onHide()} variant="secondary" title="Cancel">
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNoteModal;
