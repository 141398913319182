import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import { FormikObjEdit } from "./Constants"
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { categoryOptions } from "./Constants";
import { editDocument } from "../../api/FillableDocument.js";
import { axios } from "../../../lib/axios";
import { useSelector } from "react-redux";

// organization detail dialog
export function EditDocument({ modalShow, handleShow, refreshTable }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const handleStatusChange = (event) => {
      const value = event?.target?.checked;
      formik.setFieldValue("status", value === true ? "new" : "inactive");
    };

    const handleCategoryChange = (event) => {
      const value = event?.target?.value;
      formik.setFieldValue("category", value);
  };

    const formik = useFormik({
        ...FormikObjEdit,
        onSubmit: (values) => {
            setBtnLoading(true);
            editDocument(values)
                .then((res) => {
                    if (res.status === true) {
                        success(res.message);
                        handleShow();
                        refreshTable();
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
      fetchOrgnization(modalShow?.custodian?.reference?.split("/")[1])
    }, [modalShow?.custodian?.reference?.split("/")[1]])

    const fetchOrgnization = async(orgId)=>{
     const response = orgId ? await axios.get(`organization/${orgId}`) : null; 
     formik.setFieldValue("organization", response?.data?.name || "");
    }
    

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("id", modalShow?.id || "");
            formik.setFieldValue("name", modalShow?.documentName.trim() || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue("category", modalShow?.category?.[0]?.coding?.[0]?.display || "");
            formik.setFieldValue("status", modalShow?.status || "")
            formik.setFieldValue("orgName", modalShow?.custodian?.display || "")
        }
    }, [modalShow]);



    return (
      <Modal
        backdropClassName
        backdrop={"static"}
        size="lg"
        show={modalShow}
        onHide={handleShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            <Row>
              {userRoles?.includes("Super-Admin") ? (<Col>
                  <TextInput
                    keyField={"organization"}
                    label={"Organization"}
                    formik={formik}
                    placeholder={"Organization"}
                    disabled={true}
                  />
              </Col>) : null}
              <Col>
                <TextInput
                  keyField={"name"}
                  label={"Document Name"}
                  formik={formik}
                  placeholder={"Document Name"}
                />
              </Col>
              <Col>
              <SelectFiled
                  keyField={"category"}
                  label={"Category"}
                  formik={formik}
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  keyField={"description"}
                  label={"Description"}
                  formik={formik}
                  placeholder={"Description..."}
                  required={false}
                />
              </Col>
              {/* <Col>
                <label>Uploaded Document</label>
                <p>{modalShow?.content?.[0]?.attachment?.title}</p>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <label>Status</label>
                <Switch
                  name="status"
                  onClick={handleStatusChange}
                  defaultChecked={modalShow?.status == 'new' ? true : false}
                />
              </Col>
            </Row>
            <div className="btn-wrap" style={{ display: "flex" }}>
              <Button
                onClick={() => handleShow(false)}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={btnLoading}>
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
}