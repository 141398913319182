import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useClinicLocationsQuery } from '../../../hooks/ReactQueryHooks/useClinicLocationsQuery'
import { useOrgQuery } from '../../../hooks/ReactQueryHooks/useOrgQuery'
import { editUser, getSingleUser } from '../../api/Individual'
import { failed, success } from '../../common/Toastify'
import { FormikEditDeviceObj } from './Constants'
import { useFormik } from 'formik';
import { decryptData, encryptData } from '../../EncryptDecrypt';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../common/textfield/TextInput';
import SelectFiled from '../../common/textfield/SelectFiled';
import { Button } from '../../common/Button';
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from 'react-redux';

const DeviceEditViewModal = ({ modalShow, handleHide, isReadOnly }) => {
    const navigate = useNavigate();
    const [locations, setLocations] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    useEffect(() => {
        if (modalShow) {
            setLoading(true)
            getSingleUser({ id: modalShow, userOrgId: adminOrgs?.[0]?.id }).then((res) => {
                formik.setFieldValue("id", res?.data?.id)
                formik.setFieldValue("device_name", decryptData(res?.data?.name?.[0]?.text))
                formik.setFieldValue("email", decryptData(res?.data?.telecom?.filter((data) => data.system === "email")?.[0]?.value))
                formik.setFieldValue("clinic_location", res?.data?.location?.reference?.split('/')?.[1])
                formik.setFieldValue("organization", res?.data?.managingOrganization?.[0]?.reference?.split('/')?.[1])
            }).catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])

    const onSuccessLocations = (data) => {
        setLocations(data?.map((location) => (location = { ...location, value: location?.id })))
    }
    const onSuccessOrg = (data) => {
        setOrganizations(data?.map((org) => (org = { ...org, value: org?.id })));
    };

    useClinicLocationsQuery({ onSuccess: onSuccessLocations })
    useOrgQuery({ onSuccess: onSuccessOrg });

    const formik = useFormik({
        ...FormikEditDeviceObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            const selectedLocationData = locations?.filter(location => location?.id === values?.clinic_location)?.[0] || null
            const encryptedData = {
                device_name: encryptData(values?.device_name),
                email: encryptData(values?.email?.toLowerCase()),
            };
            editUser(values, encryptedData, organizations, "", "", "Device", "", selectedLocationData, adminOrgs?.[0]?.id)
                .then((res) => {
                    navigate("/app/manage-individuals");
                    handleHide(false, false, true)
                    success(res.message);
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });

    return (
        <Modal show={modalShow} onHide={() => handleHide(false, false, true)} size="lg"
            backdropClassName
            backdrop={'static'}
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{isReadOnly ? "View" : "Edit"} Device Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading
                    ? <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40vh",
                        }}
                    >
                        <CircularProgress />
                    </div>
                    : <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col>
                                <TextInput keyField={"device_name"} label={"Device Name"} formik={formik} placeholder={"Device 1"} />
                            </Col>
                            <Col>
                                <TextInput keyField={"email"} label={"Email Address"} formik={formik} placeholder={"Email Address"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectFiled readOnly={true} keyField={"organization"} label={"Organization"} formik={formik} options={organizations} />
                            </Col>
                            <Col>
                                <SelectFiled keyField={"clinic_location"} label={"Select Clinic Location"} formik={formik} options={locations} />
                            </Col>
                        </Row>
                        {!isReadOnly ? (
                            <div className="btn-wrap" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <Button
                                    onClick={() => handleHide(false, false, true)}
                                    variant="secondary"
                                    title="Cancel"
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" isLoading={btnLoading}>
                                    Update
                                </Button>
                            </div>
                        ) : null}
                    </form>}
            </Modal.Body>
        </Modal>
    )
}

export default DeviceEditViewModal