import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useMedia } from 'react-use';
import moment from 'moment';
import { ViewDate } from '../../../utils/DateSupport';
import { Tooltip } from '@mui/material';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';

const FullCalendarComp = ({ eventsList = [], source, ...props }) => {
    const isWide = useMedia('(min-width: 480px)');
    const renderEventContent = (eventInfo) => {
        const { serviceCategory, startTime, endTime } = eventInfo?.event?.extendedProps;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '5px' }}>
                <span><b>{moment(startTime).format("HH:mm") + "-" + moment(endTime).format("HH:mm")}</b></span>
                <Tooltip 
                    title={serviceCategory} 
                    placement="top"
                >
                    <InfoIconOutlined style={{ fontSize: 'medium', marginRight: '5px', cursor: 'pointer' }} />
                </Tooltip>
            </div>
        );
    };

    return (
        <FullCalendar
            headerToolbar={isWide
                ? {
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay'
                }
                : {
                    start: 'prev,next today',
                    center: false,
                    end: 'title'
                }
            }
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={eventsList?.length > 0 ?  eventsList?.map((obj)=> {
                return{
                    ...obj,
                    startTime: obj.startTime,
                    extendedProps: {
                        startTime: obj.startTime, 
                        endTime: obj.endTime
                    },
                    title : ViewDate(obj.date)
                }
            }) : []}
            locale='en' // Set locale directly
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: false,
                hour12: false,
            }}
            eventContent={source === "appointments" ? renderEventContent : undefined}
            {...props}

        //     locales={[
        //     {
        //         locale: 'en',
        //         eventTimeFormat: {
        //             hour: 'numeric',
        //             minute: '2-digit',
        //             omitZeroMinute: false,
        //             meridiem: false,
        //             hour12: false,
        //         },
        //     },
        // ]}
        //     {...props}
        />
    );
};

export default FullCalendarComp;
