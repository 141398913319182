import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import moment from "moment";
import { Tooltip } from "@mui/material";
import {
  AddCircleOutline,
  DescriptionOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import ViewNotesModal from "./ViewNotesModal";
import AddNoteModal from "./AddNoteModal";
import { Button } from "../../common/Button";
import { useSelector } from "react-redux";
import { editEncounter, getAppointmentRecord } from "../api/AppointmentRequests";
import { Buffer } from "buffer";
import { getSignature } from "../../Zoom/functions";


export const ViewEncounterModal = ({ data, onShow, onHide }) => {
  const [patientDetails, setPatientDetails] = useState({});
  const patientId = data.subject.reference.split("/")[1];
  const [addNoteModalData, setAddNoteModalData] = useState(null);
  const [addNoteModalShow, setAddNoteModalShow] = useState(false);
  const [viewNoteModalShow, setViewNoteModalShow] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );
  const practitionerUniqueId = useSelector(
    (state) => state?.auth?.user?.["custom:unique_id"]
  );
  const navigate = useNavigate();
  const [patientHCN, setPatientHCN] = useState("");
  const [myUserId, setMyUserId] = useState("");

  useEffect(() => {
    getSingleUser({ id: practitionerUniqueId, type: "Practitioner" })
      .then((res) => {
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    if (patientId) {
      getSingleUser({
        id: patientId,
        type: "Patient",
      })
        .then((res) => {
          setPatientDetails(res.data);
          const hcnValue =  res.data?.patient_identifier?.find((ele) => ele.type === "HCN")?.value || "-";
          setPatientHCN(hcnValue);
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        });
    }
  }, [patientId]);

  const handleAddNoteClick = (data) => {
    setAddNoteModalData(data);
    setAddNoteModalShow(true);
  };

  const handleViewNotesClick = (data) => {
    setAddNoteModalData(data);
    setViewNoteModalShow(true);
  };

  const handleCancelClick = (data) => {
    setBtnLoading(true);
    const currentActor = {
      reference: `Practitioner/${myUserId}`,
      display: practUserName,
      type: "Practitioner",
    };
    let encounterPayload = {
      patient: data?.subject,
      orgId: data?.orgId,
      questionnairInfo: data?.intakeQuestions,
      requestedPeriod: data?.requestedPeriod,
      practitionerId: data?.practitionerId,
      practitionerName: practUserName,
      proceedWith: data?.proceedWith,
      status: "cancelled",
      encounterId: data?.id,
      patientLocation: data?.patientLocation,
      participant: data?.participant,
      actor: currentActor,
      encounterClass: "NULL"
    };

    editEncounter(encounterPayload)
      .then((res) => {
        if (res?.status === true) {
          success("Encounter Cancelled Successfully");
          onHide();
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      )
      .finally(() => setBtnLoading(false));
  };

  const handlePreviewEncounterClick = (data) => {
      const encounterId = data?.id;
      navigate("/app/encounter-details", {
        state: { encounterId: encounterId, notPatientDashboard: true },
    })  };

  const handleConnectClick = (data) => {
    const utcString = new Date().toISOString().split(".")[0] + ".00Z";
    setBtnLoading(true);
    if (data?.status === "in-progress-appointment") {      
      getAppointmentRecord({appointmentId: data?.appointmentId})
      .then(async (res) => {
        console.log("This is the res from appointment: ", res)
        if (res?.data?.sessionToken && (res?.data?.intakeQuestions?.preferredChannel === 'video' || res?.data?.intakeQuestions?.preferredChannel === 'video/chat')) {
          let encounterId = data?.id;
          const base64Url = res?.data?.sessionToken?.split(".")[1];
          const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
          const buff = await Buffer.from(base64, "base64")
          const payloadinit = await buff.toString("ascii")
          const parseddata = JSON.parse(payloadinit || "{}")
          let topicForSession = parseddata?.tpc;
          let passwordForSession = parseddata?.password;
          await getSignature({ topic: topicForSession, role: 1, password: passwordForSession })
          .then(async (response) => {
            await navigate("/app/zoom-meeting-create", { state: { encounterId, appointmentData: res?.data, topic: topicForSession, sessionToken: response?.data?.sessionToken, password: passwordForSession, patientName: data?.subject?.display, questionnairInfo: data?.intakeQuestions } });
          })
        }
        else{ 
          navigate("/app/phone-call-create", {
            state: {
              practitionerName: practUserName,
              practitionerId: practitionerUniqueId,
              patientId: data?.subject?.reference?.split("/")?.[1],
              patientName: data?.subject?.display,
              encounterId: data?.id,
              appointmentData: res?.data,
              currentUser: {
                patient: {
                  display: data?.subject?.display
                }
              },
              questionnairInfo: data?.intakeQuestions
            }
          });
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
        )
      )
      .finally(() => setBtnLoading(false));
    } else {
      const currentActor = {
        reference: `Practitioner/${myUserId}`,
        display: practUserName,
        type: "Practitioner",
      };
      const newParticipant = {
        individual: {
          reference: `Practitioner/${myUserId}`,
          display: practUserName,
          type: "Practitioner",
        },
        period: {
          end: null,
          start: utcString,
        },
      };
      const updatedParticipant = [...data?.participant, newParticipant];
  
      let encounterPayload = {
        patient: data?.subject,
        orgId: data?.orgId,
        questionnairInfo: data?.intakeQuestions,
        requestedPeriod: data?.requestedPeriod,
        practitionerId: data?.practitionerId,
        practitionerName: practUserName,
        proceedWith: data?.proceedWith,
        status: "in-progress-waitingroom",
        encounterId: data?.id,
        patientLocation: data?.patientLocation,
        participant: updatedParticipant,
        actor: currentActor,
        encounterClass: "NULL"
      };
  
      editEncounter(encounterPayload)
        .then((res) => {
          if (res?.status === true) {
            success("Encounter In Progress");
          }
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setBtnLoading(false));
  
      navigate("/app/phone-call-create", {
        state: {
          practitionerName: practUserName,
          practitionerId: practitionerUniqueId,
          patientId: data?.subject?.reference?.split("/")?.[1],
          patientName: data?.subject?.display,
          encounterId: data?.id,
          currentUser: {
            patient: { display: data?.subject?.display },
          },
          proceedWith: "WAITING_ROOM",
          requestedPeriod: data?.period,
          questionnairInfo: data?.intakeQuestions,
          period: data?.period,
          previousStatus: data?.status
        },
      });
    }
  };

  return (
    <>
      <Modal
        backdropClassName
        backdrop={"static"}
        show={onShow}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog appointment-custom-modal"
      >
        <Modal.Header closeButton className="border-0">
          <span className="header-text">Patient:</span>&nbsp;&nbsp;
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ textTransform: "capitalize" }}
          >
            {
              data?.participant?.filter(
                (data) => data?.individual?.type === "Patient"
              )?.[0]?.individual?.display || data?.subject?.display
            }
          </Modal.Title>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <b>Encounter Details</b>
              <br />
              <div className="col-section">
                <span className="label-grey">Encounter ID:</span>{" "}
                {data?.id || "-"}
                <br />
                <span className="label-grey">Arrival Time:</span>{" "}
                {moment(data?.createdAt).format("DD-MMM-YY HH:mm")}
                <br />
                <span className="label-grey">Reason for Call:</span>{" "}
                {data?.intakeQuestions?.intakeReason}
                <br />
                <span className="label-grey">Notes:</span>{" "}
                <div style={{ display: "inline-block" }}>
                  {data?.notes?.length > 0 && (
                    <Tooltip title={"View Notes"}>
                      <div>
                        <Link
                          variant="primary"
                          className="view-btn"
                          onClick={() => handleViewNotesClick(data)}
                        >
                          <DescriptionOutlined
                            style={{
                              color: "#005da8",
                              width: "24px",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </Col>

            <Col>
              <br />
              <div
                className="col-section"
                style={{ textTransform: "capitalize" }}
              >
                <span className="label-grey">Status:</span>{" "}
                {data?.status || "-"}
                <br />
                <span className="label-grey">Priority Reason:</span>{" "}
                {data?.intakeQuestions?.intakePriorityReason || "-"}
                <br />
                <span className="label-grey">PriorityLevel:</span>{" "}
                {data?.priority || "-"}
              </div>
            </Col>
          </Row>

          <Row className="my-3">
            <Col>
              <b>Patient Details</b>
              <br />
              <div
                className="col-section"
                style={{ textTransform: "capitalize" }}
              >
                <span className="label-grey">Date of Birth: </span>{" "}
                {patientDetails?.birthDate || "-"}
                <br />
                <span className="label-grey">Patient HCN: </span> {patientHCN}
                <br />
                <span className="label-grey">Gender: </span>{" "}
                {patientDetails?.gender || "-"}
                <br />
              </div>
            </Col>

            <Col>
              <br />
              <div
                className="col-section"
                style={{ textTransform: "capitalize" }}
              >
                <span className="label-grey">Caller Name:</span>{" "}
                {data?.intakeQuestions?.callerName || "-"}
                <br />
                <span className="label-grey">Return Phone Number:</span>{" "}
                {data?.intakeQuestions?.callerNumber || "-"}
                <br />
                <span className="label-grey">Assigned Practitioner:</span>{" "}
                {
                  data?.practitionerHistory[
                    data?.practitionerHistory.length - 1
                  ]?.actor?.display
                }
              </div>
            </Col>
          </Row>

          <br />

          <div className="btn-wrap-block">
            <div
              className="action-wrap"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="primary"
                onClick={() => handleAddNoteClick(data)}
                className="success-btn"
                style={{ margin: "0 7px 0 0" }}
              >
                Add Note
              </Button>
            </div>
            {data?.status !== "cancelled" ? (
              <div
                className="action-wrap"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  variant="primary"
                  onClick={() => handleConnectClick(data)}
                  className="success-btn"
                  style={{ margin: "0 7px 0 0" }}
                >
                  Connect
                </Button>
              </div>
            ) : null}

            {data?.status !== "cancelled" ? (
              <>
                <div
                  className="action-wrap"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {data?.status !== "finished" ? (
                    <Button
                      variant="primary"
                      onClick={() => handlePreviewEncounterClick(data)}
                      className="view-btn"
                      style={{ margin: "0 7px 0 0" }}
                    >
                      View Encounter Report
                    </Button>
                  ) : null}
                </div>
                
              </>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {viewNoteModalShow && (
        <ViewNotesModal
          recordData={addNoteModalData}
          onShow={viewNoteModalShow}
          onHide={() => setViewNoteModalShow(false)}
        />
      )}

      {addNoteModalShow && (
        <AddNoteModal
          data={addNoteModalData}
          onShow={addNoteModalShow}
          onHide={() => setAddNoteModalShow(false)}
        />
      )}
    </>
  );
};
