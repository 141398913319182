import moment from "moment"

export const EncounterFormikObj = {
    initialValues: {
        id: "",
        status: "",
        isOpen: [],
        start: moment().subtract(2,'days'),
        end: moment(),
    }
}

export const encounterOptions = [
    {name: "Start Date", value: "open"},
    {name: "End Date", value: "closed"}
]

export const statusOptions = [
    { name: "Arrived", value: "arrived" },
    { name: "In-Progress-Waitingroom", value: "in-progress-waitingroom" },
    { name: "In-Progress-Appointment", value: "in-progress-appointment" },
    { name: "In-Progress-Intake", value: "in-progress-intake" },
    { name: "Follow-up", value: "follow-up" },
    { name: "Parked", value: "parked" },
    { name: "Planned", value: "planned" },
    { name: "Finished", value: "finished" },
    { name: "Cancelled", value: "cancelled" }
]
export const isOpenDisclaimer = "Choose 'Start Date' to search based on encounter start date \nChoose 'End Date' to search based on encounter end date"
export const noDataAvailText = "No Data Available";
export const encounterTableTitle = ["ID", "Patient", "Practitioner", "Start", "End", "Status"]