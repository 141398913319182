import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { failed } from "../../common/Toastify";
import { checkSchedule, getSlots } from "../../apps/api/AppointmentRequests";
import { Col, Row } from "react-bootstrap";
import { Skeleton } from "@mui/material";
import { ViewDate } from "../../../utils/DateSupport";
import {getScheduleSlots} from "../../api/Schedule";
import { useSelector } from "react-redux";

export function ViewScheduleDayModal({ modalShow, handleShow, userID, }) {
    const [serviceCategory, setServiceCategory] = useState([]);
    const [reservedCategories, setReservedCategories] = useState ({})
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const userOrg = useSelector((state) => state?.auth?.user?.organizations);
    const userOrgId = userOrg?.[0]?.id;

    const formik = useFormik({
        initialValues: {
            serviceCategory: "",
        },
        validationSchema: Yup.object({
            serviceCategory: Yup.string().required("Required Title field"),
        }),
    });

    useEffect(() => {
        checkSchedule({ actorId: userID, date: moment(modalShow)?.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"})
            .then((res) => {
                if(!res?.data) {
                    failed(res?.message);
                    return;
                }

                let serviceCategoriesList = [];
                if (!Array.isArray(res.data)) res.data = [res.data];
                
                res.data.forEach(schedule => {
                    serviceCategoriesList.push(...schedule.serviceCategory?.filter(categoryData => 
                        moment(categoryData?.date?.split('T')?.[0]).valueOf() === moment(modalShow).valueOf()
                           && !serviceCategoriesList.some(item => item.value === categoryData.id)
                            )?.map(categoryData => 
                            ({...categoryData, value: categoryData?.id})
                    ))                        
                })
                
                let reservedSlots ={}
                let newData = [];
                    
                res.data.forEach(schedule => 
                    getScheduleSlots(schedule.id, "busy-reserved")
                    .then((resp) =>{
                        resp.data?.filter((slot)=> moment(slot?.start).format("YYYY-MM-DD") === moment(modalShow).format("YYYY-MM-DD"))
                        .map((slot) => {
                            let serviceCategoryId = slot?.serviceCategory?.[0]?.id
                            
                            if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                                 reservedSlots[serviceCategoryId].push({
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                                });
                            }    
                            else {
                                reservedSlots[serviceCategoryId] = [{
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                                }];
                            }
                        })

                        Object.keys(reservedSlots).forEach(key => {
                            newData.push({
                                name: `Reserved for ${reservedSlots[key]?.[0]?.serviceCategory[0]?.name}`,
                                value: `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`,
                                status: "busy-reserved"
                            });
                            
                        });
                        setReservedCategories(reservedSlots);
                        setServiceCategory([...serviceCategoriesList, ...newData])
                        handleServiceCategory({ target: { value: serviceCategoriesList?.[0]?.id } })
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                );

            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res?.message);
            });
    }, [])

    const sortByLocalTime = (a, b) => {
        let atime =`${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(a?.start)}:00Z`;
        let btime =`${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(b?.start)}:00Z`;
        let aHr = moment(atime).hour();
        let bHr = moment(btime).hour();
        let aMin = moment(atime).minute();
        let bMin = moment(btime).minute();
        return (aHr < bHr || (aHr == bHr && aMin < bMin)) ? -1 : 1
    }

    const sortedObj = data => {
        return data.sort((a,b) => {
            return a.name > b.name ? 1 : -1
        });
    }

    const handleServiceCategory = (event) => {
        event && formik.setFieldValue("serviceCategory", event?.target?.value);
        setSlotsLoading(true)
        let targetVal = event?.target?.value;
        if(targetVal?.endsWith("busyReserved")){
            let currentTime = moment();
            let slots = reservedCategories[event?.target?.value.split("-")[0]];
            slots = slots.sort(sortByLocalTime)
                //.filter(slot => moment(slot.start).isAfter(currentTime))
                //.sort((a, b) => moment(a.start).diff(moment(b.start)));
    
            setAvailableSlots(slots)
            setSlotsLoading(false)
        }else if(targetVal) {
            getSlots({ actorId: userID, date: moment(modalShow).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z", serviceCategoryId: event?.target?.value || "default", orgId: userOrgId })
                .then((res) => {
                    let slots = res?.data?.availableSlots;
                    slots.sort(sortByLocalTime);
                    setAvailableSlots(slots)
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res?.message);
                }).finally(() => { setSlotsLoading(false) })
        }
        else setSlotsLoading(false)
    };

    return (
        <>
            <Modal
                size="md" show={modalShow}
                onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName
                backdrop={'static'}
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                        {ViewDate(modalShow)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                        <SelectFiled keyField={"serviceCategory"} label={"Select Service Category"} formik={formik} options={sortedObj(serviceCategory)} onChange={handleServiceCategory} />

                        {serviceCategory?.length > 0 && availableSlots?.length > 0 ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Row style={{ justifyContent: "center", rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                    {availableSlots?.map((slot, index) =>{
                                    let time;
                                    let localTime;
                                    if(slot?.status === "busy-reserved"){
                                        localTime = moment(slot?.start).format("HH:mm");
                                    }else{
                                        //time=`${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(slot?.start)}:00Z`;
                                        localTime = moment(slot?.start).format("HH:mm");
                                    }
                                    return (<Col sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); }}>{localTime}</Col>)})
                                    }
                                </Row>
                            </>
                            : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
