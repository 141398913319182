import { Skeleton , Tooltip} from "@mui/material";
import React from "react";

    /**
     * 
     * @param {object} formik 
     * @param {string} keyField 
     * @returns a div containing an error message or null
     * 
     * In the case of a multi-line form, so unavailability, or for example a dynamic length form, where you 
     * can add duplicate rows, the keyfields will be set in a groupname.rowindex.fieldname, however the formik
     * errors don't translate gracefully to this mult-row setup, and come as a JSON object like follows:
     * 
     * formik.errors = {
     *      groupname: [
     *          fieldname
     *      ]
     * }
     * where the rowindex is the index of the field in the array.
     */
    const renderError = (formik, keyField) => {
      let keyFieldParts = keyField?.split('.');
      
      if(keyFieldParts?.length === 3 && formik?.errors[keyFieldParts[0]]) {
          let errorString = formik?.errors[keyFieldParts[0]]?.[keyFieldParts[1]]?.[keyFieldParts[2]];
          return   <div>
                      { errorString ? <div className="error-text">{errorString}</div>: null }
                   </div>
      }
      else {
          return <div>{formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
      }
  }

const SelectFiled = ({ label, options = [], selectDisabledValue = false, keyField, formik, selectLabel = "Select", required = true, hideRequired = false, optional = true, tooltip = false, tooltipText = "", readOnly = false, isLoading = false, isError = false, ...props }) => {
 return (
  <div className="field-wrap">
   {label ? (
    <label htmlFor={keyField} style={{whiteSpace: "nowrap"}} className={readOnly ? "readonly" : ""}>
     {label}
     {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : optional ? " (Optional)" : ""}
    </label>
   ) : null}
   {isLoading ? (
    <Skeleton height={40} animation="wave" />
   ) : (
    <Tooltip title = {tooltipText}><select id={keyField} className={readOnly ? "readonly" : ""} style={{ pointerEvents: readOnly ? "none" : "all", cursor: readOnly ? "auto" : "pointer" }} {...formik?.getFieldProps(keyField)} {...props}>
      <option value=""  disabled={selectDisabledValue ? false : true}>
        {options.length ? selectLabel : "No options"}
      </option>
      {options.map(({ value, name, disabled = false }, index) => (
        <option value={value} key={index} disabled={disabled}>
          {name}
        </option>
      ))}
    </select>
    </Tooltip>
   )}
  {
    renderError(formik, keyField)
  }
  </div>
 );
};

export default SelectFiled;
