import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useSurveyQuery({ onSuccess, orgID }) { 
    const fetch = () => {
        return axios.get(orgID == 'All' ? "/questionnaire?purpose=survey" : `questionnaire?purpose=survey&orgId=${orgID}`);
    }

    const query = useQuery(
        "questionnaires-list",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
